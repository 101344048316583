import { Component, inject, Inject, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedClientService, WithPermissions } from '@vdms-hq/activated-client';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogResponse } from '@vdms-hq/shared';
import { CollectionsActionsService } from '../../logic/services/collections-actions.service';
import { Subject, takeUntil } from 'rxjs';
import { MatPaginatorModule } from '@angular/material/paginator';
import { TranslateModule } from '@ngx-translate/core';
import {
  TileSelectableConfig,
  UiAddDialogSelectableTilesComponent,
  UIButtonModule,
  UIDialogWrapperModule,
  UIEmptyResultsModule,
  UIFormModule,
  UILoaderModule,
} from '@vdms-hq/ui';
import { CollectionsAddData, CollectionsAddDataSource } from '../../logic/datasources/collections-add-data-source';
import { CollectionModelFlat, CollectionsService } from '@vdms-hq/api-contract';
import { DynamicFilterInput, DynamicFiltersModule } from '@vdms-hq/dynamic-filters';
import { defaultFilters } from '../../pages/collections/config';
import { STATUS_OPTIONS } from '../../logic/utils/collections-filters-provider';
import { MatDividerModule } from '@angular/material/divider';

export interface AddToCollectionResponse {
  status: DialogResponse;
  selected: string[];
}

export interface AddToCollectionInput {
  collection?: CollectionModelFlat;
  selectedIds?: string[];
  collectionUuid?: string;
}

@Component({
  selector: 'vdms-hq-collection-add-dialog',
  templateUrl: './collection-add.component.html',
  styles: [
    '.form-content { display: flex; flex-direction: column; gap: 16px; padding-right: 1rem;} mat-divider { margin-bottom: 8px;}',
  ],
  standalone: true,
  imports: [
    CommonModule,
    MatPaginatorModule,
    ReactiveFormsModule,
    TranslateModule,
    UIButtonModule,
    UIDialogWrapperModule,
    UIFormModule,
    UILoaderModule,
    DynamicFiltersModule,
    UIEmptyResultsModule,
    MatDividerModule,
    UiAddDialogSelectableTilesComponent,
  ],
})
export class CollectionAddDialogComponent extends WithPermissions() implements OnInit, OnDestroy {
  public dialogRef = inject(MatDialogRef<CollectionAddDialogComponent>);
  public collectionsActions = inject(CollectionsActionsService);
  private collectionsService = inject(CollectionsService);
  public dataSource = inject(CollectionsAddDataSource);
  private activatedClientService = inject(ActivatedClientService);

  form = new FormGroup({
    selected: new FormControl<string[]>([], { nonNullable: true }),
  });

  filtersConfig: DynamicFilterInput[] = [];

  selectedControl = this.form.get('selected') as FormControl<string[]>;

  private destroyed$: Subject<void> = new Subject<void>();

  collectionsTilesConfig: TileSelectableConfig<CollectionsAddData> = {
    label: 'label',
    key: 'key',
    disabledIf: {
      condition: (item: CollectionsAddData) => item?.access_type === 'dashboard',
      label: 'common.collection_not_available',
    },
    columns: '2-columns',
    metadata: [
      {
        label: 'Owner',
        valuePath: 'owner',
        fallback: 'Unknown',
        singleLine: true,
        hiddenIf: (item) => item.type === 'owned',
      },
      {
        label: 'Number of assets',
        valuePath: 'number_of_assets',
        singleLine: true,
        viewFormat: {
          modifiers: {
            asNumberWithZero: true,
          },
        },
      },
      {
        label: 'Description',
        valuePath: 'description',
        singleLine: true,
        viewFormat: {
          modifiers: {
            truncateCharacters: 140,
          },
        },
      },
    ],
  };

  constructor(@Inject(MAT_DIALOG_DATA) public data: AddToCollectionInput) {
    super();
    this.activatedClientService.permissions$.pipe(takeUntil(this.destroyed$)).subscribe((permissions) => {
      this.dataSource.filters.controls['keyword'].reset();
      this.filtersConfig = defaultFilters;
      if (!permissions.includes(this.Permission.BROWSE_SHARED_COLLECTIONS)) {
        this.filtersConfig = this.filtersConfig.map((f) => {
          if (f.id == 'collectionType') {
            return {
              ...f,
              selectOptions: STATUS_OPTIONS.filter((opt) => {
                return !['all', 'shared'].includes(opt.key);
              }),
            };
          }
          return f;
        });
      }
    });
    if (this.data?.collectionUuid && !this.data?.selectedIds) {
      this.dataSource.isLoading$.next(true);
      this.collectionsService.getCollectionData(this.data.collectionUuid).subscribe((collection) => {
        this.selectedControl.setValue(collection.parent_uuids ?? []);
        this.dataSource.isLoading$.next(false);
      });
    } else {
      this.selectedControl.setValue(this.data?.selectedIds ?? []);
    }
  }

  ngOnInit() {
    this.dataSource.isLoading$.next(true);
    this.activatedClientService.permissions$.pipe(takeUntil(this.destroyed$)).subscribe((permissions) => {
      this.dataSource.filters.controls['keyword'].reset();
      this.filtersConfig = defaultFilters;
      if (!permissions.includes(this.Permission.BROWSE_SHARED_COLLECTIONS)) {
        this.filtersConfig = this.filtersConfig.map((f) => {
          if (f.id == 'collectionType') {
            return {
              ...f,
              selectOptions: STATUS_OPTIONS.filter((opt) => {
                return !['all', 'shared'].includes(opt.key);
              }),
            };
          }
          return f;
        });
      }
    });
    if (this.data?.collectionUuid && !this.data?.selectedIds) {
      this.dataSource.isLoading$.next(true);
      this.collectionsService.getCollectionData(this.data.collectionUuid).subscribe((collection) => {
        this.selectedControl.setValue(collection.parent_uuids ?? []);
        this.dataSource.isLoading$.next(false);
      });
    } else {
      this.selectedControl.setValue(this.data?.selectedIds ?? []);
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  selectCollection(item: CollectionsAddData) {
    const selected = this.selectedControl.value;
    if (selected.includes(item.key)) {
      this.selectedControl.setValue(selected.filter((id) => id !== item.key));
    } else {
      this.selectedControl.setValue([...selected, item.key]);
    }
  }

  close() {
    this.dataSource.refresh();
    this.dialogRef.close();
  }

  onSubmit() {
    const selectedCollections = this.selectedControl.value.filter((id) => !this.data?.selectedIds?.includes(id));

    this.dataSource.refresh();
    this.dialogRef.close({
      status: DialogResponse.OK,
      selected: selectedCollections,
    });
  }

  createNewCollection() {
    this.collectionsActions.openCreateDialog(this.dataSource.filters.controls.keyword.value, true);
  }
}
