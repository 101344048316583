import { Injectable } from '@angular/core';
import { ToastService } from '@vdms-hq/toast';
import { CollectionsService, CollectionViewModel } from '@vdms-hq/api-contract';
import {
  BehaviorSubject,
  catchError,
  combineLatest,
  EMPTY,
  Observable,
  switchMap,
  shareReplay,
  take,
  throwError,
  of,
  debounceTime,
} from 'rxjs';
import { map, tap, withLatestFrom } from 'rxjs/operators';
import { SingleCollectionResolverService } from './single-collection-resolver.service';
import { AuthService } from '@vdms-hq/auth';
import { CollectionMetadataTransformerSnippet } from '../utils/collection.metadata-transformer';
import { CollectionsRefresh } from '../utils/collections-refresh';
import { DestroyComponent } from '@vdms-hq/shared';
import { AssetActionsService } from '@vdms-hq/asset-results';
import { HttpErrorResponse } from '@angular/common/http';
import { ClientAccessChecker } from '@vdms-hq/clients';

@Injectable({ providedIn: 'root' })
export class SingleCollectionService extends DestroyComponent {
  isLoading$ = new BehaviorSubject<boolean>(true);

  uuid$ = this.collectionIdParamResolver.currentIdDefinite$;
  userAuth$ = this.authService.authDefinite$;
  refresh$ = this.collectionsRefresher.refreshAssets$;
  total$ = new BehaviorSubject<number>(0);

  collectionData$: Observable<CollectionViewModel> = combineLatest([
    this.uuid$,
    this.refresh$,
    this.assetActions.finishBatchUpdate$,
  ]).pipe(
    this.takeUntilDestroyed(),
    withLatestFrom(this.userAuth$),
    tap(() => this.isLoading$.next(true)),
    switchMap(([[uuid], userData]) => {
      const userId = userData.id;
      return this.collectionsService.getCollectionData(uuid).pipe(
        map((data) => this.#collectionToViewModel(data, userId)),
        map((data: CollectionViewModel) => {
          this.total$.next(data.number_of_assets);
          data.children = data.children.map((child) => this.#collectionToViewModel(child, <string>userId));
          return data;
        }),
        tap(() => this.isLoading$.next(false)),
        catchError((err) => {
          if (err.error?.error === 'Invalid group') {
            return this.clientAccessChecker.checkAccess({ clientId: err.error.data }).pipe(
              tap(() => this.refresh$.next(!this.refresh$.value)),
              switchMap(() => EMPTY),
            );
          }

          this.toastService.error({ id: 'collectionError', message: 'common.collection_get_error' });
          throw err;
        }),
      );
    }),
    shareReplay(1),
  );

  subAllData$ = combineLatest([this.refresh$, this.collectionData$]).pipe(
    map(([, collectionData]) => {
      this.isLoading$.next(false);
      return collectionData.children;
    }),
  );

  subCollectionsOnlyView$ = combineLatest([this.total$, this.subAllData$]).pipe(
    map(([assets, subCollections]) => {
      const assetsEmpty = !assets;
      const subCollectionsEmpty = !subCollections.length;

      return assetsEmpty && !subCollectionsEmpty;
    }),
    this.takeUntilDestroyed(),
  );

  constructor(
    private collectionIdParamResolver: SingleCollectionResolverService,
    private collectionsService: CollectionsService,
    private authService: AuthService,
    private toastService: ToastService,
    private collectionsRefresher: CollectionsRefresh,
    private metadataTransformer: CollectionMetadataTransformerSnippet,
    private assetActions: AssetActionsService,
    private clientAccessChecker: ClientAccessChecker,
  ) {
    super();
  }

  #collectionToViewModel(collection: any, userId: string): any {
    return {
      ...collection,
      ...this.metadataTransformer.transformCollectionWithMetadata(collection, userId),
    };
  }
}
