import { Injectable } from '@angular/core';
import { AdvancedPlayerService } from '../advanced-player.service';
import {
  Analysis,
  AudioChannelLane,
  AudioGroupingLane,
  AudioMediaTrack,
  BaseGroupingLane,
  Channel,
  Constants,
  CustomAudioTrackLane,
  CustomGroupingLine,
  CustomSubtitlesLane,
  DomainUtil,
  StringUtil,
  TelemetryMarkerLane,
  TextTrackGroupingLane,
  TimelineService as OmakaseTimelineService,
  VideoGroupingLane,
  VideoMediaTrack,
} from '@vdms-hq/omakase-player';
import { BehaviorSubject, filter, Observable, startWith, Subject, take, takeUntil } from 'rxjs';
import {
  AudioTrackLane,
  ImageButtonImageConfig,
  MarkerLane,
  MomentMarker,
  PeriodMarker,
  PeriodMarkerChangeEvent,
  SubtitlesVttTrack,
  ThumbnailLane,
  TimelineApi,
} from '@byomakase/omakase-player';
import {
  AUDIOTRACK_LINE_STYLES,
  CUSTOM_LINE_STYLES,
  CUSTOM_MARKER_MOMENT_STYLES,
  CUSTOM_MARKER_PERIOD_STYLES,
  THUMBNAIL_LINE_STYLES,
  TimelineRow,
} from '../advanced-player';
import { TimecodesService } from '../timecodes.service';
import { ToastService } from '@vdms-hq/toast';
import { Timecode } from '@vdms-hq/timecode';
import { TimelineScrubberService } from '../timeline-manifest-source/timeline-scrubber.service';
import { TimelineService } from '../timeline.service';
import { PlayerSessionService } from '../player-session.service';
import { MediaPlaylist } from 'hls.js';
import { withLatestFrom } from 'rxjs/operators';
import { Subtitles } from '../player.interface';

export interface GroupingLine {
  type: 'normal' | 'subtitle';
  groupingName: string;
  button?: {
    icon: ImageButtonImageConfig;
    onClick: () => void;
  };
  index?: number;
  lanes: Analysis[];
}

@Injectable({
  providedIn: 'root',
})
export class TimelineMixedSourceService implements TimelineService {
  readonly #PLAYER_IDS = {
    inOutMarker: 'marker_lane_inout_1',
  };
  customRowClickedEmitter$ = new Subject();
  abortListener$ = new Subject<void>();
  #customRows: TimelineRow[] = [];

  get #omakasePlayerApi() {
    return this.playerService.player;
  }

  get #timeline() {
    return this.#omakasePlayerApi?.timeline;
  }

  #videoPreviousTime: number | undefined;

  lanes$ = new BehaviorSubject<GroupingLine[] | null>([]);

  #groupingLanes?: BaseGroupingLane<any>[] = [];

  constructor(
    private playerService: AdvancedPlayerService,
    private timecodesService: TimecodesService,
    private toast: ToastService,
    private timelineScrubberService: TimelineScrubberService,
    private omakaseTimeline: OmakaseTimelineService,
    private playerSessionService: PlayerSessionService,
  ) {}

  load(timelineApi: TimelineApi, useRasp = false) {
    if (useRasp) {
      this.playerService.currentQualityPlayerV2$
        .pipe(startWith('-1'), takeUntil(this.abortListener$), filter(Boolean))
        .subscribe((qualityId) => {
          if (qualityId.toString() === '-1') {
            this.loadManifest();
          } else {
            this.loadManifest(qualityId);
          }
        });

      this.playerService.setVideoQualities(
        this.playerSessionService.masterManifests?.map((manifest) => ({ key: manifest.id, label: manifest.name })),
      );
    } else {
      this.playerService.state$
        .pipe(
          filter((player) => player.state === 'ready'),
          take(1),
        )
        .subscribe(() => {
          if (!this.playerService.player) {
            this.#handleError('Missing video player');
            return;
          }

          if (!this.#timeline) {
            return;
          }
          this.cleanTimeline();
          this.#initInOutMarkerLine();

          this.#initThumbnails(this.#timeline);
          this.#initWaveForm(this.#timeline);

          this.lanes$.pipe(takeUntil(this.abortListener$)).subscribe((lanes) => {
            this.#processGroupingLanes(lanes ?? []);
            this.addCustomRows([]);
          });
        });
    }

    this.timelineScrubberService.processScrubberLane(this.playerService.player);

    this.timecodesService.timecodes$
      .pipe(takeUntil(this.abortListener$), withLatestFrom(this.playerService.offset$))
      .subscribe(([[tcIn, tcOut], offset]) => {
        if (!tcIn) {
          return;
        }

        this.#updateMarker(tcIn, tcOut ?? undefined, offset ?? undefined);
      });

    this.playerService.player?.timeline?.onTimecodeClick$.subscribe((event) => {
      this.playerService.player?.video.seekToTimecode(event.timecode).subscribe();
    });
  }

  unload() {
    this.abortListener$.next();
    this.abortListener$.complete();
    this.#customRows = [];
    this.playerSessionService.currentMasterManifest = undefined;
    this.playerSessionService.masterManifests = [];
  }

  loadManifest(manifestId: string | undefined = undefined) {
    // todo below lines, by default load latest manifest (with worst quality // tmp for ibc
    const defaultIndex = (this.playerSessionService.masterManifests?.length ?? 1) - 1;

    this.playerSessionService.currentMasterManifest = manifestId
      ? this.playerSessionService.masterManifests?.find((p) => p.id === manifestId)
      : this.playerSessionService.masterManifests?.[defaultIndex];

    console.log('Manifest selected: ', this.playerSessionService.currentMasterManifest);

    if (!this.playerSessionService.currentMasterManifest) {
      throw new Error(`Could not select master manifest with id: ${manifestId}`);
    }

    const matchesManifest = (obj: { manifest_ids: string[] }): boolean => {
      return !!obj.manifest_ids.find((p) => p === this.playerSessionService.currentMasterManifest?.id);
    };

    this.playerSessionService.videoMediaTracks = this.playerSessionService.sessionData?.data.media_tracks.video
      ? this.playerSessionService.sessionData?.data.media_tracks.video.filter((p) => matchesManifest(p))
      : void 0;

    this.playerSessionService.audioMediaTracks = this.playerSessionService.sessionData?.data.media_tracks.audio;
    this.playerSessionService.textMediaTracks = this.playerSessionService.sessionData?.data.media_tracks.text;

    if (this.#omakasePlayerApi?.video.isVideoLoaded()) {
      this.#videoPreviousTime = this.#omakasePlayerApi?.video.getCurrentTime();

      if (manifestId) {
        this.playerService
          .loadRaspVideo()
          .pipe(take(1))
          .subscribe({
            next: () => {
              if (this.#videoPreviousTime !== void 0) {
                this.#omakasePlayerApi?.video.seekToTime(this.#videoPreviousTime).subscribe({
                  error: (err) => {
                    console.error(err);
                  },
                });
              }
            },
          });
      }
    }

    const cacheCustomRows = this.#customRows;
    this.cleanTimeline();
    this.#processVideoMediaTracks();
    this.#processAudioMediaTracks();
    this.#initInOutMarkerLine();

    this.lanes$.pipe(takeUntil(this.abortListener$)).subscribe((lanes) => {
      this.#processGroupingLanes(lanes ?? []);
      this.addCustomRows(cacheCustomRows);
    });
  }

  addCustomRows(customRows: TimelineRow[]) {
    this.#customRows = [...this.#customRows, ...customRows];
    if (!this.#timeline) {
      return;
    }

    customRows?.forEach((customRow, index) => {
      const customRowExist = this.#timeline?.getTimelineLane(customRow.id);
      if (customRowExist) {
        return;
      }

      const markerLine = new TelemetryMarkerLane({
        id: customRow.id,
        description: customRow.label,
        style: CUSTOM_LINE_STYLES,
      });

      this.#timeline?.addTimelineLane(markerLine);

      customRow.values.forEach((markerValue, innerIndex) => {
        const periodMarker = markerValue.tcOut
          ? new PeriodMarker({
              id: customRow.id + `_period_marker${innerIndex}`,
              text: markerValue.tooltip,
              timeObservation: {
                start: markerValue.tcIn,
                end: markerValue.tcOut,
              },
              editable: false,
              style: {
                ...CUSTOM_MARKER_PERIOD_STYLES,
                color: markerValue.color,
              },
            })
          : new MomentMarker({
              id: customRow.id + `_period_marker${innerIndex}`,
              text: markerValue.tooltip,
              timeObservation: {
                time: markerValue.tcIn,
              },
              editable: false,
              style: {
                ...CUSTOM_MARKER_MOMENT_STYLES,
                color: markerValue.color,
              },
            });

        periodMarker.onClick$.subscribe(() => this.customRowClickedEmitter$.next(markerValue));
        markerLine.addMarker(periodMarker);
      });
    });
  }

  hasCustomRow(id: string) {
    return !!this.#timeline?.getTimelineLane(id);
  }

  removeCustomRow(id: string) {
    this.#timeline?.removeTimelineLane(id);

    const withoutDeletedRow = this.#customRows.filter((row) => row?.id !== id);
    this.#customRows = withoutDeletedRow;
  }

  #initThumbnails(timeline: TimelineApi) {
    const thumbnails = this.playerService.config?.thumbnailsVttUrl;

    if (thumbnails) {
      const defaultThumbnailLane = new ThumbnailLane({
        axiosConfig: {},
        // description: 'Thumbnails',
        vttUrl: thumbnails,
        style: THUMBNAIL_LINE_STYLES,
      });
      timeline.addTimelineLane(defaultThumbnailLane);
    }
  }

  #initInOutMarkerLine() {
    const timelineExist = this.#timeline?.getTimelineLane(this.#PLAYER_IDS.inOutMarker);
    if (timelineExist) {
      this.#timeline?.removeTimelineLane(this.#PLAYER_IDS.inOutMarker);
    }

    const inAndOutMarkersLane = new TelemetryMarkerLane({
      id: this.#PLAYER_IDS.inOutMarker,
      description: 'In and out',
      style: {
        ...Constants.MARKER_LANE_STYLE,
      },
    });

    this.#timeline?.addTimelineLane(inAndOutMarkersLane);
  }

  #initWaveForm(timeline: TimelineApi) {
    const waveforms = this.playerService.config?.waveformsVtt;

    waveforms?.forEach((waveform, k) => {
      const defaultThumbnailLane = new AudioTrackLane({
        vttUrl: waveform.url,
        // description: waveform.name,
        style: AUDIOTRACK_LINE_STYLES,
      });
      timeline.addTimelineLane(defaultThumbnailLane);
    });
  }

  #updateMarker(tcIn: Timecode, tcOut?: Timecode, offset?: Timecode) {
    const inAndOutMarkersLane = this.#timeline?.getTimelineLane('marker_lane_inout_1') as MarkerLane;

    if (!inAndOutMarkersLane) {
      return;
    }
    inAndOutMarkersLane.clearContent();

    const newPeriodMarker = new PeriodMarker({
      id: 'periodMarker1',
      timeObservation: {
        start: tcIn?.countSeconds(),
        end: tcOut?.countSeconds() ?? tcIn.countSeconds(),
      },
      editable: true,
      style: Constants.IN_OUT_MARKER_STYLE,
    });

    newPeriodMarker.onChange$.pipe().subscribe(
      ((originalTcIn, originalTcOut) => (event: PeriodMarkerChangeEvent) => {
        const tcIn = Timecode.fromSeconds(event.timeObservation.start, originalTcIn?.getFps());
        const tcOut = Timecode.fromSeconds(event.timeObservation.end, originalTcOut?.getFps());

        tcIn && this.timecodesService.setTcIn(tcIn);
        tcOut && this.timecodesService.setTcOut(tcOut);
      })(tcIn, tcOut),
    );

    inAndOutMarkersLane.addMarker(newPeriodMarker);
  }

  #handleError(message: string) {
    this.toast.error({ id: 'error', message });
  }

  #processVideoMediaTracks() {
    const timeline = this.#omakasePlayerApi?.timeline;

    if (this.playerSessionService.videoMediaTracks && this.playerSessionService.videoMediaTracks.length > 0) {
      this.playerSessionService.videoMediaTracks.forEach((videoMediaTrack, index) => {
        const groupingLane = this.#createVideoGroupingLane(videoMediaTrack, index);
        timeline?.addTimelineLane(groupingLane);
        this.#groupingLanes?.push(groupingLane);

        if (videoMediaTrack.visual_reference && videoMediaTrack.visual_reference.length > 0) {
          videoMediaTrack.visual_reference.forEach((visualReference) => {
            const lane = this.omakaseTimeline.createLaneByVisualReference(visualReference);
            timeline?.addTimelineLane(lane);
            groupingLane.addChildLane(lane);
          });
        }

        if (videoMediaTrack.analysis && videoMediaTrack.analysis.length > 0) {
          const analysisLanes = this.omakaseTimeline.createAnalysisLanes(videoMediaTrack.analysis);
          analysisLanes.forEach((lane) => {
            timeline?.addTimelineLane(lane);
            groupingLane.addChildLane(lane);
          });
        }
      });
    }
  }

  #processAudioMediaTracks() {
    const timeline = this.#omakasePlayerApi?.timeline;
    if (this.playerSessionService.audioMediaTracks && this.playerSessionService.audioMediaTracks.length > 0) {
      let defaultTrackSet = false;

      this.playerSessionService.audioMediaTracks.forEach((audioMediaTrack, index) => {
        const audioGroupingLane = this.#createAudioGroupingLane(audioMediaTrack, index);
        timeline?.addTimelineLane(audioGroupingLane);
        this.#groupingLanes?.push(audioGroupingLane);

        if (!defaultTrackSet && !audioGroupingLane.isDisabled) {
          audioGroupingLane.setAudioTrack(false);
          defaultTrackSet = true;
        }

        const channelsInOrder = DomainUtil.resolveAudioMediaTrackChannelsInOrder(audioMediaTrack);
        const isAudioChannelLane = () => {
          return channelsInOrder && channelsInOrder.length > 0;
        };

        const isCustomAudioTrackLane = () => {
          return (
            (!channelsInOrder || channelsInOrder.length === 0) &&
            audioMediaTrack.visual_reference &&
            audioMediaTrack.visual_reference.find((p) => p.type === 'waveform')
          );
        };

        if (isAudioChannelLane()) {
          channelsInOrder?.forEach((channel, index) => {
            const audioChannelLane = this.#createAudioChannelLane(
              audioMediaTrack,
              channel,
              index,
              channelsInOrder?.length,
            );
            timeline?.addTimelineLane(audioChannelLane);
            audioGroupingLane.addChildLane(audioChannelLane);

            if (!defaultTrackSet && !audioChannelLane.isDisabled) {
              audioChannelLane.setAudioTrack(false);
              defaultTrackSet = true;
            }
          });
        } else if (isCustomAudioTrackLane()) {
          const customAudioTrackLane = this.#createCustomAudioTrackLane(audioMediaTrack);
          timeline?.addTimelineLane(customAudioTrackLane);
          audioGroupingLane.addChildLane(customAudioTrackLane);
        }

        if (audioMediaTrack.analysis && audioMediaTrack.analysis.length > 0) {
          const analysisLanes = this.omakaseTimeline.createAnalysisLanes(audioMediaTrack.analysis);
          analysisLanes.forEach((lane) => {
            timeline?.addTimelineLane(lane);
            audioGroupingLane.addChildLane(lane);
          });
        }
      });
    }
  }

  #processGroupingLanes(groups: GroupingLine[]) {
    for (const groupCfg of groups) {
      let groupingLane: BaseGroupingLane<any>;

      if (groupCfg.type === 'subtitle') {
        const corespondingSubtitle = groupCfg.lanes[0];

        groupingLane = this.#createTextTrackGroupingLane(
          {
            language: corespondingSubtitle.name,
            path: corespondingSubtitle.url,
          },
          groupCfg.index ?? 0,
        );
      } else {
        groupingLane = this.#createCustomGroupingLane(groupCfg.groupingName, groupCfg.button);
      }

      this.#timeline?.addTimelineLane(groupingLane);

      for (const laneCfg of groupCfg.lanes) {
        const isMarker = laneCfg.visualization === 'point' || laneCfg.visualization === 'marker';

        const lanes = isMarker
          ? [this.omakaseTimeline.createMarkerLane(laneCfg)]
          : this.omakaseTimeline.createAnalysisLanes([laneCfg]);

        lanes.forEach((lane) => {
          this.#timeline?.addTimelineLane(lane);
          groupingLane.addChildLane(lane);
        });
      }
    }
  }

  #createVideoGroupingLane(videoMediaTrack: VideoMediaTrack, index: number): VideoGroupingLane {
    const description = `V${index + 1}`;

    const lane = new VideoGroupingLane({
      description: description,
      text: videoMediaTrack.name,
      videoMediaTrack: videoMediaTrack,
      style: {
        ...Constants.LABEL_LANE_STYLE,
        // DP-CORE-TIME-4
        // TODO Since player is currently limited to a single video track, if a video track is presented it will be active. Logic will need to change in the future when support for multiple video tracks is added
        // ...Constants.LABEL_LANE_STYLE_ACTIVE,
      },
    });

    return lane;
  }

  #createCustomGroupingLane(description: string, button?: GroupingLine['button']): CustomGroupingLine {
    const grouping = new CustomGroupingLine({
      description: description,
      text: '',
      button,
    });

    if (grouping.customButton) {
      grouping.customButton.onClick$.subscribe(button?.onClick);
    }

    return grouping;
  }

  #createAudioGroupingLane(audioMediaTrack: AudioMediaTrack, index: number): AudioGroupingLane {
    const description = `A${index + 1}${
      audioMediaTrack.channels && audioMediaTrack.channels.length > 0 ? ` (${audioMediaTrack.channels.length} ch)` : ``
    }`;

    const lane = new AudioGroupingLane({
      description: description,
      text: audioMediaTrack.name,
      audioMediaTrack: audioMediaTrack,
    });

    this.playerService.manifest.onHlsMediaPlaylistsLoaded$.pipe(take(1)).subscribe({
      next: () => {
        lane.mediaPlaylistAudioTrack = StringUtil.isNonEmpty(audioMediaTrack.name)
          ? this.playerService.manifest.hlsMediaPlaylistsByName?.get(audioMediaTrack.name)
          : undefined;
      },
    });

    return lane;
  }

  #createTextTrackGroupingLane(subtitle: Subtitles, index: number): TextTrackGroupingLane {
    const textTrackUsageLabel = DomainUtil.resolveTextTrackUsageLabel(subtitle);

    const description = `T${index + 1}${textTrackUsageLabel ? ` (${textTrackUsageLabel})` : ``}`;

    const url = new URL(subtitle.path);

    const textTrackGroupingLane = new TextTrackGroupingLane({
      description: description,
      text: url.pathname.replace('/subtitles/', ''),
      onSubtitleChange$: this.playerService.currentSubtitle$.asObservable(),
      activateSubtitle: () => {
        this.playerService.handleAction('changeSubtitles', {
          language: subtitle.language,
          path: subtitle.path,
        });
      },
      subtitle: subtitle,
    });

    return textTrackGroupingLane;
  }

  #createSubtitlesLane(subtitlesVttTrack: SubtitlesVttTrack): CustomSubtitlesLane {
    const lane = new CustomSubtitlesLane({
      subtitlesVttTrack: subtitlesVttTrack,
      description: ``,
      style: {
        ...Constants.SUBTITLES_LANE_STYLE,
      },
    });

    return lane;
  }

  #createAudioChannelLane(
    audioMediaTrack: AudioMediaTrack,
    channel: Channel,
    channelIndex: number,
    channelsCount: number,
  ): AudioChannelLane {
    const lane = new AudioChannelLane({
      channel: channel,
      channelIndex: channelIndex,
      channelsCount: channelsCount,
      style: {
        ...Constants.CUSTOM_AUDIO_TRACK_LANE_STYLE,
      },
    });

    this.playerService.manifest.onHlsMediaPlaylistsLoaded$.pipe(take(1)).subscribe({
      next: () => {
        lane.mediaPlaylistAudioTrack = StringUtil.isNonEmpty(audioMediaTrack.name)
          ? this.playerService.manifest.hlsMediaPlaylistsByName?.get(audioMediaTrack.name)
          : undefined;
        lane.channelMediaPlaylistAudioTrack = StringUtil.isNonEmpty(channel.program_name)
          ? this.playerService.manifest.hlsMediaPlaylistsByName?.get(channel.program_name)
          : undefined;
      },
    });

    return lane;
  }

  #createCustomAudioTrackLane(audioMediaTrack: AudioMediaTrack): CustomAudioTrackLane {
    return new CustomAudioTrackLane({
      audioMediaTrack: audioMediaTrack,
      style: {
        ...Constants.CUSTOM_AUDIO_TRACK_LANE_STYLE,
      },
    });
  }

  cleanTimeline() {
    this.#timeline?.removeTimelineLanes(this.#timeline?.getTimelineLanes().map((p) => p.id));
    this.#customRows = [];
  }
}
