import { Component, inject } from '@angular/core';
import {
  InputMultipleComponent,
  MultipleDataPresentationComponent,
  MultipleViewConfiguration,
  ResultsWrapperViewConfiguration,
  UIConfirmationDialogService,
  UIFormModule,
  UILoaderModule,
  UIResultsWrapperModule,
} from '@vdms-hq/ui';
import { QualityControlConfig, QualityControlService } from '@vdms-hq/api-contract';
import { AsyncPipe, CommonModule, NgIf } from '@angular/common';
import { QualityControlDatasource } from '../logic/quality-control.datasource.service';
import { MatDialog } from '@angular/material/dialog';
import { AddEditQCConfigDialogComponent } from '../components/qc-config-add-edit-dialog/qc-config-add-edit-dialog.component';
import { delay, map, Observable, combineLatest, switchMap } from 'rxjs';
import { filterEmpty } from '@vdms-hq/shared';
import { filter, tap } from 'rxjs/operators';
import { Permission, PermissionService } from '@vdms-hq/activated-client';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'vdms-hq-quality-control-page',
  standalone: true,
  imports: [
    CommonModule,
    UIFormModule,
    UIResultsWrapperModule,
    AsyncPipe,
    MultipleDataPresentationComponent,
    NgIf,
    UILoaderModule,
    InputMultipleComponent,
    TranslateModule,
  ],
  providers: [],
  templateUrl: './quality-control-page.component.html',
})
export class QualityControlPageComponent {
  #permissionsService = inject(PermissionService);
  confirmationDialog = inject(UIConfirmationDialogService);
  configuration$: Observable<MultipleViewConfiguration<QualityControlConfig>> = combineLatest([
    this.#permissionsService.verifyWithOwnedPermissions$([Permission.CREATE_QA_CONFIGS]),
    this.#permissionsService.verifyWithOwnedPermissions$([Permission.EDIT_QA_CONFIGS]),
    this.#permissionsService.verifyWithOwnedPermissions$([Permission.DELETE_QA_CONFIGS]),
  ]).pipe(
    map(([canCreate, canEdit, canDelete]) => ({
      multiView: {
        defaultView: 'tableAdvanced',
        fitToVisibleArea: false,
        emptyResults: {
          message: 'Could not find any configs',
        },
      },
      tableAdvanced: {
        layout: {
          actionsSpace: true,
        },
        footer: {},
        actions: [
          {
            key: 'edit',
            icon: 'edit',
            onDoubleClick: false,
            label: 'common.global.edit',
            hiddenIf: () => !canEdit,
          },
          {
            key: 'delete',
            icon: 'delete',
            onDoubleClick: false,
            label: 'common.global.delete',
            hiddenIf: () => !canDelete,
          },
          {
            key: 'duplicate',
            icon: 'content_copy',
            onDoubleClick: false,
            label: 'Copy values and create new config',
            hiddenIf: () => !canCreate,
          },
        ],
        columns: [
          {
            id: 'select',
            type: 'select',
          },
          {
            id: 'asset_type',
            label: 'Asset Type',
            valuePath: 'asset_type',
          },
          {
            id: 'file_extension',
            label: 'File exteions',
            valuePath: 'file_extension',
          },
          {
            id: 'timecode_start',
            label: 'Timecode start',
            valuePath: 'timecode_start',
          },
          {
            id: 'dropped_frames',
            label: 'Dropped frames',
            valuePath: 'dropped_frames',
            viewFormat: {
              pills: {},
            },
          },
          {
            id: 'video_codec',
            label: 'Video codec',
            valuePath: 'video_codec',
          },
          {
            id: 'bit_depth',
            label: 'Bit depth',
            valuePath: 'bit_depth',
          },
          {
            id: 'chroma_format',
            label: 'Chroma format',
            valuePath: 'chroma_format',
          },
          {
            id: 'framerate',
            label: 'Framerate',
            valuePath: 'framerate',
          },
          {
            id: 'interlace_mode',
            label: 'Interlace mode',
            valuePath: 'interlace_mode',
          },
          {
            id: 'width',
            label: 'Width',
            valuePath: 'width',
          },
          {
            id: 'height',
            label: 'Height',
            valuePath: 'height',
          },
          {
            id: 'audio_codec',
            label: 'Audio codec',
            valuePath: 'audio_codec',
          },
          {
            id: 'channel_count',
            label: 'Channel count',
            valuePath: 'channel_count',
          },
          {
            id: 'channels_per_track',
            label: 'Channels per track',
            valuePath: 'channels_per_track',
          },
          {
            id: 'audio_sample_rate',
            label: 'Audio sample rate',
            valuePath: 'audio_sample_rate',
          },
          {
            id: 'audio_bit_depth',
            label: 'Audio bit depth',
            valuePath: 'audio_bit_depth',
          },
          {
            id: 'actions',
            type: 'actions',
          },
        ],
        columnsEnabled: [
          'asset_type',
          'file_extension',
          'timecode_start',
          'dropped_frames',
          'video_codec',
          'bit_depth',
          'chroma_format',
          'framerate',
          'interlace_mode',
          'width',
          'height',
          'audio_codec',
          'channel_count',
          'channels_per_track',
          'audio_sample_rate',
          'audio_bit_depth',
          'actions',
        ],
      },
    })),
  );

  wrapperConfiguration$: Observable<ResultsWrapperViewConfiguration | undefined> = this.#permissionsService
    .verifyWithOwnedPermissions$([Permission.CREATE_QA_CONFIGS])
    .pipe(
      map((canCreate: boolean) => {
        if (canCreate) {
          return {
            headerActions: [
              {
                key: 'new',
                name: 'common.quality_control.add_quality_config',
                color: 'primary',
                disabled: false,
              },
            ],
            withLoading: true,
          };
        }

        return undefined;
      }),
    );

  handleAction($event: { key: string; item?: QualityControlConfig }) {
    if ($event.key === 'new') {
      return this.matDialog
        .open(AddEditQCConfigDialogComponent)
        .afterClosed()
        .pipe(
          filterEmpty(),
          tap(() => {
            this.dataSource.isLoading$.next(true);
          }),
          switchMap((data) => this.qaService.postQAConfig(data)),
          delay(1500),
        )
        .subscribe(() => {
          this.dataSource.refresh();
        });
    }

    const uuid = $event.item?.uuid;
    if (!uuid) {
      return;
    }

    if ($event.key === 'edit') {
      return this.matDialog
        .open(AddEditQCConfigDialogComponent, {
          data: { config: $event.item, isEdit: true, isDuplicate: false },
        })
        .afterClosed()
        .pipe(
          filterEmpty(),
          tap(() => {
            this.dataSource.isLoading$.next(true);
          }),
          switchMap((data) => this.qaService.patchQAConfig(data, uuid)),
          delay(1500),
        )
        .subscribe(() => {
          this.dataSource.refresh();
        });
    }

    if ($event.key === 'delete') {
      this.confirmationDialog
        .openDelete()
        .pipe(
          filter(Boolean),
          tap(() => this.dataSource.isLoading$.next(true)),
          switchMap(() => {
            return this.qaService.deleteQAConfig(uuid).pipe(
              tap(() => {
                this.dataSource.refresh();
              }),
            );
          }),
        )
        .subscribe();
    }

    if ($event.key === 'duplicate') {
      return this.matDialog
        .open(AddEditQCConfigDialogComponent, {
          data: { config: $event.item, isEdit: false, isDuplicate: true },
        })
        .afterClosed()
        .pipe(
          filterEmpty(),
          tap(() => {
            this.dataSource.isLoading$.next(true);
          }),
          switchMap((data) => this.qaService.postQAConfig(data)),
          delay(1500),
        )
        .subscribe(() => {
          this.dataSource.refresh();
        });
    }

    return;
  }

  constructor(
    public dataSource: QualityControlDatasource,
    private matDialog: MatDialog,
    private qaService: QualityControlService,
  ) {}
}
