<div class="timecode-overlay">
  <div class="header">
    <div *ngIf="title" class="title">{{ title }}</div>
    <div *ngIf="subtitle" class="subtitle">{{ subtitle }}</div>
  </div>
  <div class="content">
    <vdms-hq-form-input-timecode
      #timecodeInput
      [(ngModel)]="timecode"
      (keyup)="keyUp($event)"
      [framerate]="framerate"
      [autofocus]="true"
      [mask]="mask"
    ></vdms-hq-form-input-timecode>
  </div>
  <div class="footer">
    <vdms-hq-ui-button color="secondary" (click)="dismiss.emit()">Cancel</vdms-hq-ui-button>
  </div>
</div>
