<vdms-hq-ui-dialog-wrapper>
  <div title>
    <span class="title">{{ 'common.dialogs.cold_estimation.title' | translate }}</span>
  </div>
  <div content class="relative">
    <vdms-hq-ui-info-bar
      *ngIf="isLoading$ | async"
      [type]="InfoBarType.INFO"
      [clickAction]="InfoBarClickAction.DISABLE"
      [message]="'common.dialogs.cold_estimation.data_loading' | translate"
      [disableClose]="true"
    ></vdms-hq-ui-info-bar>
    <div class="estimation-container" *ngIf="(dataError$ | async) === false">
      <ng-container *ngIf="estimationData$ | async; let estimation">
        <p>{{ 'common.dialogs.cold_estimation.message' | translate }}</p>
        <div>
          <span>{{ 'common.dialogs.cold_estimation.total_GB' | translate }}</span>
          <span>{{ estimation.total_bytes | formatBytes }}</span>
        </div>
        <div>
          <span>{{ 'common.dialogs.cold_estimation.total_count' | translate }}</span>
          <span>{{ estimation.total_assets }}</span>
        </div>
        <div>
          <span>{{ 'common.dialogs.cold_estimation.price_to_restore_per_GB' | translate }}</span>
          <span>{{ estimation.price_per_gb | clientCurrency : estimation.currency }}</span>
        </div>
        <h2>{{ 'common.dialogs.cold_estimation.confirm' | translate }}</h2>
      </ng-container>
    </div>
    <vdms-hq-ui-info-bar
      *ngIf="dataError$ | async"
      [type]="InfoBarType.ERROR"
      [message]="'common.dialogs.cold_estimation.data_error' | translate"
      [disableClose]="true"
    ></vdms-hq-ui-info-bar>
  </div>
  <ng-container footer>
    <vdms-hq-ui-button (click)="close()" color="secondary">{{ 'common.global.cancel' | translate }}</vdms-hq-ui-button>
    <vdms-hq-ui-button
      [loading]="(isLoading$ | async) === true"
      [disabled]="(dataError$ | async) === true"
      (click)="confirm()"
      color="primary"
      >{{ 'common.dialogs.cold_estimation.button_confirm' | translate }}</vdms-hq-ui-button
    >
  </ng-container>
</vdms-hq-ui-dialog-wrapper>
