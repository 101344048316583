import { DialogResponse as ConfirmationDialogResponse } from '@vdms-hq/shared';

export interface ConfirmationInput {
  title?: string;
  message?: string;
  okAction?: ConfirmationAction;
  abortAction?: ConfirmationAction;
}

export interface ConfirmationAction {
  label: string;
  color: ConfirmationActionColor;
}
export type ConfirmationActionColor = 'secondary' | 'warn' | 'primary' | 'transparent';

export const DEFAULT_OK_CONFIRMATION_ACTION: ConfirmationAction = {
  color: 'primary',
  label: 'common.global.dialog.yes',
};
export const DEFAULT_ABORT_CONFIRMATION_ACTION: ConfirmationAction = {
  color: 'secondary',
  label: 'common.global.dialog.no',
};

export const DEFAULT_ABORT_CONFIRMATION_ACTION_CANCEL: ConfirmationAction = {
  color: 'secondary',
  label: 'common.global.cancel',
};

export const DEFAULT_CONFIRMATION_DIALOG: Required<ConfirmationInput> = {
  title: 'common.global.dialog.question',
  message: 'common.global.dialog.default_message',
  okAction: DEFAULT_OK_CONFIRMATION_ACTION,
  abortAction: DEFAULT_ABORT_CONFIRMATION_ACTION,
};

export const DELETE_CONFIRMATION_DIALOG: Required<ConfirmationInput> = {
  title: 'common.global.dialog.delete_title',
  message: 'common.global.dialog.delete_message',
  abortAction: DEFAULT_ABORT_CONFIRMATION_ACTION_CANCEL,
  okAction: {
    label: 'common.global.dialog.delete',
    color: 'warn',
  },
};

export type ConfirmationOutput =
  | boolean
  | {
      status: ConfirmationDialogResponse;
    };
