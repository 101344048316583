import { Routes } from '@angular/router';
import { Permission, RequiresPermissionsGuard } from '@vdms-hq/activated-client';

import { AuthOnlyGuard } from '@vdms-hq/auth';
import { DISCOUNTS_ROUTER_BASE } from '@vdms-hq/discounts';
import { RATE_CARDS_ROUTES } from '@vdms-hq/rate-cards';
import { GLOBAL_CONFIGURATION_ROUTES } from '@vdms-hq/global-configuration';
import { VIDEO_CODECS_ROUTES } from '@vdms-hq/video-codecs';
import { CREDENTIALS_ROUTES } from '@vdms-hq/credentials';
import { METADATA_RECOGNITION_ROUTES } from '@vdms-hq/metadata-recognition';
import { CONTENT_CORNER_ROUTES } from '@vdms-hq/content-corner-admin';
import { REINDEX_ROUTES } from '@vdms-hq/reindex-automatization';
import { NOTIFICATION_SUBSCRIPTION_ROUTES } from '@vdms-hq/notifications';
import { MANDATORY_FIELDS_ROUTES } from '@vdms-hq/mandatory-fields';
import { QUALITY_CONTROL_ROUTES } from '@vdms-hq/quality-control';
import { QUARANTINE_ROUTES } from '@vdms-hq/quarantine';
import { ASPERA_KEYS_ROUTES } from '@vdms-hq/aspera-keys';
import { METADATA_SNAPSHOTS_ROUTES } from '@vdms-hq/metadata-snapshots';
import { AdminNavWrapperComponent } from './admin-nav-wrapper/admin-nav-wrapper.component';
import { SelectOptionsAdminTableComponent, DynamicModuleFactoryService } from '@vdms-hq/select-options-editor';
import { Compiler } from '@angular/core';

export enum ADMIN_ROUTING {
  ROOT = 'admin',
  ADMIN_RAW = 'admin/raw',
}

const dynamicModuleFactoryService = new DynamicModuleFactoryService(new Compiler());

export const adminRoutes: Routes = [
  {
    path: '',
    component: AdminNavWrapperComponent,
    canActivate: [AuthOnlyGuard, RequiresPermissionsGuard],
    data: { permissions: [Permission.EDIT_GROUPS] },
    children: [
      {
        path: '',
        loadChildren: () => import('./general/general.module').then((m) => m.GeneralModule),
      },
      {
        path: 'raw',
        loadChildren: () =>
          import('./raw-admin/activated-client-admin.module').then((m) => m.ActivatedClientAdminModule),
      },
      {
        path: 'asset-filters',
        loadChildren: () => import('./asset-filters/asset-filters.module').then((m) => m.AssetFiltersModule),
      },
      {
        path: 'asset-tables',
        loadChildren: () => import('./asset-tables/asset-tables.module').then((m) => m.AssetTablesModule),
      },
      {
        path: 'asset-details',
        loadChildren: () => import('./asset-details/asset-details.module').then((m) => m.AssetDetailsModule),
      },
      {
        path: 'fields',
        loadChildren: () =>
          import('./field-definitions/field-definitions.module').then((m) => m.FieldDefinitionsModule),
      },
      {
        path: 'field-sources',
        loadChildren: () =>
          dynamicModuleFactoryService.createDynamicModule([{ path: '', component: SelectOptionsAdminTableComponent }]),
      },
      {
        path: 'settings-snapshots',
        loadChildren: () =>
          import('./settings-snapshots/settings-snapshots.module').then((m) => m.SettingsSnapshotsModule),
      },
      {
        path: 'browse-library',
        loadChildren: () => import('./library-browser/library-browser.module').then((m) => m.LibraryBrowserModule),
      },
      {
        path: 'languages',
        loadChildren: () => import('@vdms-hq/client-languages').then((m) => m.ClientLanguagesModule),
      },
      {
        path: DISCOUNTS_ROUTER_BASE.ROOT,
        loadChildren: () => import('@vdms-hq/discounts').then((m) => m.DISCOUNTS_ROUTING),
      },
      {
        path: RATE_CARDS_ROUTES.ROOT,
        loadChildren: () => import('@vdms-hq/rate-cards').then((m) => m.RATE_CARDS_ROUTING),
      },
      {
        path: GLOBAL_CONFIGURATION_ROUTES.ROOT,
        loadChildren: () => import('@vdms-hq/global-configuration').then((m) => m.GLOBAL_CONFIGURATION_ROUTING),
      },
      {
        path: VIDEO_CODECS_ROUTES.ROOT,
        loadChildren: () => import('@vdms-hq/video-codecs').then((m) => m.VIDEO_CODECS_ROUTING),
      },
      {
        path: CREDENTIALS_ROUTES.ROOT,
        loadChildren: () => import('@vdms-hq/credentials').then((m) => m.CREDENTIALS_ROUTING),
      },
      {
        path: METADATA_RECOGNITION_ROUTES.ROOT,
        loadChildren: () => import('@vdms-hq/metadata-recognition').then((m) => m.METADATA_RECOGNITION_ROUTING),
      },
      {
        path: CONTENT_CORNER_ROUTES.ROOT,
        loadChildren: () => import('@vdms-hq/content-corner-admin').then((m) => m.CONTENT_CORNER_ROUTING),
      },
      {
        path: REINDEX_ROUTES.ROOT,
        loadChildren: () => import('@vdms-hq/reindex-automatization').then((m) => m.REINDEX_ROUTING),
      },
      {
        path: NOTIFICATION_SUBSCRIPTION_ROUTES.ROOT,
        loadChildren: () => import('@vdms-hq/notifications').then((m) => m.NOTIFICATION_SUBSCRIPTION_ROUTING),
      },
      {
        path: MANDATORY_FIELDS_ROUTES.ROOT,
        loadChildren: () => import('@vdms-hq/mandatory-fields').then((m) => m.MANDATORY_FIELDS_ROUTING),
      },
      {
        path: QUALITY_CONTROL_ROUTES.ROOT,
        loadChildren: () => import('@vdms-hq/quality-control').then((m) => m.QUALITY_CONTROL_ROUTING),
        canActivate: [RequiresPermissionsGuard],
        data: { permissions: [Permission.PERMISSION_BROWSE_QA_CONFIGS] },
      },
      {
        path: QUARANTINE_ROUTES.ROOT,
        loadChildren: () => import('@vdms-hq/quarantine').then((m) => m.QUARANTINE_ROUTING),
      },
      {
        path: ASPERA_KEYS_ROUTES.ROOT,
        loadChildren: () => import('@vdms-hq/aspera-keys').then((m) => m.ASPERA_KEYS_ROUTING),
      },
      {
        path: METADATA_SNAPSHOTS_ROUTES.ROOT,
        loadChildren: () => import('@vdms-hq/metadata-snapshots').then((m) => m.METADATA_SNAPSHOTS_ROUTING),
      },
    ],
  },
];
