import { Injectable } from '@angular/core';
import { ApiService } from '../api.service';
import { GetResponseData } from '../../operators/get-response-data.operator';
import { ApiResponse } from '../api.model';
import { RaspRegisterResponse } from './rasp-register.model';
import { ASSETS_ENDPOINTS } from '../asset/models/asset-endpoints.model';

@Injectable({ providedIn: 'root' })
export class RaspRegisterService {
  constructor(private readonly apiService: ApiService) {}

  raspAssetRegister(uuid: string) {
    return this.apiService
      .post<null, ApiResponse<RaspRegisterResponse>>(`${ASSETS_ENDPOINTS.ONE}/${uuid}/rasp-register`, null)
      .pipe(GetResponseData);
  }
  raspAssetUnRegister(uuid: string) {
    return this.apiService.delete<null, ApiResponse<RaspRegisterResponse>>(
      `${ASSETS_ENDPOINTS.ONE}/${uuid}/rasp-register`,
    );
  }
}
