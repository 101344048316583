<vdms-hq-ui-dialog-wrapper>
  <h1 title class="mb-0">{{ dialogTitle }}</h1>
  <ng-template #loading>
    <vdms-hq-ui-loader></vdms-hq-ui-loader>
  </ng-template>
  <vdms-hq-client-form
    content
    *ngIf="clientForm && (policiesOptions$ | async) as policiesOptions; else loading"
    [clientForm]="clientForm.getGroup()"
    [asperaCredentialsOptions]="(credentialsFetcher.asperaCredentials$ | async) ?? []"
    [externalBucketCredentialsOptions]="(credentialsFetcher.externalBucketCredentials$ | async) ?? []"
    [policiesOptions]="policiesOptions"
    [proxyFilenameOptions]="(proxyFilenameOptions$ | async) ?? []"
    (emitSyncQueue)="syncQueue()"
    class="fit-inputs metadata-wrapper dialog-content"
  ></vdms-hq-client-form>
  <div class="footer buttons-group" footer>
    <vdms-hq-ui-button (click)="cancel()" color="secondary">{{ 'common.global.cancel' | translate }}</vdms-hq-ui-button>
    <vdms-hq-ui-button (click)="onSubmit()" [disabled]="!clientForm.isValid" color="primary">{{
      'common.global.save' | translate
    }}</vdms-hq-ui-button>
  </div>
</vdms-hq-ui-dialog-wrapper>
