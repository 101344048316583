import { OverlayModule } from '@angular/cdk/overlay';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAnalyticsModule } from '@angular/fire/compat/analytics';
import { AngularFireAuthModule, USE_EMULATOR as USE_AUTH_EMULATOR } from '@angular/fire/compat/auth';
import {
  AngularFirestoreModule,
  SETTINGS as USE_FIRESTORE_SETTINGS,
  USE_EMULATOR as USE_FIRESTORE_EMULATOR,
} from '@angular/fire/compat/firestore';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { ActivatedClientModule } from '@vdms-hq/activated-client';
import { CartCheckoutGuard } from '@vdms-hq/advanced-checkout';
import { ApiContractModule } from '@vdms-hq/api-contract';
import { AssetResultsModule } from '@vdms-hq/asset-results';
import { AuthModule as LibAuthModule } from '@vdms-hq/auth';
import { CartButtonComponent, configureCart } from '@vdms-hq/cart';
import { CLIENTS_CONFIG, ClientsFirebaseProjectIdConfig } from '@vdms-hq/clients';
import { APPLIED_FILTER_VALUE_FORMATTER } from '@vdms-hq/dynamic-filters';
import { CONTROL_VALUE_FORMATTER_LIB } from '@vdms-hq/dynamic-form';
import { configureSentry, ErrorHandlingCoreModule } from '@vdms-hq/error-handling';
import { LOGIN_PAGE_CONFIG, LoginPageConfig } from '@vdms-hq/login';
import { METADATA_MINIMUM_PERCENT_ACCURACY } from '@vdms-hq/metadata-recognition';
import { configureOrders, OrderActionsService, OrderResolverService } from '@vdms-hq/orders';
import { RefreshService } from '@vdms-hq/shared';
import { StateRootModule } from '@vdms-hq/state';
import { ASPERA_CONFIG, AsperaConfig, AssetDownloadService, STORAGE_CONFIG, StorageConfig } from '@vdms-hq/storage';
import { ThemeSwitcherService, ThemingModule } from '@vdms-hq/theming';
import { ToastConfig, ToastModule } from '@vdms-hq/toast';
import { TourItemComponent } from '@vdms-hq/tour-guide';
import { TRANSCRIPTION_CONFIG, TranscriptionConfig } from '@vdms-hq/transcription';
import {
  UIButtonModule,
  UILoaderModule,
  UILoaderService,
  UIModule,
  UINavbarModule,
  UISidenavModule,
  UISiteNavigationModule,
} from '@vdms-hq/ui';
import { DefaultValueFormatterService } from '@vdms-hq/value-formatter';
import { NgPipesModule } from 'ng-pipes';
import { CookieService } from 'ngx-cookie-service';
import { NgxMaskModule } from 'ngx-mask';
import { MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowseCoreModule } from './browse/browse-core.module';
import { BrowseSearchBarModule } from './browse/search-bar/browse-search-bar.module';
import { ImageLoadedDirective } from './logic/directives/image.loaded.directive';
import { StorageHandlerService } from './logic/services/storage-handler.service';
import { SecuredComponent } from './pages/secured/secured.component';
import { SharedModule } from './shared/shared.module';
import { LogoComponent } from './smart-components/logo/logo.component';
import { UploadAsperaModule } from './upload-aspera/upload-aspera.module';
import { AdminSubscriptionWebsocketUpdatesService, NotificationsButtonComponent } from '@vdms-hq/notifications';
import { configureAnalytics } from '@vdms-hq/analytics';
import { MediaInsightsModule } from './media-insights/media-insights.module';
import { windowProvider, WindowToken } from '@vdms-hq/omakase-player';
import { NgOptimizedImage } from '@angular/common';

export function HttpLoaderFactory(http: HttpClient) {
  const hash = '?hash=' + Date.now();
  return new MultiTranslateHttpLoader(http, [
    { prefix: './assets/common/i18n/', suffix: '.json' + hash },
    { prefix: './assets/i18n/', suffix: '.json' + hash },
  ]);
}

const loginPageConfig: LoginPageConfig = {
  app: 'vida',
  useBrandLogin: true,
  useCustomPasswordlessEmail: true,
  providers: {
    microsoft: environment.microsoftEnabled,
    google: true,
  },
};

const smart = [LogoComponent];

const view = [];

const pipes = [];

const asperaConfig: AsperaConfig = {
  remoteHost: environment.asperaRemoteHost,
  remoteUser: environment.asperaRemoteUser,
  remotePassword: '',
  destinationRoot: environment.asperaDestinationRoot,
  sdkLocation: environment.asperaSDKLocation,
  debug: !environment.production,
};

const ToastModuleConfig: ToastConfig = {
  durationInSeconds: 5,
  position: 'top-right',
  isCloseButton: true,
};

const TooltipOptions = {
  showDelay: 500,
  hideDelay: 0,
  touchendHideDelay: 0,
  disableTooltipInteractivity: true,
};

@NgModule({
  declarations: [AppComponent, ImageLoadedDirective, SecuredComponent, ...smart, ...view, ...pipes],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    ThemingModule.forRoot(),
    UIModule.forRoot(),
    UIButtonModule,
    UILoaderModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
      defaultLanguage: 'en',
    }),
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireAuthModule,
    AngularFirestoreModule,
    NgxMaskModule.forRoot({
      validation: false,
    }),
    NgPipesModule,
    OverlayModule,
    StateRootModule,
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production,
    }),
    LibAuthModule.forRoot({
      firebase: environment.firebaseConfig,
      microsoftTenantId: environment.microsoftTenantId,
      routing: {
        forAuthorized: '/home',
        forUnauthorized: '/login',
      },
    }),
    ApiContractModule.forRoot({
      websocketUrl: environment.websocketUrl,
      dolbyRaspApiUrl: environment.dolbyRaspApiUrl,
      dolbyRaspApiUrlV2: environment.dolbyRaspApiUrlV2,
      production: environment.production,
      apiUrl: environment.apiUrl,
      cookieDomain: environment.cookieDomain,
      releaseNotes: {
        dark: environment.releaseNotes.dark,
        light: environment.releaseNotes.light,
      },
      interceptors: {
        bearer: true,
        client: true,
        errorHandling: true,
        cloudfrontCookie: {
          notRequiredForPrefixes: ['order/download', 'order/aspera-download', 'request-new-account'],
        },
        ccSalesforceToken: true,
        cacheControl: true,
      },
    }),
    EffectsModule.forRoot([]),
    AssetResultsModule.forRoot(TooltipOptions),
    StoreRouterConnectingModule.forRoot(),
    SharedModule,
    MediaInsightsModule,
    AngularFireAnalyticsModule,
    CartButtonComponent,
    UploadAsperaModule,
    ErrorHandlingCoreModule,
    ActivatedClientModule.forRoot({
      routing: {
        insufficientPermissionsRoute: '/error/403',
        clientSwitchRoute: '/home',
      },
      allowChangeClientForHostname: true,
    }),
    ToastModule.forRoot(ToastModuleConfig),
    BrowseCoreModule,
    BrowseSearchBarModule,
    UISidenavModule,
    UISiteNavigationModule,
    UINavbarModule,
    TourItemComponent,
    NotificationsButtonComponent,
    NgOptimizedImage,
  ],
  providers: [
    { provide: USE_AUTH_EMULATOR, useValue: environment.firebaseEmulators?.authDomain },
    { provide: USE_FIRESTORE_EMULATOR, useValue: environment.firebaseEmulators?.firestoreDomain },
    { provide: USE_FIRESTORE_EMULATOR, useValue: environment.firebaseEmulators?.firestoreDomain },
    {
      provide: USE_FIRESTORE_SETTINGS,
      useValue: {
        experimentalForceLongPolling: true,
      },
    },
    {
      provide: ASPERA_CONFIG,
      useValue: asperaConfig,
    },
    {
      provide: CONTROL_VALUE_FORMATTER_LIB,
      useClass: DefaultValueFormatterService,
    },
    {
      provide: APPLIED_FILTER_VALUE_FORMATTER,
      useClass: DefaultValueFormatterService,
    },
    {
      provide: LOGIN_PAGE_CONFIG,
      useValue: loginPageConfig,
    },
    {
      provide: STORAGE_CONFIG,
      useValue: <StorageConfig>{
        cdn: environment.cdn,
      },
    },
    {
      provide: WindowToken,
      useFactory: windowProvider,
    },
    {
      provide: METADATA_MINIMUM_PERCENT_ACCURACY,
      useValue: 25,
    },
    {
      provide: TRANSCRIPTION_CONFIG,
      useValue: <TranscriptionConfig>{
        voiceAIApiUrl: environment.voiceAIApiUrl,
        voiceAIApiKey: environment.voiceAIApiKey,
      },
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (storageHandlerService: StorageHandlerService) => () => storageHandlerService.registerListeners(),
      multi: true,
      deps: [StorageHandlerService],
    },
    {
      provide: CLIENTS_CONFIG,
      useValue: <ClientsFirebaseProjectIdConfig>{
        firebaseProjectId: environment.firebaseConfig.projectId,
      },
    },
    ThemeSwitcherService,
    OrderResolverService,
    configureOrders({ cdn: environment.cdn }),
    configureCart({ cdn: environment.cdn }),
    RefreshService,
    CookieService,
    DefaultValueFormatterService,
    UILoaderService,
    OrderActionsService,
    AssetDownloadService,
    AdminSubscriptionWebsocketUpdatesService,
    configureSentry({
      printInConsole: true,
    }),
    CartCheckoutGuard,
    configureAnalytics({
      appName: 'vida',
      debug: false,
    }),
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
