import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { StatsType } from '../logic/models/stats-type.enum';
import { StatsConfig } from '../logic/models/stats-config';

@Component({
  selector: 'vdms-hq-ui-stats-wrapper',
  templateUrl: './stats-wrapper.component.html',
  styleUrls: ['./stats-wrapper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatsWrapperComponent {
  StatsType = StatsType;

  @Input()
  statsType: StatsType = StatsType.STATIC;

  @Input() withoutPadding = false;

  @Input()
  statsConfig!: StatsConfig;

  @Input() fullWidth = false;
}
