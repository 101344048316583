import { Pipe, PipeTransform } from '@angular/core';
import { FieldDefinitionModel } from '@vdms-hq/shared';
import { Observable, of } from 'rxjs';
import { FieldsConfigService } from '@vdms-hq/config';
import { map } from 'rxjs/operators';

@Pipe({
  name: 'definitionForSelectedId',
})
export class DefinitionForSelectedIdPipe implements PipeTransform {
  constructor(private fields: FieldsConfigService) {}

  transform(id?: string | null): Observable<FieldDefinitionModel | null> {
    if (!id) {
      return of(null);
    }
    return this.fields.inputDefinitions$.pipe(map((input) => input.find((input) => input.id === id) ?? null));
  }
}
