import moment from 'moment-timezone';
import { FormControl, FormGroup } from '@angular/forms';
import { debounceTime, Observable, of, startWith, switchMap, take } from 'rxjs';
import { Injectable } from '@angular/core';

type MediaPulseOrdersFiltersView = {
  ordersType?: string;
  title?: string;
  orderNo?: number;
  poNo?: string;
  txDate?: {
    from?: moment.Moment;
    to?: moment.Moment;
  };
};

@Injectable()
export class MediaPulseOrdersForm {
  filters = new FormGroup({
    keyword: new FormControl<string>(''),
    ordersType: new FormControl<string | null>(null),
    orderNo: new FormControl<number | null>(null),
    poNo: new FormControl<string | null>(null),
    txDate: new FormControl<{
      from: moment.Moment | null;
      to: moment.Moment | null;
    }>({
      from: null,
      to: null,
    }),
  });

  values$: Observable<MediaPulseOrdersFiltersView> = this.filters.valueChanges.pipe(
    startWith(this.filters.value),
    debounceTime(400),
    switchMap(() => {
      const formValue = this.filters.value;
      const filters = <MediaPulseOrdersFiltersView>{};

      if (formValue.ordersType) {
        filters.ordersType = formValue.ordersType;
      }

      if (formValue?.keyword) {
        filters.title = formValue.keyword;
      }

      if (formValue?.orderNo) {
        filters.orderNo = formValue.orderNo;
      }

      if (formValue?.poNo) {
        filters.poNo = formValue.poNo;
      }

      if (formValue.txDate?.from && formValue.txDate?.to) {
        filters.txDate = {
          from: formValue.txDate.from,
          to: formValue.txDate.to,
        };
      }

      return of(filters);
    }),
  );
}
