import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SuppliersListOption } from '@vdms-hq/shared';
import { Observable } from 'rxjs';
import { GetDetailedResponsePaginationData, GetResponseData } from '../../operators/get-response-data.operator';
import { ApiDetailedResourcePaginatedResponse, ApiPaginatedResponse, ApiResponse } from '../api.model';
import { ApiService } from '../api.service';
import { SupplierEndpoints } from './endpoints';
import { Supplier, SupplierPatchRequest, SupplierPostRequest, SupplierResponse } from './supplier.model';

@Injectable({ providedIn: 'root' })
export class SupplierService {
  constructor(private readonly apiService: ApiService) {}

  public getSuppliers(): Observable<Supplier[]> {
    const headers = new HttpHeaders({
      'per-page': '1000',
      page: '0',
    });
    return this.apiService
      .get<ApiResponse<Supplier[]>>(SupplierEndpoints.ROOT_PLURAL, { headers })
      .pipe(GetResponseData);
  }

  public getPaginatedSuppliers(
    pagination?: { text?: string; perPage?: string; page?: string },
    orderBy?: string,
    orderDir?: string,
  ) {
    let headers = new HttpHeaders({
      'per-page': pagination?.perPage ?? '24',
      page: pagination?.page ?? '0',
      'order-by': orderBy ?? 'supplier.emailAddress',
      'order-dir': orderDir ?? 'ASC',
    });
    headers = pagination?.text ? headers.set('filter', pagination.text) : headers;
    return this.apiService
      .get<ApiDetailedResourcePaginatedResponse<SuppliersListOption[]>>(SupplierEndpoints.ROOT_PLURAL, { headers })
      .pipe(GetDetailedResponsePaginationData);
  }

  public getSupplier(id: Pick<Supplier, 'id'>): Observable<Supplier> {
    return this.apiService.get<ApiResponse<Supplier>>(`${SupplierEndpoints.ROOT}/${id}`).pipe(GetResponseData);
  }

  public patchSupplier(uuid: string, data: SupplierPatchRequest): Observable<SupplierResponse> {
    return this.apiService
      .patch<SupplierPatchRequest, ApiResponse<SupplierResponse>>(`${SupplierEndpoints.ROOT}/${uuid}`, data)
      .pipe(GetResponseData);
  }

  public postSupplier(data: SupplierPostRequest): Observable<SupplierResponse> {
    return this.apiService
      .post<SupplierPatchRequest, ApiResponse<SupplierResponse>>(SupplierEndpoints.ROOT, data)
      .pipe(GetResponseData);
  }

  public deleteSupplier(uuid: SuppliersListOption['uuid']) {
    return this.apiService.delete(`${SupplierEndpoints.ROOT}/${uuid}`);
  }
}
