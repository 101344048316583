import { Injectable } from '@angular/core';
import { MultipleViewDataSource, Pagination } from '@vdms-hq/ui';
import { RightsPartnerListItem, RightsPartnersService, SortOptions } from '@vdms-hq/api-contract';
import {
  BehaviorSubject,
  debounceTime,
  EMPTY,
  map,
  Observable,
  of,
  shareReplay,
  startWith,
  switchMap,
  take,
  tap,
  combineLatest,
  catchError,
} from 'rxjs';
import { FieldConfigId, RefreshService } from '@vdms-hq/shared';
import { ToastService } from '@vdms-hq/toast';
import { RightsPartnersRefreshService } from './rights-partners-refresh.service';
import { FormControl, FormGroup } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { RouterParamsPagination } from '@vdms-hq/view-settings';

type RightsPartnersFiltersView = {
  keyword: string;
  sort: string;
};

@Injectable({ providedIn: 'root' })
export class RightsPartnersResultsMultiDs
  extends RouterParamsPagination
  implements MultipleViewDataSource<RightsPartnerListItem>
{
  static readonly defaultPerPage = 1000;
  static readonly defaultPage = 0;
  sortBy$ = new BehaviorSubject<FieldConfigId>('partner.name');
  sortDirection$ = new BehaviorSubject<SortOptions['direction']>('desc');

  total$ = new BehaviorSubject(0);
  emptyResults$ = new BehaviorSubject(this.total$.value === 0);
  isLoading$ = new BehaviorSubject(false);
  refresh$ = this.rightsPartnersRefresher.refresh$;

  filters = new FormGroup({
    keyword: new FormControl<string>(''),
    sort: new FormControl<string | null>(null),
  });

  values$: Observable<RightsPartnersFiltersView> = this.filters.valueChanges.pipe(
    startWith(this.filters.value),
    debounceTime(400),
    switchMap(() => {
      const formValue = this.filters.value;
      const filters = <RightsPartnersFiltersView>{};

      if (formValue?.keyword) {
        filters.keyword = formValue.keyword;
      }
      this.changePageIndex$.next(0);
      return of(filters);
    }),
  );

  allData$: Observable<RightsPartnerListItem[]> = combineLatest([
    this.pageIndex$,
    this.pageSize$,
    this.sortBy$,
    this.sortDirection$,
    this.values$,
    this.refresh$,
    this.refreshService.refresh$,
  ]).pipe(
    tap(() => this.isLoading$.next(true)),
    switchMap(([pageIndex, pageSize, sortBy, sortDirection, filters]) => {
      const headers = Pagination.create({
        page: pageIndex,
        perPage: pageSize,
        orderBy: sortBy,
        orderDir: sortDirection,
      });
      return this.rightsPartnersService.getMany(headers, filters.keyword).pipe(
        take(1),
        catchError((err) => this.#errorHandler(err)),
      );
    }),
    tap((response) => this.total$.next(response.total)),
    tap((response) => this.emptyResults$.next(response.total === 0)),
    map((response) => response.data),
    tap(() => this.isLoading$.next(false)),
    shareReplay(1),
  );

  connection$: Observable<RightsPartnerListItem[]> = this.allData$;

  constructor(
    private toastService: ToastService,
    private refreshService: RefreshService,
    private rightsPartnersRefresher: RightsPartnersRefreshService,
    private rightsPartnersService: RightsPartnersService,
  ) {
    super();
  }

  sortChange($event: { active: string; direction: SortOptions['direction'] }) {
    this.sortBy$.next($event.active);
    this.sortDirection$.next($event.direction);
    this.changePerPageSize$.next(0);
  }

  #errorHandler(err: HttpErrorResponse) {
    this.toastService.error({ id: 'rightsPartners', message: 'Error fetching licensees' });
    this.isLoading$.next(false);
    this.emptyResults$.next(true);
    return EMPTY;
  }
}
