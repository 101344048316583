<div class="floating-controls-v2" *ngIf="item; let item; else: missingItem">
  <ng-container *ngIf="(alternativePillConfig | disableIfRow : item).disabled; else defaultView">
    <div
      class="alternative-pill"
      *ngIf="alternativePillConfig?.disabledIf?.icon || alternativePillConfig?.disabledIf?.label"
    >
      <mat-icon *ngIf="alternativePillConfig?.disabledIf?.icon">{{
        alternativePillConfig?.disabledIf?.icon(item)
      }}</mat-icon>
      <span *ngIf="alternativePillConfig?.disabledIf?.label">{{ alternativePillConfig?.disabledIf?.label(item) }}</span>
    </div>
  </ng-container>
  <ng-template #defaultView>
    <ng-container *ngFor="let actionDef of actions">
      <mat-icon
        mat-ripple
        [matRippleDisabled]="actionDef | actionState : 'ifDisabled' : item"
        [class.control-disabled]="actionDef | actionState : 'ifDisabled' : item"
        [matTooltip]="
          ((actionDef | actionState : 'ifDisabled' : item)
            ? actionDef.disabledTooltip ?? actionDef.label
            : actionDef.label
          )
            | nameTransformer : item
            | translate
        "
        (click)="emitAction($event, actionDef)"
        [attr.data-tourguide-item]="actionDef?.tourItem?.name ?? ''"
        *ngIf="!(actionDef | actionState : 'ifHidden' : item)"
        >{{ actionDef.icon }}</mat-icon
      >
    </ng-container>
  </ng-template>
</div>
<ng-template #missingItem>
  <vdms-hq-ui-runtime-error message="Missing item"></vdms-hq-ui-runtime-error>
</ng-template>
