<div class="player" #playerContainer>
  <div class="player__video" id="advanced-player-window"></div>
  <ng-container *ngIf="(advancedPlayerService.state$ | async)?.state; let state">
    <vdms-hq-ui-loader *ngIf="state === 'loading'" [backdrop]="true" mode="over-parent"></vdms-hq-ui-loader>
    <vdms-hq-ui-empty-results
      *ngIf="state === 'error'"
      message="Unable to load this video, please contact support."
    ></vdms-hq-ui-empty-results>

    <vdms-hq-advanced-player-controls
      *ngIf="state === 'ready' && advancedPlayerService.player; let player"
      [player]="player"
      [sliderMax]="(advancedPlayerService.duration$ | async)?.countSeconds() ?? 0"
      [sliderValue]="(advancedPlayerService.currentTimecode$ | async)?.countSeconds() ?? 0"
      [isPictureInPictureAvailable]="(advancedPlayerService.isPictureInPictureAvailable$ | async) ?? true"
      [currentTimecode]="(advancedPlayerService.currentTimecode$ | async) ?? fallbackTimecode"
      [duration]="advancedPlayerService.duration$ | async"
      [spriteCss]="spriteCss"
      [closedCaptions]="(advancedPlayerService.currentSubtitle$ | async) !== null"
      [currentPlaybackRate]="(advancedPlayerService.currentPlaybackRate$ | async) ?? 1"
      [currentSubtitles]="advancedPlayerService.currentSubtitle$ | async"
      [loadedSubtitles]="advancedPlayerService.loadedSubtitles$ | async"
      [qualityOptions]="(advancedPlayerService.qualityOptions$ | async) ?? []"
      [currentQuality]="advancedPlayerService.currentQuality$ | async"
      [currentQualityPlayerV2]="advancedPlayerService.currentQualityPlayerV2$ | async"
      [isAdvancedV2]="advancedPlayerService.config?.isAdvancedV2 ?? false"
      (sliderValueChange)="advancedPlayerService.goToTime($event)"
      (mouseMoveTimelineBar)="onMouseMoveTimelineBar($event)"
      (mouseLeaveTimelineBar)="onMouseLeaveTimelineBar($event)"
      (action)="advancedPlayerService.handleAdvancedPlayerControlsAction($event)"
    >
    </vdms-hq-advanced-player-controls>
  </ng-container>
</div>
