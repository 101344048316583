import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import { sentryInit } from '@vdms-hq/error-handling';

if (environment.production) {
  enableProdMode();
}
const sentryEnabled = environment.type !== 'local';

if (sentryEnabled) {
  sentryInit({
    dns: environment.sentryDSN,
    env: environment.type,
    version: environment.version,
    debug: !environment.production,
    appName: 'vida',
    sampleRate: ['stage', 'demo', 'local'].includes(environment.type) ? 1 : 0.1,
  });
}

platformBrowserDynamic().bootstrapModule(AppModule);
