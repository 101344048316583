import { AudioChannel, EncodeProfile } from './dolby.model';
import { Video, Audio, Timecode, SessionData } from '@vdms-hq/omakase-player';

export enum DolbyPlayerVurlAdvisor {
  HLS_PACKAGER = 'hls-packager',
  MEDIA_PROPERTIES = 'media-properties',
  POSTERFRAME = 'posterframe',
  STORAGE = 'storage',
  THUMBNAILS = 'thumbnails',
  WAVEFORM = 'waveform',
}

export enum DolbyPlayerVariant {
  MINIMAL = 'minimal',
  BBC_AUDIOWAVEFORM = 'bbc-audiowaveform',
}

export interface DolbyPlayerRegisterAssetBody {
  urls: AssetUrl[];
  name?: string;
  metadata_selector?: 'asset_media_descriptor' | 'asset_track_map' | 'media_index' | 'rasp_manifest' | 'rasp_info';
  expiration?: string;
  asset_mime?: string;
  vurls?: DolbyPlayerVurls[];
}

interface AssetUrl {
  url: string;
  id?: string;
  dolby_vision_metadata_xml?: string;
  mime?: string;
}

export interface TrackMap {
  file_id: string;
  track_idx: number;
}

export interface MediaProperties {
  id: string;
  url: string;
  size_bytes: number;
  bitrate_kb: number;
  duration_sec: number;
  video_tracks: number;
  audio_tracks: number;
  video: Video[];
  audio: Audio[];
  timecode: Timecode[];
}

export interface DolbyPlayerVurls {
  vurl: string;
  advisor?: DolbyPlayerVurlAdvisor;
  config: {
    advisor?: DolbyPlayerVurlAdvisor;
    encode_profile?: EncodeProfile;
    variant?: DolbyPlayerVariant;
    width?: number;
    height?: number;
    codec?: string;
    quantization?: number;
    interval_sec?: number;
    transformation_map?: {
      video?: { track_idx: number }[];
      audio?: { channels: AudioChannel[]; channel_order?: 'mono' }[];
    };
    track_selector?: {
      track_idx: number;
    };
    channels?: number;
    sample_duration_ms?: number;
    payload?: SessionData;
  };
  mime?: string;
  prefetch?: string;
}
