import { Component, OnInit, inject } from '@angular/core';

import { FormatBytesPipe, RenamePipe } from '@vdms-hq/shared';
import { FlatOrderViewModel, OrdersDataSource } from '../../logic/order-results-ds';
import { DynamicFilterInput, DynamicFiltersModule } from '@vdms-hq/dynamic-filters';
import { FiltersOrderForm } from '../../logic/filters-order-form';
import {
  approvalInitialValues,
  defaultFilters,
  defaultFiltersOnlyMine,
  defaultInitialValues,
  FILTER_DATE_DAYS_DURATION,
  salesForceFilters,
  salesForceFiltersApprovalValues,
  salesForceFiltersInitialValues,
  salesForceFiltersOnlyMine,
} from '../../../logic/config';
import { ActivatedClientModule, ActivatedClientService, Permission } from '@vdms-hq/activated-client';
import { combineLatest, take, Observable } from 'rxjs';
import { CommonModule, DatePipe } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { ApiContractModule, OrdersType } from '@vdms-hq/api-contract';
import { ResultsComponent } from '../../components/results/results.component';
import { OrderMetadataTransformerService } from '../../../logic/order-metadata-transformer';
import { ActivatedRoute, Router } from '@angular/router';
import { ORDERS_ROUTER_BASE } from '../../../orders-routing.module';
import { ResultsActions } from '@vdms-hq/asset-results';
import { CollectionsActionsService } from '@vdms-hq/collections';
import { DataAction, DefaultMultiViewType } from '@vdms-hq/ui';
import { AddToCartActionsService } from '@vdms-hq/cart-core';
import { TABLE_TYPE, ViewSettingsService } from '@vdms-hq/view-settings';
import { ColumnsSettingsScopes } from '@vdms-hq/firebase-contract';
import { ColumnsConfigService } from '../../logic/columns-config.service';
import { ordersViewConfiguration } from '../../../details/logic/view-configurations';
import { OrderActionsService } from '../../../details/logic/order-actions.service';
import { map } from 'rxjs/operators';
import { OrderActionsProperties } from '../../../details/logic/models';

@Component({
  selector: 'vdms-hq-order-results',
  templateUrl: './order-results.component.html',
  imports: [
    CommonModule,
    DynamicFiltersModule,
    TranslateModule,
    ActivatedClientModule,
    ApiContractModule,
    ResultsComponent,
  ],
  providers: [
    OrdersDataSource,
    FiltersOrderForm,
    OrderMetadataTransformerService,
    OrderMetadataTransformerService,
    FormatBytesPipe,
    DatePipe,
    RenamePipe,
  ],
  standalone: true,
})
export class OrderResultsComponent implements OnInit {
  private activatedClientService = inject(ActivatedClientService);
  private activatedRouter = inject(ActivatedRoute);
  private addToCartActionsService = inject(AddToCartActionsService);
  private collectionService = inject(CollectionsActionsService);
  private router = inject(Router);
  private viewSettingsService = inject(ViewSettingsService);
  private columnsConfigService = inject(ColumnsConfigService);
  private orderActionsService = inject(OrderActionsService);
  public dataSource = inject(OrdersDataSource);
  public filtersForm = inject(FiltersOrderForm);

  actions: DataAction<FlatOrderViewModel>[] = [];
  actions$: Observable<(DataAction<FlatOrderViewModel> | null)[]> = combineLatest([
    this.orderActionsService.buildAddToCartAction$(),
    this.orderActionsService.buildAddToCollectionAction$(),
    this.orderActionsService.buildDownloadDeliveryConfirmationAction$(),
  ]).pipe(
    map(([addToCartAction, addToCollectionAction, downloadDeliveryConfirmationAction]) => [
      addToCartAction,
      addToCollectionAction,
      ...ordersViewConfiguration.actions,
      downloadDeliveryConfirmationAction,
    ]),
  );
  filtersConfig: DynamicFilterInput[] = [];
  title = 'common.orders.title';
  baseUrl?: string;
  readonly tableType = TABLE_TYPE.ORDER;
  readonly columnsSettingsScope = ColumnsSettingsScopes.ORDER_ITEMS;

  ngOnInit() {
    const isApprovalView = this.activatedRouter.snapshot.routeConfig?.path === 'approval';

    this.title = isApprovalView ? 'common.orders.title_approval' : 'common.orders.title';
    this.baseUrl = isApprovalView ? ORDERS_ROUTER_BASE.ORDERS_APPROVAL : ORDERS_ROUTER_BASE.ORDERS;

    combineLatest({
      client: this.activatedClientService.clientDefinite$,
      permissions: this.activatedClientService.permissions$,
      defaultView: this.viewSettingsService.defaultViewFor$(TABLE_TYPE.ORDER),
    })
      .pipe(take(1))
      .subscribe(({ client, permissions }) => {
        const filterDateDaysDuration = client.vida?.ordersFilterDateDuration ?? FILTER_DATE_DAYS_DURATION;

        if (client.integrations.salesforce) {
          this.filtersConfig = salesForceFiltersOnlyMine;
          if (
            permissions.some((perm) =>
              [Permission.EDIT_ORDERS, Permission.APPROVE_ORDERS, Permission.BROWSE_SHARED_ORDERS].includes(perm),
            )
          ) {
            this.filtersConfig = salesForceFilters;
          }

          this.filtersForm.filters.setValue(
            isApprovalView ? salesForceFiltersApprovalValues : salesForceFiltersInitialValues,
          );
        } else {
          this.filtersConfig = defaultFiltersOnlyMine;
          if (
            permissions.some((perm) =>
              [
                Permission.EDIT_ORDERS,
                Permission.APPROVE_ORDERS,
                Permission.BROWSE_SHARED_ORDERS,
                Permission.BROWSE_OWN_ORDERS,
              ].includes(perm),
            )
          ) {
            this.filtersConfig = defaultFilters;
          }

          const initialValues = isApprovalView
            ? approvalInitialValues(filterDateDaysDuration)
            : defaultInitialValues(filterDateDaysDuration);
          this.filtersForm.filters.setValue(initialValues);
        }

        const hasBrowseAllOrdersPermission = permissions.some((perm) => perm === Permission.BROWSE_ALL_ORDERS);
        const hasBrowseSharedAndOwnOrdersPermission = [
          Permission.BROWSE_SHARED_ORDERS,
          Permission.BROWSE_OWN_ORDERS,
        ].every((p) => permissions.includes(p));

        if (hasBrowseAllOrdersPermission) {
          this.#setOrderTypeConfig('all');
        } else if (hasBrowseSharedAndOwnOrdersPermission) {
          this.#setOrderTypeConfig(['shared', 'own']);
        } else if (permissions.includes(Permission.BROWSE_SHARED_ORDERS)) {
          this.#setOrderTypeConfig('shared');
        } else if (permissions.includes(Permission.BROWSE_OWN_ORDERS)) {
          this.#setOrderTypeConfig('own');
        } else {
          this.#setOrderTypeConfig();
        }
      });
  }

  handleAction($event: { item: FlatOrderViewModel; key: string }) {
    const { key, item } = $event;
    switch (key) {
      case OrderActionsProperties.DOWNLOAD_DELIVERY_CONFIRMATION:
        break;
      case OrderActionsProperties.DOWNLOAD_MANY_DELIVERY_CONFIRMATION:
        break;
      case ResultsActions.PREVIEW:
        this.#preview(item);
        break;
      case ResultsActions.ADD_TO_CART:
        this.#addToCart(item);
        break;
      case ResultsActions.ADD_TO_COLLECTION:
        this.#addToCollection(item);
        break;
      case ResultsActions.SETTINGS:
        this.columnsConfigService.popColumnsConfigDialog(this.columnsSettingsScope);
        break;
      default:
        console.log('Unknown action', key);
    }

    this.orderActionsService.handleDownloadDeliveryConfirmationAction($event);
    /* this.orderActionsService.handleDownloadManyDeliveryConfirmationAction($event, {
      uuids: this.dataSource.selection.identifiersSnapshot as string[],
    }); */
  }

  #filterOrderTypeFromOptions(availableType: OrdersType | OrdersType[] | null) {
    this.filtersConfig = this.filtersConfig.map((filter) => {
      if (filter.id !== 'ordersType') {
        return filter;
      }

      if (!availableType) {
        return { ...filter, selectOptions: [] };
      }

      return {
        ...filter,
        selectOptions: filter.selectOptions?.filter((select) =>
          Array.isArray(availableType)
            ? availableType.includes(select.key as OrdersType)
            : select.key === availableType,
        ),
      };
    });
  }

  #setOrderTypeConfig(orderType?: OrdersType | OrdersType[]) {
    if (Array.isArray(orderType)) {
      this.filtersForm.filters.controls.ordersType.setValue('own');
      this.#filterOrderTypeFromOptions(['shared', 'own']);
      return;
    }

    switch (orderType) {
      case 'all':
        this.filtersForm.filters.controls.ordersType.setValue('all');
        break;

      case 'own':
        this.filtersForm.filters.controls.ordersType.setValue('own');
        this.#filterOrderTypeFromOptions('own');
        break;

      case 'shared':
        this.filtersForm.filters.controls.ordersType.setValue('shared');
        this.#filterOrderTypeFromOptions('shared');
        break;
    }
  }

  #preview(item: FlatOrderViewModel) {
    const { uuid } = item;
    this.router.navigate([this.baseUrl + '/' + uuid]);
  }

  #addToCart(item: FlatOrderViewModel) {
    this.addToCartActionsService.addOrders([item.uuid]);
  }

  #addToCollection(item: FlatOrderViewModel) {
    this.collectionService.addOrderAssetsToCollections([], item.uuid).subscribe();
  }

  changeDefaultView($event: DefaultMultiViewType) {
    this.viewSettingsService.saveDefaultViewFor(this.tableType, $event);
  }
}
