<div [formGroup]="innerFormControl">
  <div class="siblings--row" formArrayName="siblings" *ngFor="let control of siblings.controls; let i = index">
    <div [formGroup]="control" class="siblings--inputs">
      <vdms-hq-ui-form-input-select
        [label]="'Sibling field type - #' + (i + 1)"
        formControlName="key"
        [selectOptions]="allFieldsSelectOptions$ | async"
      ></vdms-hq-ui-form-input-select>
      <ng-container *ngIf="control.controls.key.value | definitionForSelectedId | async; let definition">
        <vdms-hq-control [formControlName]="'value'" [definition]="definition"></vdms-hq-control>
      </ng-container>
    </div>
    <vdms-hq-ui-button [iconOnly]="true" icon="remove" (click)="removeSibling(i)"></vdms-hq-ui-button>
  </div>

  <div class="siblings--footer">
    <vdms-hq-ui-button color="secondary" (click)="addSibling()">Add sibling</vdms-hq-ui-button>
  </div>
</div>
