import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { SelectorsDataSource } from '../../logic/services/selectors-data-source';
import { ActivatedClientService, PermissionService, Permission } from '@vdms-hq/activated-client';
import { MatDialog } from '@angular/material/dialog';
import { FieldsOptionsService } from '@vdms-hq/api-contract';
import { ToastService } from '@vdms-hq/toast';
import { UIConfirmationDialogService, WrapperHeaderAction } from '@vdms-hq/ui';
import { SelectOptionsTableComponent } from '../select-options-table/select-options-table.component';
import { DynamicFilterInput } from '@vdms-hq/dynamic-filters';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'vdms-hq-selectors-select-options-admin-table',
  templateUrl: './select-options-admin-table.component.html',
  styleUrls: ['./select-options-admin-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectOptionsAdminTableComponent extends SelectOptionsTableComponent implements OnInit, OnDestroy {
  readonly filtersConfig: DynamicFilterInput[] = [];

  constructor(
    selectorsDataSource: SelectorsDataSource,
    matDialog: MatDialog,
    fieldsOptionsService: FieldsOptionsService,
    confirmationDialog: UIConfirmationDialogService,
    toastService: ToastService,
    permissionService: PermissionService,
    activatedClientService: ActivatedClientService,
  ) {
    super(
      selectorsDataSource,
      matDialog,
      fieldsOptionsService,
      confirmationDialog,
      toastService,
      permissionService,
      activatedClientService,
    );
  }

  headerWrapperActions$: Observable<WrapperHeaderAction[]> = this.permissionService
    .verifyWithOwnedPermissions$([Permission.CREATE_FIELDS])
    .pipe(
      map((hasPermission) =>
        hasPermission
          ? [
              {
                key: 'new',
                name: 'pages.fields.new_field',
                color: 'primary',
              },
            ]
          : [],
      ),
    );
}
