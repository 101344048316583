import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClientsService, SupplierService } from '@vdms-hq/api-contract';
import {
  MultipleDataPresentationComponent,
  ResultsWrapperViewConfiguration,
  UIConfirmationDialogService,
  UILoaderModule,
  UIResultsWrapperModule,
} from '@vdms-hq/ui';
import { TranslateModule } from '@ngx-translate/core';
import { AsperaKeysDs } from '../../logic/aspera-keys.ds';
import { filter, switchMap, take } from 'rxjs';
import { ToastService } from '@vdms-hq/toast';
import { AsperaKeysViewModel } from '../../logic/aspera-keys-view.model';
import { MatDialog } from '@angular/material/dialog';
import { NewDialogComponent } from '../../components/new-dialog/new-dialog.component';
import { DeleteDialogComponent } from '../../components/delete-keys-dialog/delete-dialog.component';

@Component({
  selector: 'vdms-hq-aspera-keys-page',
  standalone: true,
  imports: [CommonModule, MultipleDataPresentationComponent, TranslateModule, UILoaderModule, UIResultsWrapperModule],
  providers: [AsperaKeysDs, SupplierService],
  templateUrl: './aspera-keys-page.component.html',
})
export class AsperaKeysPageComponent {
  headerConfig: ResultsWrapperViewConfiguration = {
    headerActions: [
      {
        key: 'new',
        name: 'common.aspera-keys.new',
        color: 'primary',
      },
      {
        key: 'remove_keys',
        name: 'common.aspera-keys.remove_keys',
        color: 'warn',
      },
    ],
    withLoading: true,
  };

  enabled = [
    'name',
    'facility_of_origin_name',
    'supplier_name',
    'supplier_uuid',
    'key_id',
    'created_by',
    'created_at',
    'actions',
  ];

  viewConfig = {
    tableAdvanced: {
      actions: [
        {
          key: 'set_new_key',
          icon: 'add',
          onDoubleClick: false,
          label: 'common.aspera-keys.new_key_dialog.title',
        },
        {
          key: 'delete_key',
          icon: 'delete',
          onDoubleClick: false,
          label: 'common.aspera-keys.actions.delete_key',
        },
      ],
      columnsEnabled: this.enabled,
      columns: [
        {
          id: 'name',
          label: 'Name',
          valuePath: 'name',
        },
        {
          id: 'key_id',
          label: 'Key ID',
          valuePath: 'keyId',
        },
        {
          id: 'supplier_uuid',
          label: 'Supplier UUID',
          valuePath: 'supplierUuid',
        },
        {
          id: 'supplier_name',
          label: 'Supplier Name',
          valuePath: 'supplierName',
        },
        {
          id: 'created_by',
          label: 'Created By',
          valuePath: 'createdBy',
        },
        {
          id: 'created_at',
          label: 'Created At',
          valuePath: 'createdAt',
        },
        {
          id: 'facility_of_origin_name',
          label: 'Facility of Origin',
          valuePath: 'facilityOfOriginName',
        },
        {
          id: 'actions',
          type: 'actions',
        },
      ],
    },
  };

  constructor(
    private clientsService: ClientsService,
    public dataSource: AsperaKeysDs,
    private confirmationDialog: UIConfirmationDialogService,
    private toast: ToastService,
    private dialog: MatDialog,
  ) {}

  handleAction($event: { key: string; item?: AsperaKeysViewModel }) {
    switch ($event.key) {
      case 'set_new_key':
      case 'new':
        console.log('$event.item', $event.item);
        this.#new($event?.item?.supplierUuid);
        break;
      case 'remove_keys':
        this.#deleteSupplierKeys();
        break;
      case 'delete_key':
        if ($event.item) this.#deleteKey($event?.item?.keyId);
        break;
    }
  }

  #new(supplierUuid?: string) {
    this.dialog
      .open(NewDialogComponent, {
        data: {
          supplierUuid,
        },
      })
      .afterClosed()
      .pipe(take(1), filter(Boolean))
      .subscribe(() => {
        this.dataSource.refresh();
      });
  }

  #deleteKey(keyId: string) {
    this.confirmationDialog
      .openDelete({
        title: 'common.aspera-keys.delete_key_dialog.title',
        message: 'common.aspera-keys.delete_key_dialog.message',
      })
      .pipe(
        take(1),
        filter(Boolean),
        switchMap(() => this.clientsService.deleteAsperaKey(keyId)),
      )
      .subscribe(() => {
        this.toast.success({ id: 'deleted', message: 'common.aspera-keys.notifications.deleted' });
        this.dataSource.refresh();
      });
  }

  #deleteSupplierKeys() {
    this.dialog
      .open(DeleteDialogComponent)
      .afterClosed()
      .pipe(take(1), filter(Boolean))
      .subscribe(() => {
        this.toast.success({ id: 'success', message: 'common.aspera-keys.notifications.deleted' });
        this.dataSource.refresh();
      });
  }
}
