import { inject, Injectable, NgZone } from '@angular/core';
import { SENTRY_CONFIG, SentryConfig } from './sentry-configure';
import * as Sentry from '@sentry/angular-ivy';
import { ToastService } from '@vdms-hq/toast';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class AppErrorHandlerService extends Sentry.SentryErrorHandler {
  config = inject<SentryConfig>(SENTRY_CONFIG);
  toast = inject(ToastService);
  ngZone = inject(NgZone);
  _options = {
    showDialog: false,
    logErrors: this.config.printInConsole,
  };

  constructor() {
    super({});
  }

  override handleError(error: any): void {
    const extractedError = this._extractError(error) || 'Handled unknown error';

    if (String(error).includes('ChunkLoadError: Loading chunk')) {
      this.toast.info({
        id: 'new_version',
        message: 'New app version detected, we recommend refreshing the page',
      });

      // eslint-disable-next-line no-restricted-syntax, no-console
      console.info(error);
    }

    let level: Sentry.SeverityLevel = 'error';

    if (error instanceof HttpErrorResponse) {
      level = 'log';
    }

    // Capture handled exception and send it to Sentry.
    this.ngZone.runOutsideAngular(() =>
      Sentry.captureException(extractedError, {
        mechanism: { type: 'angular', handled: false },
        captureContext: {
          level,
        },
      }),
    );

    // When in development mode, log the error to console for immediate feedback.
    if (this._options.logErrors) {
      // eslint-disable-next-line no-console
      console.error(extractedError);
    }
  }
}
