import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedClientService, WithPermissions } from '@vdms-hq/activated-client';
import { AuthService } from '@vdms-hq/auth';
import { CollectionsActionsService } from '@vdms-hq/collections';
import { LicenseAgreementDialogService, MfaReminderDialogService } from '@vdms-hq/login';
import { SidenavConfigService } from '@vdms-hq/nav';
import { CollectionsMediatorService } from '@vdms-hq/shared';
import { LayoutService, Theme } from '@vdms-hq/theming';
import { TourGuideService } from '@vdms-hq/tour-guide';
import { SidenavService } from '@vdms-hq/ui';
import { map, Observable, Subject, switchMap } from 'rxjs';
import { filter, take, takeUntil, tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { WebsocketInitializerService } from '../../logic/services/websocket-initializer.service';
import { sidenavConfig } from '../../sidenav/sidenav-menu.config';

export const AVAILABLE_PRD_MAIL_SUFFIXES = ['@vida.studio', '@visualdatamedia.com'];

@Component({
  selector: 'app-secured',
  templateUrl: './secured.component.html',
  styleUrls: ['./secured.component.scss'],
})
export class SecuredComponent extends WithPermissions() implements OnDestroy, OnInit {
  protected readonly Theme = Theme;
  isSalesForceEnabled$ = this.activatedClientService.clientDefinite$.pipe(
    map((definitions) => definitions.integrations.salesforce?.enabled ?? false),
  );

  client$ = this.activatedClientService.clientDefinite$;

  config$ = this.activatedClientService.vida$.pipe(
    map((def) => ({
      vidaSupportButton: def.vidaSupportButton,
      thirdPartySupport: def.thirdPartySupport,
    })),
    switchMap((supportCfg) => this.sidenavConfigService.getSidenavConfig$(sidenavConfig(supportCfg))),
  );
  displayName$ = this.authService.displayName$;
  sidenavState$ = this.sidenavService.sidenavStatus$;
  theme$ = this.layoutSwitcher.currentTheme();

  isCollapsed = true;
  selectedClientId$: Observable<string>;

  #destroy$ = new Subject<void>();

  constructor(
    private licenseAgreementDialogService: LicenseAgreementDialogService,
    private mfaReminderDialogService: MfaReminderDialogService,
    private tourGuideService: TourGuideService,
    private authService: AuthService,
    private sidenavConfigService: SidenavConfigService,
    private sidenavService: SidenavService,
    private layoutSwitcher: LayoutService,
    private collectionsMediatorService: CollectionsMediatorService,
    private collectionsActionsService: CollectionsActionsService,
    private activatedClientService: ActivatedClientService,
    private websocketInitializer: WebsocketInitializerService,
  ) {
    super();

    authService.authDefinite$.pipe(takeUntil(this.#destroy$)).subscribe((auth) => {
      if (auth.policies.length != 0) {
        this.#displayTourGuideDialog(() => {
          this.#displayAgreementsDialog();
          if (environment.production) {
            this.#displayDialogToPermittedUsers();
          } else {
            this.#displayMFADialog();
          }
        });
      }
    });

    this.collectionsMediatorService.addingToCollection$.pipe(takeUntil(this.#destroy$)).subscribe((assetsUuid) => {
      if (assetsUuid.length) {
        this.collectionsActionsService.addAssetsToCollection(assetsUuid);
      }
    });
  }

  ngOnDestroy(): void {
    this.#destroy$.next();
    this.#destroy$.complete();
    this.websocketInitializer.unregisterListeners();
  }

  ngOnInit() {
    this.selectedClientId$ = this.activatedClientService.clientDefinite$.pipe(map((client) => client.uuid));
    this.client$
      .pipe(
        takeUntil(this.#destroy$),
        switchMap(() => this.websocketInitializer.registerListeners()),
      )
      .subscribe();
  }

  #displayDialogToPermittedUsers(): void {
    this.authService.email$
      .pipe(
        take(1),
        map((email) => AVAILABLE_PRD_MAIL_SUFFIXES.some((suffix) => email.includes(suffix))),
        filter(Boolean),
        tap(() => this.#displayMFADialog()),
      )
      .subscribe();
  }

  #displayMFADialog(): void {
    this.mfaReminderDialogService.popMfaReminderDialog();
  }

  #displayAgreementsDialog(): void {
    this.licenseAgreementDialogService.popLicenseAgreementDialog();
  }

  #displayTourGuideDialog(callback: () => void): void {
    this.tourGuideService.openDialog(callback);
  }

  runTour() {
    this.tourGuideService.runGuide();
  }

  logout() {
    this.authService.logout().then();
  }

  async toggleTheme() {
    this.layoutSwitcher.toggleTheme();
  }

  toggleSidenav() {
    this.sidenavService.toggle();
  }
}
