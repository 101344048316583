import { AuthorizedClient, Permission } from '@vdms-hq/activated-client';
import { AnySidebarItem, SidebarItem } from '@vdms-hq/ui';
import { DISCOUNTS_ROUTER_BASE } from '@vdms-hq/discounts';
import { filterEmpty } from '@vdms-hq/shared';

export function adminNavItemsConfig(client: AuthorizedClient): AnySidebarItem[] {
  const configs = Object.entries(client.assetView).sort(([keyA], [keyB]) => keyA.localeCompare(keyB));
  const libraryConfigs = client.libraryConfigs ?? [
    {
      id: 'legacy-default',
      isClient: false,
      name: 'Client legacy default',
      structure: client.library.structure,
    },
  ];

  const isSalesForceClient = client.integrations.salesforce;

  const tablesAndLists: SidebarItem[] = [
    {
      name: 'Default',
      routerLink: '/admin/asset-tables/default',
    },
    {
      name: 'Deleted',
      routerLink: '/admin/asset-tables/deleted',
    },
    {
      name: 'Browse',
      routerLink: '/admin/asset-tables/browse',
    },
    {
      name: 'Cart',
      routerLink: '/admin/asset-tables/cart',
    },
    {
      name: 'Collections',
      routerLink: '/admin/asset-tables/collections',
    },
    {
      name: 'Downloads',
      routerLink: '/admin/asset-tables/shared-packs',
    },
    {
      name: 'Orders',
      routerLink: '/admin/asset-tables/orders',
    },
    {
      name: 'Upload jobs',
      routerLink: '/admin/asset-tables/upload-jobs',
    },
    {
      name: 'Content Corner',
      routerLink: '/admin/asset-tables/content-corner',
    },
    {
      name: 'Licensed Packages',
      routerLink: '/admin/asset-tables/license_package',
    },
  ];

  if (isSalesForceClient) {
    tablesAndLists.push({
      name: 'Preview request',
      routerLink: '/admin/asset-tables/preview-request',
    });
  }

  const navItems: (AnySidebarItem | undefined)[] = [
    {
      name: 'View configuration',
      routerLink: '/admin/general',
    },
    {
      name: 'Storage and Database',
      routerLink: '/admin/storage',
    },
    'separator',
    {
      name: 'Asset Tables and Lists',
      routerLink: '/admin/asset-tables/default',
      children: tablesAndLists,
    },
    {
      name: 'Asset filters',
      routerLink: '/admin/asset-filters/browse',
      children: [
        {
          name: 'Browse',
          routerLink: '/admin/asset-filters/browse',
        },
        {
          name: 'Deleted',
          routerLink: '/admin/asset-filters/deleted',
        },
        {
          name: 'Quarantined',
          routerLink: '/admin/asset-filters/quarantined',
        },
      ],
    },
    {
      name: 'Details & Form',
      routerLink: '/admin/asset-details/',
      children: [
        ...configs.map(([key, config]) => ({
          name: config.name,
          routerLink: `/admin/asset-details/${key}`,
        })),
        'separator',
        {
          name: 'Add new',
          routerLink: '/admin/asset-details/add',
        },
      ],
    },
    {
      name: 'Library browser',
      routerLink:
        libraryConfigs.length > 0 ? `/admin/browse-library/${libraryConfigs[0].id}` : '/admin/browse-library/add',
      children: [
        ...libraryConfigs.map((schema) => ({
          name: schema.name,
          routerLink: `/admin/browse-library/${schema.id}`,
        })),
        'separator',
        {
          name: 'Add new',
          routerLink: '/admin/browse-library/add',
        },
      ],
    },
    'separator',
    {
      name: 'Field definitions',
      routerLink: '/admin/fields',
    },
    {
      name: 'Field sources',
      routerLink: '/admin/field-sources',
    },
    {
      name: 'Mandatory fields',
      routerLink: 'mandatory-fields',
    },
    {
      name: 'Client languages',
      routerLink: 'languages',
    },
    {
      name: 'Video Codecs',
      routerLink: 'video-codecs',
    },
    {
      name: 'Filename Conventions',
      routerLink: 'metadata-recognition',
    },
    {
      name: 'Reindex Automatization',
      routerLink: 'reindex-automatization',
    },
    {
      name: 'Metadata snapshots',
      routerLink: 'metadata-snapshots',
    },
    client.permissions.includes(Permission.PERMISSION_BROWSE_QA_CONFIGS)
      ? {
          name: 'QA Configurations',
          routerLink: 'quality-control',
        }
      : undefined,

    'separator',
    client.permissions.includes(Permission.BROWSE_DISCOUNTS)
      ? {
          name: 'Discounts',
          routerLink: DISCOUNTS_ROUTER_BASE.ROOT,
        }
      : undefined,

    client.permissions.includes(Permission.BROWSE_RATES)
      ? {
          name: 'Billing configuration',
          routerLink: 'billing',
        }
      : undefined,
    'separator',
    {
      name: 'Content Corner',
      routerLink: 'content-corner-admin',
    },
    {
      name: 'Manage Notifications',
      routerLink: 'notification-subscriptions',
    },
    client.permissions.includes(Permission.MANAGE_QUARANTINE_CONFIGS)
      ? {
          name: 'Quarantine',
          routerLink: 'quarantine',
        }
      : undefined,

    'separator',
    {
      name: 'Aspera Keys',
      routerLink: 'aspera-keys',
    },
    client.permissions.includes(Permission.BROWSE_CREDENTIALS)
      ? {
          name: 'Credentials',
          routerLink: 'credentials',
        }
      : undefined,

    {
      name: 'Settings snapshots',
      routerLink: 'settings-snapshots',
    },
    'separator',

    client.permissions.includes(Permission.BROWSE_THRESHOLDS)
      ? {
          name: 'Global configuration',
          routerLink: 'global',
        }
      : undefined,
  ];

  return navItems.filter(filterEmpty) as AnySidebarItem[];
}
