export enum AssetViewComponent {
  PREVIEW = 'preview',
  ADVANCED_PLAYER = 'advanced_player',
  ADVANCED_PLAYER_DOLBY_RASP = 'advanced_player_dolby_rasp_extension',
  ADVANCED_PLAYER_DOLBY_RASP_SOURCE = 'advanced_player_dolby_rasp_source',
  ADVANCED_PLAYER_V2 = 'advanced_player_v2',
  ADD_TO_COLLECTION = 'add_to_collection',
  ADD_TO_LICENSE_PACKAGES = 'add_to_license_packages',
  ADD_TO_ORDER_TIMECODES = 'add_to_order_timecodes',
  ADD_TO_ORDER_VIRTUAL_ASSET = 'add_to_order_virtual_asset',
  ADD_TO_ORDER = 'add_to_order',
  LOG_TIMECODES = 'log_timecodes',
  TIMECODE_BUTTONS = 'timecode_buttons',
  GENERATE_STILL = 'generate_still',
  LIBRARY_SHORTCUT = 'library_shortcut',
  ASSET_REUPLOAD = 'reupload_asset',
  SAVE = 'save',
  FINISH = 'finish',
  DOWNLOAD_MASTER_FILE = 'download_master_file',
  DOWNLOAD_PROXY_FILE = 'download_proxy_file',
  RELATED_COLLECTIONS = 'related_collections',

  TRANSCRIPTION_RUN = 'transcription_run',
  MEDIA_INSIGHTS_WORKFLOW_RUN = 'media_insights_workflow_run',
  CONTENT_MODERATION_RUN = 'content_moderation_run',
  TIMELINE = 'timeline',
  DOWNLOAD_SUBTITLES = 'download_subtitles',
  CREATE_ASSETS_TRAILER = 'create_assets_trailer',
  ASSET_PDF_GENERATION = 'asset_pdf_generation',
}
