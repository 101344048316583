import { Route, RouterModule } from '@angular/router';
import { Injectable, Compiler, NgModuleFactory, NgModule } from '@angular/core';
import { SelectOptionsEditorModule } from '../../select-options-editor.module';
import { SelectorsDataSource } from './selectors-data-source';
import { SelectorsFetcher } from './selectors-fetcher';

@Injectable({
  providedIn: 'root',
})
export class DynamicModuleFactoryService {
  constructor(private compiler: Compiler) {}

  createDynamicModule(routes: Route[]): Promise<NgModuleFactory<any>> {
    @NgModule({
      imports: [RouterModule.forChild(routes)],
      providers: [SelectorsDataSource, SelectorsFetcher],
    })
    class DynamicModule extends SelectOptionsEditorModule {}

    return this.compiler.compileModuleAsync(DynamicModule);
  }
}
