import { Component, OnInit } from '@angular/core';
import { FormatBytesPipe, RenamePipe } from '@vdms-hq/shared';
import { FlatOrderViewModel, OrdersDataSource } from '../../logic/order-results-ds';
import { DynamicFilterInput, DynamicFiltersModule } from '@vdms-hq/dynamic-filters';
import { FiltersOrderForm } from '../../logic/filters-order-form';
import { sharedPacksFilters, sharedPacksInitialValues, sharedPacksMetadata } from '../../../logic/config';
import { ActivatedClientModule } from '@vdms-hq/activated-client';
import { CommonModule, DatePipe } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { ApiContractModule } from '@vdms-hq/api-contract';
import { ResultsComponent } from '../../components/results/results.component';
import { OrderMetadataTransformerService } from '../../../logic/order-metadata-transformer';
import { ORDERS_ROUTER_BASE } from '../../../orders-routing.module';
import {
  ordersColumns,
  ordersMetadata,
  sharedPacksViewConfiguration,
} from '../../../details/logic/view-configurations';
import { ResultsActions } from '@vdms-hq/asset-results';
import { OrderActionsService } from '../../../details/logic/order-actions.service';
import { AssetDownloadService } from '@vdms-hq/storage';
import { Router } from '@angular/router';
import { DefaultMultiViewType, MultipleViewConfiguration } from '@vdms-hq/ui';
import { TABLE_TYPE, ViewSettingsService } from '@vdms-hq/view-settings';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ColumnsSettingsScopes } from '@vdms-hq/firebase-contract';
import { ColumnsConfigService } from '../../logic/columns-config.service';

@Component({
  selector: 'vdms-hq-shared-packs-results',
  templateUrl: './shared-packs-results.component.html',
  imports: [
    CommonModule,
    DynamicFiltersModule,
    TranslateModule,
    ActivatedClientModule,
    ApiContractModule,
    ResultsComponent,
  ],
  providers: [
    OrdersDataSource,
    FiltersOrderForm,
    OrderMetadataTransformerService,
    FormatBytesPipe,
    RenamePipe,
    DatePipe,
    OrderActionsService,
    AssetDownloadService,
  ],
  standalone: true,
})
export class SharedPacksResultsComponent implements OnInit {
  filtersConfig: DynamicFilterInput[] = [];
  readonly tableType = TABLE_TYPE.DOWNLOAD;
  readonly columnsSettingsScope = ColumnsSettingsScopes.SHARED_PACKS_ITEMS;

  title = 'common.orders.shared_packs.title';
  actions = sharedPacksViewConfiguration.actions;

  configuration$: Observable<MultipleViewConfiguration<FlatOrderViewModel>> = this.viewSettingsService
    .defaultViewFor$(this.tableType)
    .pipe(
      map((defaultView) => {
        const metadata = ordersMetadata.filter((item) => sharedPacksMetadata.includes(item.valuePath));

        return {
          multiView: {
            defaultView: defaultView,
          },
          tableAdvanced: {
            actions: sharedPacksViewConfiguration.actions,
            columnsEnabled: ['packageTitle', 'downloadStatus', ...sharedPacksMetadata, 'actions'],
            columns: ordersColumns,
          },
          gridAdvanced: {
            content: {
              titlePath: 'title',
              metadata: metadata,
              additionalInfo: {
                valuePath: 'downloadStatus',
                viewFormat: {
                  pills: {
                    'common.orders.download.new': '',
                    'common.orders.download.downloaded_all': 'done',
                    'common.orders.download.downloaded': 'yellow',
                    'common.orders.download.expired': 'orange',
                    default: 'yellow',
                  },
                  modifiers: {
                    translate: true,
                  },
                },
              },
            },
            image: {
              bgPath: 'background',
              bgHoverPath: 'background',
            },
            actions: sharedPacksViewConfiguration.actions,
          },
          gridTiles: {
            layout: {
              columns: 'up-to-6',
            },
            image: {
              bgPath: 'background',
              bgHoverPath: 'background',
            },
            content: {
              titlePath: 'title',
            },
            actions: sharedPacksViewConfiguration.actions,
          },
        };
      }),
    );

  constructor(
    public dataSource: OrdersDataSource,
    public filtersForm: FiltersOrderForm,
    private orderService: OrderActionsService,
    private viewSettingsService: ViewSettingsService,
    private columnsConfigService: ColumnsConfigService,
    private router: Router,
  ) {}

  ngOnInit() {
    this.filtersConfig = sharedPacksFilters;
    this.filtersForm.filters.setValue(sharedPacksInitialValues);
  }

  handleAction($event: { item: FlatOrderViewModel; key: string }) {
    const { key, item } = $event;
    switch (key) {
      case ResultsActions.DOWNLOAD_ALL_ASPERA:
        this.#downloadAllAspera(item, false);
        break;
      case ResultsActions.DOWNLOAD_ALL_ASPERA_FOLDERS:
        this.#downloadAllAspera(item, true);
        break;
      case ResultsActions.PREVIEW:
        this.#preview(item);
        break;
      case ResultsActions.SETTINGS:
        this.columnsConfigService.popColumnsConfigDialog(this.columnsSettingsScope);
        break;
      default:
        console.log('Unknown action', key);
    }
  }

  #downloadAllAspera(item: FlatOrderViewModel, folders: boolean) {
    const { uuid } = item;
    this.orderService.downloadAll(uuid, folders);
  }

  #preview(item: FlatOrderViewModel) {
    const { uuid } = item;
    this.router.navigate([ORDERS_ROUTER_BASE.SHARED_PACKS + '/' + uuid]);
  }

  changeDefaultView($event: DefaultMultiViewType) {
    this.viewSettingsService.saveDefaultViewFor(this.tableType, $event);
  }
}
