<vdms-hq-ui-dialog-wrapper footerAlign="space-between" size="narrow">
  <h1 title class="mb-0">
    {{ title | translate }}
  </h1>
  <form [formGroup]="discountForm" content>
    <vdms-hq-ui-form-section layout="single" [divider]="false">
      <vdms-hq-ui-form-input-text
        formControlName="name"
        [label]="'pages.clients.discounts.dialogs.fields.name' | translate"
      ></vdms-hq-ui-form-input-text>
    </vdms-hq-ui-form-section>
    <vdms-hq-ui-form-section layout="grid-2-columns">
      <vdms-hq-ui-form-input-text
        formControlName="code"
        [label]="'pages.clients.discounts.dialogs.fields.code' | translate"
      ></vdms-hq-ui-form-input-text>
      <vdms-hq-ui-form-input-select
        formControlName="is_active"
        [selectOptions]="statusOptions"
        [label]="'pages.clients.discounts.dialogs.fields.status' | translate"
      ></vdms-hq-ui-form-input-select>
    </vdms-hq-ui-form-section>
    <vdms-hq-ui-form-section
      [formGroupName]="'multiplier'"
      [title]="'pages.clients.discounts.dialogs.fields.discounts_label' | translate"
      [titleSize]="'medium'"
      layout="grid-2-columns"
      [divider]="false"
    >
      <vdms-hq-ui-form-discount-multiplier
        formControlName="egress_price_per_gb"
        [label]="'pages.clients.discounts.dialogs.fields.egress' | translate"
      ></vdms-hq-ui-form-discount-multiplier>
      <vdms-hq-ui-form-discount-multiplier
        formControlName="restoration_price_per_gb"
        [label]="'pages.clients.discounts.dialogs.fields.restoration' | translate"
      ></vdms-hq-ui-form-discount-multiplier>
      <vdms-hq-ui-form-discount-multiplier
        formControlName="asset_processing_fee_price_per_asset"
        [label]="'pages.clients.discounts.dialogs.fields.asset_processing' | translate"
      ></vdms-hq-ui-form-discount-multiplier>
    </vdms-hq-ui-form-section>
    <ng-container [formGroupName]="'multiplier'">
      <vdms-hq-ui-form-section
        [formGroupName]="'transcode'"
        layout="grid-2-columns"
        [divider]="false"
        [title]="'pages.clients.discounts.dialogs.fields.transcode_label' | translate"
        [titleSize]="'medium'"
      >
        <vdms-hq-ui-form-discount-multiplier
          *ngFor="let control of transcode"
          [formControlName]="control"
          [label]="'pages.clients.discounts.dialogs.fields.' + control | translate"
        ></vdms-hq-ui-form-discount-multiplier>
      </vdms-hq-ui-form-section>
      <vdms-hq-ui-form-section
        [formGroupName]="'video_analysis'"
        layout="grid-2-columns"
        [divider]="false"
        [title]="'pages.clients.discounts.dialogs.fields.video_analysis_label' | translate"
        [titleSize]="'medium'"
      >
        <vdms-hq-ui-form-discount-multiplier
          *ngFor="let control of video_analysis"
          [formControlName]="control"
          [label]="'pages.clients.discounts.dialogs.fields.' + control | translate"
        ></vdms-hq-ui-form-discount-multiplier>
      </vdms-hq-ui-form-section>
    </ng-container>
  </form>
  <ng-container footer>
    <vdms-hq-ui-button color="secondary" (click)="closeDialog()">
      {{ 'common.global.cancel' | translate }}
    </vdms-hq-ui-button>
    <vdms-hq-ui-button
      *ngIf="editMode$ | async as editMode"
      color="primary"
      [loading]="isLoading$ | async"
      (click)="editMode === 'edit' ? edit() : create()"
      [disabled]="discountForm.invalid || discountForm.pristine"
    >
      {{ saveButton | translate }}
    </vdms-hq-ui-button>
  </ng-container>
</vdms-hq-ui-dialog-wrapper>
