import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { denormalizeTree, Option, RelationalOption } from '@vdms-hq/shared';
import { SelectorsDataSource } from '../../logic/services/selectors-data-source';
import { map, take } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';

type NestedOption = {
  uuid: string;
  label: string;
  siblings: { uuid: string; label: string }[];
} & { children: NestedOption[] };

@Component({
  selector: 'vdms-hq-select-options-tree-dialog.component',
  templateUrl: './select-options-tree-dialog.component.html',
})
export class SelectOptionsTreeDialogComponent implements OnInit {
  fields$ = new BehaviorSubject<NestedOption | null>(null);

  constructor(
    public selectOptionCreateDialogRef: MatDialogRef<SelectOptionsTreeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { option: Option },
    private fieldsDataSource: SelectorsDataSource,
  ) {}

  public close(): void {
    this.selectOptionCreateDialogRef.close();
  }

  ngOnInit(): void {
    this.fieldsDataSource.allData$
      .pipe(
        take(1),
        map((data) => {
          const fields = data.map((option) => {
            return {
              ...option,
              siblings: option.extra?.siblings ? JSON.parse(option.extra.siblings).siblings : null,
              restricted: option.extra ? option.extra.restricted : null,
              children: <string[]>[],
              hasChildren: false,
            };
          });

          fields.forEach((field) => {
            const parentId = field.parent_field?.uuid;
            if (!parentId) {
              return;
            }

            const found = fields.find((item) => item.uuid === parentId);
            if (!found) {
              return;
            }
            found.children.push(field.uuid);
            found.hasChildren = true;
          });

          const normalizedEntities: Record<
            string,
            {
              uuid: string;
              label: string;
              siblings: { uuid: string; label: string }[];
              restricted: string[] | null;
            } & { children: string[] }
          > = {};

          fields.forEach(
            (field) =>
              (normalizedEntities[field.uuid] = {
                uuid: field.uuid,
                label: `${field.type_name}: ${field.label}`,
                siblings: field.siblings,
                children: field.children,
                restricted: field.restricted,
              }),
          );

          const rootId = this.data.option.uuid;

          return denormalizeTree(normalizedEntities, rootId) as NestedOption;
        }),
      )
      .subscribe((items) => {
        this.fields$.next(items);
      });
  }
}
