import { AssetFlat, PersistenceFlatAsset } from '../asset';
import { ApprovalStatus } from './order-status.model';
import { TileModel } from '@vdms-hq/ui';

/** todo this looks like transformed model, shouldn't be in api-contract */
/**
 * @deprecated use AssetFlat instead
 */
export interface FlatAsset extends AssetFlat {
  tile: TileModel;
}

export interface OrderAssetModel {
  uuid: string;
  asset: PersistenceFlatAsset;
  approval_status: ApprovalStatus;
  download_uuid: string;
  downloaded?: boolean;
  delivery_destinations?: DeliveryDestination[];
  clipping_status: string;
  clip_sales_force_name: string;
  sales_force_uri: string;
  note: string;
  timecode_in: string;
  timecode_out: string;
  transcode_status?: TranscodeStatuses;
  transcode_progress: number;
  external_id?: string;
  publish_status: string;
  publish_progress: number;
}

export interface DeliveryDestination {
  name: string;
  uuid: string;
  configs: DeliveryDestinationConfig[];
  summary?: string;
}

export interface DeliveryDestinationConfig {
  name: string;
  path: string;
  jobs: GetOrderItemDeliveryDestinationJob[];
  uuid: string;
}

export interface GetOrderItemDeliveryDestinationJob {
  type: DeliveryDestinationJobTypeEnum;
  status: DeliveryDestinationJobStatusEnum;
}

export enum DeliveryDestinationJobTypeEnum {
  TRANSCODE = 'transcode',
  PUBLISH = 'publish',
}
export enum DeliveryDestinationJobStatusEnum {
  SUBMITTED = 'submitted',
  STARTED = 'started',
  FAILED = 'failed',
  COMPLETED = 'completed',
  NOT_INITIALIZED = 'not_initialized',
}

export type OrderDownloadPayload = { download_uuids: string[]; keep_folders_structure: boolean; config_uuid?: string };

export interface OrderPatchItemModel {
  uuid: string;
  status: 'approved' | 'rejected';
}

export interface RetryDeliveryDestinationJob {
  item_uuid: string;
  config_uuid: string;
  status: 'success' | 'error';
  message: string;
}

export interface RetryDeliveryDestination {
  jobs: Omit<RetryDeliveryDestinationJob, 'status' | 'message'>[];
}

export type TranscodeStatuses = 'submitted' | 'in-progress' | 'completed' | 'failed';
