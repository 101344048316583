import { APP_INITIALIZER, ErrorHandler, InjectionToken, Provider } from '@angular/core';
import * as Sentry from '@sentry/angular-ivy';
import { Router } from '@angular/router';
import { AppErrorHandlerService } from './app-error-handler.service';
import { AuthService } from '@vdms-hq/auth';
import { ActivatedClientService } from '@vdms-hq/activated-client';

export interface SentryConfig {
  printInConsole: boolean;
}

export const SENTRY_CONFIG: InjectionToken<SentryConfig> = new InjectionToken<SentryConfig>('SENTRY_CONFIG');

export const configureSentry = (params: SentryConfig) => {
  const providers: Provider[] = [
    {
      provide: ErrorHandler,
      useClass: AppErrorHandlerService,
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (auth: AuthService, client: ActivatedClientService) => {
        return () => {
          auth.auth$.subscribe((user) => {
            if (user) {
              Sentry.setUser({ email: user.email, id: user.id });
            } else {
              Sentry.setUser(null);
            }
          });

          client.clientId$.subscribe((clientId) => {
            Sentry.setTags({
              'client.id': clientId,
            });
          });
          return;
        };
      },
      deps: [AuthService, ActivatedClientService],
      multi: true,
    },
    { provide: SENTRY_CONFIG, useValue: params },
  ];

  return providers;
};
