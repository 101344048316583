import { Injectable } from '@angular/core';
import { ApiPaginatedResponse, ApiResponse } from '../api.model';
import { ApiService } from '../api.service';
import { GetResponseData, GetResponsePaginationData } from '../../operators/get-response-data.operator';
import {
  MediaInsightsWorkflowParamsRequest,
  MediaInsightsWorkflowParamsResponse,
  MediaInsightsWorkflowStatus,
  VideoAnalysisAiModel,
  VideoAnalysisAiModelRequest,
  VideoAnalysisJob,
  VideoAnalysisJobResponse,
  VideoAnalysisResultsRequest,
  VideoAnalysisResultsResponse,
  VideoValidateResponse,
} from './media-insights.model';
import { Observable } from 'rxjs';
import { MediaInsightsEndpoints, MediaInsightsOptionEndpoints } from './media-insights-endpoint.model';
import { HttpHeaders } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class MediaInsightsApiService {
  constructor(private apiService: ApiService) {}

  getModels = (): Observable<VideoAnalysisAiModel[]> =>
    this.apiService
      .get<ApiResponse<VideoAnalysisAiModel[]>>(`${MediaInsightsEndpoints.ROOT}/${MediaInsightsOptionEndpoints.MODELS}`)
      .pipe(GetResponseData);

  validateWorkflowUuids = (payload: VideoAnalysisAiModelRequest): Observable<VideoValidateResponse> =>
    this.apiService
      .post<VideoAnalysisAiModelRequest, ApiResponse<VideoValidateResponse>>(
        `${MediaInsightsEndpoints.ROOT}/${MediaInsightsOptionEndpoints.WORKFLOW_VALIDATE}`,
        payload,
      )
      .pipe(GetResponseData);

  getFile = (url: string) => {
    return this.apiService
      .getAbsoluteUrl<ApiPaginatedResponse<VideoAnalysisResultsResponse>>(url)
      .pipe(GetResponseData);
  };

  getResults = (
    assetUuid: string,
    options: { perPage: number; filters?: string },
    payload: VideoAnalysisResultsRequest,
  ) => {
    const headers = new HttpHeaders({ 'per-page': options.perPage.toString() });
    if (options.filters) {
      headers.append('filters', options.filters);
    }
    return this.apiService
      .post<VideoAnalysisResultsRequest, ApiPaginatedResponse<VideoAnalysisResultsResponse>>(
        `${MediaInsightsEndpoints.ROOT}/${assetUuid}/${MediaInsightsOptionEndpoints.RESULTS}`,
        payload,
        headers,
      )
      .pipe(GetResponsePaginationData);
  };

  getJobs = (assetUuid: string): Observable<VideoAnalysisJobResponse> =>
    this.apiService
      .get<ApiResponse<VideoAnalysisJobResponse>>(
        `${MediaInsightsEndpoints.ROOT}/${assetUuid}/${MediaInsightsOptionEndpoints.JOBS}`,
      )
      .pipe(GetResponseData);

  /**
   * @deprecated we use shopping cart / making order instead
   * @param json
   * @param assetUuid
   */
  runWorkflow = (
    json: MediaInsightsWorkflowParamsRequest,
    assetUuid: string,
  ): Observable<MediaInsightsWorkflowParamsResponse> =>
    this.apiService.post<MediaInsightsWorkflowParamsRequest, MediaInsightsWorkflowParamsResponse>(
      `${MediaInsightsEndpoints.ROOT_OLD}/${MediaInsightsOptionEndpoints.EXECUTE_WORKFLOW}/${assetUuid}`,
      json,
    );

  /**
   * @deprecated use getResults / getFile instead
   * @param workflowUuid
   * @param operator
   */
  getStatusWorkflow = (workflowUuid: string, operator: string): Observable<MediaInsightsWorkflowStatus> =>
    this.apiService
      .get<ApiResponse<MediaInsightsWorkflowStatus>>(
        `${MediaInsightsEndpoints.ROOT_OLD}/${MediaInsightsOptionEndpoints.METADATA}/${operator}/${workflowUuid}`,
      )
      .pipe(GetResponseData);
}
