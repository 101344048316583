export enum VideoAnalysisModelContext {
  LABEL_DETECTION = 'label-detection',
  FACE_DETECTION = 'face-detection',
  CELEBRITY_RECOGNITION = 'celebrity-recognition',
  VIDEO_SEGMENT_DETECTION = 'video-segment-detection',
  TEXT_DETECTION = 'text-detection',
  CONTENT_MODERATION = 'content-moderation',
  TRANSCRIBE_VIDEO = 'transcribe-video',
  TRANSLATE_SUBTITLES = 'translate-subtitles',
  TRANSLATE_VIDEO = 'translate-video',
  VIDEO_SUMMARY = 'video-summary',
  LANGUAGE_DETECTION = 'language-detection',
  VIDEO_STATISTICS = 'video-statistics',
  VIDEO_BORDERS_DETECTION_FULL = 'video-borders-detection-full',
  VIDEO_BORDERS_DETECTION_QUICK = 'video-borders-detection-quick',
  AUDIO_STATISTICS = 'audio-statistics',
}

export interface VideoAnalysisAiModel {
  uuid: string;
  name: string;
  type: string;
  context: string;
  dependsOn?: string; // context
}

export interface VideoAnalysisAiModelRequest {
  model_uuids: string[];
  options?: {
    target_languages?: string[];
  };
  name?: string;
}

export interface VideoValidateResponse {
  issues: Issue[];
  other_assets: AssetItemType[];
  cold_assets: AssetItemType[];
  clip_assets: AssetItemType[];
}

export interface Issue {
  message: string;
  can_proceed: boolean;
  assets_to_delete: string[];
}

export interface AssetItemType {
  uuid: string;
  name: string;
}

export interface VideoAnalysisJobResponse {
  merged_results_url: string;
  jobs: VideoAnalysisJob[];
}

export interface VideoAnalysisJob {
  type: VideoAnalysisModelContext;
  status: 'created' | 'in_progress' | 'completed' | 'failed' | 'scheduled';
  json_url: string | null;
  vtt_url: string | null;
  result_urls: VideoAnalysisResultsUrl[];
  target_language?: string;
  created_at: string;
  updated_at: string;
  name: string;
}

export interface VideoAnalysisResultsUrl {
  type: 'vtt' | 'json' | 'pdf';
  url: string;
  label?: string;
}

export interface VideoAnalysisResultsRequest {
  context?: VideoAnalysisModelContext[];
  start_from?: number;
  confidence?: number;
  timestamp?: number;
  search?: string[];
}

export interface VideoAnalysisResultsResponse {
  id: number;
  start: number;
  end?: number;
  type: VideoAnalysisModelContext;
  confidence: number;
  data: VideoAnalysisJobItemData;
}

export interface VideoAnalysisJobItemData {
  content: string | number;
  parent?: string;
  translations?: Translation[];
  instances?: {
    BoundingBox: BoundingBox;
    Urls?: string[];
    Width?: number;
    Height?: number;
    Left?: number;
    Top?: number;
    start?: number;
  }[];
}

interface BoundingBox {
  Width?: number;
  Height?: number;
  Left?: number;
  Top?: number;
}

export interface Translation {
  content: string;
  language: string;
}

/**
 * @deprecated use media-insights getJobs instead
 */
export interface Workflow {
  uuid: string;
  params: MediaInsightsWorkflowParamsRequest;
}

/**
 * @deprecated use media-insights getResults/getFile instead
 */
export interface MediaInsightsObjectRecognitionMetadata {
  Label: {
    Confidence: string;
    Name: string;
    Parents: { Name: string }[];
    Instances: VideoObjectRectangle[];
  };
  Timestamp: number;
}
/**
 * @deprecated use media-insights getResults/getFile instead
 */
export interface MediaInsightsTechnicalCueDetectionMetadata {
  DurationMillis: number;
  DurationSMPTE: string;
  EndTimecodeSMPTE: string;
  EndTimestampMillis: number;
  StartTimecodeSMPTE: string;
  StartTimestampMillis: number;
  TechnicalCueSegment: {
    Confidence: string;
    Type: string;
  };
  Type: string;
}
/**
 * @deprecated use media-insights getResults/getFile instead
 */
export interface MediaInsightsCelebrityRecognitionMetadata {
  Timestamp: number;
  Celebrity: {
    Name: string;
    Confidence: string;
    Face: VideoObjectRectangle[];
  };
}
/**
 * @deprecated use media-insights getResults/getFile instead
 */
export interface MediaInsightsTranscriptionMetadata {
  accountId: string;
  jobName: string;
  results: {
    items: MediaInsightsTranscriptionMetadataResultsItem[];
  };
  status: string;
}

/**
 * @deprecated use media-insights getResults/getFile instead
 */
export interface MediaInsightsSubtitlesMetadata {
  caption: string;
  end: string;
  sourceCaption: string;
  start: string;
}

/**
 * @deprecated use media-insights getJobs instead
 */
export interface MediaInsightsWorkflowParamsRequest {
  transcribe?: {
    sourceLanguage: string;
  };
  subtitles?: {
    targetLanguages: string[];
  };
  objectRecognition?: boolean;
  celebrityRecognition?: boolean;
  technicalCueDetection?: boolean;
  shotDetection?: boolean;
}

/**
 * @deprecated use shopping cart / making order instead
 */
export interface MediaInsightsWorkflowParamsResponse {
  uuid: string;
  status: string;
}

/**
 * @deprecated use media-insights getJobs instead
 */
export interface MediaInsightsWorkflowStatus {
  workflow_uuid: string;
  status: 'pending' | 'started' | 'completed' | 'error';
  vtt?: string;
  metadata:
    | MediaInsightsObjectRecognitionMetadata[]
    | MediaInsightsTechnicalCueDetectionMetadata[]
    | MediaInsightsCelebrityRecognitionMetadata[]
    | MediaInsightsSubtitlesMetadata[]
    | MediaInsightsTranscriptionMetadata;
}

/**
 * @deprecated use media-insights getJobs instead
 */
export interface MediaInsightsTranscriptionMetadataResultsItem {
  alternatives: {
    confidence: string;
    content: string;
  }[];
  end_time: string;
  start_time: string;
  type: string;
}

export interface VideoObjectRectangle {
  BoundingBox: VideoObjectMeasurement;
  Confidence: string;
}

export interface VideoObjectMeasurement {
  Height: string;
  Left: string;
  Top: string;
  Width: string;
}
