import { Component, EventEmitter, forwardRef, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatChipsModule } from '@angular/material/chips';
import { FormControlValueAccessorComponent } from '../../models/form/inputs/form-control-value-accessor.component';
import { FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { SharedModule } from '@vdms-hq/shared';
import { MatIconModule } from '@angular/material/icon';
import { ChipDetailsToText } from './logic/chip-detail-to-text.pipe';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
  selector: 'vdms-hq-ui-advanced-chip',
  standalone: true,
  imports: [CommonModule, MatChipsModule, MatIconModule, ChipDetailsToText, SharedModule, MatTooltipModule],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => AdvancedChipComponent),
    },
  ],
  templateUrl: './advanced-chip.component.html',
  styleUrls: ['./advanced-chip.component.scss'],
})
export class AdvancedChipComponent extends FormControlValueAccessorComponent<boolean, boolean> implements OnChanges {
  innerFormControl = new FormControl<boolean>(false);
  @Input() icon?: string;
  @Input() details?: Array<string>;
  @Input() tooltip?: string;
  @Input() disabled?: boolean;
  @Input() value?: boolean | null;
  @Output() detailClick = new EventEmitter<string>();

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.value?.currentValue !== undefined) {
      this.innerFormControl.setValue(!!changes?.value?.currentValue);
    }
  }

  emitDetail(detail: string) {
    this.detailClick.emit(detail);
  }
}
