import { Injectable } from '@angular/core';
import {
  AudioMediaTrack,
  DomainUtil,
  MasterManifest,
  SessionData,
  StringUtil,
  TextMediaTrack,
  VideoMediaTrack,
  WindowService,
} from '@vdms-hq/omakase-player';
import { z } from 'zod';
import { BehaviorSubject, Observable } from 'rxjs';
import { MediaPlaylist } from 'hls.js';

@Injectable({
  providedIn: 'root',
})
export class PlayerSessionService {
  readonly SESSION_URL = 'https://d21qumc5zimnej.cloudfront.net/ref/ref-2_24fps_demo_v1.json';

  masterManifests?: MasterManifest[];
  currentMasterManifest?: MasterManifest;
  sessionData?: SessionData;

  videoMediaTracks?: VideoMediaTrack[];
  audioMediaTracks?: AudioMediaTrack[];
  textMediaTracks?: TextMediaTrack[];
  ruid$ = new BehaviorSubject<string | null>(null);

  constructor(private windowService: WindowService) {}

  validateSessionData(sessionData: SessionData): boolean {
    const zodObject = z.object({
      security_token: z.string(),
      data: z.object({
        master_manifests: z
          .array(
            z.object({
              id: z.string(),
              name: z.string(),
              // frame_rate: z.string(),
              url: z.string(),
            }),
          )
          .min(1), // minimum of 1 master_manifest
        media_tracks: z
          .object({
            video: z
              .array(z.object({}))
              .min(0)
              .max(1) // to be relaxed in future
              .optional(),
            audio: z.array(z.object({})).min(0).optional(),
          })
          .refine(
            ({ video, audio }) => {
              return (video !== undefined && video.length > 0) || (audio !== undefined && audio.length > 0);
            },
            {
              message: 'Either video or audio must be provided',
            },
          ),
      }),
    });

    try {
      const parse = zodObject.parse(sessionData);

      const masterManifests = sessionData.data.master_manifests.filter((p) => this.isManifestSupported(p));
      if (masterManifests.length < 1) {
        throw new Error(`Could not find supported master manifests`);
      }

      return true;
    } catch (e) {
      console.error(e);
      return false;
    }
  }

  isManifestSupported(masterManifest: MasterManifest): boolean {
    return !(
      this.windowService.userAgent !== 'safari' &&
      masterManifest.color_range &&
      masterManifest.color_range !== 'sdr'
    );
  }
}
