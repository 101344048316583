import { inject, Injectable } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { BehaviorSubject, map, Observable, shareReplay, startWith, take, tap, withLatestFrom } from 'rxjs';
import { ToastService } from '@vdms-hq/toast';
import { ActivatedClientService } from '@vdms-hq/activated-client';
import { FieldsConfigService } from '@vdms-hq/config';
import { ClientContentDetailsFilters, ClientContentStructure } from '@vdms-hq/firebase-contract';
import { camelCase } from 'lodash';
import { Primitive } from '@angular/fire/compat/database/interfaces';
import { SelectOption } from '@vdms-hq/shared';
import { Policy, PolicyService } from '@vdms-hq/api-contract';
import { v4 as uuidv4 } from 'uuid';
import { UIConfirmationDialogService } from '@vdms-hq/ui';
import { filter } from 'rxjs/operators';

type StructureFormGroup = FormGroup<{
  filters: FormArray<DetailsFilters> | DetailsFilters;
  default: FormControl<boolean>;
  label: FormControl<string | null>;
  key: FormControl<string | null>;
  fields: FormControl<string[]>;
  restrictedToPolicies: FormControl<string[]>;
  downloadAllVisibleOverrideGlobal: FormControl<boolean>;
  downloadAllVisible: FormControl<boolean>;
  selectAllVisibleOverrideGlobal: FormControl<boolean>;
  selectAllVisible: FormControl<boolean>;
}>;

type DetailsFilters = FormGroup<{
  filterKey: FormControl<string | null>;
  filterValue: FormControl<Array<Primitive> | string | null>;
}>;

@Injectable()
export class FormService {
  private policy = inject(PolicyService);
  private toastService = inject(ToastService);
  private activatedClientService = inject(ActivatedClientService);
  private activatedClientConfigService = inject(FieldsConfigService);

  private readonly FIREBASE_KEY = 'contentCorner';

  constructor(private confirmationDialog: UIConfirmationDialogService) {}

  isSaving$ = new BehaviorSubject<boolean>(false);

  #form = new FormGroup({
    home: new FormGroup({
      hero: new FormGroup({
        title: new FormControl<string | null>(null),
        intro: new FormControl<string | null>(null),
      }),
    }),
    details: new FormGroup({
      filterKey: new FormControl<string | null>(null),
      filtersVisible: new FormControl<boolean>(false),
      downloadAllVisible: new FormControl<boolean>(true),
      selectAllVisible: new FormControl<boolean>(true),
      structure: new FormArray<StructureFormGroup>([]),
    }),
    support: new FormGroup({
      enabled: new FormControl<boolean>(false, { nonNullable: true }),
      icon: new FormControl<string>({ value: '', disabled: true }, { nonNullable: true }),
      label: new FormControl<string>({ value: '', disabled: true }, { nonNullable: true }),
      url: new FormControl<string>({ value: '', disabled: true }, { nonNullable: true }),
    }),
  });

  filtersDefinitions$ = this.activatedClientConfigService.filterDefinitionsForAssetFiltersAdmin$;
  contentCornerConfig$ = this.activatedClientService.clientDefinite$.pipe(
    take(1),
    map((keys) => keys?.[this.FIREBASE_KEY]),
  );

  #policies$ = this.policy.getPolicies().pipe(
    startWith([]),
    withLatestFrom(this.activatedClientService.clientIdDefinite$),
    map(([policies, clientId]) => policies.filter((policy) => policy.groups?.find((group) => group.uuid === clientId))),
    shareReplay(1),
  );

  policiesLoading$ = this.#policies$.pipe(
    map((policies) => policies.length === 0),
    shareReplay(1),
  );

  emptyPolicies$: Observable<SelectOption[]> = this.#policies$.pipe(
    map((policies) =>
      policies.filter((policy) => policy.permissions?.length === 0).map((policy) => this.#policyToSelectOption(policy)),
    ),
    shareReplay(1),
  );

  allPolicies$: Observable<SelectOption[]> = this.#policies$.pipe(
    tap((policies) => {
      const empty = policies
        .filter((policy) => policy.permissions?.length === 0)
        .map((policy) => this.#policyToSelectOption(policy));
      const nonEmpty = policies
        .filter((policy) => policy.permissions?.length !== 0)
        .map((policy) => this.#policyToSelectOption(policy));
      this.#verifyPolicies(empty, nonEmpty);
    }),
    map((policies) => policies.map((policy) => this.#policyToSelectOption(policy))),
    shareReplay(1),
  );

  nonEmptyPolicies$: Observable<SelectOption[]> = this.#policies$.pipe(
    map((policies) =>
      policies.filter((policy) => policy.permissions?.length !== 0).map((policy) => this.#policyToSelectOption(policy)),
    ),
    shareReplay(1),
  );

  get structureFormArray(): FormArray<StructureFormGroup> {
    return this.detailsGroup.get('structure') as FormArray;
  }

  get homeGroup(): FormGroup {
    return this.#form.get('home') as FormGroup;
  }

  get detailsGroup(): FormGroup {
    return this.#form.get('details') as FormGroup;
  }

  get supportGroup(): FormGroup {
    return this.#form.get('support') as FormGroup;
  }

  get form() {
    return this.#form;
  }

  get filterVisible() {
    return this.#form.get('details.filtersVisible')?.value;
  }

  getFiltersFormArray(index: number): FormArray<DetailsFilters> {
    return this.structureFormArray.at(index)?.get('filters') as FormArray;
  }

  createStructureGroup(value: Partial<ClientContentStructure> | null = null): FormGroup {
    return new FormGroup({
      uniqueKey: new FormControl<string>({ value: uuidv4(), disabled: true }),
      filters: new FormArray<DetailsFilters>([]),
      default: new FormControl<boolean>(value?.default ?? false),
      label: new FormControl<string | null>(value?.label ?? null),
      key: new FormControl<string | null>(value?.key ?? null),
      fields: new FormControl<string[]>(value?.fields ?? []),
      restrictedToPolicies: new FormControl<string[]>(value?.restrictedToPolicies ?? []),
      downloadAllVisibleOverrideGlobal: new FormControl<boolean>(value?.downloadAllVisibleOverrideGlobal ?? false),
      downloadAllVisible: new FormControl<boolean>({
        value: value?.downloadAllVisible ?? false,
        disabled: !value?.downloadAllVisibleOverrideGlobal ?? true,
      }),
      selectAllVisibleOverrideGlobal: new FormControl<boolean>(value?.selectAllVisibleOverrideGlobal ?? false),
      selectAllVisible: new FormControl<boolean>({
        value: value?.selectAllVisible ?? false,
        disabled: !value?.selectAllVisibleOverrideGlobal ?? true,
      }),
    });
  }

  createDetailsFiltersGroup(value: Partial<ClientContentDetailsFilters> | null = null): FormGroup {
    return new FormGroup({
      filterKey: new FormControl<string | null>(value?.filterKey ?? null),
      filterValue: new FormControl<Array<Primitive> | string | null>(
        this.#filterValuesPrepare(value?.filterValue ?? []),
      ),
    });
  }

  trackStructureBy = (index: number, form: FormGroup) => {
    const { uniqueKey } = form.getRawValue();
    return `${index}_${uniqueKey}`;
  };
  trackFiltersBy = (key: number) => key;

  init() {
    this.contentCornerConfig$.subscribe((client) => {
      this.#form.patchValue({
        home: client?.home,
        details: client?.details,
        support: client?.support,
      });
      (client?.details?.structure ?? []).map((element, key) => {
        this.structureFormArray.push(this.createStructureGroup(element));

        if (Array.isArray(element.filters)) {
          element.filters.map((filter) => this.getFiltersFormArray(key).push(this.createDetailsFiltersGroup(filter)));
          return;
        }

        if (element.filterKey) {
          this.getFiltersFormArray(key).push(
            this.createDetailsFiltersGroup({
              filterKey: element.filterKey,
              filterValue: element.filterValue,
            }),
          );
        }
      });
    });
  }

  save() {
    if (this.#form.invalid) {
      this.#form.markAllAsTouched();
      this.toastService.error({
        id: 'save-error',
        message: 'notifications.save.failed',
      });
      return;
    }

    this.isSaving$.next(true);
    const value = {
      [this.FIREBASE_KEY]: {
        home: this.homeGroup?.value,
        details: this.detailsGroup?.value,
        support: this.supportGroup?.value,
      },
    };

    this.activatedClientService.update(value).subscribe({
      next: () => {
        this.toastService.success({
          id: 'update_field_success',
          message: 'common.notifications.clients.update.success',
        });
        this.isSaving$.next(false);
      },
      error: () => {
        this.toastService.error({
          id: 'update_field_failure',
          message: 'common.notifications.clients.update.failure',
        });
        this.isSaving$.next(false);
      },
    });
  }

  addNewTab(name: string) {
    this.structureFormArray.push(
      this.createStructureGroup({
        key: camelCase(name),
        label: name,
        fields: [],
        default: false,
        filters: [],
      }),
    );
  }

  addNewFilter(index: number) {
    this.getFiltersFormArray(index).push(this.createDetailsFiltersGroup());
  }

  removeFilter(tabIndex: number, filterIndex: number) {
    this.getFiltersFormArray(tabIndex).removeAt(filterIndex);
  }

  #filterValuesPrepare(value: Array<Primitive> | string | null = null) {
    if (!value) {
      return null;
    }

    if (Array.isArray(value)) {
      return value;
    }

    return [value];
  }

  #verifyPolicies(emptyPolicies: SelectOption<string>[], nonEmptyPolicies: SelectOption<string>[]) {
    if (!emptyPolicies.length) {
      return;
    }

    const formPolicies = [
      ...new Set(
        this.structureFormArray.value
          .map((structure) => structure.restrictedToPolicies)
          .filter((subArray) => subArray?.some(Boolean))
          .reduce((prev, curr) => prev?.concat(curr), []),
      ),
    ];

    const flatNonEmptyPolicies = nonEmptyPolicies.map((policy) => policy.key);
    const notEmptySaved = [
      ...new Set([
        ...flatNonEmptyPolicies.filter((item) => formPolicies.includes(item)),
        ...formPolicies.filter((item) => flatNonEmptyPolicies.includes(item)),
      ]),
    ];

    if (formPolicies.length && notEmptySaved.length) {
      this.removePoliciesFromRestrictedPoliciesArray(notEmptySaved, nonEmptyPolicies);
    }
  }

  #policyToSelectOption(policy: Policy) {
    return {
      key: policy.uuid,
      label: policy.name,
    };
  }

  removePoliciesFromRestrictedPoliciesArray(policiesToRemove: string[], nonEmptyPolicies: SelectOption[]) {
    const policiesToRemoveLabels = policiesToRemove
      .map((policy) => {
        return nonEmptyPolicies.filter((item) => item.key === policy).map((policy) => policy.label);
      })
      .reduce((prev, curr) => prev?.concat(curr), []);

    this.confirmationDialog
      .open({
        title: 'Policy validation',
        message: `Non-empty policies detected:<br/><br/>${policiesToRemoveLabels.join(
          '<br/>',
        )}<br/><br/>For security reasons you can select only policies without permissions for this client.<br/>Do you want to remove them from config to continue?`,
        abortAction: {
          label: 'No',
          color: 'secondary',
        },
        okAction: {
          label: 'Yes',
          color: 'primary',
        },
      })
      .pipe(
        filter(Boolean),
        map(() =>
          this.structureFormArray.patchValue(
            this.structureFormArray.value.map((value) => ({
              ...value,
              restrictedToPolicies: value.restrictedToPolicies.filter((policy) => !policiesToRemove.includes(policy)),
            })),
          ),
        ),
      )
      .subscribe();
  }

  detachPolicy(
    control: FormGroup<{
      restrictedToPolicies: FormControl<string[]>;
    }>,
    policyID: string,
  ) {
    control.controls.restrictedToPolicies.setValue(
      (control.value?.restrictedToPolicies ?? []).filter((item) => item !== policyID),
    );
  }

  setDisabledState(control: FormControl<boolean>, disabled: boolean) {
    if (disabled) {
      control.disable();
      return;
    }
    control.enable();
  }
}
