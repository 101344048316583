import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { SelectedFilesService } from './native-upload-component/selected-files.service';
import { NativeUploadComponent } from './native-upload-component/native-upload.component';
import { DragAndDropDirective } from './native-upload-component/drag-and-drop.directive';
import { UIButtonModule } from '../ui-button';
import { TranslateModule } from '@ngx-translate/core';
import { UILoaderModule } from '../ui-loader';

@NgModule({
  declarations: [NativeUploadComponent, DragAndDropDirective],
  exports: [NativeUploadComponent, DragAndDropDirective],
  imports: [CommonModule, MatIconModule, UIButtonModule, TranslateModule, UILoaderModule],
  providers: [SelectedFilesService],
})
export class UINativeUploadModule {}
