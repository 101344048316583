<div *ngIf="!emptyQueryParams" class="wrapper d-flex flex-nowrap align-items-baseline">
  <span class="applied_filter__label">{{ 'pages.home.search.applied_filters' | translate }}</span>
  <div class="applied-filter__chip-list">
    <button type="button" class="applied-filter__clear-button" (click)="onClearAll()" *ngIf="!isQuarantineOrDeleted()">
      {{ 'tooltip.clear_all' | translate }}
    </button>
    <ng-container *ngFor="let entry of filters | keyvalue">
      <span class="applied-filter__chip" *ngFor="let value of entry.value.value | toIterable; index as idx">
        <span class="applied-filter__label">{{ entry.value | appliedFilterLabel : idx }}: </span>
        <span
          class="applied-filter__value"
          *ngIf="value | appliedFilterFormat : entry.value.filterDef | async; let value; else: emptyValue"
          >{{ value }}</span
        >
        <mat-icon
          class="applied-filter__delete"
          *ngIf="canDeleteFilter(entry)"
          (click)="onRemove(value, entry.key, idx)"
          >clear</mat-icon
        >
      </span>
    </ng-container>
  </div>
</div>

<ng-template #emptyValue>
  <span class="applied-filter__value applied-filter__value--empty">[ empty ]</span>
</ng-template>
