export interface TagHint {
  name: string;
  related: string[];
  type: string;
}

export enum TagSubject {
  ANIMAL = 'animal',
  GENERAL = 'general',
}

export enum TagEndpoints {
  ROOT = 'tag',
}
