<div class="menu-wrapper">
  <vdms-hq-ui-loader [mode]="'over-parent'" [backdrop]="true" *ngIf="loading || items.length === 0"></vdms-hq-ui-loader>

  <div *ngFor="let item of items; trackBy: trackBy; let index = index" class="menu-item">
    <vdms-hq-ui-inner-sidebar-link
      [item]="item"
      [hasChildren]="isNestedItem(item)"
      [expandable]="allowExpand && isNestedItem(item)"
      (toggle)="toggle(index)"
      [expanded]="isExpanded(index)"
    ></vdms-hq-ui-inner-sidebar-link>

    <div class="children" *ngIf="isNestedItem(item)" [class.children--expanded]="!allowExpand || isExpanded(index)">
      <vdms-hq-ui-inner-sidebar-link
        [item]="item"
        [smaller]="true"
        [expandable]="false"
        *ngFor="let item of $composite(item).children; trackBy: trackBy"
      >
      </vdms-hq-ui-inner-sidebar-link>
    </div>
  </div>

  <div class="action-link" *ngIf="actionLink" (click)="action.emit({ key: actionLink.key })">
    {{ actionLink.label | translate }}
  </div>
</div>
