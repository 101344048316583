<mat-divider *ngIf="isSeparator(item)" class="divider"></mat-divider>

<div class="link" *ngIf="item && isItem(item)" [class.link--smaller]="smaller" [class.link--has-children]="hasChildren">
  <span
    routerLinkActive="link__body--active"
    [routerLinkActiveOptions]="{ exact: false }"
    [routerLink]="$sidebarItem(item).routerLink ?? []"
    class="link__body"
  >
    {{ $sidebarItem(item).name | translate }}
  </span>
  <span *ngIf="$sidebarItem(item).additionalInfo" class="link__additional">{{
    ' | ' + $sidebarItem(item).additionalInfo
  }}</span>
  <mat-icon *ngIf="expandable" mat-ripple class="link__icon" (click)="toggle.emit()">{{
    expanded ? 'keyboard_arrow_up' : 'keyboard_arrow_down'
  }}</mat-icon>
</div>
<sup *ngIf="$sidebarItem(item)?.superscript as script" class="ml-3">{{ script }}</sup>
