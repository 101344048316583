import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgPipesModule } from 'ng-pipes';
import { AdvancedPlayerService } from '../../logic/advanced-player.service';
import { UIButtonModule, UIEmptyResultsModule, UILoaderModule } from '@vdms-hq/ui';
import { MatMenuModule } from '@angular/material/menu';
import { Timecode } from '@vdms-hq/timecode';
import { TranslateModule } from '@ngx-translate/core';
import { MatSliderModule } from '@angular/material/slider';
import { PeakMeterService } from '../../logic/peak-meter.service';
import { take, withLatestFrom } from 'rxjs/operators';
import { AdvancedPlayerControlsComponent } from '../advanced-player-controls/advanced-player-controls.component';

@Component({
  selector: 'vdms-hq-advanced-player',
  templateUrl: './advanced-player.component.html',
  standalone: true,
  imports: [
    CommonModule,
    NgPipesModule,
    UIButtonModule,
    MatMenuModule,
    MatSliderModule,
    TranslateModule,
    UILoaderModule,
    UIEmptyResultsModule,
    AdvancedPlayerControlsComponent,
  ],
})
export class AdvancedPlayerComponent implements OnInit, OnDestroy {
  #timeoutID: NodeJS.Timeout | number | null = null;

  fallbackTimecode = Timecode.fromSeconds(0);

  spriteCss: Record<string, string | number> | null = null;

  constructor(public advancedPlayerService: AdvancedPlayerService, public peakMeterService: PeakMeterService) {}

  @ViewChild('playerContainer', { read: ElementRef }) playerContainer?: ElementRef<HTMLElement>;

  onMouseMoveTimelineBar(e: MouseEvent) {
    if (this.#timeoutID) {
      clearTimeout(this.#timeoutID);
    }

    const spriteOptions = this.advancedPlayerService.config?.thumbnailsSprite;

    if (!spriteOptions) {
      this.spriteCss = null;
      return;
    }

    const target = e.target as HTMLElement;
    if (
      !target.classList.contains('mat-slider') &&
      !target.classList.contains('mat-slider-horizontal') &&
      !target.classList.contains('mat-slider-thumb-label-showing')
    ) {
      this.spriteCss = null;
      return;
    }

    const timelineSlider = document.querySelector('.timeline-control')?.querySelector('.mat-slider') as HTMLElement;
    if (!timelineSlider) {
      this.spriteCss = null;
      return;
    }

    const timelineSliderWidth = timelineSlider.clientWidth - 1;
    const hoveredPosition = e.offsetX;
    const percent = (hoveredPosition / timelineSliderWidth) * 100;

    this.advancedPlayerService.duration$
      .pipe(take(1), withLatestFrom(this.advancedPlayerService.framerate$))
      .subscribe(([duration, framerate]) => {
        const durationSec = duration?.countSeconds();

        if (!durationSec) {
          return;
        }
        const percentFromCurrentHover = (durationSec * percent) / 100;
        const hoveredTimecode = Timecode.fromSeconds(percentFromCurrentHover, framerate);

        const options = spriteOptions;

        const thumbNumber = Math.floor(percentFromCurrentHover / options.second);

        const positionX = thumbNumber * options.thumbnailWidth * -1;

        const videoHostLeftMargin = this.playerContainer?.nativeElement.getBoundingClientRect().x ?? 0;
        const videoHostRightMargin = videoHostLeftMargin + (this.playerContainer?.nativeElement.clientWidth ?? 0);
        const spritePosition = e.x - videoHostLeftMargin;

        let left = spritePosition - options.thumbnailWidth / 2;

        if (left < 0) {
          left = 0;
        }

        if (left + options.thumbnailWidth > videoHostRightMargin - videoHostLeftMargin) {
          left = videoHostRightMargin - videoHostLeftMargin - options.thumbnailWidth;
        }

        this.spriteCss = {
          position: 'absolute',
          width: options.thumbnailWidth + 'px',
          height: options.thumbnailHeight + 'px',
          bottom: '90px',
          border: 'solid 1px black',
          boxShadow: '2px -1px 36px -6px black',
          borderRadius: '5px',
          backgroundColor: 'black',
          backgroundImage: 'url(' + options.spriteUrl + ')',
          backgroundPositionX: positionX + 'px',
          display: 'block',
          backgroundRepeat: 'no-repeat',
          left: left + 'px',
        };

        const thumbLabelTimecode = document
          .querySelector('.timeline-control')
          ?.querySelector('.thumb-label-timecode') as HTMLElement;

        if (!thumbLabelTimecode) {
          return;
        }
        thumbLabelTimecode.style.left = options.thumbnailWidth / 4 + 'px';
        thumbLabelTimecode.style.padding = '5px';
        thumbLabelTimecode.innerText = hoveredTimecode?.toString() ?? '00:00:00:00';
      });
  }

  onMouseLeaveTimelineBar(e: MouseEvent) {
    this.#timeoutID = setTimeout(() => {
      this.spriteCss = null;
    }, 500);
  }

  ngOnInit(): void {
    this.advancedPlayerService.load();
  }

  ngOnDestroy() {
    this.advancedPlayerService.unload();
  }
}
