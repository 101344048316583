import { ChangeDetectionStrategy, Component, forwardRef, Input } from '@angular/core';
import { FormControlValueAccessorComponent } from '../../models/form/inputs/form-control-value-accessor.component';
import { UntypedFormControl, NG_VALUE_ACCESSOR } from '@angular/forms';

type OuterValue = number | string | null | undefined;
type InnerValue = number | null;

@Component({
  selector: 'vdms-hq-ui-form-input-number',
  templateUrl: './form-input-number.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => FormInputNumberComponent),
    },
  ],
})
export class FormInputNumberComponent extends FormControlValueAccessorComponent<OuterValue, InnerValue> {
  @Input() max: number | null = null;
  @Input() min: number | null = null;
  @Input() step = 'any';
  @Input() decimal?: number;
  @Input() prefixText: string | null = null;
  innerFormControl = new UntypedFormControl(null);

  override transformOuterToInner(value: OuterValue): InnerValue {
    return value === undefined || value === null || value === '' ? null : Number(value);
  }

  handleNumbers(): void {
    if (this.decimal && this.innerFormControl?.value !== null) {
      this.innerFormControl.setValue(parseFloat(this.innerFormControl?.value.toFixed(this.decimal)));
    }

    if (this.min != null) {
      if (this.innerFormControl?.value < this.min) {
        this.innerFormControl.setValue(this.min);
      }
    }

    if (this.max != null) {
      if (this.innerFormControl?.value > this.max) {
        this.innerFormControl.setValue(this.max);
      }
    }
  }
}
