import { Inject, Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { EMPTY, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ConfirmationDialogComponent } from '../../confirmation-dialog/confirmation-dialog.component';
import {
  ConfirmationInput,
  ConfirmationOutput,
  DEFAULT_CONFIRMATION_DIALOG,
  DELETE_CONFIRMATION_DIALOG,
} from '../../confirmation-dialog/confirmation-dialog.interface';
import { DialogResponse as ConfirmationDialogResponse } from '@vdms-hq/shared';

@Injectable()
export class UIConfirmationDialogService {
  constructor(@Inject(MatDialog) private dialog: MatDialog) {}

  #afterClosed$: Observable<ConfirmationOutput | undefined> = EMPTY;

  open(data?: ConfirmationInput, dialogConfig?: MatDialogConfig): Observable<boolean> {
    const dialogRef = this.dialog.open<ConfirmationDialogComponent, ConfirmationInput, ConfirmationOutput>(
      ConfirmationDialogComponent,
      {
        ...dialogConfig,
        data: { ...DEFAULT_CONFIRMATION_DIALOG, ...dialogConfig?.data, ...data },
      },
    );

    this.#afterClosed$ = dialogRef.afterClosed();

    return this.#afterClosed$.pipe(
      map((resp) => {
        if (!resp) {
          return false;
        }

        if (typeof resp === 'boolean') {
          return resp;
        }

        return resp.status === ConfirmationDialogResponse.OK;
      }),
    );
  }

  openDelete(data?: ConfirmationInput, dialogConfig?: MatDialogConfig): Observable<boolean> {
    return this.open({ ...DELETE_CONFIRMATION_DIALOG, ...data }, dialogConfig);
  }
}
