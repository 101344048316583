import { Injectable } from '@angular/core';
import { Constants } from '@vdms-hq/omakase-player';
import { ImageButton, ImageButtonConfig, OmakasePlayerApi } from '@byomakase/omakase-player';

@Injectable({
  providedIn: 'root',
})
export class TimelineScrubberService {
  zoomInProgress = false;

  processScrubberLane(omakasePlayerApi: OmakasePlayerApi | undefined) {
    if (!omakasePlayerApi) {
      return;
    }

    const scrubberLane = omakasePlayerApi?.timeline?.getScrubberLane();

    if (scrubberLane === undefined) {
      return;
    }

    scrubberLane.style = {
      ...Constants.SCRUBBER_LANE_STYLE,
    };

    const buttonConfig: Partial<ImageButtonConfig> = {
      width: 35,
      height: 35,
      listening: true,
    };

    const zoomInButton = new ImageButton({
      ...Constants.IMAGE_BUTTONS.circlePlus,
      ...buttonConfig,
    });

    const zoomOutButton = new ImageButton({
      ...Constants.IMAGE_BUTTONS.circleMinus,
      ...buttonConfig,
    });

    scrubberLane.addTimelineNode({
      width: zoomOutButton.config.width ?? 0,
      height: zoomOutButton.config.height ?? 0,
      justify: 'end',
      margin: [0, 0, 0, 0],
      timelineNode: zoomOutButton,
    });

    scrubberLane.addTimelineNode({
      width: zoomInButton.config.width ?? 0,
      height: zoomInButton.config.height ?? 0,
      justify: 'end',
      margin: [0, -5, 0, 0],
      timelineNode: zoomInButton,
    });

    zoomInButton.onClick$.subscribe({
      next: () => {
        if (!this.zoomInProgress) {
          this.zoomInProgress = true;
          omakasePlayerApi?.timeline
            ?.zoomInEased()
            .subscribe()
            .add(() => {
              this.zoomInProgress = false;
            });
        }
      },
    });

    zoomOutButton.onClick$.subscribe({
      next: () => {
        if (!this.zoomInProgress) {
          this.zoomInProgress = true;
          omakasePlayerApi?.timeline
            ?.zoomOutEased()
            .subscribe()
            .add(() => {
              this.zoomInProgress = false;
            });
        }
      },
    });
  }
}
