<div
  class="header"
  [class]="{ 'header--large': size === 'large', 'header--light': themeSwitcherService.isLight$ | async }"
>
  <div class="header__title">
    <vdms-hq-ui-layout-breadcrumbs
      size="small"
      [breadcrumbs]="breadcrumbs"
      *ngIf="breadcrumbs.length > 0"
    ></vdms-hq-ui-layout-breadcrumbs>
    <h1 *ngIf="title">
      <span>
        {{ title | translate }}
      </span>
      <span class="counter-indicator" *ngIf="counterIndicator"> ({{ counterIndicator }}) </span>
    </h1>
    <span class="subtitle" *ngIf="subtitle">{{ subtitle | translate }}</span>
    <ng-content select="[content]"></ng-content>
  </div>
  <div class="header__actions">
    <ng-container *ngIf="actions?.length">
      <ng-container *ngFor="let actionDef of actions">
        <vdms-hq-ui-button
          *ngIf="!(actionDef | actionState : 'ifHidden')"
          [disabled]="actionDef.disabled ?? false"
          (click)="emitAction($event, actionDef)"
          [iconOnly]="!!actionDef.icon"
          [icon]="actionDef.icon"
          [tooltip]="actionDef.icon ? (actionDef.label | translate) : ''"
          [color]="actionDef.color ?? 'primary'"
        >
          {{ actionDef.icon ? '' : (actionDef.label | translate) }}
        </vdms-hq-ui-button>
      </ng-container>
    </ng-container>
  </div>
</div>
<div class="metadata" *ngIf="listConfig.length">
  <vdms-hq-ui-simple-details-list [listConfig]="listConfig"></vdms-hq-ui-simple-details-list>
</div>
