<vdms-hq-ui-results-wrapper
  *ngIf="headerWrapperActions$ | async as headerActions; else loading"
  [dataSource]="selectorsDataSource"
  [breadcrumbs]="[{ name: 'Field sources' }]"
  [configuration]="{ headerActions, withLoading: false }"
  (headerAction)="handleAction($event)"
>
  <vdms-hq-ui-multiple-data-presentation
    [dataSource]="selectorsDataSource"
    [configuration]="viewConfiguration"
    (action)="handleAction($event)"
  >
    <vdms-hq-dynamic-form-group-renderer
      filters
      [form]="filters"
      [fieldsConfig]="filtersConfig"
    ></vdms-hq-dynamic-form-group-renderer>
  </vdms-hq-ui-multiple-data-presentation>
</vdms-hq-ui-results-wrapper>

<ng-template #loading>
  <vdms-hq-ui-loader [backdrop]="true"></vdms-hq-ui-loader>
</ng-template>
