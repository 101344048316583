<div class="upload-container" [class.full-width]="fullWidth" [ngClass]="size ?? ''">
  <div
    class="upload-dropzone"
    [class.upload-dropzone--active]="withDropzone"
    [class.upload-dropzone--is-over]="assetUploadService.dragOver$ | async"
  ></div>

  <vdms-hq-ui-loader
    *ngIf="(assetUploadService.isInitialized$ | async) === false"
    mode="over-parent"
    [backdrop]="true"
  ></vdms-hq-ui-loader>

  <div class="upload-placeholder">
    <div class="batches" *ngIf="progressVisibility === ProgressVisibilityEnum.ALL">
      <vdms-hq-storage-transferable-batch
        [batch]="item"
        *ngFor="let item of assetUploadService.selected$ | async; let index = index"
        (deleteElement)="deleteTransfer(item)"
        (closeElement)="assetUploadService.deleteFromTransfer(item)"
      >
      </vdms-hq-storage-transferable-batch>

      <vdms-hq-storage-transferable-batch
        [batch]="item"
        [isProgressBar]="false"
        *ngFor="let item of assetUploadService.queueSelected$ | async; let index = index"
        (deleteElement)="deleteTransfer(item)"
        (closeElement)="assetUploadService.deleteFromTransfer(item)"
      >
      </vdms-hq-storage-transferable-batch>
    </div>

    <div class="compact" *ngIf="progressVisibility === ProgressVisibilityEnum.COMPACT">
      <ng-container *ngIf="assetUploadService.selected$ | async; let selected">
        <ng-container *ngFor="let batch of selected">
          <vdms-hq-ui-progress-status
            [label]="file.filename"
            [value]="(batch.state$ | async)?.progress ?? 0"
            [mode]="(batch.state$ | async)?.progress === 0 ? 'indeterminate' : 'determinate'"
            *ngIf="batch.files[0]; let file"
          ></vdms-hq-ui-progress-status>
        </ng-container>
      </ng-container>
    </div>

    <img [src]="imageUrl" alt="Aspera" class="upload-dropzone" />
    <ng-content></ng-content>
    <vdms-hq-ui-button
      color="primary"
      class="upload-dropzone"
      (click)="selectFiles()"
      [disabled]="!multiple && !!(countSelected$ | async)"
      [size]="buttonSize"
    >
      {{ (multiple ? 'common.aspera.upload_button.plural' : 'common.aspera.upload_button') | translate }}
    </vdms-hq-ui-button>

    <span *ngIf="customText?.length; else defaultText" [innerText]="customText"></span>

    <ng-template #defaultText>
      <span *ngIf="withDropzone">{{
        (multiple ? 'common.aspera.or_drop.plural' : 'common.aspera.or_drop') | translate
      }}</span>
    </ng-template>
  </div>
</div>
