/*
 * Copyright 2024 ByOmakase, LLC (https://byomakase.org)
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { ClickEvent, ConfigWithOptionalStyle, LabelLaneConfig, TextLabel, Timeline } from '@byomakase/omakase-player';
import { BaseGroupingLane } from './base-grouping-lane';
import { SubtitlesControlImageButton } from './subtitles-control/subtitles-control-image-button';
import { VideoControllerApi } from '@byomakase/omakase-player/dist/video/video-controller-api';
import { Constants } from '../../../constants/constants';
import { Observable } from 'rxjs';

export interface TextTrackGroupingLaneConfig extends LabelLaneConfig {
  onSubtitleChange$: Observable<{
    language: string;
    path: string;
  } | null>;
  activateSubtitle: () => void;
  subtitle: {
    language: string;
    path: string;
  };
}

export class TextTrackGroupingLane extends BaseGroupingLane<TextTrackGroupingLaneConfig> {
  onSubtitleChange$?: Observable<{
    language: string;
    path: string;
  } | null>;
  activateSubtitle?: () => void;
  subtitle: {
    language: string;
    path: string;
  };

  subtitlesControlButton: SubtitlesControlImageButton;

  private _languageLabel?: TextLabel | undefined;
  #active = false;

  constructor(config: ConfigWithOptionalStyle<TextTrackGroupingLaneConfig>) {
    super({
      ...config,
      style: {
        ...Constants.LABEL_LANE_STYLE,
      },
    });
    this.onSubtitleChange$ = config.onSubtitleChange$;
    this.activateSubtitle = config.activateSubtitle;
    this.subtitle = config.subtitle;
    this.subtitlesControlButton = new SubtitlesControlImageButton({
      disabled: false,
      srcDefault: `${Constants.IMAGES_ROOT}/icon-chatbox.svg`,
      srcActive: `${Constants.IMAGES_ROOT}/icon-chatbox-active.svg`,
      srcDisabled: `${Constants.IMAGES_ROOT}/icon-chatbox-disabled.svg`,
      width: 22,
      height: 22,
    });

    this.addTimelineNode({
      timelineNode: this.subtitlesControlButton.timelineNode,
      width: this.subtitlesControlButton.dimension.width,
      height: this.subtitlesControlButton.dimension.height,
      justify: 'start',
      margin: [0, 10, 0, 0],
    });

    if (this._languageLabel) {
      this.addTimelineNode({
        timelineNode: this._languageLabel,
        width: 30,
        height: 20,
        justify: 'start',
        margin: [0, 10, 0, 0],
      });
    }
  }

  override prepareForTimeline(timeline: Timeline, videoController: VideoControllerApi) {
    super.prepareForTimeline(timeline, videoController);

    this.onSubtitleChange$?.subscribe((nextSub) => {
      if (nextSub?.path === this.subtitle.path) {
        this.setActive();
      } else {
        this.setUnActive();
      }

      this.updateStyles();
    });

    this.subtitlesControlButton.timelineNode.onClick$.subscribe({
      next: (event: ClickEvent) => {
        this.activateSubtitle && this.activateSubtitle();
      },
    });

    this.updateStyles();
  }

  private updateStyles() {
    if (this.#active) {
      this.style = {
        ...Constants.LABEL_LANE_STYLE_ACTIVE,
      };
      this.subtitlesControlButton.state = 'active';
    } else {
      this.style = {
        ...Constants.LABEL_LANE_STYLE,
      };
      this.subtitlesControlButton.state = 'default';
    }
  }

  setActive() {
    this.#active = true;
  }
  setUnActive() {
    this.#active = false;
  }
}
