import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { ActivatedClientService } from '@vdms-hq/activated-client';
import { AnySidebarItem, UILayoutModule } from '@vdms-hq/ui';
import { Subject, switchMap } from 'rxjs';
import { map, startWith, takeUntil } from 'rxjs/operators';
import { RouterOutlet } from '@angular/router';
import { AsyncPipe } from '@angular/common';
import { ImportExportModule } from '../import-export/import-export.module';
import { ClientDataService } from '../general/logic/client-data.service';
import { adminNavItemsConfig } from './admin-nav-items.config';

@Component({
  selector: 'vdms-hq-admin-nav-wrapper',
  templateUrl: './admin-nav-wrapper.component.html',
  styleUrls: ['./admin-nav-wrapper.component.scss'],
  standalone: true,
  imports: [UILayoutModule, ImportExportModule, RouterOutlet, AsyncPipe],
})
export class AdminNavWrapperComponent implements OnInit, OnDestroy {
  private clientDataService = inject(ClientDataService);
  private activatedClient = inject(ActivatedClientService);

  navItems: AnySidebarItem[] = [];
  version$ = this.activatedClient.clientDefiniteValueChanges$.pipe(
    map((current) => current?.version ?? 0),
    switchMap((currentVersion) =>
      this.activatedClient.snapshotLatestVersion$.pipe(
        startWith({ version: 0 }),
        map((latest) => latest?.version ?? 0),
        map((lastVersion) => (currentVersion < lastVersion ? `${currentVersion} (restored)` : currentVersion)),
      ),
    ),
  );
  clientDefinite$ = this.activatedClient.clientDefinite$;
  #destroy = new Subject<void>();

  ngOnDestroy(): void {
    this.#destroy.next();
    this.#destroy.complete();
  }

  ngOnInit() {
    this.activatedClient.clientDefiniteValueChanges$.pipe(takeUntil(this.#destroy)).subscribe((client) => {
      this.clientDataService.getClientData(client.uuid);
      this.navItems = adminNavItemsConfig(client);
    });
  }
}
