import { inject, Injectable, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Params, Router } from '@angular/router';
import { Permission, PermissionService } from '@vdms-hq/activated-client';
import { Asset, AssetApiService, AssetSearchFilterParam, StreamService } from '@vdms-hq/api-contract';
import { AuthService } from '@vdms-hq/auth';
import {
  AssetsColdEstimationData,
  AssetsColdEstimationDialogComponent,
  AssetsColdEstimationResult,
} from '@vdms-hq/hot-cold';
import {
  FilterStreamMsgFactory,
  Identifier,
  RefreshService,
  WebsocketAnyNotificationMessage,
  WebsocketNotificationActionEnum,
  WebsocketNotificationInterface,
  WebsocketProgressInterface,
  snakeToCapitalizedString,
  WsProgressToast,
  generateProgressToastMessage,
  IMAGE_CACHE_ENUM,
  ImageCacheService,
} from '@vdms-hq/shared';
import { ToastService } from '@vdms-hq/toast';
import { DataAction } from '@vdms-hq/ui';
import {
  BehaviorSubject,
  catchError,
  combineLatest,
  EMPTY,
  filter,
  Observable,
  of,
  Subject,
  switchMap,
  take,
  takeUntil,
  withLatestFrom,
} from 'rxjs';
import { map, tap } from 'rxjs/operators';
import {
  AssetsDeleteConfirmationDialogComponent,
  ConfirmationInput,
  ConfirmationOutput,
} from '../../components/assets-delete-confirmation-dialog/assets-delete-confirmation-dialog.component';
import {
  BatchActionDialogComponent,
  BatchActionDialogInput,
  BatchActionDialogOutput,
} from '../../components/batch-action-dialog/batch-action-dialog.component';
import { ResultsActions } from '../results-actions.model';
import { AssetFlatView2Model } from '../transformation/asset-flat-view-2.model';
import { QualityControlResultsDialogService } from '@vdms-hq/quality-control';

@Injectable({ providedIn: 'root' })
export class AssetActionsService implements OnDestroy {
  private assetApiService = inject(AssetApiService);
  private toastService = inject(ToastService);
  private matDialog = inject(MatDialog);
  private streamService = inject(StreamService);
  private imageCache = inject(ImageCacheService);
  private authService = inject(AuthService);
  private router = inject(Router);
  private permissionsService = inject(PermissionService);
  private readonly refreshService = inject(RefreshService);
  private qualityControlResultsDialogService = inject(QualityControlResultsDialogService);

  private destroyed$ = new Subject<void>();

  finishBatchUpdate$ = new BehaviorSubject<number | null>(null);

  popToast = {
    BATCH_UPDATE_SUCCESS: () => {
      return this.toastService.success({
        id: 'collection_batch_update',
        message: 'common.lists.notifications.batch_update.done',
      });
    },
    BATCH_UPDATE_FAILURE: () =>
      this.toastService.success({
        id: 'collection_batch_update',
        message: 'common.lists.notifications.batch_update.fail',
      }),
    QUARANTINE_IMPOSE_SUCCESS: () =>
      this.toastService.success({
        id: 'quarantine',
        message: 'common.lists.notifications.quarantine.impose.done',
      }),
    QUARANTINE_IMPOSE_FAILURE: () =>
      this.toastService.error({
        id: 'quarantine',
        message: 'common.lists.notifications.quarantine.impose.fail',
      }),
    QUARANTINE_LIFT_SUCCESS: () =>
      this.toastService.success({
        id: 'quarantine',
        message: 'common.lists.notifications.quarantine.lift.done',
      }),
    QUARANTINE_LIFT_FAILURE: () =>
      this.toastService.error({
        id: 'quarantine',
        message: 'common.lists.notifications.quarantine.lift.fail',
      }),
    WS_BATCH_UPDATE_PROGRESS: (
      id: string,
      percent: number,
      counter?: { processing: number; all: number; errors: WebsocketAnyNotificationMessage },
    ) =>
      this.toastService.processing({
        id,
        message: `Batch update ${percent}% ${counter ? `(${counter?.processing}/${counter?.all})` : ''}${
          counter?.errors.error ? `, ${counter.errors.error} failed` : ''
        }`,
      }),
    WS_BATCH_UPDATE_SUCCESS: (id: string) => {
      this.finishBatchUpdate$.next(Date.now());
      return this.toastService.success({
        id,
        message: `Batch updated`,
      });
    },
    WS_BATCH_UPDATE_ERROR: (id: string) =>
      this.toastService.error({
        id,
        message: `Batch updated error`,
      }),
    WS_STORAGE_PROGRESS: (
      id: string,
      percent: number,
      counter?: { processing: number; all: number; errors: WebsocketAnyNotificationMessage },
    ) => {
      if (percent === 100) {
        this.toastService.info(
          {
            id: `Storage_websocket_${id}`,
            message: generateProgressToastMessage(
              'Moving to storage',
              percent,
              this.imageCache.getImage(IMAGE_CACHE_ENUM.PROGRESS_LOADER_INFO_ICON),
              counter,
              true,
            ),
          },
          false,
        );
      } else {
        this.toastService.processing({
          id: `Storage_websocket_${id}`,
          message: generateProgressToastMessage(
            'Moving to storage',
            percent,
            this.imageCache.getImage(IMAGE_CACHE_ENUM.PROGRESS_LOADER_INFO_ICON),
            counter,
          ),
        });
      }
    },
    WS_STORAGE_SUCCESS: (id: string) => {
      this.toastService.success({
        id,
        message: `${snakeToCapitalizedString(id)} process finished`,
      });
    },
    WS_STORAGE_ERROR: (id: string) => {
      return this.toastService.error({
        id,
        message: `Storage move failed. Error: ${snakeToCapitalizedString(id)}`,
      });
    },
    DELETE_ASSETS_FAILED: () =>
      this.toastService.error({
        id: 'delete_asset',
        message: 'pages.assets_list.deleted_assets_failed',
      }),
    DELETE_ASSETS_IN_PROGRESS: () =>
      this.toastService.info({
        id: 'delete_asset',
        message: 'pages.assets_list.deleted_assets_in_progress',
      }),
    DELETE_ASSETS_SUCCESS: () =>
      this.toastService.success({
        id: 'delete_asset',
        message: 'pages.assets_list.deleted_assets',
      }),
  };
  readonly connect$ = this.streamService.connect();

  openAssetPreviewInPopup$ = this.authService.userAttributesDefinite$.pipe(
    map((attrs) => attrs.vida.openAssetPreviewInPopup),
  );

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  imposeQuarantine(assets: string[]) {
    return this.assetApiService.setQuarantine(assets, true).pipe(
      tap(() => {
        this.popToast.QUARANTINE_IMPOSE_SUCCESS();
      }),
      catchError((error) => {
        this.popToast.QUARANTINE_IMPOSE_FAILURE();
        throw error;
      }),
    );
  }

  liftQuarantine(assets: string[]) {
    return this.assetApiService.setQuarantine(assets, false).pipe(
      tap(() => {
        this.popToast.QUARANTINE_LIFT_SUCCESS();
      }),
      catchError((error) => {
        this.popToast.QUARANTINE_LIFT_FAILURE();
        throw error;
      }),
    );
  }

  popBatchUpdateDialog(assetUuids: string[], batchConfig = 'batch_update') {
    const batchDialogRef = this.matDialog.open<
      BatchActionDialogComponent,
      BatchActionDialogInput,
      BatchActionDialogOutput
    >(BatchActionDialogComponent, {
      data: {
        assetsCounter: assetUuids.length,
        batchConfig: batchConfig,
      },
    });

    batchDialogRef
      .afterClosed()
      .pipe(
        take(1),
        filter((metadata) => !!metadata),
        switchMap((metadata) =>
          this.assetApiService.batchUpdate(assetUuids, metadata?.data as Partial<Asset>).pipe(
            tap(() => {
              this.popToast.BATCH_UPDATE_SUCCESS();
            }),
            catchError((error) => {
              this.popToast.BATCH_UPDATE_FAILURE();
              throw error;
            }),
          ),
        ),
      )
      .subscribe();
  }

  deleteAsset(body: { deleteReason: string; deletionBillable: boolean; assetUuids: string[] }) {
    return this.assetApiService.deleteAssets(body);
  }

  setAsCold(assetIds: string[]) {
    return this.matDialog
      .open<AssetsColdEstimationDialogComponent, AssetsColdEstimationData, AssetsColdEstimationResult>(
        AssetsColdEstimationDialogComponent,
        {
          data: { assetsIds: assetIds },
        },
      )
      .afterClosed()
      .pipe(
        takeUntil(this.destroyed$),
        take(1),
        filter(Boolean),
        switchMap(() =>
          this.assetApiService.setAssetsAsCold(assetIds).pipe(
            catchError(() => {
              return EMPTY;
            }),
          ),
        ),
      );
  }

  setFiltersAsCold(filters: AssetSearchFilterParam) {
    return this.matDialog
      .open<AssetsColdEstimationDialogComponent, AssetsColdEstimationData, AssetsColdEstimationResult>(
        AssetsColdEstimationDialogComponent,
        {
          data: { assetsIds: [], filters },
        },
      )
      .afterClosed()
      .pipe(
        takeUntil(this.destroyed$),
        filter(Boolean),
        switchMap(() =>
          this.assetApiService.setFilteredAssetsAsCold(filters).pipe(
            catchError(() => {
              return EMPTY;
            }),
          ),
        ),
      );
  }

  registerWebSocketListener(): Observable<WebsocketNotificationInterface<WebsocketProgressInterface>> {
    return this.connect$.pipe(
      FilterStreamMsgFactory([WebsocketNotificationActionEnum.ASSET_BATCH_UPDATE]),
      WsProgressToast({
        SUCCESS: this.popToast.WS_BATCH_UPDATE_SUCCESS,
        PROGRESS: this.popToast.WS_BATCH_UPDATE_PROGRESS,
        ERROR: this.popToast.WS_BATCH_UPDATE_ERROR,
      }),
    );
  }

  registerHotColdWebSocketListener(): Observable<WebsocketNotificationInterface<WebsocketProgressInterface>> {
    return this.connect$.pipe(
      FilterStreamMsgFactory([
        WebsocketNotificationActionEnum.MOVE_TO_HOT,
        WebsocketNotificationActionEnum.MOVE_TO_COLD,
      ]),
      WsProgressToast({
        SUCCESS: this.popToast.WS_STORAGE_SUCCESS,
        PROGRESS: this.popToast.WS_STORAGE_PROGRESS,
        ERROR: this.popToast.WS_STORAGE_ERROR,
      }),
    );
  }

  buildQAResultsAction<T extends AssetFlatView2Model = AssetFlatView2Model>(): Observable<DataAction<T>> {
    return of({
      key: 'asset.quality_control_results',
      icon: 'checklist',
      label: 'common.qc_analyse_results.label',
      permissions: {
        ids: [Permission.BROWSE_QA_RESULTS],
      },
      hiddenIf: (item?: AssetFlatView2Model) => {
        return item?.props.upload_source != 'Launchpad';
      },
    });
  }

  buildPreviewAction$<T extends AssetFlatView2Model = AssetFlatView2Model>(): Observable<DataAction<T>> {
    return combineLatest([
      this.permissionsService.verifyWithOwnedPermissions$([Permission.BROWSE_COLLECTIONS]),
      this.permissionsService.verifyWithOwnedPermissions$([Permission.BROWSE_ASSETS]),
      this.permissionsService.verifyWithOwnedPermissions$([Permission.BROWSE_QUARANTINED_ASSETS]),
    ]).pipe(
      map(([canBrowseCollections, canBrowseAssets, canBrowseQuarantinedAssets]) =>
        canBrowseAssets || canBrowseCollections
          ? ({
              key: 'asset.preview',
              label: 'common.global.preview',
              icon: 'visibility',
              onDoubleClick: true,
              hiddenIf: (item?: T) => item?.isQuarantined && !canBrowseQuarantinedAssets,
            } as DataAction<T>)
          : ({} as DataAction<T>),
      ),
    );
  }

  handleQAResultsAction<T extends AssetFlatView2Model = AssetFlatView2Model>($event: { key: string; item?: T }): void {
    if ($event.key !== 'asset.quality_control_results') {
      return;
    }
    this.qualityControlResultsDialogService.popQCResultsDialog(<Identifier>$event.item?.props.uuid);
  }

  handlePreviewAction<T extends AssetFlatView2Model = AssetFlatView2Model>(
    $event: { key: string; item?: T },
    queryParams$?: Observable<Params>,
  ): void {
    if ($event.key !== 'asset.preview') {
      return;
    }

    this.openAssetPreviewInPopup$
      .pipe(take(1), withLatestFrom(queryParams$ ?? of({})))
      .subscribe(([openAssetPreviewInPopup, params]) => {
        let assetPreviewWindow;
        const queryParams = new URLSearchParams(this.handlePreviewParams(params)).toString();

        if (openAssetPreviewInPopup) {
          let url = `/asset/${$event.item?.props.uuid}/details?`;
          queryParams && (url += queryParams);

          assetPreviewWindow = window.open(url, 'AssetPreview', 'width=1300,height=700');
        }

        if (!assetPreviewWindow) {
          this.router.navigate(['/asset', $event.item?.props.uuid], { queryParams: this.handlePreviewParams(params) });
          return;
        }
      });
  }

  handlePreviewParams(params: Params): Params {
    const newParams = params;
    if (params['transcriptions']) {
      newParams['phrase'] = params['transcriptions'];
      delete newParams['transcriptions'];
    }
    if (params['tags']) {
      newParams['phrase'] = newParams.phrase?.length ? newParams.phrase.concat(params['tags']) : params['tags'];
      delete newParams['tags'];
    }
    return newParams;
  }

  buildDeleteAction$<T extends AssetFlatView2Model = AssetFlatView2Model>(): Observable<DataAction<T>> {
    return combineLatest([
      this.permissionsService.verifyWithOwnedPermissions$([Permission.DELETE_ASSETS]),
      this.permissionsService.verifyWithOwnedPermissions$([Permission.USE_QUARANTINED_ASSETS]),
    ]).pipe(
      map(([canDelete, canUseQuarantined]) => {
        return canDelete
          ? ({
              key: 'asset.delete',
              label: 'common.global.delete_from_vida',
              icon: 'delete',
              onDoubleClick: false,
              hiddenIf: (item: T) => !canDelete || (!!item?.isQuarantined && !canUseQuarantined),
            } as DataAction<T>)
          : ({} as DataAction<T>);
      }),
    );
  }

  handleDeleteAction<T extends AssetFlatView2Model = AssetFlatView2Model>($event: { key: string; item?: T }): void {
    if ($event.key !== 'asset.delete') {
      return;
    }

    this.removeSelectedAssets$([<Identifier>$event.item?.props.uuid]).subscribe({
      next: () => this.popToast.DELETE_ASSETS_SUCCESS(),
      error: () => this.popToast.DELETE_ASSETS_FAILED(),
    });
  }

  removeSelectedAssets$(assetIds: Identifier[]) {
    const dialogRef = this.matDialog.open<
      AssetsDeleteConfirmationDialogComponent,
      ConfirmationInput,
      ConfirmationOutput
    >(AssetsDeleteConfirmationDialogComponent, {
      data: {
        totalAssets: assetIds.length ?? 0,
      },
    });
    return dialogRef.afterClosed().pipe(
      take(1),
      map((dialogResponse) => ({ assetIds, output: dialogResponse })),
      switchMap((resp) => {
        if (!resp.output) {
          return EMPTY;
        }
        return this.deleteAsset({
          deleteReason: resp.output.data.reason,
          deletionBillable: resp.output.data.billable,
          assetUuids: assetIds,
        });
      }),
      tap(() => {
        if (assetIds.length > 100) {
          this.popToast.DELETE_ASSETS_IN_PROGRESS();
        }
      }),
    );
  }

  /**
   * @deprecated
   * todo move to quarantine-actions.service
   */
  buildImposeQuarantineAction$<T extends AssetFlatView2Model = AssetFlatView2Model>() {
    return this.permissionsService
      .verifyWithOwnedPermissions$([Permission.RELEASE_QUARANTINED_ASSETS, Permission.BROWSE_QUARANTINED_ASSETS])
      .pipe(
        map((permitted) => {
          return permitted
            ? ({
                key: ResultsActions.IMPOSE_QUARANTINE,
                label: 'common.global.impose_quarantine',
                icon: 'add_moderator',
                onDoubleClick: false,
                hiddenIf: (item: T) => item?.isQuarantined === true,
              } as DataAction<T>)
            : ({} as DataAction<T>);
        }),
      );
  }

  /**
   * @deprecated
   * todo move to quarantine-actions.service
   */
  handleImposeQuarantineAction<T extends AssetFlatView2Model = AssetFlatView2Model>(event: { key: string; item?: T }) {
    if (event.key !== ResultsActions.IMPOSE_QUARANTINE || !event.item) {
      return;
    }

    this.imposeQuarantine([event.item.props.uuid])
      .pipe(
        take(1),
        tap((refresh) => refresh && this.refreshService.refresh()),
      )
      .subscribe();
  }

  /**
   * @deprecated
   * todo move to quarantine-actions.service
   */
  buildLiftQuarantineAction$<T extends AssetFlatView2Model = AssetFlatView2Model>() {
    return this.permissionsService
      .verifyWithOwnedPermissions$([Permission.RELEASE_QUARANTINED_ASSETS, Permission.BROWSE_QUARANTINED_ASSETS])
      .pipe(
        map((permitted) => {
          return permitted
            ? ({
                key: ResultsActions.LIFT_QUARANTINE,
                label: 'common.global.lift_quarantine',
                icon: 'remove_moderator',
                onDoubleClick: false,
                hiddenIf: (item: T) => !item?.isQuarantined,
              } as DataAction<T>)
            : ({} as DataAction<T>);
        }),
      );
  }

  /**
   * @deprecated
   * todo move to quarantine-actions.service
   */
  handleLiftQuarantineAction<T extends AssetFlatView2Model = AssetFlatView2Model>(event: { key: string; item?: T }) {
    if (event.key !== ResultsActions.LIFT_QUARANTINE || !event.item) {
      return;
    }

    this.liftQuarantine([event.item.props.uuid]).pipe(take(1)).subscribe();
  }
}
