<vdms-hq-ui-empty-results
  *ngIf="!playerService.isConfigured"
  message="Runtime error. Please configure player service first"
></vdms-hq-ui-empty-results>

<div class="player" *ngIf="playerService.type$ | async; let type">
  <vdms-hq-simple-player *ngIf="type === 'simple'"></vdms-hq-simple-player>
  <vdms-hq-advanced-player *ngIf="type === 'advanced'"></vdms-hq-advanced-player>
  <vdms-hq-ui-audio-player *ngIf="type === 'audio'"></vdms-hq-ui-audio-player>

  <canvas id="player_canvas" class="canvas"></canvas>

  <div class="player__dev-stats" *ngIf="isDevStatsPaneVisible">
    <div class="grid">
      <div class="player__dev-stats__data">
        <span>{{ 'common.advanced_player.action_buttons.current_frame' | translate }}</span>
        <span>{{ (playerService.currentTimecode$ | async)?.countFrames() ?? 'N/A' }}</span>
      </div>
      <div class="player__dev-stats__data">
        <span>{{ 'common.advanced_player.action_buttons.current_second' | translate }}</span>
        <span>{{ ((playerService.currentTimecode$ | async)?.countSeconds() | number : '1.1-6') ?? 'N/A' }}</span>
      </div>
      <div class="player__dev-stats__data">
        <span>{{ 'common.advanced_player.action_buttons.current_timecode' | translate }}</span>
        <span>{{ (playerService.currentTimecode$ | async) ?? 'N/A' }}</span>
      </div>
      <div class="player__dev-stats__data">
        <span>{{ 'common.advanced_player.action_buttons.offset' | translate }}</span>
        <span>{{ (playerService.offset$ | async) ?? 'N/A' }}</span>
      </div>
      <div class="player__dev-stats__data">
        <span>{{ 'common.advanced_player.action_buttons.offset_timecode' | translate }}</span>
        <span>{{ (playerService.currentTimecodeWithOffset$ | async) ?? 'N/A' }}</span>
      </div>
      <div class="player__dev-stats__data">
        <span>{{ 'common.advanced_player.action_buttons.duration_seconds' | translate }}</span>
        <span>{{ (playerService.duration$ | async)?.countSeconds() ?? 'N/A' }}</span>
      </div>
      <div class="player__dev-stats__data">
        <span>{{ 'common.advanced_player.action_buttons.duration_timecode' | translate }}</span>
        <span>{{ (playerService.duration$ | async) ?? 'N/A' }}</span>
      </div>
      <div class="player__dev-stats__data">
        <span>{{ 'common.advanced_player.action_buttons.playback_rate' | translate }}</span>
        <span>{{ (playerService.activePlayer.currentPlaybackRate$ | async) ?? 'N/A' }}</span>
      </div>
      <div class="player__dev-stats__data">
        <span>{{ 'common.advanced_player.action_buttons.volume' | translate }}</span>
        <span>{{ ((playerService.activePlayer.currentVolumeLevel$ | async) ?? 1) * 100 | ceil }}%</span>
      </div>
      <div class="player__dev-stats__data">
        <span>{{ 'common.advanced_player.action_buttons.framerate' | translate }}</span>
        <span>{{ (playerService.activePlayer.framerate$ | async)?.value }}</span>
      </div>
      <div class="player__dev-stats__data">
        <span>{{ 'common.advanced_player.action_buttons.ask_to_fill_offset' | translate }}</span>
        <span>{{ playerService.activePlayer.config?.askToFillOffset ? 'Yes' : 'No' }}</span>
      </div>
      <div class="player__dev-stats__data">
        <span>{{ 'common.advanced_player.action_buttons.clip' | translate }}</span>
        <span
          >{{ playerService.activePlayer.config?.clip?.startAt?.toString() ?? 'N/A' }} -
          {{ playerService.activePlayer.config?.clip?.endAt?.toString() ?? 'N/A' }}</span
        >
      </div>
      <div class="player__dev-stats__data">
        <span>{{ 'common.advanced_player.action_buttons.file_extension' | translate }}</span>
        <span>{{ playerService.activePlayer.config?.file?.extension }}</span>
      </div>
    </div>
    <div class="player__dev-stats__data">
      <span>{{ 'common.advanced_player.action_buttons.file_name' | translate }}</span>
      <span>{{ playerService.activePlayer.config?.file?.filename }}</span>
    </div>
  </div>
  <ng-container [ngTemplateOutlet]="modalsRef"></ng-container>
</div>

<ng-template #modalsRef>
  <vdms-hq-ui-input-timecode-overlay
    *ngIf="dialog === 'offset'"
    (submitTimecode)="dialog = null; changeOffset.emit($event); playerService.changeOffset($event)"
    (dismiss)="dialog = null"
    [timecode]="playerService.offset$ | async"
    [framerate]="(playerService.activePlayer.framerate$ | async) ?? undefined"
    [title]="'Change offset'"
    [mask]="(playerService.type$ | async) === 'audio' ? '00:00:00' : '00:00:00:00'"
  >
  </vdms-hq-ui-input-timecode-overlay>

  <vdms-hq-ui-input-timecode-overlay
    *ngIf="dialog === 'gototimecode'"
    (submitTimecode)="dialog = null; playerService.goToTimecode($event, true)"
    (dismiss)="dialog = null"
    [framerate]="(playerService.activePlayer.framerate$ | async) ?? undefined"
    [timecode]="playerService.offset$ | async"
    [title]="'Go to timecode'"
    [mask]="(playerService.type$ | async) === 'audio' ? '00:00:00' : '00:00:00:00'"
  >
  </vdms-hq-ui-input-timecode-overlay>

  <ng-container *ngIf="isCurrentTimecodeVisible">
    <div class="player__current-timecode" *ngIf="playerService.currentTimecodeWithOffset$ | async; let timeCode">
      <div *ngIf="playerService.type$ | async; let type">
        {{ type === 'audio' ? timeCode.substring(0, 8) : timeCode }}
      </div>
    </div>
  </ng-container>
</ng-template>
