import {
  TimelineStyle,
  ScrubberLaneStyle,
  TimelineLaneStyle,
  AudioTrackLaneStyle,
  SubtitlesLaneStyle,
  ThumbnailLaneStyle,
  MarkerLaneStyle,
  LineChartLaneStyle,
  BarChartLaneStyle,
} from '@byomakase/omakase-player';
import { PeriodMarkerStyle } from '@byomakase/omakase-player/dist/timeline/marker/period-marker';
import { MomentMarkerStyle } from '@byomakase/omakase-player/dist/timeline/marker/moment-marker';
import { ColorUtil, Constants } from '@vdms-hq/omakase-player';

type BaseTimelineRow = {
  id: string;
  label: string;
};

export type TimelineValue = {
  tcIn: number;
  tcOut?: number;
  tooltip: string;
  color?: string;
};

export type TimelineRow = {
  values: TimelineValue[];
  actions?: () => void;
} & BaseTimelineRow;

export type AudioTrackRow = BaseTimelineRow;

export type SubtitlesRow = BaseTimelineRow & { url: string; default: boolean };

export const ADVANCED_PLAYER_REQUIRED_COOKIES = [
  {
    name: 'Access-Control-Allow-Headers',
    value: 'Content-Type, Accept, X-Requested-With',
  },
  {
    name: 'Access-Control-Allow-Origin',
    value: '*',
  },
];

export const PLAYBACK_RATES = [0.25, 0.5, 1, 2, 4, 8, 16];

export const PLAYER_STYLES = {
  fontFamily: 'Source Sans Pro, sans-serif',
};

export const TIMELINE_STYLES: Partial<TimelineStyle> = {
  stageMinWidth: 700,
  stageMinHeight: 50,

  backgroundFill: '#151619',
  headerBackgroundFill: '#151619',
  footerBackgroundFill: '#151619',

  scrollbarHeight: 0,
  thumbnailHoverWidth: 200,
  thumbnailHoverStroke: '#3573FF',
  thumbnailHoverStrokeWidth: 5,
  thumbnailHoverYOffset: 0,
  playheadFill: '#5C8EFF',
  playheadPlayProgressFill: '#3573ff',
  playheadPlayProgressOpacity: 1,
  playheadBufferedFill: '#4A4E58',
  playheadBufferedOpacity: 1,
  // playheadBackgroundFill: '#3573ff',
  playheadBackgroundFill: '#151619',
  headerHeight: 20,
  footerHeight: 20,
  leftPaneWidth: 200,
  // rightPanelLeftGutterWidth: 30,
  // rightPanelRightGutterWidth: 30,
  // timecodedContainerClipPadding: 20,
};

const TIMELINE_LANE_STYLE: Partial<TimelineLaneStyle> = {
  height: 45,
  backgroundFill: '#151619',
  descriptionTextFill: '#fff',
  leftBackgroundFill: '#151619',
};

export const SCRUBBER_STYLES: Partial<ScrubberLaneStyle> = {
  ...TIMELINE_LANE_STYLE,
  tickFill: '#A4A9B7',
  tickHeight: 12,
  timecodeShowFirst: true,
  timecodeFontSize: 11,
  timecodeFill: '#A4A9B7',
};

export const THUMBNAIL_LINE_STYLES: Partial<ThumbnailLaneStyle> = {
  ...TIMELINE_LANE_STYLE,
  height: 60,
};

export const IN_OUT_LINE_STYLES: Partial<MarkerLaneStyle> = {
  ...TIMELINE_LANE_STYLE,
};

export const IN_OUT_PERIOD_MARKER_STYLES: Partial<PeriodMarkerStyle> = {
  color: '#A4A9B7',
  selectedAreaOpacity: 0.7,
  markerHandleAreaOpacity: 0.7,
  renderType: 'spanning',
  // @todo 'bar'
  symbolType: 'square',
  lineStrokeWidth: 1,
  lineOpacity: 0.7,
};

export const PEAK_METER_STYLES = {
  backgroundColor: '#151619',
  tickColor: '#a4a9b7',
  labelColor: '#a4a9b7',
  fontSize: 12,
  dbRange: 60,
  dbTickSize: 3,
  font: 'Source Sans Pro',
  vertical: true,
};

export const AUDIOTRACK_LINE_STYLES: Partial<AudioTrackLaneStyle> = {
  ...TIMELINE_LANE_STYLE,
  paddingTop: 3,
  paddingBottom: 3,
  height: 60,
  itemWidth: 3,
  itemMinPadding: 1,
  itemCornerRadius: 2,
  maxSampleFillLinearGradientColorStops: [0, '#ff0099', 0.2, 'yellow', 1, 'green'],
  minSampleFillLinearGradientColorStops: [0, 'green', 0.8, 'yellow', 1, 'red'],
};

export const SUBTITLES_LINE_STYLES: Partial<SubtitlesLaneStyle> = {
  ...TIMELINE_LANE_STYLE,
  subtitlesLaneItemOpacity: 0.7,
  subtitlesLaneItemFill: '#A4A9B7',
  paddingTop: 3,
  paddingBottom: 3,
};

export const ANALYSE_LINE_STYLES = (color: string): Partial<LineChartLaneStyle> => ({
  ...Constants.LINE_CHART_LANE_STYLE,
  paddingTop: 3,
  paddingBottom: 3,
  fill: color,
  pointFill: ColorUtil.changeShade(color, 30),
});

export const CUSTOM_LINE_STYLES: Partial<MarkerLaneStyle> = {
  ...TIMELINE_LANE_STYLE,
  height: 30,
  descriptionTextFontSize: 12,
};

export const CUSTOM_MARKER_TRANSCRIBE_STYLES: Partial<PeriodMarkerStyle> = {
  renderType: 'lane',
  symbolType: 'none',
  lineStrokeWidth: 1,
  lineOpacity: 0,
};

export const CUSTOM_MARKER_PERIOD_STYLES: Partial<PeriodMarkerStyle> = {
  color: '#A4A9B7',
  renderType: 'lane',
  symbolType: 'circle',
  lineStrokeWidth: 1,
  symbolSize: 15,
  lineOpacity: 0.2,
};

export const CUSTOM_MARKER_MOMENT_STYLES: Partial<MomentMarkerStyle> = {
  color: '#A4A9B7',
  renderType: 'lane',
  symbolType: 'circle',
  lineStrokeWidth: 1,
  symbolSize: 15,
  lineOpacity: 0.2,
};
