import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { SelectOption } from '@vdms-hq/shared';
import { OptionType } from '@vdms-hq/shared';
import { TranslateService } from '@ngx-translate/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  SelectOptionsCreateDialogInput,
  SelectOptionsCreateDialogResponse,
} from './select-options-create-dialog.model';

@Component({
  selector: 'vdms-hq-selectors-select-options-create-dialog',
  templateUrl: './select-options-create-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectOptionsCreateDialogComponent {
  public createSelectOptionForm = new UntypedFormGroup({
    label: new UntypedFormControl('', Validators.required),
    type_name: new UntypedFormControl('', Validators.required),
  });

  constructor(
    private readonly translateService: TranslateService,
    public selectOptionCreateDialogRef: MatDialogRef<
      SelectOptionsCreateDialogComponent,
      SelectOptionsCreateDialogResponse
    >,
    @Inject(MAT_DIALOG_DATA) public data: SelectOptionsCreateDialogInput,
  ) {}

  #fallbackTypeSelectOptions: SelectOption[] = Object.values(OptionType)
    .map((type: string) => ({
      key: type,
      label: type.split('_').join(' '),
    }))
    .sort((a, b) => a.label.localeCompare(b.label));

  typeSelectOptions = this.data?.autocompleteOptions?.length
    ? this.data.autocompleteOptions
    : this.#fallbackTypeSelectOptions;

  public save(): void {
    this.selectOptionCreateDialogRef.close({
      optionChanges: {
        ...this.createSelectOptionForm.value,
        key: this.createSelectOptionForm.value.label,
        type_name: this.createSelectOptionForm.value?.type_name?.replace(' ', '_'),
      },
    });
  }

  public cancel(): void {
    this.selectOptionCreateDialogRef.close();
  }
}
