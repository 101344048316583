export type SelectOptionKey = string | boolean | number | null | object;

export interface SelectOption<K = SelectOptionKey> {
  key: K;
  label: string;
  disabled?: boolean;
  suffix?: string;
  hidden?: boolean;
}

export interface ChipOption {
  name: string;
  value: string;
}

export interface SelectOptionGroup extends SelectOption {
  options: SelectOption[];
}

export const typeToSelectOption = (type: string): SelectOption => ({ key: type, label: type });

/**
 * @deprecated convert to enumValuesToSelectOptions
 * @param object
 * @param translationPrefix
 * @param lowerCaseValue
 */
export const enumToSelectOptions = (
  object: Record<string, string>,
  translationPrefix: string,
  lowerCaseValue = false,
): SelectOption[] => {
  return Object.entries(object).map(([value, key]) => ({
    key: key,
    label: `${translationPrefix}${lowerCaseValue ? value.toLowerCase() : value}`,
  }));
};

export const enumValuesToSelectOptions = (
  object: Record<string, string>,
  translationPrefix: string,
  lowerCaseValue = false,
): SelectOption[] => {
  return Object.values(object).map((value) => ({
    key: value,
    label: `${translationPrefix}${lowerCaseValue ? value.toLowerCase() : value}`,
  }));
};

export const enumValueToSelectOption = (value: string): SelectOption => ({ key: value, label: value });
