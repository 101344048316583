import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Pagination, PaginationProps } from '@vdms-hq/ui';
import { Observable } from 'rxjs';
import { GetResponseData } from '../../operators/get-response-data.operator';
import { ApiEmptyResponse, ApiPaginatedResponse, ApiResponse } from '../api.model';
import { ApiService } from '../api.service';
import { AssetSearchFilters } from '../asset';
import { CART_ENDPOINTS } from './models/cart-endpoints.model';
import {
  AssetFlatCartItem,
  CartSummaryRequest,
  CartSummary,
  Counters,
  DestinationsValidateResponse,
  OrderPdfResponse,
  RecentEmailsResponse,
  InvalidCartAsset,
} from './models/cart.model';
import { ORDER_TYPE } from '../order/order.model';

@Injectable({ providedIn: 'root' })
export class CartApiService {
  constructor(private readonly apiService: ApiService) {}

  getCartLibrary = (pagination?: PaginationProps) => {
    const headers = Pagination.create(pagination).applyToHeaders(new HttpHeaders());
    return this.apiService
      .get<ApiResponse<number>>(`${CART_ENDPOINTS.CART}/${CART_ENDPOINTS.CATALOG_ITEMS}`, { headers })
      .pipe(GetResponseData);
  };

  getCartValidation = (options?: { pagination?: PaginationProps }): Observable<ApiResponse<InvalidCartAsset[]>> => {
    const headers = Pagination.create(options?.pagination).applyToHeaders(new HttpHeaders());

    return this.apiService.get<ApiResponse<InvalidCartAsset[]>>(
      `${CART_ENDPOINTS.CART}/${CART_ENDPOINTS.ASSETS}/${CART_ENDPOINTS.INVALID}`,
      {
        headers,
      },
    );
  };

  getCartAssets = (options?: {
    pagination?: PaginationProps;
    embargoed?: boolean;
    quarantined?: boolean;
  }): Observable<ApiPaginatedResponse<AssetFlatCartItem>> => {
    let headers = Pagination.create(options?.pagination).applyToHeaders(
      options?.embargoed !== undefined
        ? new HttpHeaders().set('embargoed', options.embargoed ? 'true' : 'false')
        : new HttpHeaders(),
    );

    headers =
      options?.quarantined !== undefined ? headers.set('quarantined', options.quarantined ? 'true' : 'false') : headers;

    return this.apiService.get<ApiPaginatedResponse<AssetFlatCartItem>>(
      `${CART_ENDPOINTS.CART}/${CART_ENDPOINTS.ASSETS}`,
      { headers },
    );
  };

  getCartSummary = (
    order_type: ORDER_TYPE,
    destinations?: CartSummaryRequest['destinations'],
    discount_code?: string,
    video_analysis?: {
      model_uuids: string[];
      options?: {
        target_languages: string[];
      };
    },
  ) => {
    return this.apiService
      .post<CartSummaryRequest, ApiResponse<CartSummary>>(`${CART_ENDPOINTS.CART}/summary`, {
        order_type,
        discount_code,
        destinations: destinations?.length ? destinations : undefined,
        video_analysis,
      })
      .pipe(GetResponseData);
  };

  getOrderPdf = (orderUuid: string, order_type: ORDER_TYPE, discount_code?: string) => {
    return this.apiService
      .post<CartSummaryRequest, ApiResponse<OrderPdfResponse>>(`${CART_ENDPOINTS.ORDER}/${orderUuid}/pdf`, {
        order_type,
        discount_code,
      })
      .pipe(GetResponseData);
  };

  getRecentEmails = () =>
    this.apiService
      .get<ApiResponse<RecentEmailsResponse>>(`${CART_ENDPOINTS.ORDER}/${CART_ENDPOINTS.EMAILS}`)
      .pipe(GetResponseData);

  getTotal = (): Observable<number> =>
    this.apiService.get<ApiResponse<number>>(`${CART_ENDPOINTS.CART}/${CART_ENDPOINTS.COUNT}`).pipe(GetResponseData);

  delete = (ids: string[]): Observable<Counters> =>
    this.apiService.delete<ApiResponse<Counters>, string[]>(`${CART_ENDPOINTS.CART}`, ids).pipe(GetResponseData);

  deleteAllItems = (): Observable<boolean> =>
    this.apiService.delete<ApiResponse<boolean>>(`${CART_ENDPOINTS.CART}`).pipe(GetResponseData);

  addFromSearchQuery = (searchFilters: AssetSearchFilters) =>
    this.apiService.put(`${CART_ENDPOINTS.CART_FOLDER}`, { text: '', filters: searchFilters });

  put = <PUT, RESPONSE>(payload: PUT) =>
    this.apiService.put<PUT, ApiResponse<RESPONSE>>(`${CART_ENDPOINTS.CART}`, payload);

  validate = (payload: { delivery_config_uuids: string[] }): Observable<DestinationsValidateResponse[]> => {
    return this.apiService
      .post<{ delivery_config_uuids: string[] }, ApiResponse<DestinationsValidateResponse[]>>(
        `${CART_ENDPOINTS.CART}/validate`,
        payload,
      )
      .pipe(GetResponseData);
  };
}
