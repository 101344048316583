import { inject, Injectable } from '@angular/core';
import { Timecode } from '@vdms-hq/timecode';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import * as Wavesurfer from 'videojs-wavesurfer/dist/videojs.wavesurfer';
import { SharedPlayerService } from './shared-player.service';
import { PlayerInterface } from './player.interface';
import videojs from 'video.js';
import { AUDIO_FRAME } from './player-util';
import { map, take } from 'rxjs/operators';
import { TimecodesService } from './timecodes.service';
import { PLAYBACK_RATES } from './advanced-player';

@Injectable({
  providedIn: 'root',
})
export class AudioPlayerService extends SharedPlayerService implements PlayerInterface {
  private readonly AUDIO_DOM_ID = 'audio-player';
  private plugin = Wavesurfer;

  #previousTimeCode: Timecode | null = null;
  #currentTimeCode: Timecode | null = null;
  #requestAnimationFrameId?: number;
  #timecodes = inject(TimecodesService);
  #latestVolume?: number;

  #audioPlayer?: Wavesurfer.VideojsWavesurfed;

  load = () => {
    if (!this.config) {
      this.setError('Config not passed to audio-player.service.ts');
      throw new Error('Player must by configured first');
    }
    this.stateSubject$.next({ state: 'loading' });

    if (this.config.waveformsVtt) {
      this.#audioWithWaveForm();
    }

    if (!this.config.waveformsVtt) {
      this.#audioPure();
    }

    this.#audioPlayer?.on('loadedmetadata', () => {
      if (!this.#audioPlayer) {
        return;
      }
      this.#emitTime();
      this.duration$.next(Timecode.fromSeconds(this.#audioPlayer?.duration(), AUDIO_FRAME));
      this.stateSubject$.next({ state: 'ready' });
    });
  };

  unload() {
    this.#audioPlayer?.dispose();
    this.#audioPlayer = undefined;
    this.duration$.next(null);
    this.currentTimecode$.next(null);
    this.#previousTimeCode = null;
    this.#currentTimeCode = null;
    this.currentPlaybackRate$.next(1);
    this.currentVolumeLevel$.next(1);
  }

  loadSubtitles(): Promise<void> {
    return Promise.resolve(undefined);
  }

  get #currentTime() {
    return this.#audioPlayer?.currentTime() ?? 0;
  }

  get #duration() {
    return this.#audioPlayer?.duration() ?? 0;
  }

  #emitTime() {
    if (!this.#audioPlayer) {
      return;
    }

    this.#currentTimeCode = this.#prepareTimecode(Timecode.fromSeconds(this.#currentTime, AUDIO_FRAME, false));

    if (!this.#previousTimeCode || !this.#currentTimeCode?.equals(this.#previousTimeCode)) {
      this.#previousTimeCode = this.#currentTimeCode;
      this.currentTimecode$.next(this.#currentTimeCode);
    }

    this.#requestAnimationFrameId = window.requestAnimationFrame(this.#emitTime.bind(this));
  }

  #prepareTimecode(tc: Timecode | null) {
    if (!tc) {
      return null;
    }

    const separator = ':';
    const tcStr = tc.toString();
    const tcArr = tcStr.split(separator);
    tcArr[3] = '00';

    return Timecode.fromTimecode(tcArr.join(separator), AUDIO_FRAME, false);
  }

  #audioWithWaveForm() {
    this.#audioPlayer = videojs(this.AUDIO_DOM_ID, {
      controls: true,
      autoplay: false,
      preload: 'auto',
      poster: undefined,
      bigPlayButton: false,
      plugins: {
        wavesurfer: {
          backend: 'MediaElement',
          displayMilliseconds: false,
          debug: true,
          progressColor: '#3573FF',
          waveColor: '#A4A9B7',
          cursorColor: '#fff',
          barWidth: 1,
          normalize: true,
          xhr: {
            credentials: 'include',
          },
        },
      },
    });

    this.#audioPlayer.src({
      src: this.config?.file.url,
      type: this.config?.file.extension === 'mp3' ? 'audio/mp3' : 'audio/wav',
      peaks: this.config?.waveformsVtt?.[0].url,
    });
  }

  #audioPure() {
    this.#audioPlayer = videojs(this.AUDIO_DOM_ID, {
      controls: true,
      autoplay: false,
      liveui: false,
      preload: 'auto',
      bigPlayButton: false,
      poster: '/assets/ui/preview/audio_placeholder.svg',
    });

    this.#audioPlayer.src({
      src: this.config?.file.url,
      type: this.config?.file.extension === 'mp3' ? 'audio/mp3' : 'audio/wav',
    });
  }

  protected changeQualityLevel(index: number) {
    return;
  }

  protected override togglePictureInPicture() {
    return;
  }

  protected override toggleHelpDialog() {
    return;
  }

  protected togglePlayPause(): void {
    if (this.#audioPlayer?.paused()) {
      this.play();
    } else {
      this.pause();
    }
  }

  protected seekSeconds(seconds: number): void {
    const defaultFramerate = this.framerateSubject.value.value;
    this.seekFrames(Number((seconds as number) * (this.config?.framerate?.value ?? defaultFramerate)));
  }

  protected seekFrames(frames: number) {
    if (!this.#currentTimeCode) {
      return;
    }

    this.pause();
    const newTc = this.#currentTimeCode.addFrames(frames, false)?.countSeconds() ?? 0;
    this.#audioPlayer?.currentTime(Math.min(this.#duration, newTc));
  }

  protected updateVolumeUp(change: number): void {
    if (!this.#audioPlayer) {
      return;
    }

    const current = this.#audioPlayer.volume();

    const next = Math.max(0, Math.min(1, current + change));

    this.#audioPlayer.volume(next);
  }

  protected updateVolume() {
    return;
  }

  protected toggleMute(): void {
    if (!this.#audioPlayer) {
      return;
    }

    const current = this.#audioPlayer.volume();

    if (current && current > 0) {
      this.#latestVolume = current;
      this.#audioPlayer.volume();
    } else {
      this.#audioPlayer.volume(this.#latestVolume ?? 1);
    }
  }

  protected changeSubtitles(): void {
    return;
  }

  protected increasePlaybackRate(): void {
    const currentPlaybackValue = this.currentPlaybackRate$.value;

    const index = PLAYBACK_RATES.indexOf(currentPlaybackValue) || 0;

    const nextIndex = index === PLAYBACK_RATES.length ? PLAYBACK_RATES[0] : PLAYBACK_RATES[index + 1];

    this.#audioPlayer?.playbackRate(nextIndex);
    this.currentPlaybackRate$.next(nextIndex);
  }

  protected decreasePlaybackRate(): void {
    const currentPlaybackValue = this.currentPlaybackRate$.value;

    const index = PLAYBACK_RATES.indexOf(currentPlaybackValue) || 0;

    const nextIndex = index === 0 ? PLAYBACK_RATES[PLAYBACK_RATES.length - 1] : PLAYBACK_RATES[index - 1];

    this.#audioPlayer?.playbackRate(nextIndex);
    this.currentPlaybackRate$.next(nextIndex);
  }

  protected setPlaybackRate(rate: number): void {
    this.#audioPlayer?.playbackRate(rate);
    this.currentPlaybackRate$.next(rate);
  }

  protected resetPlaybackRate(): void {
    this.#audioPlayer?.playbackRate(1);
    this.currentPlaybackRate$.next(1);
  }

  protected pause(): void {
    this.#audioPlayer?.pause();
  }

  protected play(): void {
    this.#audioPlayer?.play();
  }

  protected toggleFullScreen(): void {
    if (!this.#audioPlayer) {
      return;
    }

    this.#audioPlayer.isFullscreen() ? this.#audioPlayer.cancelFullScreen() : this.#audioPlayer.requestFullscreen();
  }

  protected changeAudioTrack(): void {
    return;
  }

  protected playToIn(): void {
    this.#timecodes.timecodes$
      .pipe(
        take(1),
        map(([tcIn]) => tcIn?.countSeconds()),
      )
      .subscribe((tcIn) => tcIn && this.goToTime(tcIn));
  }

  protected playToOut(): void {
    this.#timecodes.timecodes$
      .pipe(
        take(1),
        map(([, tcOut]) => tcOut?.countSeconds()),
      )
      .subscribe((tcOut) => tcOut && this.goToTime(tcOut));
  }

  protected inToPlayhead(): void {
    this.#currentTime() && this.#audioPlayer.setTcIn(Timecode.fromSeconds(this.#currentTime()) as Timecode);
  }

  protected outToPlayhead(): void {
    this.#currentTime() && this.#audioPlayer.setTcIn(Timecode.fromSeconds(this.#currentTime()) as Timecode);
  }

  protected goToTime(time: number): void {
    if (!this.#audioPlayer) {
      return;
    }

    this.pause();
    this.#audioPlayer.currentTime(time);
  }
}
