import { ActivatedClientService } from '@vdms-hq/activated-client';
import { AssetFlat } from '@vdms-hq/api-contract';
import { TitleFormat } from '@vdms-hq/firebase-contract';
import { DataProviderService, SelectorSourceType } from '@vdms-hq/selectors';
import { isEmbargoActive } from '@vdms-hq/shared';
import { StorageUrlService } from '@vdms-hq/storage';
import { Framerate, Timecode } from '@vdms-hq/timecode';
import { Observable, startWith } from 'rxjs';
import { map } from 'rxjs/operators';
import { getFallbackBackground, getIcon } from './asset-media-config';

export interface AssetFlatView2Params {
  touchedAt?: string;
}

export interface AssetFlatView2Model {
  /** @description input asset model, not modified */
  props: AssetFlat;

  /** @description title combined based on client.vida?.grid?.titleFormat */
  title: Observable<string | null>;

  /** @description background or background fallback */
  background: string | null;

  /** @description icon */
  icon: string | null;

  /** @description backgroundHover or backgroundHover fallback */
  backgroundHover: string | null;

  /** @description series number with leading zeros */
  seriesNumber: string | null;

  /** @description series number with leading zeros */
  episodeNumber: string | null;

  /** @description duration of container */
  containerDuration: Timecode | null;

  /** @description duration of video */
  duration: Timecode | null;

  /** @description video tc in */
  timecodeIn: Timecode | null;

  /** @description video tc out */
  timecodeOut: Timecode | null;

  /** @description framerate if applied */
  framerate: Framerate | null;

  isCold: boolean;

  isEmbargoActive: boolean;

  isDeleted: boolean;

  isQuarantined: boolean | undefined;

  embargo: Date | 'EMBARGO UNSET' | 'EMBARGO INFINITY' | null;

  canAddToCart: boolean;

  canAddToCollection: boolean;

  canAddToLicensedPackage: boolean;

  canDelete: boolean;

  isColdAsset: boolean;
}

export class AssetFlatView2 implements AssetFlatView2Model {
  public touchedAt = Date.now();

  constructor(
    public props: AssetFlat,
    private deps: {
      dataProvider: DataProviderService;
      storageUrlService: StorageUrlService;
      activatedClientService: ActivatedClientService;
    },
  ) {}

  static fromAssetFlat(
    originalAsset: AssetFlat,
    deps: {
      dataProvider: DataProviderService;
      storageUrlService: StorageUrlService;
      activatedClientService: ActivatedClientService;
    },
  ) {
    return new AssetFlatView2(originalAsset, deps);
  }

  updateProps(newProps: Partial<AssetFlat>) {
    this.props = { ...this.props, ...newProps };
    this.touchedAt = Date.now();
  }

  get icon() {
    return getIcon(this.props.type);
  }

  get isColdAsset() {
    return this.props.storage === 'cold';
  }

  get backgroundBig() {
    const bigThumbnail = this.props.thumbnails?.find((thumb) => {
      return thumb.type === 'big';
    });

    return bigThumbnail ? this.deps.storageUrlService.updateCdn(bigThumbnail.url) : this.background;
  }

  get storageStatus() {
    return this.props.storage_status === 'active' && this.props.storage === 'cold'
      ? this.props.estimated_restore_at
      : this.props.storage_status;
  }

  get background() {
    const externalThumbnail = this.props.thumbnails?.find((thumb) => thumb.type === 'external');

    if (this.props.type === 'video' && externalThumbnail?.url) {
      const url = this.deps.storageUrlService.updateCdn(externalThumbnail?.url);

      if (url) {
        return url;
      }
    }

    const smallThumbnail = this.props.thumbnails?.find((thumb) => thumb.type === 'main');
    const bigThumbnail = this.props.thumbnails?.find((thumb) => thumb.type === 'big');

    const thumbToUse = smallThumbnail ?? bigThumbnail;

    if (thumbToUse) {
      const url = this.deps.storageUrlService.updateCdn(thumbToUse.url);

      if (url) {
        return url;
      }
    }

    return getFallbackBackground(this.props.type) ?? 'assets/common/asset_fallbacks/unknown.png';
  }

  get backgroundHover() {
    const gifThumbnail = this.props.thumbnails?.find((thumb) => thumb.type === 'gif');

    if (this.props.type === 'video' && gifThumbnail?.url) {
      const url = this.deps.storageUrlService.updateCdn(gifThumbnail?.url);

      if (url) {
        return url;
      }
    }

    return this.background;
  }

  get processing() {
    if (this.props.processing_status.toLowerCase() == 'created') {
      return 'INITIALIZING';
    }

    if (['PRORES_REUPLOADED', 'ANALYSE_REUPLOADED'].includes(this.props.processing_status)) {
      return 'AWAITING_ANALYSE';
    }
    return this.props.processing_status;
  }

  get embargo() {
    if (!this.props.embargoed_to || this.props.embargoed_to === '1970-01-01T00:00:01.000Z') {
      return 'EMBARGO UNSET';
    }

    if (this.props.embargoed_to === '2038-01-19T03:14:07.000Z') {
      return 'EMBARGO INFINITY';
    }

    return new Date(this.props.embargoed_to);
  }

  get isCold() {
    if (!this.props.storage) {
      return false;
    }

    return this.props.storage === 'cold';
  }

  get isEmbargoActive() {
    if (!this.props.embargoed_to) {
      return false;
    }

    return isEmbargoActive(this.props.embargoed_to);
  }

  get episodeNameShort() {
    if (!this.props.episode_name) {
      return null;
    }

    if (this.props.episode_name.length > 124) {
      return String(this.props.episode_name).slice(0, 110) + '...';
    }
    return this.props.episode_name;
  }

  get episodeNumber() {
    return this.props.episode_number ? 'E' + this.#addLeadingZeros(this.props.episode_number) : null;
  }

  get seriesNumber() {
    return this.props.series_number ? 'S' + this.#addLeadingZeros(this.props.series_number) : null;
  }

  get framerate() {
    if (!this.props.framerate) {
      return null;
    }
    if (this.props.framerate === 'null') {
      // todo can be removed as soon as /vida-backend/issues/6389 is ready
      return null;
    }
    return Framerate.fromValue(this.props.framerate, this.props.droppedFrames ?? false);
  }

  get offset() {
    if (this.props.offset) {
      return Timecode.fromTimecode(this.props.offset, this.framerate);
    }

    return Timecode.fromSeconds(0, this.framerate, false);
  }

  get isAudio() {
    return this.props.type === 'audio';
  }

  get timecodeIn() {
    return Timecode.fromTimecode(this.props.timecode_in, this.framerate, false, this.isAudio);
  }

  get timecodeOut() {
    return Timecode.fromTimecode(this.props.timecode_out, this.framerate, false, this.isAudio);
  }

  get videoDuration() {
    if (!this.props.video_duration) {
      return null;
    }

    return Timecode.fromSeconds(this.props.video_duration, this.framerate, false);
  }

  get playerOffset() {
    if (!this.props.player_offset) {
      return null;
    }

    return Timecode.fromTimecode(this.props.player_offset, this.framerate, false);
  }

  get containerDuration() {
    if (!this.props.container_duration) {
      return null;
    }

    return Timecode.fromSeconds(this.props.container_duration, this.framerate, false, this.isAudio);
  }

  get duration() {
    if (this.timecodeOut && this.timecodeIn) {
      return this.timecodeOut.subtract(this.timecodeIn);
    }

    if (this.props.duration) {
      return Timecode.fromTimecode(this.props.duration, this.framerate, false, this.isAudio);
    }
    return null;
  }

  get title() {
    return this.deps.activatedClientService.clientDefinite$.pipe(
      map((client) => {
        const titleFormat = client.vida?.grid?.titleFormat;

        let name = '';

        switch (titleFormat) {
          case TitleFormat.seriesNameAndSxxExxOrEpisodeName:
            if (this.props.series_name) {
              name += this.props.series_name + ' ';

              if (this.seriesNumber) {
                name += this.seriesNumber;
              }

              if (this.episodeNumber) {
                name += this.episodeNumber;
              }
            } else if (this.episodeNameShort) {
              name += this.episodeNameShort;
            }

            if (name.trim() !== '') {
              return name;
            }

            return;

          case TitleFormat.episodeNameAndSxExOrOriginalFilename:
            if (this.episodeNameShort) {
              name += this.episodeNameShort + ' ';
            }

            if (this.seriesNumber) {
              name += this.seriesNumber;
            }

            if (this.episodeNumber) {
              name += this.episodeNumber;
            }

            if (name.trim() !== '') {
              return name;
            }

            return;

          case TitleFormat.originalFilename:
            return;

          case TitleFormat.programmeNumber:
            return this.props.programme_number ? String(this.props.programme_number) : null;

          case TitleFormat.truncatedEpisodeNameProgrammeNumber:
            if (this.episodeNameShort) {
              name += this.episodeNameShort + ' ';
            }

            if (this.props.programme_number) {
              name += this.props.programme_number;
            }

            return name;

          default:
            return this.episodeNameShort;
        }
      }),
      map((title) => {
        return title ? title : String(this.props.original_filename);
      }),
    );
  }

  get description() {
    return this.props.description ?? this.props.synopsis_short ?? this.props.synopsis ?? null;
  }

  // todo: currently we can add assets with embargo to cart
  get canAddToCart() {
    return !this.isEmbargoActive;
  }

  get canAddToCollection() {
    return true;
  }

  get canAddToLicensedPackage() {
    return true;
  }

  get canDelete() {
    return true;
  }

  get deletedAt() {
    return this.props?.deleted_at;
  }

  get isDeleted() {
    return !!this.props?.deleted_at ?? false;
  }

  get isQuarantined() {
    return this.props?.is_quarantined;
  }

  get isVirtual() {
    return this.props?.is_virtual ?? false;
  }

  get audio_tracks() {
    return (this.props.audio_tracks ?? []).map((audioTrack) => {
      return [audioTrack.layout, audioTrack.class, audioTrack.language].filter((item) => !!item).join(', ');
    });
  }

  get ratingsSummary() {
    if (!this.props.rating_uuids || this.props.rating_uuids.length === 0) {
      return null;
    }

    return this.deps.dataProvider
      .getValueForGroupSelector(SelectorSourceType.ASSET_GENERAL_RATINGS, this.props.rating_uuids)
      .pipe(startWith('Loading...'));
  }

  #addLeadingZeros = (value?: string | number) => String(value).padStart(2, '0');
}
