import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import {
  ActivatedClientModule,
  ActivatedClientService,
  Permission,
  PermissionService,
} from '@vdms-hq/activated-client';
import { CollectionModelFlat, CollectionViewModel } from '@vdms-hq/api-contract';
import {
  AssetActionsService,
  AssetResults2Component,
  AssetResultsModule,
  ColumnsConfigService,
  ColumnsFetcherService,
  ContextMenuActionsService,
  ResultsActions,
} from '@vdms-hq/asset-results';
import { AuthService } from '@vdms-hq/auth';
import { AddToCartActionsService, CartStateService } from '@vdms-hq/cart-core';
import { FieldsConfigService } from '@vdms-hq/config';
import { DynamicFiltersModule } from '@vdms-hq/dynamic-filters';
import { ColumnSettingsScope } from '@vdms-hq/firebase-contract';
import { ResultDefinitionModel } from '@vdms-hq/shared';
import {
  ActionContextLess,
  ActionIdentifier,
  DataAction as Action,
  DataAction,
  DataPresentationHeaderComponent,
  FloatingControlsComponent,
  MultipleDataPresentationComponent,
  MultipleViewConfiguration,
  PaginatorComponent,
  UIButtonModule,
  UIConfirmationDialogService,
  UIDataHeaderActionsComponent,
  UILayoutModule,
  UILoaderModule,
  UIResultsWrapperModule,
  UISimpleDetailsListModule,
} from '@vdms-hq/ui';
import {
  BehaviorSubject,
  combineLatest,
  filter,
  Observable,
  shareReplay,
  startWith,
  Subject,
  take,
  takeUntil,
} from 'rxjs';
import { map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { SingleCollectionAssetsDataSource } from '../../logic/datasources/single-collection-assets-ds.service';
import { SingleCollectionSubDataSource } from '../../logic/datasources/single-collection-sub-data-source';
import { CollectionAssetViewModel } from '../../logic/models/collection-asset-view.model';
import { CollectionAssetsFilterService } from '../../logic/services/collection-assets-filter.service';
import { CollectionsActionsService } from '../../logic/services/collections-actions.service';
import { SingleCollectionService } from '../../logic/services/single-collection.service';
import { CollectionsRefresh } from '../../logic/utils/collections-refresh';
import { COLLECTIONS_ROUTER_BASE } from '../collections-routes';

@Component({
  selector: 'vdms-hq-single-collection',
  templateUrl: './single-collection.component.html',
  styleUrls: ['./single-collection.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    UILayoutModule,
    UIResultsWrapperModule,
    UISimpleDetailsListModule,
    UILoaderModule,
    TranslateModule,
    UIButtonModule,
    MatTooltipModule,
    MatDividerModule,
    FloatingControlsComponent,
    UIDataHeaderActionsComponent,
    AssetResultsModule,
    ActivatedClientModule,
    PaginatorComponent,
    AssetResults2Component,
    DataPresentationHeaderComponent,
    DynamicFiltersModule,
    MultipleDataPresentationComponent,
  ],
  providers: [SingleCollectionSubDataSource, ColumnsConfigService, ColumnsFetcherService],
})
export class SingleCollectionComponent implements OnDestroy, OnInit {
  protected readonly assetResultsMenuConfig2: DataAction<CollectionAssetViewModel>[] = [
    {
      key: 'tab',
      icon: 'tab',
      label: 'Open in new tab',
    },
    {
      key: 'window',
      icon: 'window',
      label: 'Open in new window',
    },
    {
      key: 'uuid',
      icon: 'uuid',
      label: 'Copy asset Uuid',
    },
  ];
  Permission = Permission;
  destroy$ = new Subject<void>();

  scopeName: ColumnSettingsScope = 'collections';
  currentCollectionUuid = new BehaviorSubject<string>('');
  actions: Action<CollectionModelFlat>[] = [];
  subCollectionConfig: MultipleViewConfiguration<CollectionModelFlat> = {
    multiView: {
      fitToVisibleArea: false,
    },
    gridAdvanced: {
      layout: {
        columns: 'up-to-2',
      },
      actions: this.actions,
      content: {
        titlePath: 'name',
        metadata: [
          {
            label: 'common.collection_type',
            valuePath: 'access_type',
            viewFormat: {
              modifiers: {
                textTransform: 'capitalize',
              },
            },
          },
          {
            label: 'common.collection_owner',
            valuePath: 'owner_name',
            hiddenIf: (item: CollectionModelFlat) => item.access_type === 'shared' || item.access_type === 'dashboard',
          },
          {
            label: 'common.assets_number',
            valuePath: 'number_of_assets',
            fallback: 'Empty',
          },
          {
            label: 'common.created_at',
            valuePath: 'created_at',
            viewFormat: {
              modifiers: {
                dateFormat: 'date-time',
              },
            },
          },
          {
            label: 'common.updated_at',
            valuePath: 'updated_at',
            viewFormat: {
              modifiers: {
                dateFormat: 'date-time',
              },
            },
          },
          {
            label: 'common.description',
            valuePath: 'description',
            fullLine: true,
            fallback: 'N/A',
          },
        ],
      },
      image: {
        bgPath: 'custom_cover_path',
        bgHoverPath: 'custom_cover_path',
      },
    },
  };

  isDashboardCollection$ = this.dataSource.collectionData$.pipe(
    map((data) => data.access_type === 'dashboard'),
    shareReplay(1),
  );

  headerActions$: Observable<ActionContextLess[]> = combineLatest([
    this.collectionsActions.buildEditCollectionAction$<CollectionAssetViewModel>(),
    this.collectionsActions.buildExportMetadataAction$<CollectionAssetViewModel>(),
    this.addToCartActionsService.buildAddAssetAction$<CollectionAssetViewModel>(),
  ]).pipe(map((actions) => (actions as ActionContextLess[]).filter((action) => action !== null)));

  actions$: Observable<DataAction<CollectionAssetViewModel>[]> = this.isDashboardCollection$.pipe(
    switchMap((condition) => {
      return combineLatest([
        this.addToCartActionsService.buildAddAssetAction$<CollectionAssetViewModel>(),
        this.collectionsActions.buildAddToCollectionAction$<CollectionAssetViewModel>(),
        this.collectionsActions.buildDeleteAssetFromCollectionAction$<CollectionAssetViewModel>(condition),
        this.assetActionsService.buildQAResultsAction<CollectionAssetViewModel>(),
        this.assetActionsService.buildPreviewAction$<CollectionAssetViewModel>(),
      ]).pipe(map((actions) => actions.filter((action) => action !== null)));
    }),
  );

  subCollectionsOnlyView$ = this.dataSource.subCollectionsOnlyView$;

  subCollectionConfig$ = this.subCollectionsOnlyView$.pipe(
    map((subCollectionsOnly) =>
      subCollectionsOnly
        ? { ...this.subCollectionConfig, multiView: { fitToVisibleArea: true } }
        : this.subCollectionConfig,
    ),
    takeUntil(this.destroy$),
  );

  constructor(
    private fieldsConfigService: FieldsConfigService,
    private columnsFetcherService: ColumnsFetcherService,
    private activatedClientService: ActivatedClientService,
    private confirmationDialog: UIConfirmationDialogService,
    public collectionAssetDataSource: SingleCollectionAssetsDataSource,
    public subCollectionDataSource: SingleCollectionSubDataSource,
    public dataSource: SingleCollectionService,
    public collectionsActions: CollectionsActionsService,
    public addToCartActionsService: AddToCartActionsService,
    public cartStateService: CartStateService,
    private collectionsRefresher: CollectionsRefresh,
    public filterService: CollectionAssetsFilterService,
    private permissionService: PermissionService,
    private assetActionsService: AssetActionsService,
    private contextMenuActions: ContextMenuActionsService,
    private auth: AuthService,
    private router: Router,
  ) {
    this.dataSource.uuid$.pipe(takeUntil(this.destroy$)).subscribe((uuid) => {
      this.currentCollectionUuid.next(uuid);
    });
    this.collectionsRefresher.clearSelection$.pipe(takeUntil(this.destroy$)).subscribe((clear) => {
      if (clear && this.collectionAssetDataSource.selection) {
        this.collectionAssetDataSource.selection.clear();
        this.collectionsRefresher.clearSelection$.next(false);
      }
    });
  }

  ngOnInit() {
    this.filterService.listenUntil(this.destroy$);
    this.filterService.initConfig();

    this.auth.authDefinite$.pipe(take(1), takeUntil(this.destroy$)).subscribe((user) => this.#buildActions(user.id));
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
    this.collectionAssetDataSource.selection.clear();
  }

  #buildActions(userUuid: string): void {
    this.cartStateService.isEnabled$
      .pipe(
        takeUntil(this.destroy$),
        startWith(false),
        withLatestFrom(
          this.cartStateService.isClipOnlyAndPermitted$,
          this.permissionService.verifyWithOwnedPermissions$([Permission.SHARE_COLLECTIONS]),
        ),
      )
      .subscribe(([isDisabled, permitted, permittedToShare]) => {
        this.actions = permittedToShare
          ? [
              {
                key: 'sub.share',
                icon: 'share',
                onDoubleClick: false,
                label: 'common.global.share',
                hiddenIf: (element: CollectionModelFlat | undefined) => element?.owner !== userUuid,
              },
            ]
          : [];

        if (permitted) {
          this.actions.push({
            key: 'sub.cart',
            icon: 'add_shopping_cart',
            onDoubleClick: false,
            label: 'common.global.add_to_cart',
            disabledIf: () => isDisabled,
          });
        }

        this.permissionService
          .filterItemsAgainstPermissions$([
            {
              key: 'sub.collection',
              icon: 'playlist_add',
              onDoubleClick: false,
              label: 'common.global.add_to_collection',
              permissions: { ids: [Permission.EDIT_COLLECTIONS] },
              hiddenIf: (element: CollectionModelFlat | undefined) => element?.access_type === 'dashboard',
            },
            {
              key: 'sub.delete',
              icon: 'delete',
              onDoubleClick: false,
              label: 'common.global.delete',
              permissions: { ids: [Permission.DELETE_COLLECTIONS] },
              hiddenIf: (element: CollectionModelFlat) => element.owner !== userUuid,
            },
            {
              key: 'sub.delete',
              icon: 'delete',
              onDoubleClick: false,
              label: 'common.global.remove',
              permissions: { ids: [Permission.DELETE_COLLECTIONS] },
              hiddenIf: (element: CollectionModelFlat) => element.owner === userUuid,
            },
          ])
          .pipe(take(1))
          .subscribe((config) => {
            this.actions = [...(this.actions || []), ...config] as Action<CollectionModelFlat>[];
            this.actions.push({
              key: 'sub.preview',
              label: 'common.global.open_collection',
              icon: 'visibility',
            });
            if (this.subCollectionConfig.gridAdvanced) {
              this.subCollectionConfig.gridAdvanced.actions = this.actions;
            }
          });
      });
  }

  customActionHandler($event: { key: ResultsActions | ActionIdentifier | string; item?: any }) {
    const assetIds: { asset_uuid: string; item_uuid: string }[] = [];
    const selection = this.collectionAssetDataSource.selection;

    if ($event.item) {
      assetIds.push({ asset_uuid: $event.item.props.asset_uuid, item_uuid: $event.item.props.item_uuid });
      this.handleActions($event, assetIds);
      return true;
    } else {
      const selected = selection.identifiersSnapshot as string[];
      this.collectionAssetDataSource.connection$.pipe(take(1)).subscribe((data) => {
        selected.forEach((item: string) => {
          const asset = data.find((asset) => asset.context.uuid === item);
          if (asset) {
            assetIds.push({ asset_uuid: asset.context.uuid, item_uuid: asset.props.item_uuid as string });
          }
        });
        this.handleActions($event, assetIds);
      });
      return true;
    }
  }

  handleActions(
    $event: { key: ResultsActions | ActionIdentifier | string; item?: any },
    assetIds: { asset_uuid: string; item_uuid: string }[],
  ) {
    const asset_uuids = assetIds.map((assetId) => assetId.asset_uuid);
    const item_uuids = assetIds.map((assetId) => assetId.item_uuid);

    this.contextMenuActions.handleAction($event);

    switch ($event.key) {
      case 'asset.quality_control_results':
        this.assetActionsService.handleQAResultsAction($event);
        return true;
      case 'batch_update':
        this.assetActionsService.popBatchUpdateDialog(asset_uuids, 'collection_batch_update');
        return true;
      case 'asset.collection':
        this.collectionsActions.addAssetsToCollection(asset_uuids, undefined);
        return true;
      case 'cart.add':
        this.addToCartActionsService.addAssets(asset_uuids.map((assetId) => ({ assetId })));
        return true;
      case 'asset.delete':
        this.collectionsActions.removeAssets(this.currentCollectionUuid.value, item_uuids);
        return true;
      case 'deselect_all':
        this.collectionAssetDataSource.selection.clear();
        return true;
      case 'asset.preview':
        this.router.navigate(['/asset', $event.item.props.asset_uuid]);
        return true;
      default:
        return false;
    }
  }

  handleHeaderAction($event: { key: string }): boolean {
    switch ($event.key) {
      case 'collection.export_csv':
        this.exportCSV();
        return true;
      case 'cart.add':
        this.addCollectionToCart();
        return true;
      case 'collection.edit':
        this.editCollection();
        return true;
      default:
        return false;
    }
  }

  editCollection() {
    this.dataSource.collectionData$
      .pipe(
        take(1),
        takeUntil(this.destroy$),
        tap((collection) => {
          this.collectionsActions.openEditDialog(<CollectionModelFlat>collection, true);
        }),
      )
      .subscribe();
  }

  exportCSV() {
    this.confirmationDialog
      .open({
        title: 'Please confirm export action',
        message: 'If you confirm, we’ll send you a CSV with applied filters via email shortly.',
      })
      .pipe(
        filter((confirmed) => confirmed),
        takeUntil(this.destroy$),
        withLatestFrom(
          this.dataSource.collectionData$,
          this.activatedClientService.getColumns$(this.scopeName),
          this.columnsFetcherService.userConfig$(this.scopeName),
          this.fieldsConfigService.resultsDefinitions$,
        ),
        switchMap(([, collection, clientConfig, userConfig, allColumns]) => {
          const filtered: ResultDefinitionModel[] = [];
          const enabled: ResultDefinitionModel[] = (userConfig.length > 0 ? userConfig : clientConfig.default).reduce(
            (filtered, id) => {
              const findRes = allColumns.find((def) => def.id === id);
              if (findRes) {
                filtered.push(findRes);
              }
              return filtered;
            },
            filtered,
          );
          return this.collectionsActions.exportCollection(collection.uuid, enabled);
        }),
      )
      .subscribe();
  }

  addCollectionToCart() {
    this.dataSource.collectionData$
      .pipe(
        take(1),
        takeUntil(this.destroy$),
        map((value: CollectionViewModel) => value.uuid),
        tap((uuid) => {
          this.addToCartActionsService.addCollections([uuid]);
        }),
      )
      .subscribe();
  }

  emitAction($event: { key: string; item?: CollectionModelFlat }) {
    if (!$event.item) {
      return;
    }

    switch ($event.key) {
      case 'sub.delete':
        this.collectionsActions.removeSubCollection($event.item, this.currentCollectionUuid.value);
        this.collectionsRefresher.refreshSubCollectionsPagination$
          .pipe(takeUntil(this.destroy$), filter(Boolean))
          .subscribe(() => this.subCollectionDataSource.pageIndex$.next(0));
        break;
      case 'sub.cart':
        this.addToCartActionsService.addCollections([$event.item.uuid]);
        break;
      case 'sub.collection':
        this.collectionsActions.addToCollection($event.item.uuid);
        break;
      case 'sub.share':
        this.collectionsActions.openShareDialog($event.item);
        break;
      case 'sub.preview':
        this.router.navigate([COLLECTIONS_ROUTER_BASE.COLLECTIONS, $event.item.uuid]).then();
        break;
    }
  }
}
