<vdms-hq-orders-results
  [tableType]="tableType"
  [columnsSettingsScope]="columnsSettingsScope"
  [title]="title"
  [filtersConfig]="filtersConfig"
  [actions]="(actions$ | async) || []"
  (action)="handleAction($event)"
  (changeDefaultView)="changeDefaultView($event)"
>
</vdms-hq-orders-results>
