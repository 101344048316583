<div class="field-row" *ngIf="!unknownControl; else unknownFormControl">
  <vdms-hq-ui-form-input-toggle
    class="toggle"
    [ngModel]="innerFormControl.enabled"
    (ngModelChange)="changeDisabledState($event)"
    [label]="
      (innerFormControl.enabled
        ? content?.enabled ?? 'common.global.enabled'
        : content?.disabled ?? 'common.global.disabled'
      ) | translate
    "
    *ngIf="withStateToggle"
  >
  </vdms-hq-ui-form-input-toggle>

  <div [ngSwitch]="definition.input?.type" class="field">
    <vdms-hq-audio-tracks-table
      *ngSwitchCase="types.AUDIO_TRACK_TABLE"
      [formControl]="innerFormControl"
      [readonly]="definition.input?.readonly || formBuilder.locked"
    >
    </vdms-hq-audio-tracks-table>
    <vdms-hq-audio-tracks-legacy
      *ngSwitchCase="types.AUDIO_TRACK"
      [formControl]="innerFormControl"
      [readonly]="definition.input?.readonly || formBuilder.locked"
    >
    </vdms-hq-audio-tracks-legacy>
    <vdms-hq-audio-tracks-legacy
      *ngSwitchCase="types.AUDIO_TRACK_TEDIAL"
      [type]="types.AUDIO_TRACK_TEDIAL"
      [formControl]="innerFormControl"
      [readonly]="definition.input?.readonly || formBuilder.locked"
    >
    </vdms-hq-audio-tracks-legacy>
    <div class="mw-500">
      <ng-container *ngSwitchDefault>
        <vdms-hq-form-control-read-only
          *ngIf="definition.input?.readonly || formBuilder.locked; else formControlTemplate"
          [definition]="definition"
          [value]="innerFormControl.value"
        >
        </vdms-hq-form-control-read-only>
      </ng-container>
    </div>
  </div>

  <ng-template #formControlTemplate>
    <ng-container [ngSwitch]="definition.input?.type">
      <vdms-hq-ui-form-input-date-time-unix-timestamp-limited
        *ngSwitchCase="types.DATE_TIME_UNIX_TIMESTAMP_LIMITED"
        [label]="definition.label"
        [formControl]="innerFormControl"
        [sourceType]="definition.sourceListKey"
        [withTouchedIndicator]="withTouchedIndicator"
      ></vdms-hq-ui-form-input-date-time-unix-timestamp-limited>

      <vdms-hq-selector
        *ngSwitchCase="types.SELECT"
        [formControl]="innerFormControl"
        [withFooter]="withFooter"
        [label]="definition.label"
        [nullValue]="nullValue"
        [withTouchedIndicator]="withTouchedIndicator"
        class="material-custom-form"
        [filterMethod]="definition.sourceListKey ? fieldRelationsService.filters[definition.sourceListKey] : undefined"
        [sourceType]="$sourceListKeyToSourceType(definition.sourceListKey)"
      >
      </vdms-hq-selector>
      <vdms-hq-selector
        *ngSwitchCase="types.SELECT_MULTI"
        [formControl]="innerFormControl"
        [withFooter]="withFooter"
        [label]="definition.label"
        [nullValue]="nullValue"
        [withTouchedIndicator]="withTouchedIndicator"
        [multiple]="true"
        class="material-custom-form"
        [sourceType]="$sourceListKeyToSourceType(definition.sourceListKey)"
      >
      </vdms-hq-selector>

      <vdms-hq-ui-form-input-date
        *ngSwitchCase="types.DATE"
        [formControl]="innerFormControl"
        [label]="definition.label"
        [withFooter]="withFooter"
        [withTouchedIndicator]="withTouchedIndicator"
      >
      </vdms-hq-ui-form-input-date>

      <vdms-hq-ui-form-input-text-area
        *ngSwitchCase="types.TEXTAREA"
        [formControl]="innerFormControl"
        [label]="definition.label"
        [withFooter]="withFooter"
        [withTouchedIndicator]="withTouchedIndicator"
      >
      </vdms-hq-ui-form-input-text-area>

      <vdms-hq-form-input-timecode
        *ngSwitchCase="types.TIMECODE"
        [formControl]="innerFormControl"
        [framerate]="framerate"
        [label]="definition.label"
        [withFooter]="withFooter"
        [withTouchedIndicator]="withTouchedIndicator"
      >
      </vdms-hq-form-input-timecode>

      <vdms-hq-grouped-selector
        *ngSwitchCase="types.SELECT_GROUP"
        [label]="definition.label"
        [formControl]="innerFormControl"
        [withTouchedIndicator]="withTouchedIndicator"
      >
      </vdms-hq-grouped-selector>

      <vdms-hq-ui-form-input-checkbox
        *ngSwitchCase="types.CHECKBOX"
        [formControl]="innerFormControl"
        [label]="definition.label"
        [withFooter]="withFooter"
        [withTouchedIndicator]="withTouchedIndicator"
      >
      </vdms-hq-ui-form-input-checkbox>

      <vdms-hq-ui-form-input-number
        *ngSwitchCase="types.NUMBER"
        [formControl]="innerFormControl"
        [label]="definition.label"
        [withFooter]="withFooter"
        [withTouchedIndicator]="withTouchedIndicator"
      >
      </vdms-hq-ui-form-input-number>

      <vdms-hq-ui-form-input-text
        *ngSwitchCase="types.TEXT"
        [formControl]="innerFormControl"
        [label]="definition.label"
        [mask]="definition.input?.mask ?? ''"
        [withFooter]="withFooter"
        [withTouchedIndicator]="withTouchedIndicator"
      >
      </vdms-hq-ui-form-input-text>

      <vdms-hq-ui-form-input-text
        *ngSwitchCase="types.FILE_SIZE"
        [formControl]="innerFormControl"
        [label]="definition.label"
        [mask]="definition.input?.mask ?? ''"
        [withFooter]="withFooter"
        [withTouchedIndicator]="withTouchedIndicator"
      >
      </vdms-hq-ui-form-input-text>

      <vdms-hq-ai-summary
        *ngSwitchCase="types.AI_TRANSCODE_SUMMARY"
        [formControl]="innerFormControl"
        [label]="definition.label"
        [withFooter]="withFooter"
        [withTouchedIndicator]="withTouchedIndicator"
      >
      </vdms-hq-ai-summary>
      <vdms-hq-form-tags-selector
        *ngSwitchCase="types.TAGS"
        [formControl]="innerFormControl"
        [label]="definition.label"
        [withFooter]="withFooter"
        [withTouchedIndicator]="withTouchedIndicator"
      >
      </vdms-hq-form-tags-selector>

      <ng-container *ngSwitchDefault>
        <vdms-hq-ui-empty-results
          icon="warning"
          message="Unknown field type - {{ definition.input?.type | json }}"
        ></vdms-hq-ui-empty-results>
      </ng-container>
    </ng-container>
  </ng-template>
  <vdms-hq-ui-loader [size]="19" *ngIf="definition | isRelationLoading | async"></vdms-hq-ui-loader>
</div>
<ng-template #unknownFormControl>
  <vdms-hq-ui-empty-results
    icon="warning"
    message="Unknown form field - {{ definition | json }}"
  ></vdms-hq-ui-empty-results>
</ng-template>
