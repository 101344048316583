import { default as VendorTimecode, FRAMERATE } from 'smpte-timecode';
import { Framerate } from './framerate';

export class TimecodeCreationError extends Error {
  constructor(public reason: string) {
    super(`TimecodeCreationError: ${reason}`);
  }
}

export class TimecodeManipulationError extends Error {
  constructor(public reason: string) {
    super(`TimecodeManipulationError: ${reason}`);
  }
}

export class Timecode extends String {
  private static keepLastSegment = false;

  constructor(private timecode: VendorTimecode.TimecodeInstance, private fps: Framerate) {
    super();
  }

  static fromSeconds(
    seconds: undefined | null | number | string,
    fps: Framerate | null | undefined = undefined,
    throwOnFail = true,
    keepLastSegment = false,
  ) {
    try {
      if (seconds === undefined || seconds === null || seconds < 0) {
        return null;
      }

      if (!fps) {
        fps = Framerate.default();
      }

      this.keepLastSegment = keepLastSegment;

      const vendor = new VendorTimecode(Number(seconds) * fps.value, fps.value as FRAMERATE);
      vendor.dropFrame = fps.isDrop;
      return new Timecode(vendor, fps);
    } catch (e) {
      if (throwOnFail) {
        throw new TimecodeCreationError((e as Error).message);
      }
      console.log(
        'TimecodeCreationError',
        `GivenValue: ${seconds}`,
        (e as Error).message,
        `GivenFPS: ${fps?.label}`,
        fps,
      );
    }
    return null;
  }

  static fromFrames(
    frames: number | null | undefined,
    fps: Framerate | null | undefined = undefined,
    throwOnFail = true,
    keepLastSegment = false,
  ) {
    try {
      if (frames === undefined || frames === null) {
        return null;
      }
      if (!fps) {
        fps = Framerate.default();
      }

      this.keepLastSegment = keepLastSegment;

      const vendor = new VendorTimecode(frames, fps.value as FRAMERATE);
      vendor.dropFrame = fps.isDrop;
      return new Timecode(vendor, fps);
    } catch (e) {
      if (throwOnFail) {
        throw new TimecodeCreationError((e as Error).message);
      }
      console.log(
        'TimecodeCreationError',
        `GivenValue: ${frames}`,
        (e as Error).message,
        `GivenFPS: ${fps?.label}`,
        fps,
      );
    }
    return null;
  }

  static fromTimecode(
    timeCode: string | null | undefined,
    fps: Framerate | null | undefined = undefined,
    throwOnFail = true,
    keepLastSegment = false,
  ) {
    try {
      if (!timeCode) {
        return null;
      }

      if (!fps) {
        fps = Framerate.default();
      }

      const TIMECODE_PATTERN = new RegExp('^([0-1][0-9]|[0-2][0-3]):([0-5][0-9]):([0-5][0-9])[:;]([0-6][0-9])$');
      if (!TIMECODE_PATTERN.test(timeCode)) {
        timeCode = timeCode.substring(0, 11);
      }

      this.keepLastSegment = keepLastSegment;

      const vendor = new VendorTimecode(timeCode, fps.value as FRAMERATE);
      vendor.dropFrame = fps.isDrop;
      return new Timecode(vendor, fps);
    } catch (e) {
      if (throwOnFail) {
        throw new TimecodeCreationError((e as Error).message);
      }
      console.log(
        'TimecodeCreationError',
        `GivenValue: ${timeCode}`,
        (e as Error).message,
        `GivenFPS: ${fps?.label}`,
        fps,
      );
    }
    return null;
  }

  add(timeCode: Timecode, throwOnFail = true) {
    if (!timeCode.fps.equals(this.fps)) {
      if (throwOnFail) {
        throw new TimecodeManipulationError(
          `FPS should be same for both timecodes 1: ${timeCode.fps.label} 2: ${this.fps.label}`,
        );
      }
    } else {
      try {
        const next = Object.assign(Object.create(this.timecode), this.timecode).add(timeCode.timecode);

        return new Timecode(next, this.fps);
      } catch (e) {
        if (throwOnFail) {
          throw new TimecodeManipulationError((e as Error).message);
        }
        console.log('TimecodeManipulationError', (e as Error).message);
      }
    }

    return null;
  }

  subtract(timeCode: Timecode, throwOnFail = true) {
    if (!timeCode.fps.equals(this.fps)) {
      if (throwOnFail) {
        throw new TimecodeManipulationError('FPS should be same for both timecodes');
      }
    } else {
      try {
        const next = Object.assign(Object.create(this.timecode), this.timecode).subtract(timeCode.timecode);

        return new Timecode(next, this.fps);
      } catch (e) {
        if (throwOnFail) {
          throw new TimecodeManipulationError((e as Error).message);
        }
        console.log('TimecodeManipulationError', (e as Error).message);
      }
    }
    return null;
  }

  addFrames(frames: number, throwOnFail = true) {
    const nextValue = this.countFrames() + frames;
    if (nextValue < 0) {
      if (throwOnFail) {
        throw new TimecodeManipulationError("Timecode can't be less than 0 framerate");
      }
      console.log('TimecodeManipulationError', "Timecode can't be less than 0 framerate");
      return null;
    }
    return Timecode.fromFrames(this.countFrames() + frames, this.fps, throwOnFail);
  }

  equals(timeCode: Timecode) {
    return this.countFrames() === timeCode.countFrames() && timeCode.fps.equals(this.fps);
  }

  greaterThan(tcSecond: Timecode, orEqual = false) {
    if (orEqual) {
      return Number(this.countFrames()) >= Number(tcSecond.countFrames());
    }
    return Number(this.countFrames()) > Number(tcSecond.countFrames());
  }

  lessThan(tcSecond: Timecode, orEqual = false) {
    if (orEqual) {
      return Number(this.countFrames()) <= Number(tcSecond.countFrames());
    }
    return Number(this.countFrames()) < Number(tcSecond.countFrames());
  }

  countSeconds = () => {
    return this.countFrames() / this.fps.value;
  };

  countFrames = () => this.timecode.frameCount;

  override toString = () =>
    Timecode.keepLastSegment ? this.timecode.toString().substring(0, 8) : this.timecode.toString();

  getFps = () => this.fps;
}
