<vdms-hq-ui-loader mode="over-parent" *ngIf="(dataSource.loading$ | async) || loading"></vdms-hq-ui-loader>
<vdms-hq-ui-inner-header
  [formGroup]="formService.form"
  [breadcrumbs]="dataSource.breadcrumbs$ | async"
  subheader="common.delivery_destinations.edit.subtitle"
  [status]="{
    label: formService.form.get('status').value.toUpperCase(),
    color: formService.form.get('status').value | statusColor
  }"
  [action]="{ label: 'common.delivery_destinations.edit.details' | translate, icon: 'edit' }"
  (clickAction)="editDetails()"
>
  <vdms-hq-ui-simple-details-list
    class="grid-list-template"
    [listConfig]="destinationDetailsListConfig"
  ></vdms-hq-ui-simple-details-list>
</vdms-hq-ui-inner-header>

<vdms-hq-ui-inner-sidebar>
  <vdms-hq-ui-inner-sidebar-menu
    [loading]="dataSource.loading$ | async"
    [navItems]="dataSource.navLinks$ | async"
    left
  ></vdms-hq-ui-inner-sidebar-menu>
  <router-outlet></router-outlet>
</vdms-hq-ui-inner-sidebar>

<vdms-hq-ui-actions-footer>
  <div right class="actions">
    <span class="unsaved-changes" *ngIf="formService.form.dirty">Unsaved changes</span>
    <vdms-hq-ui-button color="warn" [loading]="loading" (click)="delete()">
      {{ 'common.global.delete' | translate }}
    </vdms-hq-ui-button>

    <div (click)="markAsTouched()">
      <vdms-hq-ui-button color="primary" [loading]="loading" (click)="save()" [disabled]="formService.form.invalid">
        {{ 'common.global.save' | translate }}
      </vdms-hq-ui-button>
    </div>
  </div>
</vdms-hq-ui-actions-footer>
