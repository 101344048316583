import { Injectable, inject } from '@angular/core';
import {
  DeliveryDestinationConfig,
  DeliveryDestinationJobStatusEnum,
  GetResponseData,
  OrderDownloadPayload,
  OrderModel,
  OrderPatchItemModel,
  OrderService,
  RetryDeliveryDestination,
  RetryDeliveryDestinationJob,
  StreamService,
  OrderDeliveryStatus,
  OrderConfirmationPdfRequest,
} from '@vdms-hq/api-contract';
import { AssetDownloadService, StorageUrlService } from '@vdms-hq/storage';
import { ToastService } from '@vdms-hq/toast';
import { BehaviorSubject, combineLatest, EMPTY, Observable, of, switchMap } from 'rxjs';
import { filter, map, shareReplay, take, tap, withLatestFrom, catchError } from 'rxjs/operators';
import {
  FilterStreamMsgFactory,
  generateProgressToastMessage,
  IMAGE_CACHE_ENUM,
  ImageCacheService,
  isEmbargoActive,
  SelectionManager,
  WebsocketAnyNotificationMessage,
  WebsocketNotificationActionEnum,
  WsProgressToast,
} from '@vdms-hq/shared';
import { ActionContextLess, DataAction, UIConfirmationDialogService } from '@vdms-hq/ui';
import { OrderActionsProperties, OrderAssetViewModel2 } from './models';
import moment from 'moment/moment';
import { Permission } from '@vdms-hq/firebase-contract';
import { ActivatedClientService, PermissionService } from '@vdms-hq/activated-client';
import { OrderResolverService } from './order-resolver.service';
import { MatDialog } from '@angular/material/dialog';
import { DataProviderService } from '@vdms-hq/selectors';
import { TranscodeDetailsDialogComponent } from '../components/transcode-details-dialog/transcode-details-dialog.component';
import { FieldsConfigService } from '@vdms-hq/config';
import { AddToCartActionsService } from '@vdms-hq/cart-core';
import {
  ReDeliverOrderDialogComponent,
  ReDeliverOrderDialogInput,
} from '../components/re-deliver-order-dialog/re-deliver-order-dialog.component';
import { ORDERS_ROUTER_BASE } from '../../orders-routing.module';
import { Router } from '@angular/router';
import { ResultsActions } from '@vdms-hq/asset-results';
import { FlatOrderViewModel } from '../../results/logic/order-results-ds';
import { DOCUMENT } from '@angular/common';

type DownloadableOrderAsset = {
  downloadUuid: string;
};

@Injectable({ providedIn: 'root' })
export class OrderActionsService {
  private orderService = inject(OrderService);
  private assetDownloadService = inject(AssetDownloadService);
  private toastService = inject(ToastService);
  private imageCache = inject(ImageCacheService);
  private streamService = inject(StreamService);
  private permissionService = inject(PermissionService);
  private orderParamResolver = inject(OrderResolverService);
  private dataProvider = inject(DataProviderService);
  private storageUrlService = inject(StorageUrlService);
  private activatedClientService = inject(ActivatedClientService);
  private fieldsConfigService = inject(FieldsConfigService);
  private confirmationDialog = inject(UIConfirmationDialogService);
  private dialog = inject(MatDialog);
  private addToCartService = inject(AddToCartActionsService);
  private router = inject(Router);
  private document = inject(DOCUMENT);

  readonly connect$ = this.streamService.connect();
  retriggerInProgress$ = new BehaviorSubject(false);
  popToast = {
    WS_RESTART_PROGRESS: (
      id: string,
      percent: number,
      counter?: { processing: number; all: number; errors: WebsocketAnyNotificationMessage },
    ) =>
      this.toastService.processing({
        id,
        message: generateProgressToastMessage(
          'Retrying delivery destinations',
          percent,
          this.imageCache.getImage(IMAGE_CACHE_ENUM.PROGRESS_LOADER_INFO_ICON),
          counter,
        ),
      }),
    WS_RESTART_SUCCESS: (id: string) =>
      this.toastService.success({
        id,
        message: `Retrying delivery destinations success`,
      }),
    WS_RESTART_ERROR: (id: string) =>
      this.toastService.error({
        id,
        message: `Retrying delivery destinations error`,
      }),
  };
  #refresh$ = new BehaviorSubject<Date>(new Date());

  #currentOrder$ = this.#refresh$.pipe(
    switchMap(() => {
      return this.orderParamResolver.currentIdDefinite$.pipe(switchMap((uuid) => this.orderService.getOrder(uuid)));
    }),
    shareReplay(),
  );

  #currentOrderAssets = this.#refresh$.pipe(
    switchMap(() => {
      return this.orderParamResolver.currentIdDefinite$.pipe(
        switchMap((uuid) => this.orderService.getAssets(uuid).pipe(GetResponseData)),
        map((assets) =>
          assets.map((asset) =>
            OrderAssetViewModel2.fromOrderItem(asset, {
              dataProvider: this.dataProvider,
              storageUrlService: this.storageUrlService,
              activatedClientService: this.activatedClientService,
            }),
          ),
        ),
        shareReplay(),
      );
    }),
  );

  #isSalesForce$ = this.activatedClientService.clientDefinite$.pipe(
    map((client) => Boolean(client.integrations.salesforce?.enabled)),
  );

  get isApprovalPage(): boolean {
    return this.router.url.includes(ORDERS_ROUTER_BASE.ORDERS_APPROVAL);
  }

  buildAddToCartAction$(): Observable<DataAction<FlatOrderViewModel>> {
    return of({
      key: ResultsActions.ADD_TO_CART,
      icon: 'add_shopping_cart',
      onDoubleClick: false,
      label: 'common.global.add_to_cart',
      hiddenIf: (item?: FlatOrderViewModel) => item?.orderScheduled || item?.isWarmingUp || false,
    });
  }

  buildAddToCollectionAction$(): Observable<DataAction<FlatOrderViewModel>> {
    return this.permissionService.verifyWithOwnedPermissions$([Permission.EDIT_COLLECTIONS]).pipe(
      map((permitted) => {
        return permitted
          ? {
              key: ResultsActions.ADD_TO_COLLECTION,
              label: 'common.global.add_to_collection',
              icon: 'playlist_add',
              hiddenIf: (item: FlatOrderViewModel) => item?.orderScheduled || item?.isWarmingUp || false,
            }
          : null;
      }),
    );
  }

  buildDownloadDeliveryConfirmationAction$<T>(): Observable<DataAction<FlatOrderViewModel> | null> {
    return this.permissionService.verifyWithOwnedPermissions$([Permission.GENERATE_ORDER_DELIVERY_CONFIRMATION]).pipe(
      map((permitted) => ({
        key: OrderActionsProperties.DOWNLOAD_DELIVERY_CONFIRMATION,
        label: 'orders.download.delivery_confirmation',
        icon: 'cloud_download',
        hiddenIf: (item?: FlatOrderViewModel) => !item?.isDelivered || !permitted,
      })),
    );
  }

  buildApproveOrderAction$<
    T extends OrderAssetViewModel2 = OrderAssetViewModel2,
  >(): Observable<ActionContextLess | null> {
    return combineLatest([
      this.#currentOrder$,
      this.permissionService.verifyWithOwnedPermissions$([Permission.APPROVE_ORDERS]),
    ]).pipe(
      map(([order, permitted]) => {
        const available = !!(this.isApprovalPage && permitted);
        const disabled = !!order.approval_status || order.has_embargoed_asset || !order.has_approved_item;

        return available
          ? {
              key: OrderActionsProperties.APPROVE_ORDER,
              label: 'pages.orders.approve_order',
              color: 'primary',
              disabled,
            }
          : null;
      }),
    );
  }

  buildApproveAssetAction$<T extends OrderAssetViewModel2 = OrderAssetViewModel2>(): Observable<DataAction<T>> {
    return this.#currentOrder$.pipe(
      map((order) => ({
        key: OrderActionsProperties.APPROVE_ASSET,
        label: 'pages.orders.approve',
        icon: 'thumb_up_alt',
        hiddenIf: () => !this.isApprovalPage && !order.sales_force_id,
        disabledIf: (item: OrderAssetViewModel2) => item.isApproved,
      })),
    );
  }

  buildRejectAssetAction$<T extends OrderAssetViewModel2 = OrderAssetViewModel2>(): Observable<DataAction<T>> {
    return this.#currentOrder$.pipe(
      map((order) => ({
        key: OrderActionsProperties.REJECT_ASSET,
        label: 'pages.orders.reject',
        icon: 'thumb_down_alt',
        hiddenIf: () => !this.isApprovalPage && !order.sales_force_id,
        disabledIf: (item: OrderAssetViewModel2) => item.isRejected,
      })),
    );
  }

  buildDownloadAsperaAction$<T extends OrderAssetViewModel2 = OrderAssetViewModel2>(): Observable<DataAction<T>> {
    return this.#currentOrder$.pipe(
      map((order) => ({
        key: OrderActionsProperties.DOWNLOAD_ASPERA,
        label: 'common.global.download',
        icon: 'download',
        onDoubleClick: false,
        hiddenIf: (item?: OrderAssetViewModel2) => {
          return !(
            Boolean(item?.props.storage === 'hot' && !item.deliveryDestinations?.length) ||
            Boolean(
              item?.props.storage === 'hot' &&
                item?.deliveryDestinations?.length &&
                item?.transcodeStatus === 'completed',
            ) ||
            item?.downloadUuid
          );
        },
        disabledIf: (item?: OrderAssetViewModel2) =>
          moment(order.expires_at).isBefore(moment()) ||
          item?.isEmbargoActive ||
          [OrderDeliveryStatus.SCHEDULED].includes(order.delivery_status) ||
          false,
      })),
    );
  }

  buildAddToCartHeaderAction$<
    T extends OrderAssetViewModel2 = OrderAssetViewModel2,
  >(): Observable<ActionContextLess | null> {
    return combineLatest([
      this.permissionService.verifyWithOwnedPermissions$([Permission.SHOPPING_CART]),
      this.#currentOrderAssets,
    ]).pipe(
      map(([permitted, assets]) => {
        const disabled = !assets?.length;
        return permitted
          ? {
              key: OrderActionsProperties.ADD_TO_CART,
              label: 'pages.order.add_to_cart',
              icon: 'shopping_cart',
              color: 'primary',
              disabled,
            }
          : null;
      }),
    );
  }

  buildRetryAllDeliveryDestinationsAction$<
    T extends OrderAssetViewModel2 = OrderAssetViewModel2,
  >(): Observable<ActionContextLess | null> {
    return combineLatest([
      this.permissionService.verifyWithOwnedPermissions$([Permission.RETRY_DELIVERY_DESTINATIONS]),
      this.#currentOrder$,
      this.#currentOrderAssets,
    ]).pipe(
      map(([permitted, order, assets]) => {
        const disabled = !this.generateRetryDeliveryDestinationsPayload(assets)?.length;
        const available = order?.delivery_destinations && permitted;
        return available
          ? {
              key: OrderActionsProperties.RETRY_DELIVERY_DESTINATIONS,
              icon: 'refresh',
              label: 'tooltip.retry_delivery_destinations',
              color: 'transparent',
              disabled,
            }
          : null;
      }),
    );
  }

  buildRetryDeliveryDestinationsAction$<T extends OrderAssetViewModel2 = OrderAssetViewModel2>(): Observable<
    DataAction<T>
  > {
    return combineLatest([
      this.permissionService.verifyWithOwnedPermissions$([Permission.RETRY_DELIVERY_DESTINATIONS]),
      this.#currentOrder$,
    ]).pipe(
      map(([permitted, order]) => ({
        key: OrderActionsProperties.RETRY_DELIVERY_DESTINATIONS,
        icon: 'refresh',
        label: 'tooltip.retry_delivery_destinations',
        disabledIf: (item: OrderAssetViewModel2) => {
          return !this.generateRetryDeliveryDestinationsPayload([item])?.length || item.isEmbargoActive;
        },
        hiddenIf: () => !order?.delivery_destinations?.length || !permitted,
      })),
    );
  }

  buildTranscodeStatusAction$<T extends OrderAssetViewModel2 = OrderAssetViewModel2>(): Observable<DataAction<T>> {
    return of({
      key: OrderActionsProperties.TRANSCODE_STATUS,
      icon: 'list_alt',
      label: 'common.global.transcode_status.more',
      hiddenIf: (item: OrderAssetViewModel2) => !item.deliveryDestinations?.length,
    });
  }

  buildSharedPackRetryDeliveryDestinationAction$<T extends OrderAssetViewModel2 = OrderAssetViewModel2>(): Observable<
    DataAction<T>
  > {
    return combineLatest([
      this.permissionService.verifyWithOwnedPermissions$([Permission.RETRY_DELIVERY_DESTINATIONS]),
      this.#currentOrderAssets,
      this.#currentOrder$,
    ]).pipe(
      map(([permitted, assets, order]) => ({
        key: OrderActionsProperties.RETRY_DELIVERY_DESTINATIONS,
        icon: 'refresh',
        label: 'tooltip.retry_delivery_destinations',
        color: 'transparent',
        disabledIf: () => {
          const hasEmbargo = !!assets.find(({ asset }) => isEmbargoActive(asset.embargoed_to));
          return (
            moment(order.expires_at).isAfter(moment()) ||
            (order?.completed_items ?? 0) <= order?.total_assets ||
            hasEmbargo
          );
        },
        hiddenIf: (asset) => !order?.delivery_destinations || !permitted || asset.isEmbargoActive,
      })),
    );
  }

  buildRetriggerClippingAction$<T extends OrderAssetViewModel2 = OrderAssetViewModel2>(
    selection: SelectionManager<OrderAssetViewModel2>,
  ): Observable<ActionContextLess | null> {
    return combineLatest([
      this.permissionService.verifyWithOwnedPermissions$([Permission.RETRIGGER_CLIPPING]),
      this.#isSalesForce$,
      this.retriggerInProgress$,
      selection.entities$,
    ]).pipe(
      map(([permitted, isSalesForce, inProgress, entities]) => {
        return permitted && isSalesForce && entities?.length
          ? {
              key: OrderActionsProperties.RETRIGGER_CLIPPING,
              label: 'pages.orders.retrigger_clipping',
              color: 'secondary',
              disabled: inProgress,
            }
          : null;
      }),
    );
  }

  buildForceSalesforceRequestAction$<
    T extends OrderAssetViewModel2 = OrderAssetViewModel2,
  >(): Observable<ActionContextLess | null> {
    return combineLatest([
      this.permissionService.verifyWithOwnedPermissions$([Permission.FORCE_RELEASE_SF_REQUEST]),
      this.#currentOrder$,
      this.#isSalesForce$,
    ]).pipe(
      map(([permitted, order, isSalesForce]) => {
        const available = !!(permitted && order.sales_force_id && isSalesForce);
        return available
          ? {
              key: OrderActionsProperties.FORCE_SALESFORCE_REQUEST,
              label: 'pages.orders.force_salesforce_request',
              color: 'secondary',
            }
          : null;
      }),
    );
  }

  buildRedeliverOrderAction$<
    T extends OrderAssetViewModel2 = OrderAssetViewModel2,
  >(): Observable<ActionContextLess | null> {
    return combineLatest([
      this.permissionService.verifyWithOwnedPermissions$([Permission.REDELIVER_ORDERS]),
      this.#currentOrder$,
    ]).pipe(
      map(([permitted, order]) => {
        const available = !!(permitted && order.sales_force_id);
        return available
          ? {
              key: OrderActionsProperties.REDELIVER_ORDER,
              label: 'common.orders.redeliver_order.button',
              color: 'secondary',
            }
          : null;
      }),
    );
  }

  buildPreviewSalesforceRequestAction$<
    T extends OrderAssetViewModel2 = OrderAssetViewModel2,
  >(): Observable<ActionContextLess | null> {
    return combineLatest([
      this.permissionService.verifyWithOwnedPermissions$([Permission.REDELIVER_ORDERS]),
      this.#currentOrder$,
      this.#isSalesForce$,
    ]).pipe(
      map(([permitted, order, isSalesForce]) => {
        const available = !!(permitted && order.sales_force_uri && isSalesForce && order.sales_force_id);
        return available
          ? {
              key: OrderActionsProperties.PREVIEW_SALESFORCE_REQUEST,
              label: 'common.orders.redeliver_order.preview_salesforce_request',
              icon: 'open_in_new',
              color: 'secondary',
            }
          : null;
      }),
    );
  }

  buildDownloadClearanceNotesAction$(): Observable<ActionContextLess | null> {
    return this.#isSalesForce$.pipe(
      map((permitted) => {
        return permitted
          ? {
              key: OrderActionsProperties.DOWNLOAD_CLEARANCE_NOTES,
              label: 'tooltip.clearance',
              icon: 'cloud_download',
              color: 'transparent',
            }
          : null;
      }),
    );
  }

  handleApproveAssetAction<T extends OrderAssetViewModel2 = OrderAssetViewModel2>(
    $event: {
      key: string;
      item: OrderAssetViewModel2;
    },
    onComplete: () => void,
  ) {
    if ($event.key !== OrderActionsProperties.APPROVE_ASSET) {
      return;
    }

    this.orderParamResolver.currentIdDefinite$
      .pipe(
        take(1),
        switchMap((orderUuid) => this.updateAssets(orderUuid, [{ status: 'approved', uuid: $event.item.orderUuid }])),
        tap(() => {
          if (!onComplete) {
            return;
          }

          onComplete();
          this.#refresh$.next(new Date());
        }),
      )
      .subscribe();
  }

  handleRejectAssetAction<T extends OrderAssetViewModel2 = OrderAssetViewModel2>(
    $event: {
      key: string;
      item: OrderAssetViewModel2;
    },
    onComplete: () => void,
  ) {
    if ($event.key !== OrderActionsProperties.REJECT_ASSET) {
      return;
    }

    this.orderParamResolver.currentIdDefinite$
      .pipe(
        take(1),
        switchMap((orderUuid) => this.updateAssets(orderUuid, [{ status: 'rejected', uuid: $event.item.orderUuid }])),
        tap(() => {
          if (!onComplete) {
            return;
          }

          onComplete();
          this.#refresh$.next(new Date());
        }),
      )
      .subscribe();
  }

  handleApproveOrderAction<T extends OrderAssetViewModel2 = OrderAssetViewModel2>($event: { key: string }) {
    if ($event.key !== OrderActionsProperties.APPROVE_ORDER) {
      return;
    }

    this.orderParamResolver.currentIdDefinite$
      .pipe(
        take(1),
        switchMap((id) => this.approveOrder(id)),
      )
      .subscribe({
        next: () => this.toastService.success({ id: 'order_approved', message: 'common.orders.approval.message' }),
      });
  }

  handleDownloadClearanceNotesAction$<T extends OrderAssetViewModel2 = OrderAssetViewModel2>($event: { key: string }) {
    if ($event.key !== OrderActionsProperties.DOWNLOAD_CLEARANCE_NOTES) {
      return;
    }

    this.#currentOrder$
      .pipe(
        take(1),
        filter((data) => !!data?.clearance_notes_uri),
        switchMap((data) => {
          this.downloadClearance(data.clearance_notes_uri as string);
          return EMPTY;
        }),
      )
      .subscribe();
  }

  handleDownloadDeliveryConfirmationAction($event: { key: string; item?: FlatOrderViewModel }) {
    if ($event.key !== OrderActionsProperties.DOWNLOAD_DELIVERY_CONFIRMATION || !$event.item) {
      return;
    }
    this.orderService
      .getDeliveryConfirmationPdf($event.item.uuid)
      .pipe(
        take(1),
        tap(({ url }) => setTimeout(() => this.document.defaultView?.open(url, '_blank'), 3000)),
        tap(() => this.toastService.success({ id: 'download', message: 'orders.download.success' })),
        catchError(() => {
          this.toastService.error({ id: 'download', message: 'orders.download.failed' });
          return EMPTY;
        }),
      )
      .subscribe();
  }

  handleDownloadManyDeliveryConfirmationAction(
    $event: { key: string; item?: FlatOrderViewModel },
    payload?: OrderConfirmationPdfRequest,
  ) {
    if ($event.key !== OrderActionsProperties.DOWNLOAD_MANY_DELIVERY_CONFIRMATION || !payload) {
      return;
    }
    this.orderService
      .getManyDeliveryConfirmationPdf(payload)
      .pipe(
        take(1),
        tap((urls) => {
          urls.forEach(({ url }) => {
            setTimeout(() => this.document.defaultView?.open(url, '_blank'));
          });
        }),
        tap(() => this.toastService.success({ id: 'download', message: 'orders.download.success' })),
        catchError(() => {
          this.toastService.error({ id: 'download', message: 'orders.download.failed' });
          return EMPTY;
        }),
      )
      .subscribe();
  }

  handleRedeliverOrderAction$<T extends OrderAssetViewModel2 = OrderAssetViewModel2>($event: { key: string }) {
    if ($event.key !== OrderActionsProperties.REDELIVER_ORDER) {
      return;
    }

    this.orderParamResolver.currentIdDefinite$
      .pipe(
        take(1),
        switchMap((orderId) => {
          return this.dialog
            .open<ReDeliverOrderDialogComponent, ReDeliverOrderDialogInput>(ReDeliverOrderDialogComponent, {
              data: {
                orderId,
              },
            })
            .afterClosed();
        }),
      )
      .subscribe();
  }

  handleForceSalesforceRequestAction$<T extends OrderAssetViewModel2 = OrderAssetViewModel2>($event: { key: string }) {
    if ($event.key !== OrderActionsProperties.FORCE_SALESFORCE_REQUEST) {
      return;
    }

    this.confirmationDialog
      .open({
        title: 'pages.orders.force_salesforce_request',
        message: 'pages.orders.force_salesforce_message',
        okAction: { label: 'Confirm', color: 'primary' },
        abortAction: { label: 'Cancel', color: 'secondary' },
      })
      .pipe(
        take(1),
        filter(Boolean),
        switchMap(() => this.orderParamResolver.currentIdDefinite$),
        switchMap((id) => this.salesForceUnlockRequest(id)),
      )
      .subscribe();
  }
  handlePreviewSalesforceRequestAction$<T extends OrderAssetViewModel2 = OrderAssetViewModel2>($event: {
    key: string;
  }) {
    if ($event.key !== OrderActionsProperties.PREVIEW_SALESFORCE_REQUEST) {
      return;
    }

    this.#currentOrder$
      .pipe(
        take(1),
        filter((data) => !!data?.clearance_notes_uri),
      )
      .subscribe((data) => {
        const url = data.sales_force_uri;
        url && window && window.open(url, '_blank');
        return true;
      });
  }

  buildDownloadAllAsperaAction$<T extends OrderAssetViewModel2 = OrderAssetViewModel2>(
    withFolders = false,
  ): Observable<ActionContextLess> {
    return this.#currentOrder$.pipe(
      map((order) => {
        const key = withFolders
          ? OrderActionsProperties.DOWNLOAD_ALL_ASPERA_FOLDERS
          : OrderActionsProperties.DOWNLOAD_ALL_ASPERA;
        const label = withFolders ? 'tooltip.download_all_with_aspera_in_folders' : 'tooltip.download_all_with_aspera';
        const icon = withFolders ? 'cloud_download' : 'download';
        const disabled =
          order.has_embargoed_asset ||
          moment().isAfter(order.expires_at) ||
          (order?.completed_items ?? 0) < order?.total_assets ||
          [OrderDeliveryStatus.SCHEDULED, OrderDeliveryStatus.RETRIEVING_FROM_COLD].includes(order.delivery_status);
        return {
          key,
          label,
          icon,
          color: 'transparent',
          disabled,
        };
      }),
    );
  }

  handleRetriggerClippingAction$<T extends OrderAssetViewModel2 = OrderAssetViewModel2>(
    $event: { key: string },
    selection: SelectionManager<OrderAssetViewModel2>,
  ) {
    if ($event.key !== OrderActionsProperties.RETRIGGER_CLIPPING) {
      return;
    }

    combineLatest([selection?.entities$, this.orderParamResolver.currentIdDefinite$])
      .pipe(
        take(1),
        switchMap(([entities, uuid]) => {
          if (!entities?.length) {
            this.toastService.error({ id: 'retrigger_error', message: 'pages.orders.retrigger_validation_error' });
            return EMPTY;
          }

          const assetsNotAllowedToRetrigger = entities.some(
            (entity) => entity.approvalStatus !== 1 || entity.clippingStatus === 'completed',
          );
          if (assetsNotAllowedToRetrigger) {
            this.toastService.error({ id: 'retrigger_error', message: 'pages.orders.selected_assets_not_allowed' });
            return EMPTY;
          }

          this.retriggerInProgress$.next(true);
          const items = entities.map(({ externalId }) => externalId)?.filter(Boolean) as string[];
          return this.retriggerClipping(uuid, { items });
        }),
        tap(() => selection.clear()),
      )
      .subscribe({
        error: () => {
          this.retriggerInProgress$.next(false);
          this.toastService.error({ id: 'retrigger_error', message: 'notifications.create.failed' });
        },
        next: () => {
          this.retriggerInProgress$.next(false);
          this.toastService.success({ id: 'retrigger_success', message: 'notifications.create.done' });
        },
      });
  }

  handleAddToCartOrderAction<T extends OrderAssetViewModel2 = OrderAssetViewModel2>($event: { key: string }) {
    if ($event.key !== OrderActionsProperties.ADD_TO_CART) {
      return;
    }

    this.orderParamResolver.currentIdDefinite$
      .pipe(
        switchMap((id) => {
          this.addToCartService.addOrders([id]);
          return EMPTY;
        }),
      )
      .subscribe();
  }

  handleRetryDeliveryDestinationsAction<T extends OrderAssetViewModel2 = OrderAssetViewModel2>($event: {
    key: string;
    item?: OrderAssetViewModel2;
  }) {
    if ($event.key !== OrderActionsProperties.RETRY_DELIVERY_DESTINATIONS) {
      return;
    }

    if ($event.item) {
      this.#retryDeliveryDestinationsAction([$event.item]);
    } else {
      this.#retryDeliveryDestinationsAction();
    }
  }

  handleTranscodeStatusAction<T extends OrderAssetViewModel2 = OrderAssetViewModel2>($event: {
    key: string;
    item: OrderAssetViewModel2;
  }) {
    if ($event.key !== OrderActionsProperties.TRANSCODE_STATUS) {
      return;
    }

    this.#currentOrder$
      .pipe(
        take(1),
        withLatestFrom(this.orderParamResolver.currentIdDefinite$, this.fieldsConfigService.resultsDefinitions$),
        switchMap(([order, orderId, definitions]) => {
          return this.dialog
            .open(TranscodeDetailsDialogComponent, {
              data: {
                orderActive: order.expires_at ? moment().isBefore(order.expires_at) : false,
                items: [$event.item],
                assetUuid: $event.item?.orderUuid,
                orderId,
                delivery_destinations: $event.item.deliveryDestinations,
                definition: definitions.find((field) => field.sourceListKey === 'transcode_status')?.results2,
                isEmbargoActive: $event.item.isEmbargoActive,
              },
            })
            .afterClosed();
        }),
      )
      .subscribe();
  }

  handleDownloadAllAsperaAction<T extends OrderAssetViewModel2 = OrderAssetViewModel2>($event: {
    key: OrderActionsProperties;
  }) {
    if (
      ![OrderActionsProperties.DOWNLOAD_ALL_ASPERA_FOLDERS, OrderActionsProperties.DOWNLOAD_ALL_ASPERA].includes(
        $event.key,
      )
    ) {
      return;
    }

    const withFolders = $event?.key === OrderActionsProperties.DOWNLOAD_ALL_ASPERA_FOLDERS;
    this.orderParamResolver.currentIdDefinite$
      .pipe(
        take(1),
        switchMap((uuid) => {
          this.downloadAll(uuid, withFolders);
          return EMPTY;
        }),
      )
      .subscribe();
  }

  handleDownloadAsperaAction($event: { key: string; item: OrderAssetViewModel2 }) {
    if (OrderActionsProperties.DOWNLOAD_ASPERA !== $event.key) {
      return;
    }

    this.orderParamResolver.currentIdDefinite$
      .pipe(
        take(1),
        switchMap((id) => {
          this.download(id, [$event.item]);
          return EMPTY;
        }),
      )
      .subscribe();
  }

  download(
    orderId: string,
    assets: DownloadableOrderAsset[],
    keep_folders_structure = false,
    config_uuid?: string,
  ): void {
    const payload: OrderDownloadPayload = {
      download_uuids: assets.map(({ downloadUuid }) => downloadUuid),
      keep_folders_structure: keep_folders_structure,
    };

    if (config_uuid) {
      payload.config_uuid = config_uuid;
    }

    this.toastService.info({
      id: 'Processing_Download',
      message: 'common.notifications.generic.download_processing',
    });

    this.orderService
      .downloadAssets(orderId, payload)
      .pipe(map(({ data }) => data))
      .subscribe((asperaPayload) => this.assetDownloadService.withAspera(asperaPayload));
  }

  downloadAll(orderId: string, keep_folders_structure = false, configUuid?: string) {
    this.download(orderId, [], keep_folders_structure, configUuid);
  }

  downloadClearance(url: string) {
    return this.orderService.downloadClearance(url);
  }

  approveOrder(uuid: string): Observable<OrderModel> {
    return this.orderService.approveOrder(uuid);
  }

  updateAssets(orderUuid: string, assets: OrderPatchItemModel[]) {
    return this.orderService.updateAssets(orderUuid, assets);
  }

  salesForceUnlockRequest(orderUuid: string) {
    return this.orderService.salesForceUnlockRequest(orderUuid);
  }

  retriggerClipping(uuid: string, payload: { items: string[] }) {
    return this.orderService.retriggerClipping(uuid, payload);
  }

  retryDeliveryDestinations(
    uuid: string,
    payload: RetryDeliveryDestination,
  ): Observable<RetryDeliveryDestinationJob[]> {
    return this.orderService.retryDeliveryDestination(uuid, payload);
  }

  registerWebSocketListener() {
    this.#initToastElement();
    return this.connect$.pipe(
      FilterStreamMsgFactory([WebsocketNotificationActionEnum.RETRY_DD_JOBS]),
      WsProgressToast({
        SUCCESS: this.popToast.WS_RESTART_SUCCESS,
        PROGRESS: this.popToast.WS_RESTART_PROGRESS,
        ERROR: this.popToast.WS_RESTART_ERROR,
      }),
    );
  }

  generateRetryDeliveryDestinationsPayload(order: OrderAssetViewModel2[]) {
    !Array.isArray(order) && (order = [order]);

    const availableConfigs = order
      .map((asset) => {
        const deliveryDestinationConfigs: DeliveryDestinationConfig[] = [];
        asset.deliveryDestinations?.forEach((deliveryDestination) => {
          deliveryDestination.configs.forEach((config) => deliveryDestinationConfigs.push(config));
        });
        const permittedConfigs = deliveryDestinationConfigs.filter(
          ({ jobs }) =>
            !(
              jobs?.every(({ status }) => status === DeliveryDestinationJobStatusEnum.COMPLETED) ||
              jobs?.some(({ status }) => status === DeliveryDestinationJobStatusEnum.NOT_INITIALIZED)
            ),
        );
        return permittedConfigs?.length ? { asset, delivery_destinations: permittedConfigs } : null;
      })
      .filter(Boolean);
    const jobs: RetryDeliveryDestination['jobs'] = [];
    availableConfigs.forEach((data) => {
      data?.delivery_destinations.forEach((config) => {
        jobs.push({ item_uuid: data?.asset.orderUuid, config_uuid: config.uuid });
      });
    });
    return jobs;
  }

  #initToastElement() {
    this.imageCache.cacheImage(IMAGE_CACHE_ENUM.PROGRESS_LOADER_INFO_ICON);
  }

  #retryDeliveryDestinationsAction(data?: OrderAssetViewModel2[]) {
    const startPoint = data ? of(data) : this.#currentOrderAssets;

    startPoint
      .pipe(
        switchMap((assets) => {
          const message = this.#chooseCorrectRetryDDMessage(assets);
          return combineLatest([
            this.confirmationDialog.open({
              message,
            }),
            of(assets),
            this.orderParamResolver.currentIdDefinite$,
          ]);
        }),
      )
      .pipe(
        take(1),
        switchMap(([result, all, id]) => {
          if (!result) {
            return EMPTY;
          }

          const payload: RetryDeliveryDestination = {
            jobs: this.generateRetryDeliveryDestinationsPayload(data ?? all),
          };

          if (!payload.jobs?.length) {
            this.toastService.error({
              id: 'dd_retry',
              message: 'common.delivery_destinations.retry_dd.no_assets_to_retry',
            });
            return EMPTY;
          }

          return this.retryDeliveryDestinations(id, payload);
        }),
      )
      .subscribe();
  }

  #chooseCorrectRetryDDMessage(data: OrderAssetViewModel2[]): string {
    !Array.isArray(data) && (data = [data]);
    const assetAlreadyProcessing = data.some((asset) => asset.assetAvailableToRetrigger);

    return assetAlreadyProcessing
      ? 'common.delivery_destinations.retry_dd.already_retried'
      : 'common.delivery_destinations.retry_dd.retry';
  }
}
