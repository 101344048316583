import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule, DatePipe, NgOptimizedImage } from '@angular/common';
import { DynamicFiltersModule } from '@vdms-hq/dynamic-filters';
import { MatIconModule } from '@angular/material/icon';
import {
  ActionContextLess,
  DataAction,
  DataPresentationHeaderComponent,
  PaginatorComponent,
  UIButtonModule,
  UIEmptyResultsModule,
  UILayoutModule,
  UILoaderModule,
  UIResultsWrapperModule,
  UISimpleDetailsListModule,
} from '@vdms-hq/ui';
import { TranslateModule } from '@ngx-translate/core';
import { CurrentOrderService } from '../../logic/current-order.service';
import { ToastModule } from '@vdms-hq/toast';
import { CountdownPipe, FormatBytesPipe, RenamePipe } from '@vdms-hq/shared';
import { CurrentOrderAssetsDataSource } from '../../logic/current-order-assets.ds';
import { ColumnSettingsScope } from '@vdms-hq/firebase-contract';
import {
  AssetActionsService,
  AssetFlatView2Model,
  AssetResults2Component,
  assetResultsMenuConfig2,
  AssetResultsModule,
  ContextMenuActionsService,
} from '@vdms-hq/asset-results';
import { SharedPackAssetsFooterComponent } from '../../components/shared-pack-assets-footer/shared-pack-assets-footer.component';
import { ReactiveFormsModule } from '@angular/forms';
import { OrderAssetsFilterService } from '../../logic/order-assets-filters.service';
import { OrderActionsProperties, OrderAssetViewModel2 } from '../../logic/models';
import { combineLatest, Observable, Subject, take, takeUntil } from 'rxjs';
import { OrderMetadataTransformerService } from '../../../logic/order-metadata-transformer';
import { map } from 'rxjs/operators';
import { AssetDownloadService } from '@vdms-hq/storage';
import { OrderActionsService } from '../../logic/order-actions.service';
import { ActivatedClientService } from '@vdms-hq/activated-client';
import { defaultOrdersColumns, defaultSalesForceOrdersColumns } from '../../logic/view-configurations';
import { LayoutService, Theme } from '@vdms-hq/theming';

@Component({
  selector: 'vdms-hq-order-details',
  templateUrl: './order-details.component.html',
  imports: [
    CommonModule,
    DynamicFiltersModule,
    MatIconModule,
    PaginatorComponent,
    TranslateModule,
    UILoaderModule,
    UILayoutModule,
    ToastModule,
    UIResultsWrapperModule,
    UISimpleDetailsListModule,
    AssetResultsModule,
    SharedPackAssetsFooterComponent,
    ReactiveFormsModule,
    UIButtonModule,
    DataPresentationHeaderComponent,
    AssetResults2Component,
    NgOptimizedImage,
    UIEmptyResultsModule,
    CountdownPipe,
  ],
  providers: [
    CurrentOrderService,
    CurrentOrderAssetsDataSource,
    OrderAssetsFilterService,
    OrderMetadataTransformerService,
    DatePipe,
    RenamePipe,
    FormatBytesPipe,
    OrderActionsService,
    AssetDownloadService,
  ],
  standalone: true,
})
export class OrderDetailsComponent implements OnInit, OnDestroy {
  scopeName: ColumnSettingsScope = 'orders';
  destroy$ = new Subject<void>();
  hiddenColumns: string[] = [];
  assetResultsMenuConfig2 = assetResultsMenuConfig2;
  estimatedRestoreAt?: Date | string | null;

  thermometerImageType$ = this.layoutService
    .currentTheme()
    .pipe(
      map((theme) =>
        theme === Theme.dark
          ? '/assets/common/icons/order_warmup_dark.svg'
          : '/assets/common/icons/order_warmup_light.svg',
      ),
    );

  headerActions$: Observable<ActionContextLess[]> = combineLatest([
    this.orderActionsService.buildDownloadClearanceNotesAction$(),
    this.orderActionsService.buildApproveOrderAction$(),
    this.orderActionsService.buildAddToCartHeaderAction$(),
    this.orderActionsService.buildForceSalesforceRequestAction$(),
    this.orderActionsService.buildRedeliverOrderAction$(),
    this.orderActionsService.buildPreviewSalesforceRequestAction$(),
    this.orderActionsService.buildRetryAllDeliveryDestinationsAction$(),
  ]).pipe(map((actions) => actions.filter(Boolean) as ActionContextLess[]));

  canShowTable$ = this.currentOrder.data$.pipe(
    map((data) => {
      const hasChanged = this.estimatedRestoreAt !== data.estimated_restore_at;
      if (hasChanged) {
        this.estimatedRestoreAt = data.estimated_restore_at;
      }
      return !data.estimated_restore_at;
    }),
  );

  actions$: Observable<DataAction<OrderAssetViewModel2>[]> = combineLatest([
    this.orderActionsService.buildApproveAssetAction$<OrderAssetViewModel2>(),
    this.orderActionsService.buildRejectAssetAction$<OrderAssetViewModel2>(),
    this.assetActionsService.buildPreviewAction$<AssetFlatView2Model>(),
    this.assetActionsService.buildQAResultsAction(),
  ]);

  actionsAndCanShow$: Observable<{ actions: DataAction<OrderAssetViewModel2>[]; canShow: boolean }> = combineLatest({
    actions: this.actions$,
    canShow: this.canShowTable$,
  });

  footerActions$: Observable<ActionContextLess[]> = combineLatest([
    this.orderActionsService.buildRetriggerClippingAction$(this.dataSource.selection),
  ]).pipe(map((actions) => actions.filter(Boolean) as ActionContextLess[]));

  constructor(
    public currentOrder: CurrentOrderService,
    public dataSource: CurrentOrderAssetsDataSource,
    public filterService: OrderAssetsFilterService,
    private activatedClientService: ActivatedClientService,
    private orderActionsService: OrderActionsService,
    private assetActionsService: AssetActionsService,
    private contextMenuService: ContextMenuActionsService,
    private layoutService: LayoutService,
  ) {
    this.dataSource.sortBy$.next('estimated_restore_at');
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnInit() {
    this.activatedClientService.clientDefinite$.pipe(takeUntil(this.destroy$), take(1)).subscribe((client) => {
      if (client.integrations.salesforce) {
        this.currentOrder.configure(defaultSalesForceOrdersColumns);
      } else {
        //todo select & footer bar should be invisible for non-salesforce clients (others than bbc)
        this.#setHiddenColumns(['select']);
        this.currentOrder.configure(defaultOrdersColumns);
      }

      this.filterService.listenUntil(this.destroy$);
      this.filterService.initConfig();
    });
  }

  handleHeaderAction($event: { key: OrderActionsProperties }) {
    this.orderActionsService.handleDownloadClearanceNotesAction$($event);
    this.orderActionsService.handleAddToCartOrderAction($event);
    this.orderActionsService.handleRetriggerClippingAction$($event, this.dataSource.selection);
    this.orderActionsService.handleForceSalesforceRequestAction$($event);
    this.orderActionsService.handlePreviewSalesforceRequestAction$($event);
    this.orderActionsService.handleRedeliverOrderAction$($event);
    this.orderActionsService.handleApproveOrderAction($event);
    this.orderActionsService.handleRetryDeliveryDestinationsAction($event);
  }

  handleAction($event: { key: string; item: OrderAssetViewModel2 }) {
    this.orderActionsService.handleApproveAssetAction($event, this.refreshDataSource.bind(this));
    this.orderActionsService.handleRejectAssetAction($event, this.refreshDataSource.bind(this));
    this.assetActionsService.handlePreviewAction($event);
    this.assetActionsService.handleQAResultsAction($event);
    this.contextMenuService.handleAction($event);
  }

  refreshDataSource() {
    this.dataSource?.refresh$?.next(true);
  }

  #setHiddenColumns(cols: string[]) {
    this.hiddenColumns = cols;
  }
}
