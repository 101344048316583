import { BehaviorSubject, Observable } from 'rxjs';
import { PageEvent } from '../../models/pagination/pagination.model';

export interface PageableDataSource {
  pageIndex$: Observable<number>;
  pageSize$: Observable<number>;
  pageSizeOptions: number[];
  pageSizeOptions$?: Observable<number[]>;
  total$: Observable<number>;
  changePerPageSize$?: BehaviorSubject<number>;
  pagination$?: BehaviorSubject<{ page: string; perPage: string } | null>;

  pageChange($event: PageEvent): void;
}

export const isPageableDataSource = (obj: any): obj is PageableDataSource => {
  return 'pageIndex$' in obj && 'pageSize$' in obj && 'pageSizeOptions' in obj && 'total$' in obj;
};
