import { Pipe, PipeTransform } from '@angular/core';
import { Timecode } from '@vdms-hq/timecode';

@Pipe({
  standalone: true,
  name: 'formatTime',
})
export class FormatTimePipe implements PipeTransform {
  transform(value?: Timecode | null): string {
    if (!value) {
      return 'N/A';
    }

    return Timecode.fromSeconds(value.countSeconds())?.toString() ?? 'N/A';
  }
}
