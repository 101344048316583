import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormInputTimecodeComponent } from '../../../ui-form';
import { Framerate, Timecode } from '@vdms-hq/timecode';

@Component({
  selector: 'vdms-hq-ui-input-timecode-overlay',
  templateUrl: './form-input-timecode-overlay.component.html',
  styleUrls: ['./form-input-timecode-overlay.component.scss'],
})
export class FormInputTimecodeOverlayComponent {
  @Input() timecode: Timecode | null = null;

  @ViewChild('timecodeInput') timecodeInput!: FormInputTimecodeComponent;
  @Input() title?: string;
  @Input() subtitle?: string;
  @Input() framerate?: Framerate;
  @Output() dismiss = new EventEmitter<void>();
  @Output() submitTimecode = new EventEmitter<Timecode>();
  @Input() mask = '00:00:00:00';

  keyUp($event: KeyboardEvent) {
    if ($event.code === 'Enter') {
      if (this.timecode && this.timecodeInput.innerFormControl.valid) {
        this.submitTimecode.emit(this.timecode);
      } else {
        this.timecodeInput.innerFormControl.markAsTouched();
      }
    }
  }
}
