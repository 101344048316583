import { Pipe, PipeTransform } from '@angular/core';
import { SelectOption } from '@vdms-hq/shared';

@Pipe({
  name: 'filterByProp',
})
export class FilterByPropPipe implements PipeTransform {
  transform<T>(items: T[] | undefined | null, filterBy: string, prop = 'value', currentOptions?: string[]): T[] {
    if (!items) {
      return [] as T[];
    }

    if (!filterBy) {
      return items;
    }

    const normalizedFilter = filterBy.toLowerCase();

    if (Array.isArray(currentOptions) && items?.length && 'key' in items[0] && 'label' in items[0]) {
      return [
        ...(items.filter((item: any) => {
          return item[prop] && (item[prop] as string).toLowerCase().includes(normalizedFilter);
        }) as unknown as SelectOption[]),
        ...(currentOptions.map((o) => ({ key: o, label: o, hidden: true })) as SelectOption[]),
      ] as unknown as T[];
    }

    return items.filter((item: any) => {
      return item[prop] && (item[prop] as string).toLowerCase().includes(normalizedFilter);
    });
  }
}
