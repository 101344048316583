import { OptionType } from '@vdms-hq/shared';
import { LanguageSources } from '@vdms-hq/selectors';

export enum METADATA_RECOGNITION_ROUTES {
  ROOT = 'metadata-recognition',
}

export const KeyTypeToOptionTypeKey: Record<string, string> = {
  episodeName: OptionType.EPISODE_NAME,
  seriesName: OptionType.SERIES_NAME,
  facilityOfOrigin: OptionType.FACILITY_OF_ORIGIN,
  language: LanguageSources.LANGUAGE,
  elements: OptionType.ELEMENTS,
  segments: OptionType.SEAMLESS_SEGMENTED,
  genre: OptionType.GENRE,
  productionCompany: OptionType.PRODUCTION_COMPANY,
  contentClass: OptionType.CONTENT_CLASS,
  contentType: OptionType.CONTENT_CLASS,
  theme: OptionType.THEME,
  variation: OptionType.VARIATION,
  category: OptionType.CATEGORY,
  seasonTitle: OptionType.SEASON_TITLE,
  releaseYear: OptionType.RELEASE_YEAR,
};
