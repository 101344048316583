import * as Sentry from '@sentry/browser';

export const sentryInit = (params: {
  dns: string;
  env: string;
  appName: string;
  version: string;
  debug?: boolean;
  sampleRate?: number;
}) => {
  if (!params.dns) {
    return;
  }

  Sentry.init({
    dsn: params.dns,
    debug: params.debug ?? false,
    environment: params.env,
    attachStacktrace: true,

    initialScope: {
      tags: { app: params.appName },
    },
    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: params.sampleRate ?? 1,
    release: params.version,
    ignoreErrors: [
      'ResizeObserver loop completed with undelivered notifications',
      'Proof of ownership of a second factor is required to complete',
      'ChunkLoadError: Loading chunk',
      'Object captured as exception', // this one happens for http calls, and register an error twice
    ],
  });
};
