import { Asset, AssetCollection } from '@vdms-hq/api-contract';
import { AssetViewConfig } from '@vdms-hq/firebase-contract';
import { PlayerConfiguration } from '@vdms-hq/player';
import { FileDestination, InputDefinitionModel } from '@vdms-hq/shared';
import { Framerate, Timecode } from '@vdms-hq/timecode';
import { BreadCrumb, PreviewErrorModel, PreviewUISettings, TileModel } from '@vdms-hq/ui';
import { MetadataListConfigParams } from '@vdms-hq/metadata-list';

export type DolbyRaspPlayerConfiguration = {
  masterFile: FileDestination;
  originalPlayerConfig: PlayerConfiguration;
};

export type FilePreviewModel =
  | (PlayerConfiguration & { type: 'player' })
  | (PreviewUISettings & { type: 'preview' })
  | (DolbyRaspPlayerConfiguration & { type: 'player-dolby-rasp-source' })
  | (DolbyRaspPlayerConfiguration & { type: 'player-dolby-rasp' })
  | PreviewErrorModel;

export const isPlayer = (obj: FilePreviewModel) => {
  return 'type' in obj && obj.type === 'player' && <PlayerConfiguration>obj;
};

export const isPreview = (obj: FilePreviewModel) => {
  return 'type' in obj && obj.type === 'preview' && <PreviewUISettings>obj;
};

export const isDolbyRasp = (obj: FilePreviewModel) => {
  return 'type' in obj && obj.type === 'player-dolby-rasp' && <DolbyRaspPlayerConfiguration>obj;
};

export const isDolbyRaspSource = (obj: FilePreviewModel) => {
  return 'type' in obj && obj.type === 'player-dolby-rasp-source' && <DolbyRaspPlayerConfiguration>obj;
};

export const isError = (obj: FilePreviewModel) => {
  return 'message' in obj && <PreviewErrorModel>obj;
};

export type AvailableAssetViewConfig = {
  name: string;
  isActive: boolean;
  key: string;
  config: AssetViewConfig;
};
export interface AssetViewModel extends Omit<Asset, 'general'> {
  uuid: string;
  viewConfig: AssetViewConfig;
  availableViewConfigs: AvailableAssetViewConfig[];
  clip: {
    startAt: Timecode;
    endAt: Timecode;
  } | null;
  fieldsDefinitions: InputDefinitionModel[];
  framerate: Framerate | null;
  currentOffset: Timecode | null;
  breadcrumb: BreadCrumb[];
  general: Omit<Asset['general'], 'timecode_in' | 'timecode_out' | 'mediainfo'> & {
    timecode_in: Timecode | null;
    timecode_out: Timecode | null;
    embargoed_to?: string;
  };
  header: string;
  collectionTiles: (AssetCollection & {
    tile: TileModel;
  })[];

  metadataListConfig?: MetadataListConfigParams;
}
