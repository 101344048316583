import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LicensePackagesActionsService } from '../../../logic/license-packages-actions';
import { MatPaginatorModule } from '@angular/material/paginator';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import {
  TileSelectableConfig,
  UiAddDialogSelectableTilesComponent,
  UIButtonModule,
  UIDialogWrapperModule,
  UIEmptyResultsModule,
  UIFormModule,
  UILoaderModule,
} from '@vdms-hq/ui';
import {
  AssetSearchFilterParam,
  LICENSED_PACKAGE_STATUS,
  LicensedPackagePatchItems,
  LicensePackagesService,
  SORT_BY,
} from '@vdms-hq/api-contract';
import { LicensePackagesAddData, LicensePackagesAddDsService } from '../../../logic/license-packages-add-ds.service';
import { combineLatest, concat, Observable, Subject, takeUntil } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { LicensePackagesRefreshService } from '../../../logic/license-packages-refresher.service';
import { WithPermissions } from '@vdms-hq/activated-client';
import { DynamicFilterInput, DynamicFiltersModule } from '@vdms-hq/dynamic-filters';
import { RightsContractsAddDsService } from '@vdms-hq/rights-contracts';
import { RightsPartnersAddDsService } from '@vdms-hq/rights-partners';
import { MatDividerModule } from '@angular/material/divider';
import { getFilters } from '../../../logic/license-package-filters';

export interface LicensedPackagesAddDialogData {
  selectedIds?: string[];
  filters?: AssetSearchFilterParam;
}

@Component({
  selector: 'vdms-hq-licensed-packages-add-dialog',
  standalone: true,
  imports: [
    CommonModule,
    MatPaginatorModule,
    ReactiveFormsModule,
    TranslateModule,
    UIButtonModule,
    UIDialogWrapperModule,
    UIFormModule,
    UILoaderModule,
    DynamicFiltersModule,
    MatDividerModule,
    UiAddDialogSelectableTilesComponent,
    UIEmptyResultsModule,
  ],
  templateUrl: './licensed-packages-add-dialog.component.html',
  styles: [
    '.form-content { display: flex; flex-direction: column; gap: 16px; padding-right: 1rem;} mat-divider { margin-bottom: 8px;}',
  ],
})
export class LicensedPackagesAddDialogComponent extends WithPermissions() implements OnInit, OnDestroy {
  form = new FormGroup({
    selected: new FormControl<string[]>([], { validators: Validators.required, nonNullable: true }),
  });

  filtersConfig: DynamicFilterInput[] = [];

  selectedLicensePackagesControl = this.form.get('selected') as FormControl<string[]>;

  private destroyed$: Subject<void> = new Subject<void>();

  licensedPackagesTilesConfig: TileSelectableConfig<LicensePackagesAddData> = {
    label: 'label',
    key: 'key',
    metadata: [
      {
        valuePath: 'status',
        viewFormat: {
          pills: {
            active: 'done',
            draft: '',
          },
        },
      },
      {
        label: 'Number of assets',
        valuePath: 'number_of_assets',
        viewFormat: {
          modifiers: {
            asNumberWithZero: true,
          },
        },
      },
    ],
  };

  constructor(
    public dataSource: LicensePackagesAddDsService,
    private licensePackagesService: LicensePackagesService,
    private licensedPackagesActions: LicensePackagesActionsService,
    private licensedPackagesRefreshService: LicensePackagesRefreshService,
    private rightsContractsAddDsService: RightsContractsAddDsService,
    private rightsPartnersAddDsService: RightsPartnersAddDsService,
    private dialogRef: MatDialogRef<LicensedPackagesAddDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: LicensedPackagesAddDialogData,
  ) {
    super();
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  ngOnInit() {
    if (this.data.filters) {
      this.dataSource.filters.controls.status.setValue(LICENSED_PACKAGE_STATUS.DRAFT);
    }
    this.dataSource.isLoading$.next(true);
    this.dataSource.filters.controls['keyword'].reset();
    combineLatest([this.rightsContractsAddDsService.allData$, this.rightsPartnersAddDsService.allData$])
      .pipe(takeUntil(this.destroyed$))
      .subscribe(([contracts, partners]) => {
        this.filtersConfig = getFilters(contracts, partners);
      });
  }

  selectLicensedPackage(item: LicensePackagesAddData) {
    if (this.selectedLicensePackagesControl.value.includes(item.key)) {
      this.selectedLicensePackagesControl.setValue(
        this.selectedLicensePackagesControl.value.filter((id) => id !== item.key),
      );
    } else {
      this.selectedLicensePackagesControl.setValue([...this.selectedLicensePackagesControl.value, item.key]);
    }
  }

  save() {
    const items: LicensedPackagePatchItems = {
      items: this.data.selectedIds?.map((uuid) => ({ asset_uuid: uuid })) ?? [],
    };
    this.dataSource.isLoading$.next(true);
    const addItems: Observable<string[]>[] = [];
    this.selectedLicensePackagesControl.value.forEach((uuid) => {
      if (!this.data.filters) {
        addItems.push(
          this.licensePackagesService.addItems(uuid, items).pipe(
            catchError(() => {
              this.licensedPackagesActions.popToast.ADD_ITEMS_FAILURE(
                `Adding asset to licensed package with uuid ${uuid} failed`,
              );
              return [];
            }),
          ),
        );
      } else {
        addItems.push(
          this.licensePackagesService.addFilters(uuid, this.data.filters).pipe(
            catchError(() => {
              this.licensedPackagesActions.popToast.ADD_ITEMS_FAILURE(
                `Adding asset to licensed package with uuid ${uuid} failed`,
              );
              return [];
            }),
          ),
        );
      }
    });

    concat(...addItems).subscribe({
      next: () => {
        this.dataSource.isLoading$.next(false);
        this.licensedPackagesRefreshService.refreshSingleLicensePackage$.next(true);
        this.licensedPackagesRefreshService.refreshLicensePackagesList$.next(true);
        this.data.selectedIds?.length === 1
          ? this.licensedPackagesActions.popToast.ADD_SINGLE_ITEM_SUCCESS()
          : this.licensedPackagesActions.popToast.ADD_ITEMS_SUCCESS();
      },
      complete: () => {
        this.close();
      },
    });
  }

  close() {
    this.dataSource.refresh();
    this.dialogRef.close();
  }
}
