import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'parseUrl',
  pure: true,
  standalone: true,
})
export class ParseUrl implements PipeTransform {
  transform(link: string): {
    url: string;
    hostname: string;
    shortName: string;
  } | null {
    try {
      const url = new URL(link);

      return {
        hostname: url.hostname,
        shortName: url.hostname.replace('www.', ''),
        url: url.toString(),
      };
    } catch (error) {
      return null;
    }
  }
}
