<vdms-hq-ui-dialog-wrapper footerAlign="space-between">
  <h1 title>
    {{
      (userListHidden$ | async)
        ? ('pages.lists.select_users_bbc' | translate)
        : ('pages.lists.select_users' | translate)
    }}
  </h1>
  <vdms-hq-ui-loader content *ngIf="!viewInitialized"></vdms-hq-ui-loader>
  <div content class="dialog-content" *ngIf="viewInitialized && userListAllowed">
    <vdms-hq-users-selector [formControl]="users" (updateUsers)="updateSelection($event)"></vdms-hq-users-selector>
  </div>
  <vdms-hq-ui-empty-results content class="dialog-content" *ngIf="!userListAllowed"> </vdms-hq-ui-empty-results>
  <ng-container footer>
    <vdms-hq-ui-button (click)="onNoClick()" color="secondary">{{
      'common.global.cancel' | translate
    }}</vdms-hq-ui-button>
    <vdms-hq-ui-button (click)="onSubmit()" [disabled]="noChanges()" color="primary">{{
      'common.global.save' | translate
    }}</vdms-hq-ui-button>
  </ng-container>
</vdms-hq-ui-dialog-wrapper>
