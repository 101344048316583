import { Constants } from '../../../constants/constants';
import { BaseGroupingLane } from './base-grouping-lane';

import {
  ConfigWithOptionalStyle,
  ImageButton,
  ImageButtonImageConfig,
  LabelLaneConfig,
} from '@byomakase/omakase-player';

export type CustomGroupingLaneConfig = LabelLaneConfig & {
  button?: {
    icon: ImageButtonImageConfig;
  };
};

export class CustomGroupingLine extends BaseGroupingLane<CustomGroupingLaneConfig> {
  private button?: ImageButton;

  constructor(config: ConfigWithOptionalStyle<CustomGroupingLaneConfig>) {
    super({
      ...config,
      style: {
        ...Constants.LABEL_LANE_STYLE,
      },
    });

    if (config.button) {
      this.addButton(config.button.icon);
    }
  }

  get customButton() {
    return this.button;
  }

  addButton(icon: ImageButtonImageConfig) {
    this.button = new ImageButton({
      ...icon,
      listening: true,
    });

    this.addTimelineNode({
      timelineNode: this.button,
      width: this.button.config.width ?? 0,
      height: this.button.config.height ?? 0,
      justify: 'start',
      margin: [0, 10, 0, 0],
    });
  }
}
