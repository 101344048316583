import { NgModule } from '@angular/core';
import { SelectOptionsTableComponent } from './components/select-options-table/select-options-table.component';
import { RouterModule } from '@angular/router';
import {
  DataPresentationHeaderComponent,
  MultipleDataPresentationComponent,
  UIButtonModule,
  UIDialogModule,
  UIDialogWrapperModule,
  UIFormModule,
  UILayoutModule,
  UILoaderModule,
  UIModule,
  UIResultsWrapperModule,
} from '@vdms-hq/ui';
import { MatTableModule } from '@angular/material/table';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import { SelectorsDataSource } from './logic/services/selectors-data-source';
import { MatSortModule } from '@angular/material/sort';
import { MatButtonModule } from '@angular/material/button';
import { TranslateModule } from '@ngx-translate/core';
import { ActivatedClientModule } from '@vdms-hq/activated-client';
import { SelectOptionsCreateDialogComponent } from './components/select-options-create-dialog/select-options-create-dialog.component';
import { SelectOptionsEditDialogComponent } from './components/select-options-edit-dialog/select-options-edit-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatPaginatorModule } from '@angular/material/paginator';
import { ToastModule } from '@vdms-hq/toast';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SelectorsFetcher } from './logic/services/selectors-fetcher';
import { SelectorsModule } from '@vdms-hq/selectors';
import { SelectOptionsTreeDialogComponent } from './components/select-options-tree-dialog/select-options-tree-dialog.component';
import { FormBuilderModule } from '@vdms-hq/dynamic-form';
import { SelectOptionsEditExtraComponent } from './components/select-options-edit-extra/select-options-edit-extra.component';
import { DefinitionForSelectedIdPipe } from './components/select-options-edit-extra/definition-for-selected-id.pipe';
import { DynamicFiltersModule } from '@vdms-hq/dynamic-filters';
import { SelectOptionsAdminTableComponent } from './components/select-options-admin-table/select-options-admin-table.component';

@NgModule({
  declarations: [
    SelectOptionsTableComponent,
    SelectOptionsAdminTableComponent,
    SelectOptionsEditExtraComponent,
    SelectOptionsCreateDialogComponent,
    SelectOptionsEditDialogComponent,
    SelectOptionsTreeDialogComponent,
    DefinitionForSelectedIdPipe,
  ],
  imports: [
    RouterModule.forChild([]),
    UILayoutModule,
    UIFormModule,
    UIDialogModule,
    MatTableModule,
    MatIconModule,
    MatDialogModule,
    MatCheckboxModule,
    CommonModule,
    MatSortModule,
    UILoaderModule,
    MatButtonModule,
    TranslateModule,
    ActivatedClientModule,
    MatPaginatorModule,
    ToastModule,
    MatTooltipModule,
    UIResultsWrapperModule,
    UIDialogWrapperModule,
    SelectorsModule,
    UIButtonModule,
    UIModule,
    FormBuilderModule,
    DataPresentationHeaderComponent,
    DynamicFiltersModule,
    MultipleDataPresentationComponent,
  ],
  exports: [SelectOptionsTableComponent],
  providers: [SelectorsDataSource, SelectorsFetcher],
})
export class SelectOptionsEditorModule {}
