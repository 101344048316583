import { ChangeDetectorRef, Component, ElementRef, forwardRef, Input, TrackByFunction, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, UntypedFormControl } from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { BehaviorSubject, Observable } from 'rxjs';
import { debounceTime, filter, switchMap } from 'rxjs/operators';
import { TagHint, TagService, TagSubject } from '@vdms-hq/api-contract';
import { UIFormModule } from '@vdms-hq/ui';
import { CommonModule } from '@angular/common';
import { HighlightPipe } from '@vdms-hq/shared';

export interface ChipOption {
  name: string;
  value: string;
}

@Component({
  selector: 'vdms-hq-form-tags-selector',
  templateUrl: './tags-selector.component.html',
  styleUrls: ['./tags-selector.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => TagsSelectorComponent),
    },
  ],
  standalone: true,
  imports: [UIFormModule, CommonModule, HighlightPipe],
})
export class TagsSelectorComponent implements ControlValueAccessor {
  @Input() label?: string;
  @Input() withFooter = true; // todo implement me
  @Input() withTouchedIndicator = false; // todo implement me
  @Input() subject: TagSubject = TagSubject.GENERAL;
  @Input() readonly = false;
  value: ChipOption[] = [];
  disabled = false;
  @Input() placeholder = '';
  @ViewChild('input') input?: ElementRef;
  searchControl = new UntypedFormControl();
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  inputValue = new BehaviorSubject<string>('');
  hints$: Observable<TagHint[]> = this.inputValue.pipe(
    debounceTime(300),
    filter((value) => value.length > 0),
    switchMap((value) => this.tags.findBySubject(this.subject, value)),
  );

  constructor(private changeDetectorRefs: ChangeDetectorRef, public tags: TagService) {}

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  propagateChange: (value) => void;

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  propagateTouch: (value) => void;

  trackByFn: TrackByFunction<ChipOption> = (index: number, item: ChipOption) => item.name + item.value;

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.propagateTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(value: ChipOption[] | null | undefined | ''): void {
    this.value = Array.isArray(value) ? [...value] : [];
    this.changeDetectorRefs.detectChanges();
  }

  removeChip(name: string, value: string): void {
    this.value = this.value.filter((element) => !(element.name === name && element.value === value));
    this.propagateChange(this.value);
  }

  addChip($event: MatChipInputEvent): void {
    const value = $event.value;
    if (this.input) {
      this.input.nativeElement.value = '';
    }
    this.searchControl.setValue(null);

    this.addValue(value);
  }

  addValue(value: string, type = 'General') {
    value = (value || '').trim();
    if (!value || value === '') {
      return;
    }

    const name = type;
    const exist = this.value.find((element) => element.name === name && element.value === value);

    if (!exist) {
      this.value.push({ name, value });
      this.propagateChange(this.value);
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    const hint = event.option.value as TagHint;
    if (this.input) {
      this.input.nativeElement.value = '';
    }
    this.searchControl.setValue(null);

    this.addValue(hint.name, hint.type);
    hint.related.forEach((item) => this.addValue(item, hint.type));
  }

  keyUp($event: KeyboardEvent) {
    const allowedKeys = ['Backspace'].includes($event.key);
    const allowedCharacters = $event.key.length === 1 && /\w|[ .-]/.test($event.key);

    if (allowedKeys || allowedCharacters) {
      this.inputValue.next(this.input?.nativeElement.value);
    }
  }
}
