import { Client } from '../client/client.model';
import { PermissionModel } from './permission.model';
import { VidaAppTypes } from '../client-user-admin/client-user-admin.model';

export interface Policy {
  uuid: string;
  name: string;
  groups?: Client[];
  permissions?: PermissionModel[];
  isSuperAdmin?: boolean;
  isCrossGroup?: boolean;
}

export interface PolicySelectOption {
  uuid?: string;
  name?: string;
  isCrossGroup?: boolean;
  isSuperAdmin?: boolean;
  key: string | null;
  label: string;
}

export interface PolicyAttachableData {
  attach?: string[];
  detach?: string[];
}

export interface PolicyPatchRequest {
  name?: string;
  groups?: PolicyAttachableData;
  permissions?: PolicyAttachableData;
}

export interface PolicyPostRequest extends PolicyPatchRequest {
  uuid?: string;
}

export interface PolicyResponse {
  status: string;
  data: string[];
}

export const SHARING_ORDER_POLICY_TYPE = 'sharing_order';
export const GroupDefaultPolicyTypes = [SHARING_ORDER_POLICY_TYPE, ...VidaAppTypes] as const;
export type GroupDefaultPolicyType = (typeof GroupDefaultPolicyTypes)[number];
export type DefaultPolicies = {
  [key in GroupDefaultPolicyType]?: string;
};
