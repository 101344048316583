import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { isItem, isSeparator, Separator, SidebarItem } from '../../models/sidebar.model';
import { castTo } from '@vdms-hq/shared';

@Component({
  selector: 'vdms-hq-ui-inner-sidebar-link',
  templateUrl: './inner-sidebar-link.component.html',
  styleUrls: ['./inner-sidebar-link.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InnerSidebarLinkComponent {
  $sidebarItem = castTo<SidebarItem>();

  @Input() item!: SidebarItem | Separator;
  @Input() hasChildren = false;
  @Input() expandable = true;
  @Input() expanded = false;
  @Input() smaller = false;
  @Output() toggle = new EventEmitter<void>();
  isSeparator = isSeparator;
  isItem = isItem;
}
