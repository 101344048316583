import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SessionData } from '@vdms-hq/omakase-player';

@Injectable({
  providedIn: 'root',
})
export class PlayerDownloadSessionService {
  constructor(private http: HttpClient) {}

  fetchBootstrapPayload(sessionUrl: string): Observable<SessionData> {
    return this.http.get<SessionData>(sessionUrl);
  }
}
