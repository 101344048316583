import { Injectable } from '@angular/core';
import { ApiService } from '../api.service';
import { Observable } from 'rxjs';
import { ApiResponse } from '../api.model';
import { GetResponseData } from '../../operators/get-response-data.operator';
import { TagSubject, TagEndpoints, TagHint } from './tag.model';

@Injectable({ providedIn: 'root' })
export class TagService {
  constructor(private readonly apiService: ApiService) {}

  findBySubject = (subject: TagSubject, value: string): Observable<TagHint[]> =>
    this.apiService
      .get<ApiResponse<TagHint[]>>(`${TagEndpoints.ROOT}/${subject}/autocomplete/${value}`)
      .pipe(GetResponseData);
}
