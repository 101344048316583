import { Pipe, PipeTransform } from '@angular/core';
import { TemplateBinding } from '@angular/compiler';

@Pipe({
  name: 'nameTransformer',
  pure: true,
  standalone: true,
})
export class NameTransformerPipe implements PipeTransform {
  transform<T = TemplateBinding>(value: ((item: T) => string) | string | number, item: T): string {
    if (typeof value === 'string' || typeof value === 'number') {
      return String(value);
    }

    if (typeof value === 'function') {
      return value(item);
    }

    return 'Undefined name';
  }
}
