import {
  AssetViewComponent,
  AssetViewConfig,
  ConfigLessComponentType,
  ContainerType,
  FieldConfig,
  NewContainerType,
  Permission,
  PlayerMetadataListSource,
} from '@vdms-hq/firebase-contract';
import { v4 as uuidv4 } from 'uuid';

export const transformLegacyConfigToNew = (assetViewConfig: AssetViewConfig): AssetViewConfig => {
  const fieldContainers = assetViewConfig.fieldsContainers ?? [];
  const mainTabs: AssetViewConfig['containers'] = fieldContainers
    .filter((container) => container.type === ContainerType.before)
    .map((item) => ({
      id: item.id,
      label: item.label ? item.label : 'Core',
      type: NewContainerType.main,
      columns: 2,
    }));

  const otherTabs: AssetViewConfig['containers'] = fieldContainers
    .filter((container) => container.type === ContainerType.tab)
    .map((item) => ({
      id: item.id,
      label: item.label,
      type: NewContainerType.other,
      columns: 1,
    }));

  const fieldElements: FieldConfig[] =
    assetViewConfig.fields !== undefined
      ? assetViewConfig.fields.map((field) => ({
          ...field,
          type: 'field',
        }))
      : [];

  const componentTabs: AssetViewConfig['containers'] = [];
  const componentElements: AssetViewConfig['elements'] = [];
  const enabledStreams: PlayerMetadataListSource[] = [];

  const edlId = uuidv4();
  if (assetViewConfig.components.includes(<AssetViewComponent>'edl')) {
    componentTabs.push({
      id: edlId,
      label: 'Logging',
      type: NewContainerType.other,
      columns: 1,
    });

    componentElements.push({
      type: ConfigLessComponentType.LOG_LIST,
      place: edlId,
    });
  }

  const attachmentsId = uuidv4();
  if (assetViewConfig.components.includes(<AssetViewComponent>'attachments')) {
    componentTabs.push({
      id: attachmentsId,
      label: 'Attachments',
      type: NewContainerType.other,
      columns: 1,
    });

    componentElements.push({
      type: ConfigLessComponentType.STORAGE_LIST_ATTACHMENT,
      place: attachmentsId,
    });

    componentElements.push({
      type: ConfigLessComponentType.STORAGE_UPLOAD_ATTACHMENT,
      place: attachmentsId,
    });
  }

  const transactionsId = uuidv4();
  if (assetViewConfig.components.includes(<AssetViewComponent>'transactions')) {
    componentTabs.push({
      id: transactionsId,
      label: 'Transactions',
      type: NewContainerType.other,
      columns: 1,
    });

    componentElements.push({
      type: ConfigLessComponentType.PREVIEW_TRANSACTIONS,
      place: transactionsId,
    });
  }

  const hybrikBrowse = assetViewConfig.components.includes(<AssetViewComponent>'hybrik_browse_jobs');
  const hybrikResults = assetViewConfig.components.includes(<AssetViewComponent>'hybrik_analyze_results');
  const hybrikRestart = assetViewConfig.components.includes(<AssetViewComponent>'hybrik_restart_jobs');

  if (hybrikBrowse || hybrikResults || hybrikRestart) {
    componentTabs.push({
      id: 'hybrik',
      label: 'Hybrik',
      type: NewContainerType.other,
      columns: 1,
      permissions: [Permission.BROWSE_HYBRIK_JOBS],
    });

    componentElements.push({
      type: ConfigLessComponentType.HYBRIK_JOBS,
      place: 'hybrik',
    });

    componentElements.push({
      type: ConfigLessComponentType.HYBRIK_RESULTS,
      place: 'hybrik',
    });
  }

  const playerMetadataListId = uuidv4();
  if (assetViewConfig.components.includes(<AssetViewComponent>'player_metadata_list')) {
    componentTabs.push({
      id: playerMetadataListId,
      label: 'All list',
      type: NewContainerType.main,
      columns: 1,
    });
  }

  if (assetViewConfig.components.includes(<AssetViewComponent>'player_metadata_list')) {
    componentElements.push({
      type: 'metadata_list',
      place: playerMetadataListId,
      settings: {
        defaultStream: enabledStreams,
        enabledStreams: enabledStreams,
      },
    });
  }

  if (assetViewConfig?.elements?.length || assetViewConfig?.containers?.length) {
    return {
      ...assetViewConfig,
    };
  } else {
    return {
      ...assetViewConfig,
      containers: [...mainTabs, ...otherTabs, ...componentTabs],
      elements: [...fieldElements, ...componentElements],
    };
  }
};
