import { Validators } from '@angular/forms';
import { Permission } from '@vdms-hq/activated-client';
import { PermissionsComparators } from '@vdms-hq/firebase-contract';
import { SelectorSourceType } from '@vdms-hq/selectors';
import {
  FieldDefinitionModel,
  FieldType,
  FilterType,
  PillColorEnum,
  ResourceModel,
  SelectOption,
  ValueFormat,
} from '@vdms-hq/shared';

export const assetFields: Record<string, FieldDefinitionModel> = {
  orderApprovalStatus: {
    isModified: false,
    isCustomDefinition: false,
    resource: [
      ResourceModel.ASSET_BROWSE,
      ResourceModel.ASSET_ORPHAN,
      ResourceModel.LICENSED_PACKAGE,
      ResourceModel.ASSET_CART,
      ResourceModel.ASSET_PREVIEW_REQUEST,
      ResourceModel.ASSET_ORDER,
    ],
    id: 'orderApprovalStatus',
    label: 'common.asset.general.orderApprovalStatus',
    format: ValueFormat.APPROVAL_STATUS,
    results2: {
      exportObjectPath: 'approval_status',
      objectPath: 'approval_status',
      sortable: false,
      viewFormat: {
        pills: {
          requested: 'orange',
          approved: 'done',
          rejected: 'failed',
        },
      },
    },
  },
  uploadJobApprovalStatus: {
    isModified: false,
    isCustomDefinition: false,
    resource: [
      ResourceModel.ASSET_BROWSE,
      ResourceModel.ASSET_ORPHAN,
      ResourceModel.LICENSED_PACKAGE,
      ResourceModel.ASSET_CART,
      ResourceModel.UPLOAD_JOBS,
      ResourceModel.ASSET_PREVIEW_REQUEST,
    ],
    id: 'uploadJobApprovalStatus',
    label: 'common.asset.general.uploadJobApprovalStatus',
    format: ValueFormat.AS_IS,
    input: {
      objectPath: 'general.status',
      type: FieldType.TEXT,
      readonly: true,
      validators: [],
    },
    results2: {
      exportObjectPath: 'approval_status',
      objectPath: 'context.status',
      sortable: false,
      viewFormat: {
        pills: {
          approved: 'done',
          completed: 'done',
          pending_approval: 'orange',
          rejected: 'orange-light',
          draft: 'done-light',
          default: '',
        },
      },
    },
  },

  processingStatus: {
    isModified: false,
    isCustomDefinition: false,
    resource: [
      ResourceModel.ASSET_BROWSE,
      ResourceModel.ASSET_ORPHAN,
      ResourceModel.LICENSED_PACKAGE,
      ResourceModel.ASSET_CART,
      ResourceModel.ASSET_PREVIEW_REQUEST,
      ResourceModel.ASSET_ORDER,
      ResourceModel.ASSET_SHARED_PACK,
    ],
    id: 'processingStatus',
    label: 'common.asset.general.processingStatus',
    sourceListKey: SelectorSourceType.PROCESSING_STATUS,
    format: ValueFormat.PROCESSING_STATUS,
    input: {
      objectPath: 'processing_status',
      type: FieldType.SELECT_MULTI,
      readonly: true,
      validators: [],
    },
    results2: {
      objectPath: 'processing',
      sortObjectPath: 'processing_status',
      exportObjectPath: 'processing',
      sortable: true,
      viewFormat: {
        modifiers: {
          removeUnderscores: true,
        },
        greyLineIf: 'placeholder',
        pills: {
          placeholder: 'white-dashed',
          initializing: 'yellow-dashed',
          analyse: 'orange',
          creating_proxy: 'orange-light',
          creating_stream: 'done-light',
          done: 'done',
          error: 'failed',
          qa_failed: 'failed',
          default: '',
        },
      },
    },
    filters: {
      objectPath: 'processing_status',
      aggregationKey: 'processing_status',
      validFormat: 'keyword',
      type: FilterType.SELECTOR,
    },
  },
  audioTrackLanguage: {
    featureDate: new Date(2023, 12, 13),
    isModified: false,
    isCustomDefinition: false,
    resource: [
      ResourceModel.ASSET_BROWSE,
      ResourceModel.ASSET_ORPHAN,
      ResourceModel.LICENSED_PACKAGE,
      ResourceModel.ASSET_CART,
      ResourceModel.ASSET_PREVIEW_REQUEST,
    ],
    sourceListKey: SelectorSourceType.AUDIO_LANGUAGE,
    id: 'audioTrackLanguage',
    label: 'common.asset.general.audio_track_language',
    format: ValueFormat.AS_IS,
    filters: {
      objectPath: 'audio_tracks_language',
      type: FilterType.SELECTOR,
      singleValue: true,
      validFormat: 'keyword',
      aggregationKey: 'audio_tracks_language',
    },
  },
  audioTrackLayout: {
    featureDate: new Date(2023, 12, 13),
    isModified: false,
    isCustomDefinition: false,
    resource: [
      ResourceModel.ASSET_BROWSE,
      ResourceModel.ASSET_ORPHAN,
      ResourceModel.LICENSED_PACKAGE,
      ResourceModel.ASSET_CART,
      ResourceModel.ASSET_PREVIEW_REQUEST,
    ],
    sourceListKey: SelectorSourceType.AUDIO_LAYOUT,
    id: 'audioTrackLayout',
    label: 'common.asset.general.audio_track_layout',
    format: ValueFormat.AS_IS,
    filters: {
      objectPath: 'audio_tracks_layout',
      type: FilterType.SELECTOR,
      singleValue: true,
      validFormat: 'keyword',
      aggregationKey: 'audio_tracks_layout',
    },
  },
  audioTrackClass: {
    featureDate: new Date(2023, 12, 13),
    isModified: false,
    isCustomDefinition: false,
    resource: [
      ResourceModel.ASSET_BROWSE,
      ResourceModel.ASSET_ORPHAN,
      ResourceModel.LICENSED_PACKAGE,
      ResourceModel.ASSET_CART,
      ResourceModel.ASSET_PREVIEW_REQUEST,
    ],
    sourceListKey: SelectorSourceType.AUDIO_CLASS,
    id: 'audioTrackClass',
    label: 'common.asset.general.audio_track_class',
    format: ValueFormat.AS_IS,
    filters: {
      objectPath: 'audio_tracks_class',
      type: FilterType.SELECTOR,
      singleValue: true,
      validFormat: 'keyword',
      aggregationKey: 'audio_tracks_class',
    },
  },
  uuid: {
    isModified: false,
    isCustomDefinition: false,
    resource: [
      ResourceModel.ASSET_BROWSE,
      ResourceModel.ASSET_ORPHAN,
      ResourceModel.LICENSED_PACKAGE,
      ResourceModel.ASSET_CART,
      ResourceModel.ASSET_PREVIEW_REQUEST,
      ResourceModel.ASSET_ORDER,
      ResourceModel.ASSET_SHARED_PACK,
    ],
    id: 'uuid',
    label: 'common.asset.general.uuid',
    format: ValueFormat.AS_IS,
    input: {
      objectPath: 'general.uuid',
      type: FieldType.TEXT,
      readonly: true,
      validators: [],
    },
    results2: {
      exportObjectPath: 'uuid',
      objectPath: 'props.uuid',
      sortable: false,
    },
    sourceListKey: 'uuid',
    filters: {
      objectPath: 'uuid',
      aggregationKey: 'uuid',
      type: FilterType.CHIPS,
      validFormat: 'keyword',
    },
  },
  batchId: {
    isModified: false,
    isCustomDefinition: false,
    resource: [
      ResourceModel.ASSET_BROWSE,
      ResourceModel.ASSET_ORPHAN,
      ResourceModel.LICENSED_PACKAGE,
      ResourceModel.ASSET_SHARED_PACK,
      ResourceModel.ASSET_ORDER,
      ResourceModel.ASSET_CART,
      ResourceModel.ASSET_PREVIEW_REQUEST,
    ],
    id: 'batchId',
    label: 'common.asset.general.batch_id',
    format: ValueFormat.AS_IS,
    input: {
      objectPath: 'general.batch_id',
      type: FieldType.TEXT,
      readonly: true,
      validators: [],
    },
  },
  sObjectId: {
    isModified: false,
    isCustomDefinition: false,
    resource: [
      ResourceModel.ASSET_BROWSE,
      ResourceModel.ASSET_ORPHAN,
      ResourceModel.LICENSED_PACKAGE,
      ResourceModel.ASSET_SHARED_PACK,
      ResourceModel.ASSET_ORDER,
      ResourceModel.ASSET_CART,
      ResourceModel.ASSET_PREVIEW_REQUEST,
    ],
    id: 'sObjectId',
    results2: {
      sortable: false,
      exportObjectPath: 's_object_id',
      objectPath: 'props.s_object_id',
    },
    label: 'common.asset.general.s_object_id',
    format: ValueFormat.AS_IS,
    input: {
      objectPath: 'general.s_object_id',
      type: FieldType.TEXT,
      readonly: true,
      validators: [],
    },
  },
  sObjectIdBk: {
    isModified: false,
    isCustomDefinition: false,
    resource: [
      ResourceModel.ASSET_BROWSE,
      ResourceModel.ASSET_ORPHAN,
      ResourceModel.LICENSED_PACKAGE,
      ResourceModel.ASSET_SHARED_PACK,
      ResourceModel.ASSET_ORDER,
      ResourceModel.ASSET_CART,
      ResourceModel.ASSET_PREVIEW_REQUEST,
    ],
    id: 'sObjectIdBk',
    results2: {
      sortable: false,
      exportObjectPath: 's_object_id_bk',
      objectPath: 'props.s_object_id_bk',
    },
    label: 'common.asset.general.s_object_id_bk',
    format: ValueFormat.AS_IS,
    input: {
      objectPath: 'general.s_object_id_bk',
      type: FieldType.TEXT,
      readonly: true,
      validators: [],
    },
  },
  masterNo: {
    isModified: false,
    isCustomDefinition: false,
    resource: [
      ResourceModel.ASSET_BROWSE,
      ResourceModel.ASSET_ORPHAN,
      ResourceModel.LICENSED_PACKAGE,
      ResourceModel.ASSET_SHARED_PACK,
      ResourceModel.ASSET_ORDER,
      ResourceModel.ASSET_CART,
      ResourceModel.ASSET_PREVIEW_REQUEST,
    ],
    id: 'masterNo',
    label: 'common.asset.general.master_no',
    format: ValueFormat.AS_IS,
    input: {
      objectPath: 'general.master_no',
      type: FieldType.TEXT,
      readonly: true,
      validators: [],
    },
  },
  mediaKey: {
    isModified: false,
    isCustomDefinition: false,
    resource: [
      ResourceModel.ASSET_BROWSE,
      ResourceModel.ASSET_ORPHAN,
      ResourceModel.LICENSED_PACKAGE,
      ResourceModel.ASSET_SHARED_PACK,
      ResourceModel.ASSET_ORDER,
      ResourceModel.ASSET_CART,
      ResourceModel.ASSET_PREVIEW_REQUEST,
    ],
    id: 'mediaKey',
    label: 'common.asset.general.media_key',
    format: ValueFormat.AS_IS,
    input: {
      objectPath: 'general.media_key',
      type: FieldType.TEXT,
      readonly: true,
      validators: [],
    },
  },
  externalKey: {
    isModified: false,
    isCustomDefinition: false,
    resource: [
      ResourceModel.ASSET_BROWSE,
      ResourceModel.ASSET_ORPHAN,
      ResourceModel.LICENSED_PACKAGE,
      ResourceModel.ASSET_SHARED_PACK,
      ResourceModel.ASSET_ORDER,
      ResourceModel.ASSET_CART,
      ResourceModel.ASSET_PREVIEW_REQUEST,
    ],
    id: 'externalKey',
    label: 'common.asset.general.external_key',
    format: ValueFormat.AS_IS,
    input: {
      objectPath: 'general.external_key',
      type: FieldType.TEXT,
      readonly: false,
      validators: [],
    },
    results2: {
      sortable: true,
      exportObjectPath: 'external_key',
      objectPath: 'props.external_key',
      sortObjectPath: 'external_key',
    },
  },
  uploadSource: {
    isModified: false,
    isCustomDefinition: false,
    resource: [
      ResourceModel.ASSET_BROWSE,
      ResourceModel.ASSET_ORPHAN,
      ResourceModel.LICENSED_PACKAGE,
      ResourceModel.ASSET_SHARED_PACK,
      ResourceModel.ASSET_ORDER,
      ResourceModel.ASSET_CART,
      ResourceModel.ASSET_PREVIEW_REQUEST,
    ],
    id: 'uploadSource',
    label: 'common.asset.general.upload_source',
    format: ValueFormat.AS_IS,
    input: {
      objectPath: 'general.upload_source',
      type: FieldType.TEXT,
      readonly: true,
      validators: [],
    },
    results2: {
      sortable: true,
      exportObjectPath: 'upload_source',
      objectPath: 'props.upload_source',
      sortObjectPath: 'upload_source',
    },
  },
  uploaded: {
    isModified: false,
    isCustomDefinition: false,
    resource: [
      ResourceModel.ASSET_BROWSE,
      ResourceModel.ASSET_ORPHAN,
      ResourceModel.LICENSED_PACKAGE,
      ResourceModel.ASSET_SHARED_PACK,
      ResourceModel.ASSET_ORDER,
      ResourceModel.ASSET_CART,
      ResourceModel.ASSET_PREVIEW_REQUEST,
    ],
    id: 'uploaded',
    results2: {
      sortable: true,
      sortObjectPath: 'uploaded_at',
      objectPath: 'props.uploaded_at',
      exportObjectPath: 'uploaded_at',
      viewFormat: {
        modifiers: {
          dateFormat: 'date-time',
        },
      },
    },
    label: 'common.asset.general.uploaded_at',
    format: ValueFormat.DATE,
    input: {
      objectPath: 'general.uploaded_at',
      type: FieldType.DATE,
      readonly: true,
      validators: [],
    },
  },
  uploadedBy: {
    isModified: false,
    isCustomDefinition: false,
    resource: [
      ResourceModel.ASSET_BROWSE,
      ResourceModel.ASSET_ORPHAN,
      ResourceModel.LICENSED_PACKAGE,
      ResourceModel.ASSET_SHARED_PACK,
      ResourceModel.ASSET_ORDER,
      ResourceModel.ASSET_CART,
      ResourceModel.ASSET_PREVIEW_REQUEST,
    ],
    id: 'uploadedBy',
    label: 'common.asset.general.uploaded_by',
    format: ValueFormat.AS_IS,
    results2: {
      objectPath: 'props.uploaded_by',
      exportObjectPath: 'uploaded_by',
      sortable: false,
    },
  },
  isThumbnailProcessing: {
    isModified: false,
    isCustomDefinition: false,
    resource: [
      ResourceModel.ASSET_BROWSE,
      ResourceModel.ASSET_ORPHAN,
      ResourceModel.LICENSED_PACKAGE,
      ResourceModel.ASSET_SHARED_PACK,
      ResourceModel.ASSET_ORDER,
      ResourceModel.ASSET_CART,
      ResourceModel.ASSET_PREVIEW_REQUEST,
    ],
    id: 'isThumbnailProcessing',
    label: 'common.asset.general.thumbnail_processing',
    format: ValueFormat.YES_NO,
    input: {
      objectPath: 'general.thumbnail_processing',
      type: FieldType.CHECKBOX,
      readonly: true,
      validators: [],
    },
  },
  isVirtual: {
    isModified: false,
    isCustomDefinition: false,
    resource: [
      ResourceModel.ASSET_BROWSE,
      ResourceModel.ASSET_ORPHAN,
      ResourceModel.LICENSED_PACKAGE,
      ResourceModel.ASSET_SHARED_PACK,
      ResourceModel.ASSET_ORDER,
      ResourceModel.ASSET_CART,
      ResourceModel.ASSET_PREVIEW_REQUEST,
    ],
    id: 'isVirtual',
    results2: {
      sortable: false,
      objectPath: 'props.is_virtual',
      exportObjectPath: 'is_virtual',
      viewFormat: {
        modifiers: {
          yesNo: true,
        },
        pills: {
          default: '',
          true: 'done',
        },
      },
    },
    label: 'common.asset.general.is_virtual',
    format: ValueFormat.YES_NO,
    input: {
      objectPath: 'general.is_virtual',
      type: FieldType.CHECKBOX,
      readonly: true,
      validators: [],
    },
  },
  isProxyOnly: {
    isModified: false,
    isCustomDefinition: false,
    resource: [
      ResourceModel.ASSET_BROWSE,
      ResourceModel.ASSET_ORPHAN,
      ResourceModel.LICENSED_PACKAGE,
      ResourceModel.ASSET_SHARED_PACK,
      ResourceModel.ASSET_ORDER,
      ResourceModel.ASSET_CART,
      ResourceModel.ASSET_PREVIEW_REQUEST,
    ],
    id: 'isProxyOnly',
    label: 'common.asset.general.is_proxy_only',
    format: ValueFormat.YES_NO,
    input: {
      objectPath: 'general.is_proxy_only',
      type: FieldType.CHECKBOX,
      readonly: true,
      validators: [],
    },
  },
  timecodeIn: {
    isModified: false,
    isCustomDefinition: false,
    resource: [
      ResourceModel.ASSET_BROWSE,
      ResourceModel.ASSET_ORPHAN,
      ResourceModel.LICENSED_PACKAGE,
      ResourceModel.ASSET_SHARED_PACK,
      ResourceModel.ASSET_ORDER,
      ResourceModel.ASSET_CART,
      ResourceModel.ASSET_PREVIEW_REQUEST,
    ],
    id: 'timecodeIn',
    results2: {
      sortable: false,
      objectPath: 'timecodeIn',
    },
    label: 'common.asset.general.timecode_in',
    format: ValueFormat.AS_IS,
    input: {
      objectPath: 'general.timecode_in',
      type: FieldType.TIMECODE,
      readonly: true,
      validators: [],
    },
  },
  timecodeOut: {
    isModified: false,
    isCustomDefinition: false,
    resource: [
      ResourceModel.ASSET_BROWSE,
      ResourceModel.ASSET_ORPHAN,
      ResourceModel.LICENSED_PACKAGE,
      ResourceModel.ASSET_SHARED_PACK,
      ResourceModel.ASSET_ORDER,
      ResourceModel.ASSET_CART,
      ResourceModel.ASSET_PREVIEW_REQUEST,
    ],
    id: 'timecodeOut',
    results2: {
      sortable: false,
      objectPath: 'timecodeOut',
    },
    label: 'common.asset.general.timecode_out',
    format: ValueFormat.AS_IS,
    input: {
      objectPath: 'general.timecode_out',
      type: FieldType.TIMECODE,
      readonly: true,
      validators: [],
    },
  },
  timecodeStart: {
    isModified: false,
    isCustomDefinition: false,
    resource: [
      ResourceModel.ASSET_BROWSE,
      ResourceModel.ASSET_ORPHAN,
      ResourceModel.LICENSED_PACKAGE,
      ResourceModel.ASSET_SHARED_PACK,
      ResourceModel.ASSET_ORDER,
      ResourceModel.ASSET_CART,
      ResourceModel.ASSET_PREVIEW_REQUEST,
    ],
    id: 'timecodeStart',
    label: 'common.asset.file_metadata.timecode_start',
    format: ValueFormat.AS_IS,
    input: {
      objectPath: 'file_metadata.timecode_start',
      type: FieldType.TIMECODE,
      readonly: true,
      validators: [],
    },
    results2: {
      sortable: false,
      objectPath: 'props.timecode_start',
    },
    filters: {
      objectPath: 'timecode_start',
      aggregationKey: 'timecode_start',
      validFormat: 'range',
      type: FilterType.TIMECODE_RANGE,
    },
  },
  cleanAperture: {
    isModified: false,
    isCustomDefinition: false,
    resource: [
      ResourceModel.ASSET_BROWSE,
      ResourceModel.ASSET_ORPHAN,
      ResourceModel.LICENSED_PACKAGE,
      ResourceModel.ASSET_SHARED_PACK,
      ResourceModel.ASSET_ORDER,
      ResourceModel.ASSET_CART,
      ResourceModel.ASSET_PREVIEW_REQUEST,
    ],
    id: 'cleanAperture',
    label: 'common.asset.general.clean_aperture',
    format: ValueFormat.AS_IS,
    input: {
      objectPath: 'general.clean_aperture',
      type: FieldType.TEXT,
      readonly: true,
      validators: [],
    },
    results2: {
      sortable: false,
      objectPath: 'props.clean_aperture',
    },
    filters: {
      objectPath: 'clean_aperture',
      aggregationKey: 'clean_aperture',
      validFormat: 'keyword',
      type: FilterType.CHECKBOX_LIST,
    },
  },
  containerFormat: {
    isModified: false,
    isCustomDefinition: false,
    resource: [
      ResourceModel.ASSET_BROWSE,
      ResourceModel.ASSET_ORPHAN,
      ResourceModel.ASSET_SHARED_PACK,
      ResourceModel.ASSET_ORDER,
      ResourceModel.ASSET_CART,
      ResourceModel.ASSET_PREVIEW_REQUEST,
    ],
    id: 'containerFormat',
    label: 'common.asset.general.container_format',
    format: ValueFormat.AS_IS,
    results2: {
      exportObjectPath: 'container_format',
      sortable: false,
      objectPath: 'props.container_format',
    },
    filters: {
      objectPath: 'container_format',
      aggregationKey: 'container_format',
      validFormat: 'keyword',
      type: FilterType.CHECKBOX_LIST,
    },
  },
  encodedPixels: {
    isModified: false,
    isCustomDefinition: false,
    resource: [
      ResourceModel.ASSET_BROWSE,
      ResourceModel.ASSET_ORPHAN,
      ResourceModel.LICENSED_PACKAGE,
      ResourceModel.ASSET_SHARED_PACK,
      ResourceModel.ASSET_ORDER,
      ResourceModel.ASSET_CART,
      ResourceModel.ASSET_PREVIEW_REQUEST,
    ],
    id: 'encodedPixels',
    label: 'common.asset.general.encoded_pixels',
    format: ValueFormat.AS_IS,
    input: {
      objectPath: 'general.encoded_pixels',
      type: FieldType.TEXT,
      readonly: true,
      validators: [],
    },
    results2: {
      sortable: false,
      exportObjectPath: 'encoded_pixels',
      objectPath: 'props.encoded_pixels',
    },
    filters: {
      objectPath: 'encoded_pixels',
      aggregationKey: 'encoded_pixels',
      validFormat: 'keyword',
      type: FilterType.CHECKBOX_LIST,
    },
  },
  fileExt: {
    isModified: false,
    isCustomDefinition: false,
    resource: [
      ResourceModel.ASSET_BROWSE,
      ResourceModel.ASSET_ORPHAN,
      ResourceModel.LICENSED_PACKAGE,
      ResourceModel.ASSET_SHARED_PACK,
      ResourceModel.ASSET_ORDER,
      ResourceModel.ASSET_CART,
      ResourceModel.ASSET_PREVIEW_REQUEST,
    ],
    id: 'fileExt',
    label: 'common.asset.general.file_ext',
    format: ValueFormat.AS_IS,
    input: {
      objectPath: 'general.file_ext',
      type: FieldType.TEXT,
      readonly: true,
      validators: [],
    },
  },
  tags: {
    isModified: false,
    isCustomDefinition: false,
    resource: [
      ResourceModel.ASSET_BROWSE,
      ResourceModel.ASSET_ORPHAN,
      ResourceModel.LICENSED_PACKAGE,
      ResourceModel.ASSET_SHARED_PACK,
      ResourceModel.ASSET_ORDER,
      ResourceModel.ASSET_CART,
      ResourceModel.ASSET_PREVIEW_REQUEST,
    ],
    id: 'tags',
    label: 'common.asset.general.tags',
    format: ValueFormat.AS_IS,
    input: {
      objectPath: 'general.tags',
      type: FieldType.TAGS,
      format: ValueFormat.TAGS,
      readonly: false,
      validators: [],
    },
    filters: {
      objectPath: 'tagsValue',
      type: FilterType.CHIPS,
      validFormat: 'keyword',
    },
    results2: {
      exportObjectPath: 'tags',
      objectPath: 'props.tags',
      sortable: false,
      viewFormat: {
        maxVisibleValues: 3,
        modifiers: {
          valueObjectKey: 'value',
        },
      },
    },
  },
  clientAssetId: {
    isModified: false,
    isCustomDefinition: false,
    resource: [
      ResourceModel.ASSET_BROWSE,
      ResourceModel.ASSET_ORPHAN,
      ResourceModel.LICENSED_PACKAGE,
      ResourceModel.ASSET_SHARED_PACK,
      ResourceModel.ASSET_ORDER,
      ResourceModel.ASSET_CART,
      ResourceModel.ASSET_PREVIEW_REQUEST,
    ],
    id: 'clientAssetId',
    results2: {
      sortable: false,
      exportObjectPath: 'client_asset_id',
      objectPath: 'props.client_asset_id',
    },
    label: 'common.asset.general.client_asset_id',
    format: ValueFormat.AS_IS,
    input: {
      objectPath: 'general.client_asset_id',
      type: FieldType.TEXT,
      readonly: false,
      validators: [],
    },
    filters: {
      objectPath: 'client_asset_id',
      type: FilterType.TEXT,
      validFormat: 'keyword',
    },
  },
  format: {
    isModified: false,
    isCustomDefinition: false,
    resource: [
      ResourceModel.ASSET_BROWSE,
      ResourceModel.ASSET_ORPHAN,
      ResourceModel.LICENSED_PACKAGE,
      ResourceModel.ASSET_PREVIEW_REQUEST,
    ],
    id: 'format',
    label: 'common.asset.file_metadata.format',
    format: ValueFormat.AS_IS,
    input: {
      objectPath: 'file_metadata.format',
      type: FieldType.TEXT,
      readonly: true,
      validators: [],
    },
    results2: {
      objectPath: 'props.format',
      exportObjectPath: 'format',
      sortable: false,
    },
    filters: {
      objectPath: 'format',
      aggregationKey: 'format',
      validFormat: 'keyword',
      type: FilterType.CHECKBOX_LIST,
    },
  },
  resolution: {
    isModified: false,
    isCustomDefinition: false,
    resource: [
      ResourceModel.ASSET_BROWSE,
      ResourceModel.ASSET_ORPHAN,
      ResourceModel.LICENSED_PACKAGE,
      ResourceModel.ASSET_SHARED_PACK,
      ResourceModel.ASSET_ORDER,
      ResourceModel.ASSET_CART,
      ResourceModel.ASSET_PREVIEW_REQUEST,
    ],
    id: 'resolution',
    results2: {
      sortable: false,
      exportObjectPath: 'vida_resolution',
      objectPath: 'props.vida_resolution',
    },
    label: 'common.asset.file_metadata.resolution',
    format: ValueFormat.AS_IS,
    input: {
      objectPath: 'file_metadata.vida_resolution',
      type: FieldType.TEXT,
      readonly: true,
      validators: [],
    },
    sourceListKey: 'vida_resolution',
    filters: {
      objectPath: 'vida_resolution',
      aggregationKey: 'vida_resolution',
      validFormat: 'keyword',
      type: FilterType.CHECKBOX_LIST,
    },
  },
  duration: {
    isModified: false,
    isCustomDefinition: false,
    resource: [
      ResourceModel.ASSET_BROWSE,
      ResourceModel.ASSET_ORPHAN,
      ResourceModel.LICENSED_PACKAGE,
      ResourceModel.ASSET_SHARED_PACK,
      ResourceModel.ASSET_ORDER,
      ResourceModel.ASSET_CART,
      ResourceModel.ASSET_PREVIEW_REQUEST,
    ],
    id: 'duration',
    label: 'common.asset.file_metadata.duration',
    format: ValueFormat.AS_IS,
    input: {
      objectPath: 'file_metadata.duration',
      type: FieldType.TIMECODE,
      readonly: true,
      validators: [],
    },
    results2: {
      objectPath: 'duration',
      sortable: true,
      sortObjectPath: 'duration',
      exportObjectPath: 'duration',
    },
    filters: {
      objectPath: 'duration',
      aggregationKey: 'duration',
      validFormat: 'range',
      type: FilterType.TIMECODE_RANGE,
    },
  },
  videoDuration: {
    isModified: false,
    isCustomDefinition: false,
    resource: [
      ResourceModel.ASSET_BROWSE,
      ResourceModel.ASSET_ORPHAN,
      ResourceModel.LICENSED_PACKAGE,
      ResourceModel.ASSET_SHARED_PACK,
      ResourceModel.ASSET_ORDER,
      ResourceModel.ASSET_CART,
      ResourceModel.ASSET_PREVIEW_REQUEST,
    ],
    id: 'videoDuration',
    label: 'common.asset.file_metadata.video_duration',
    format: ValueFormat.AS_IS,
    input: {
      objectPath: 'file_metadata.video.duration',
      type: FieldType.TIMECODE,
      readonly: true,
      validators: [],
    },
    results2: {
      objectPath: 'videoDuration',
      sortObjectPath: 'video_duration',
      exportObjectPath: 'video_duration',
      sortable: true,
    },
    filters: {
      objectPath: 'video_duration',
      aggregationKey: 'video_duration',
      validFormat: 'range',
      type: FilterType.TIMECODE_RANGE,
    },
  },

  containerDuration: {
    isModified: false,
    isCustomDefinition: false,
    resource: [
      ResourceModel.ASSET_BROWSE,
      ResourceModel.ASSET_ORPHAN,
      ResourceModel.LICENSED_PACKAGE,
      ResourceModel.ASSET_CART,
      ResourceModel.ASSET_PREVIEW_REQUEST,
    ],
    id: 'containerDuration',
    label: 'common.asset.file_metadata.container_duration',
    format: ValueFormat.AS_IS,
    input: {
      objectPath: 'file_metadata.duration',
      readonly: true,
      type: FieldType.TIMECODE,
    },
    results2: {
      sortable: false,
      objectPath: 'containerDuration',
    },
    filters: {
      objectPath: 'container_duration',
      validFormat: 'range',
      type: FilterType.TIMECODE_RANGE,
      aggregationKey: 'container_duration',
    },
  },
  width: {
    isModified: false,
    isCustomDefinition: false,
    resource: [
      ResourceModel.ASSET_BROWSE,
      ResourceModel.ASSET_ORPHAN,
      ResourceModel.LICENSED_PACKAGE,
      ResourceModel.ASSET_SHARED_PACK,
      ResourceModel.ASSET_ORDER,
      ResourceModel.ASSET_CART,
      ResourceModel.ASSET_PREVIEW_REQUEST,
    ],
    id: 'width',
    results2: {
      sortable: false,
      objectPath: 'props.width',
      exportObjectPath: 'width',
    },
    label: 'common.asset.file_metadata.video.screen_size.width',
    format: ValueFormat.AS_IS,
    input: {
      objectPath: 'file_metadata.video.screen_size.width',
      type: FieldType.TEXT,
      readonly: true,
      validators: [],
    },
    filters: {
      objectPath: 'width',
      validFormat: 'keyword',
      type: FilterType.CHECKBOX_LIST,
      aggregationKey: 'width',
    },
  },
  height: {
    format: ValueFormat.AS_IS,
    isModified: false,
    isCustomDefinition: false,
    resource: [
      ResourceModel.ASSET_BROWSE,
      ResourceModel.ASSET_ORPHAN,
      ResourceModel.LICENSED_PACKAGE,
      ResourceModel.ASSET_SHARED_PACK,
      ResourceModel.ASSET_ORDER,
      ResourceModel.ASSET_CART,
      ResourceModel.ASSET_PREVIEW_REQUEST,
    ],
    id: 'height',
    results2: {
      sortable: false,
      objectPath: 'props.height',
      sortObjectPath: 'height',
      exportObjectPath: 'height',
    },
    label: 'common.asset.file_metadata.video.screen_size.height',
    input: {
      objectPath: 'file_metadata.video.screen_size.height',
      type: FieldType.TEXT,
      readonly: true,
      validators: [],
    },
    filters: {
      objectPath: 'height',
      validFormat: 'keyword',
      type: FilterType.CHECKBOX_LIST,
      aggregationKey: 'height',
    },
  },
  videoCodec: {
    isModified: false,
    isCustomDefinition: false,
    resource: [
      ResourceModel.ASSET_BROWSE,
      ResourceModel.ASSET_ORPHAN,
      ResourceModel.LICENSED_PACKAGE,
      ResourceModel.ASSET_SHARED_PACK,
      ResourceModel.ASSET_ORDER,
      ResourceModel.ASSET_CART,
      ResourceModel.ASSET_PREVIEW_REQUEST,
    ],
    id: 'videoCodec',
    sourceListKey: 'video_codec_mapping',
    results2: {
      sortable: false,
      objectPath: 'props.video_codec_mapping',
      sortObjectPath: 'video_codec_mapping',
      exportObjectPath: 'video_codec_mapping',
    },
    label: 'common.asset.file_metadata.video_codec',
    format: ValueFormat.AS_IS,
    input: {
      objectPath: 'file_metadata.video_codec_mapping',
      type: FieldType.TEXT,
      readonly: true,
      validators: [],
    },
    filters: {
      objectPath: 'video_codec_mapping',
      aggregationKey: 'video_codec_mapping',
      validFormat: 'keyword',
      type: FilterType.CHECKBOX_LIST,
    },
  },
  videoCodecProfile: {
    isModified: false,
    isCustomDefinition: false,
    resource: [
      ResourceModel.ASSET_BROWSE,
      ResourceModel.ASSET_ORPHAN,
      ResourceModel.LICENSED_PACKAGE,
      ResourceModel.ASSET_SHARED_PACK,
      ResourceModel.ASSET_ORDER,
      ResourceModel.ASSET_CART,
      ResourceModel.ASSET_PREVIEW_REQUEST,
    ],
    id: 'videoCodecProfile',
    results2: {
      sortable: false,
      objectPath: 'props.profile',
      sortObjectPath: 'profile',
      exportObjectPath: 'profile',
    },
    label: 'common.asset.file_metadata.video_codec_profile',
    format: ValueFormat.AS_IS,
    input: {
      objectPath: 'file_metadata.video.profile',
      type: FieldType.TEXT,
      readonly: true,
      validators: [],
    },
    filters: {
      objectPath: 'profile',
      aggregationKey: 'profile',
      validFormat: 'keyword',
      type: FilterType.CHECKBOX_LIST,
    },
  },
  audioCodec: {
    isModified: false,
    isCustomDefinition: false,
    resource: [
      ResourceModel.ASSET_BROWSE,
      ResourceModel.ASSET_ORPHAN,
      ResourceModel.LICENSED_PACKAGE,
      ResourceModel.ASSET_PREVIEW_REQUEST,
      ResourceModel.ASSET_CART,
      ResourceModel.ASSET_ORDER,
    ],
    id: 'audioCodec',
    results2: {
      sortable: false,
      objectPath: 'props.audio_codec',
      exportObjectPath: 'audio_codec',
    },
    label: 'common.global.audio_codec',
    format: ValueFormat.AS_IS,
  },
  videoBitrate: {
    isModified: false,
    isCustomDefinition: false,
    resource: [
      ResourceModel.ASSET_BROWSE,
      ResourceModel.ASSET_ORPHAN,
      ResourceModel.LICENSED_PACKAGE,
      ResourceModel.ASSET_SHARED_PACK,
      ResourceModel.ASSET_ORDER,
      ResourceModel.ASSET_CART,
      ResourceModel.ASSET_PREVIEW_REQUEST,
    ],
    id: 'videoBitrate',
    label: 'common.asset.file_metadata.video_bitrate',
    format: ValueFormat.BITRATE_UNIT,
    input: {
      objectPath: 'file_metadata.bitrate',
      type: FieldType.TEXT,
      readonly: true,
      validators: [],
    },
    filters: {
      objectPath: 'bitrate',
      aggregationKey: 'bitrate',
      validFormat: 'range',
      type: FilterType.BITRATE_RANGE,
    },
    results2: {
      exportObjectPath: 'bitrate',
      objectPath: 'props.bitrate',
      sortable: false,
    },
  },
  bitrateMode: {
    isModified: false,
    isCustomDefinition: false,
    resource: [
      ResourceModel.ASSET_BROWSE,
      ResourceModel.ASSET_ORPHAN,
      ResourceModel.LICENSED_PACKAGE,
      ResourceModel.ASSET_SHARED_PACK,
      ResourceModel.ASSET_ORDER,
      ResourceModel.ASSET_CART,
      ResourceModel.ASSET_PREVIEW_REQUEST,
    ],
    id: 'bitrateMode',
    label: 'common.asset.file_metadata.bitrate_mode',
    format: ValueFormat.AS_IS,
    input: {
      objectPath: 'file_metadata.bitrate_mode',
      type: FieldType.TEXT,
      readonly: true,
      validators: [],
    },
    results2: {
      objectPath: 'props.bitrate_mode',
      exportObjectPath: 'bitrate_mode',
      sortable: false,
    },
    filters: {
      objectPath: 'bitrate_mode',
      aggregationKey: 'bitrate_mode',
      validFormat: 'keyword',
      type: FilterType.CHECKBOX_LIST,
    },
  },

  videoBitDepth: {
    isModified: false,
    isCustomDefinition: false,
    resource: [
      ResourceModel.ASSET_BROWSE,
      ResourceModel.ASSET_ORPHAN,
      ResourceModel.LICENSED_PACKAGE,
      ResourceModel.ASSET_SHARED_PACK,
      ResourceModel.ASSET_ORDER,
      ResourceModel.ASSET_CART,
      ResourceModel.ASSET_PREVIEW_REQUEST,
    ],
    id: 'videoBitDepth',
    label: 'common.asset.file_metadata.bit_depth',
    format: ValueFormat.AS_IS,
    input: {
      objectPath: 'file_metadata.bit_depth',
      type: FieldType.TEXT,
      readonly: true,
      validators: [],
    },
    results2: {
      objectPath: 'props.bit_depth',
      exportObjectPath: 'bit_depth',
      sortable: false,
    },
    filters: {
      objectPath: 'bit_depth',
      aggregationKey: 'bit_depth',
      validFormat: 'keyword',
      type: FilterType.CHECKBOX_LIST,
    },
  },
  interlaceMode: {
    isModified: false,
    isCustomDefinition: false,
    resource: [
      ResourceModel.ASSET_BROWSE,
      ResourceModel.ASSET_ORPHAN,
      ResourceModel.LICENSED_PACKAGE,
      ResourceModel.ASSET_SHARED_PACK,
      ResourceModel.ASSET_ORDER,
      ResourceModel.ASSET_CART,
      ResourceModel.ASSET_PREVIEW_REQUEST,
    ],
    id: 'interlaceMode',
    label: 'common.asset.file_metadata.interlace_mode',
    format: ValueFormat.AS_IS,
    input: {
      objectPath: 'file_metadata.interlace_mode',
      type: FieldType.TEXT,
      readonly: true,
      validators: [],
    },
    results2: {
      objectPath: 'props.interlace_mode',
      exportObjectPath: 'interlace_mode',
      sortable: false,
    },
    filters: {
      objectPath: 'interlace_mode',
      aggregationKey: 'interlace_mode',
      validFormat: 'keyword',
      type: FilterType.CHECKBOX_LIST,
    },
  },
  pixelAspectRatio: {
    isModified: false,
    isCustomDefinition: false,
    resource: [
      ResourceModel.ASSET_BROWSE,
      ResourceModel.ASSET_ORPHAN,
      ResourceModel.LICENSED_PACKAGE,
      ResourceModel.ASSET_SHARED_PACK,
      ResourceModel.ASSET_ORDER,
      ResourceModel.ASSET_CART,
      ResourceModel.ASSET_PREVIEW_REQUEST,
    ],
    id: 'pixelAspectRatio',
    label: 'common.asset.file_metadata.pixel_aspect_ratio',
    format: ValueFormat.AS_IS,
    input: {
      objectPath: 'file_metadata.pixel_aspect_ratio',
      type: FieldType.TEXT,
      readonly: true,
      validators: [],
    },
    results2: {
      objectPath: 'props.pixel_aspect_ratio',
      exportObjectPath: 'pixel_aspect_ratio',
      sortable: false,
    },
    filters: {
      objectPath: 'pixel_aspect_ratio',
      aggregationKey: 'pixel_aspect_ratio',
      validFormat: 'keyword',
      type: FilterType.CHECKBOX_LIST,
    },
  },
  framerate: {
    isModified: false,
    isCustomDefinition: false,
    resource: [
      ResourceModel.ASSET_BROWSE,
      ResourceModel.ASSET_ORPHAN,
      ResourceModel.LICENSED_PACKAGE,
      ResourceModel.ASSET_SHARED_PACK,
      ResourceModel.ASSET_ORDER,
      ResourceModel.ASSET_CART,
      ResourceModel.ASSET_PREVIEW_REQUEST,
    ],
    id: 'framerate',
    label: 'common.asset.file_metadata.framerate',
    format: ValueFormat.FRAMERATE,
    sourceListKey: 'framerate',
    input: {
      objectPath: 'file_metadata.framerate',
      type: FieldType.TEXT,
      readonly: true,
      validators: [],
    },
    filters: {
      objectPath: 'framerate',
      aggregationKey: 'framerate',
      validFormat: 'keyword',
      type: FilterType.CHECKBOX_LIST,
    },
    results2: {
      objectPath: 'props.framerate',
      exportObjectPath: 'framerate',
      sortable: false,
      sortObjectPath: 'framerate',
    },
  },
  frameRateMode: {
    isModified: false,
    isCustomDefinition: false,
    resource: [
      ResourceModel.ASSET_BROWSE,
      ResourceModel.ASSET_ORPHAN,
      ResourceModel.LICENSED_PACKAGE,
      ResourceModel.ASSET_SHARED_PACK,
      ResourceModel.ASSET_ORDER,
      ResourceModel.ASSET_CART,
      ResourceModel.ASSET_PREVIEW_REQUEST,
    ],
    id: 'frameRateMode',
    label: 'common.asset.file_metadata.frame_rate_mode',
    format: ValueFormat.AS_IS,
    input: {
      objectPath: 'file_metadata.frameRateMode',
      type: FieldType.TEXT,
      readonly: true,
      validators: [],
    },
    results2: {
      objectPath: 'props.frameRateMode',
      sortable: false,
    },
  },
  chromaFormat: {
    isModified: false,
    isCustomDefinition: false,
    resource: [
      ResourceModel.ASSET_BROWSE,
      ResourceModel.ASSET_ORPHAN,
      ResourceModel.LICENSED_PACKAGE,
      ResourceModel.ASSET_SHARED_PACK,
      ResourceModel.ASSET_ORDER,
      ResourceModel.ASSET_CART,
      ResourceModel.ASSET_PREVIEW_REQUEST,
    ],
    id: 'chromaFormat',
    label: 'common.asset.file_metadata.chroma_format',
    format: ValueFormat.AS_IS,
    input: {
      objectPath: 'file_metadata.chroma_format',
      type: FieldType.TEXT,
      readonly: true,
      validators: [],
    },
    results2: {
      exportObjectPath: 'chroma_format',
      objectPath: 'props.chroma_format',
      sortable: false,
    },
    filters: {
      objectPath: 'chroma_format',
      aggregationKey: 'chroma_format',
      validFormat: 'keyword',
      type: FilterType.CHECKBOX_LIST,
    },
  },
  colorSpace: {
    isModified: false,
    isCustomDefinition: false,
    resource: [
      ResourceModel.ASSET_BROWSE,
      ResourceModel.ASSET_ORPHAN,
      ResourceModel.LICENSED_PACKAGE,
      ResourceModel.ASSET_SHARED_PACK,
      ResourceModel.ASSET_ORDER,
      ResourceModel.ASSET_CART,
      ResourceModel.ASSET_PREVIEW_REQUEST,
    ],
    id: 'colorSpace',
    label: 'common.asset.file_metadata.color_space',
    format: ValueFormat.AS_IS,
    input: {
      objectPath: 'file_metadata.color_space',
      type: FieldType.TEXT,
      readonly: true,
      validators: [],
    },
    results2: {
      objectPath: 'props.color_space',
      exportObjectPath: 'color_space',
      sortable: false,
    },
    filters: {
      objectPath: 'color_space',
      aggregationKey: 'color_space',
      validFormat: 'keyword',
      type: FilterType.CHECKBOX_LIST,
    },
  },
  colorMatrixCoefficients: {
    isModified: false,
    isCustomDefinition: false,
    resource: [
      ResourceModel.ASSET_BROWSE,
      ResourceModel.ASSET_ORPHAN,
      ResourceModel.LICENSED_PACKAGE,
      ResourceModel.ASSET_SHARED_PACK,
      ResourceModel.ASSET_ORDER,
      ResourceModel.ASSET_CART,
      ResourceModel.ASSET_PREVIEW_REQUEST,
    ],
    id: 'colorMatrixCoefficients',
    label: 'common.asset.file_metadata.color_matrix_coefficients',
    format: ValueFormat.AS_IS,
    input: {
      objectPath: 'file_metadata.color_matrix_coefficients',
      type: FieldType.TEXT,
      readonly: true,
      validators: [],
    },
    results2: {
      objectPath: 'props.color_matrix_coefficients',
      exportObjectPath: 'color_matrix_coefficients',
      sortable: false,
    },
    filters: {
      objectPath: 'color_matrix_coefficients',
      aggregationKey: 'color_matrix_coefficients',
      validFormat: 'keyword',
      type: FilterType.CHECKBOX_LIST,
    },
  },
  colorPrimaries: {
    isModified: false,
    isCustomDefinition: false,
    resource: [
      ResourceModel.ASSET_BROWSE,
      ResourceModel.ASSET_ORPHAN,
      ResourceModel.LICENSED_PACKAGE,
      ResourceModel.ASSET_SHARED_PACK,
      ResourceModel.ASSET_ORDER,
      ResourceModel.ASSET_CART,
      ResourceModel.ASSET_PREVIEW_REQUEST,
    ],
    id: 'colorPrimaries',
    label: 'common.asset.file_metadata.color_primaries',
    format: ValueFormat.AS_IS,
    input: {
      objectPath: 'file_metadata.color_primaries',
      type: FieldType.TEXT,
      readonly: true,
      validators: [],
    },
    results2: {
      objectPath: 'props.color_primaries',
      exportObjectPath: 'color_primaries',
      sortable: false,
    },
    filters: {
      objectPath: 'color_primaries',
      aggregationKey: 'color_primaries',
      validFormat: 'keyword',
      type: FilterType.CHECKBOX_LIST,
    },
  },
  colorTransferCharacteristics: {
    isModified: false,
    isCustomDefinition: false,
    resource: [
      ResourceModel.ASSET_BROWSE,
      ResourceModel.ASSET_ORPHAN,
      ResourceModel.LICENSED_PACKAGE,
      ResourceModel.ASSET_SHARED_PACK,
      ResourceModel.ASSET_ORDER,
      ResourceModel.ASSET_CART,
      ResourceModel.ASSET_PREVIEW_REQUEST,
    ],
    id: 'colorTransferCharacteristics',
    label: 'common.asset.file_metadata.color_transfer_characteristics',
    format: ValueFormat.AS_IS,
    input: {
      objectPath: 'file_metadata.color_transfer_characteristics',
      type: FieldType.TEXT,
      readonly: true,
      validators: [],
    },
    results2: {
      objectPath: 'props.color_transfer_characteristics',
      exportObjectPath: 'color_transfer_characteristics',
      sortable: false,
    },
    filters: {
      objectPath: 'color_transfer_characteristics',
      aggregationKey: 'color_transfer_characteristics',
      validFormat: 'keyword',
      type: FilterType.CHECKBOX_LIST,
    },
  },
  aspectRatio: {
    isModified: false,
    isCustomDefinition: false,
    resource: [
      ResourceModel.ASSET_BROWSE,
      ResourceModel.ASSET_ORPHAN,
      ResourceModel.LICENSED_PACKAGE,
      ResourceModel.ASSET_SHARED_PACK,
      ResourceModel.ASSET_ORDER,
      ResourceModel.ASSET_CART,
      ResourceModel.ASSET_PREVIEW_REQUEST,
    ],
    id: 'aspectRatio',
    label: 'common.asset.file_metadata.aspect_ratio',
    format: ValueFormat.AS_IS,
    input: {
      objectPath: 'file_metadata.aspect_ratio',
      type: FieldType.TEXT,
      readonly: true,
      validators: [],
    },
    results2: {
      objectPath: 'props.aspect_ratio',
      exportObjectPath: 'aspect_ratio',
      sortable: true,
      sortObjectPath: 'aspect_ratio',
    },
    filters: {
      objectPath: 'aspect_ratio',
      aggregationKey: 'aspect_ratio',
      validFormat: 'keyword',
      type: FilterType.CHECKBOX_LIST,
    },
  },
  size: {
    isModified: false,
    isCustomDefinition: false,
    resource: [
      ResourceModel.ASSET_BROWSE,
      ResourceModel.ASSET_ORPHAN,
      ResourceModel.LICENSED_PACKAGE,
      ResourceModel.ASSET_SHARED_PACK,
      ResourceModel.ASSET_ORDER,
      ResourceModel.ASSET_CART,
      ResourceModel.ASSET_PREVIEW_REQUEST,
    ],
    id: 'size',
    label: 'common.asset.file_metadata.file_size',
    format: ValueFormat.FILE_SIZE,
    input: {
      objectPath: 'file_metadata.filesize',
      type: FieldType.FILE_SIZE,
      readonly: true,
      validators: [],
    },
    results2: {
      sortable: false,
      exportObjectPath: 'size',
      objectPath: 'props.size',
      sortObjectPath: 'size',
      viewFormat: {
        modifiers: {
          fileSizeFormat: true,
        },
      },
    },
    filters: {
      objectPath: 'size',
      validFormat: 'range',
      type: FilterType.SIZE_RANGE,
      aggregationKey: 'size',
    },
  },
  episodeName: {
    isModified: false,
    isCustomDefinition: false,
    resource: [
      ResourceModel.ASSET_BROWSE,
      ResourceModel.ASSET_ORPHAN,
      ResourceModel.LICENSED_PACKAGE,
      ResourceModel.ASSET_SHARED_PACK,
      ResourceModel.ASSET_ORDER,
      ResourceModel.ASSET_CART,
      ResourceModel.ASSET_PREVIEW_REQUEST,
    ],
    id: 'episodeName',
    label: 'common.asset.core.episode_name',
    format: ValueFormat.AS_IS,
    sourceListKey: 'episode_name',
    input: {
      objectPath: 'core.episode_name',
      type: FieldType.TEXT,
      readonly: false,
      validators: [],
    },
    results2: {
      objectPath: 'props.episode_name',
      exportObjectPath: 'episode_name',
      sortObjectPath: 'episode_name',
      sortable: true,
    },
    filters: {
      objectPath: 'episode_name',
      validFormat: 'keyword',
      aggregationKey: 'episode_name',
      type: FilterType.SELECTOR,
    },
  },
  episodeNamePrefilled: {
    isModified: false,
    isCustomDefinition: false,
    resource: [
      ResourceModel.ASSET_BROWSE,
      ResourceModel.ASSET_ORPHAN,
      ResourceModel.LICENSED_PACKAGE,
      ResourceModel.ASSET_SHARED_PACK,
      ResourceModel.ASSET_ORDER,
      ResourceModel.ASSET_CART,
      ResourceModel.ASSET_PREVIEW_REQUEST,
    ],
    id: 'episodeNamePrefilled',
    label: 'common.asset.core.episode_name',
    sourceListKey: 'episode_name',
    format: ValueFormat.AS_IS,
    input: {
      objectPath: 'core.episode_name',
      type: FieldType.SELECT,
      readonly: false,
      validators: [],
    },
  },
  episodeNumber: {
    isModified: false,
    isCustomDefinition: false,
    resource: [
      ResourceModel.ASSET_BROWSE,
      ResourceModel.ASSET_ORPHAN,
      ResourceModel.LICENSED_PACKAGE,
      ResourceModel.ASSET_SHARED_PACK,
      ResourceModel.ASSET_ORDER,
      ResourceModel.ASSET_CART,
      ResourceModel.ASSET_PREVIEW_REQUEST,
    ],
    id: 'episodeNumber',
    results2: {
      exportObjectPath: 'episode_number',
      sortable: true,
      objectPath: 'props.episode_number',
      sortObjectPath: 'episode_number',
      viewFormat: {
        modifiers: {
          asNumberWithZero: true,
        },
      },
    },
    label: 'common.asset.core.episode_number',
    format: ValueFormat.NATURAL_NUMBERS_WITH_ZERO,
    input: {
      objectPath: 'core.episode_number',
      type: FieldType.NUMBER,
      readonly: false,
      validators: [Validators.min(0)],
    },
    filters: {
      objectPath: 'episode_number',
      type: FilterType.SELECTOR,
      validFormat: 'keyword',
      aggregationKey: 'episode_number',
    },
  },
  seriesName: {
    isModified: false,
    isCustomDefinition: false,
    resource: [
      ResourceModel.ASSET_BROWSE,
      ResourceModel.ASSET_ORPHAN,
      ResourceModel.LICENSED_PACKAGE,
      ResourceModel.ASSET_SHARED_PACK,
      ResourceModel.ASSET_ORDER,
      ResourceModel.ASSET_CART,
      ResourceModel.ASSET_PREVIEW_REQUEST,
    ],
    id: 'seriesName',
    label: 'common.asset.core.series_name',
    sourceListKey: 'series_name',
    format: ValueFormat.AS_IS,
    input: {
      objectPath: 'core.series_name',
      type: FieldType.TEXT,
      readonly: false,
      validators: [],
    },
    results2: {
      exportObjectPath: 'series_name',
      objectPath: 'props.series_name',
      sortObjectPath: 'series_name',
      sortable: false,
    },
    filters: {
      objectPath: 'series_name',
      validFormat: 'keyword',
      aggregationKey: 'series_name',
      type: FilterType.SELECTOR,
    },
  },
  seriesNamePrefilled: {
    isModified: false,
    isCustomDefinition: false,
    resource: [
      ResourceModel.ASSET_BROWSE,
      ResourceModel.ASSET_ORPHAN,
      ResourceModel.LICENSED_PACKAGE,
      ResourceModel.ASSET_SHARED_PACK,
      ResourceModel.ASSET_ORDER,
      ResourceModel.ASSET_CART,
      ResourceModel.ASSET_PREVIEW_REQUEST,
    ],
    id: 'seriesNamePrefilled',
    label: 'common.asset.core.series_name',
    sourceListKey: 'series_name',
    format: ValueFormat.SELECTOR,
    input: {
      objectPath: 'core.series_name',
      type: FieldType.SELECT,
      readonly: false,
      validators: [],
    },
  },
  seriesNumber: {
    isModified: false,
    isCustomDefinition: false,
    resource: [
      ResourceModel.ASSET_BROWSE,
      ResourceModel.ASSET_ORPHAN,
      ResourceModel.LICENSED_PACKAGE,
      ResourceModel.ASSET_SHARED_PACK,
      ResourceModel.ASSET_ORDER,
      ResourceModel.ASSET_CART,
      ResourceModel.ASSET_PREVIEW_REQUEST,
    ],
    id: 'seriesNumber',
    results2: {
      sortable: true,
      exportObjectPath: 'series_number',
      objectPath: 'props.series_number',
      sortObjectPath: 'series_number',
      viewFormat: {
        modifiers: {
          asNumberWithZero: true,
        },
      },
    },
    label: 'common.asset.core.series_number',
    format: ValueFormat.NATURAL_NUMBERS_WITH_ZERO,
    input: {
      objectPath: 'core.series_number',
      type: FieldType.NUMBER,
      readonly: false,
      validators: [Validators.min(0)],
    },
    filters: {
      objectPath: 'series_number',
      type: FilterType.SELECTOR,
      aggregationKey: 'series_number',
      validFormat: 'keyword',
    },
  },
  clientRef: {
    isModified: false,
    isCustomDefinition: false,
    resource: [
      ResourceModel.ASSET_BROWSE,
      ResourceModel.ASSET_ORPHAN,
      ResourceModel.LICENSED_PACKAGE,
      ResourceModel.ASSET_SHARED_PACK,
      ResourceModel.ASSET_ORDER,
      ResourceModel.ASSET_CART,
      ResourceModel.ASSET_PREVIEW_REQUEST,
    ],
    id: 'clientRef',
    label: 'common.asset.core.client_ref',
    format: ValueFormat.AS_IS,
    input: {
      objectPath: 'core.client_ref',
      type: FieldType.TEXT,
      readonly: false,
      validators: [],
    },
    results2: {
      sortable: true,
      exportObjectPath: 'client_ref',
      objectPath: 'props.client_ref',
      sortObjectPath: 'client_ref',
    },
    filters: {
      objectPath: 'client_ref',
      type: FilterType.CHIPS,
      validFormat: 'keyword',
    },
  },
  programmeNumber: {
    isModified: false,
    isCustomDefinition: false,
    resource: [
      ResourceModel.ASSET_BROWSE,
      ResourceModel.ASSET_ORPHAN,
      ResourceModel.LICENSED_PACKAGE,
      ResourceModel.ASSET_SHARED_PACK,
      ResourceModel.ASSET_ORDER,
      ResourceModel.ASSET_CART,
      ResourceModel.ASSET_PREVIEW_REQUEST,
    ],
    id: 'programmeNumber',
    results2: {
      exportObjectPath: 'programme_number',
      sortable: true,
      objectPath: 'props.programme_number',
      sortObjectPath: 'programme_number',
    },
    label: 'common.asset.core.programme_number',
    format: ValueFormat.AS_IS,
    input: {
      objectPath: 'core.programme_number',
      type: FieldType.TEXT,
      readonly: false,
      validators: [],
    },
    filters: {
      objectPath: 'programme_number',
      validFormat: 'keyword',
      aggregationKey: 'programme_number',
      type: FilterType.SELECTOR,
    },
  },
  facilityOfOrigin: {
    isModified: false,
    isCustomDefinition: false,
    resource: [
      ResourceModel.ASSET_BROWSE,
      ResourceModel.ASSET_ORPHAN,
      ResourceModel.LICENSED_PACKAGE,
      ResourceModel.ASSET_SHARED_PACK,
      ResourceModel.ASSET_ORDER,
      ResourceModel.ASSET_CART,
      ResourceModel.ASSET_PREVIEW_REQUEST,
    ],
    id: 'facilityOfOrigin',
    results2: {
      exportObjectPath: 'facility_of_origin',
      sortable: true,
      objectPath: 'props.facility_of_origin',
      sortObjectPath: 'facility_of_origin',
    },
    label: 'common.asset.core.facility_of_origin',
    sourceListKey: 'facility_of_origin',
    format: ValueFormat.AS_IS,
    input: {
      objectPath: 'core.facility_of_origin',
      type: FieldType.SELECT,
      readonly: false,
      validators: [],
    },
    filters: {
      type: FilterType.SELECTOR,
      validFormat: 'keyword',
      objectPath: 'facility_of_origin',
      aggregationKey: 'facility_of_origin',
    },
  },
  originalFilename: {
    isModified: false,
    isCustomDefinition: false,
    resource: [
      ResourceModel.ASSET_BROWSE,
      ResourceModel.ASSET_ORPHAN,
      ResourceModel.LICENSED_PACKAGE,
      ResourceModel.ASSET_SHARED_PACK,
      ResourceModel.ASSET_ORDER,
      ResourceModel.ASSET_CART,
      ResourceModel.ASSET_PREVIEW_REQUEST,
    ],
    id: 'originalFilename',
    results2: {
      sortable: true,
      exportObjectPath: 'original_filename',
      objectPath: 'props.original_filename',
      sortObjectPath: 'original_filename',
    },
    label: 'common.asset.core.original_filename',
    format: ValueFormat.AS_IS,
    input: {
      objectPath: 'core.original_filename',
      type: FieldType.TEXT,
      readonly: false,
      validators: [],
    },
    filters: {
      objectPath: 'filename',
      validFormat: 'keyword',
      type: FilterType.CHIPS,
    },
    useAsFilename: true,
  },
  language: {
    isModified: false,
    isCustomDefinition: false,
    resource: [
      ResourceModel.ASSET_BROWSE,
      ResourceModel.ASSET_ORPHAN,
      ResourceModel.LICENSED_PACKAGE,
      ResourceModel.ASSET_SHARED_PACK,
      ResourceModel.ASSET_ORDER,
      ResourceModel.ASSET_CART,
      ResourceModel.ASSET_PREVIEW_REQUEST,
    ],
    id: 'language',
    label: 'common.asset.core.language',
    sourceListKey: SelectorSourceType.LANGUAGE,
    format: ValueFormat.AS_IS,
    input: {
      format: ValueFormat.SELECTOR,
      objectPath: 'user_media_data.language',
      type: FieldType.SELECT,
      readonly: false,
    },
    filters: {
      objectPath: 'language',
      validFormat: 'keyword',
      type: FilterType.SELECTOR,
      aggregationKey: 'language',
    },
    results2: {
      exportObjectPath: 'language',
      objectPath: 'props.language',
      sortObjectPath: 'language',
      sortable: true,
    },
  },
  userAspectRatio: {
    isModified: false,
    isCustomDefinition: false,
    resource: [
      ResourceModel.ASSET_BROWSE,
      ResourceModel.ASSET_ORPHAN,
      ResourceModel.LICENSED_PACKAGE,
      ResourceModel.ASSET_SHARED_PACK,
      ResourceModel.ASSET_ORDER,
      ResourceModel.ASSET_CART,
      ResourceModel.ASSET_PREVIEW_REQUEST,
    ],
    id: 'userAspectRatio',
    label: 'common.asset.user_media_data.aspect_ratio',
    sourceListKey: 'aspect_ratio',
    format: ValueFormat.SELECTOR,
    input: {
      objectPath: 'user_media_data.aspect_ratio',
      type: FieldType.SELECT,
      readonly: false,
      validators: [],
    },
  },
  imageAspect: {
    isModified: false,
    isCustomDefinition: false,
    resource: [
      ResourceModel.ASSET_BROWSE,
      ResourceModel.ASSET_ORPHAN,
      ResourceModel.LICENSED_PACKAGE,
      ResourceModel.ASSET_SHARED_PACK,
      ResourceModel.ASSET_ORDER,
      ResourceModel.ASSET_CART,
      ResourceModel.ASSET_PREVIEW_REQUEST,
    ],
    id: 'imageAspect',
    label: 'common.asset.user_media_data.image_aspect',
    sourceListKey: 'image_aspect',
    format: ValueFormat.SELECTOR,
    filters: {
      objectPath: 'image_aspect',
      aggregationKey: 'image_aspect',
      type: FilterType.SELECTOR,
      validFormat: 'keyword',
    },
    results2: {
      sortable: false,
      objectPath: 'props.image_aspect',
      sortObjectPath: 'image_aspect',
    },
    input: {
      objectPath: 'user_media_data.image_aspect',
      type: FieldType.SELECT,
      readonly: false,
      validators: [],
    },
  },
  framing: {
    isModified: false,
    isCustomDefinition: false,
    resource: [
      ResourceModel.ASSET_BROWSE,
      ResourceModel.ASSET_ORPHAN,
      ResourceModel.LICENSED_PACKAGE,
      ResourceModel.ASSET_SHARED_PACK,
      ResourceModel.ASSET_ORDER,
      ResourceModel.ASSET_CART,
      ResourceModel.ASSET_PREVIEW_REQUEST,
    ],
    id: 'framing',
    label: 'common.asset.user_media_data.framing',
    sourceListKey: 'framing',
    format: ValueFormat.SELECTOR,
    input: {
      objectPath: 'user_media_data.framing',
      type: FieldType.SELECT,
      readonly: false,
      validators: [],
    },
  },
  elements: {
    isModified: false,
    isCustomDefinition: false,
    resource: [
      ResourceModel.ASSET_BROWSE,
      ResourceModel.ASSET_ORPHAN,
      ResourceModel.LICENSED_PACKAGE,
      ResourceModel.ASSET_SHARED_PACK,
      ResourceModel.ASSET_ORDER,
      ResourceModel.ASSET_CART,
      ResourceModel.ASSET_PREVIEW_REQUEST,
    ],
    id: 'elements',
    results2: {
      sortable: true,
      exportObjectPath: 'elements',
      objectPath: 'props.elements',
      sortObjectPath: 'elements',
    },
    label: 'common.asset.user_media_data.elements',
    sourceListKey: 'elements',
    format: ValueFormat.AS_IS,
    input: {
      objectPath: 'user_media_data.elements',
      type: FieldType.SELECT,
      readonly: false,
      validators: [],
    },
    filters: {
      objectPath: 'elements',
      aggregationKey: 'elements',
      type: FilterType.SELECTOR,
      validFormat: 'keyword',
    },
  },
  bars: {
    isModified: false,
    isCustomDefinition: false,
    resource: [
      ResourceModel.ASSET_BROWSE,
      ResourceModel.ASSET_ORPHAN,
      ResourceModel.LICENSED_PACKAGE,
      ResourceModel.ASSET_SHARED_PACK,
      ResourceModel.ASSET_ORDER,
      ResourceModel.ASSET_CART,
      ResourceModel.ASSET_PREVIEW_REQUEST,
    ],
    id: 'bars',
    label: 'common.asset.user_media_data.bars',
    format: ValueFormat.YES_NO,
    input: {
      objectPath: 'user_media_data.bars',
      type: FieldType.CHECKBOX,
      readonly: false,
      validators: [],
    },
  },
  clockSlate: {
    isModified: false,
    isCustomDefinition: false,
    resource: [
      ResourceModel.ASSET_BROWSE,
      ResourceModel.ASSET_ORPHAN,
      ResourceModel.LICENSED_PACKAGE,
      ResourceModel.ASSET_SHARED_PACK,
      ResourceModel.ASSET_ORDER,
      ResourceModel.ASSET_CART,
      ResourceModel.ASSET_PREVIEW_REQUEST,
    ],
    id: 'clockSlate',
    label: 'common.asset.user_media_data.clock_slate',
    format: ValueFormat.YES_NO,
    input: {
      objectPath: 'user_media_data.clock_slate',
      type: FieldType.CHECKBOX,
      readonly: false,
      validators: [],
    },
  },
  seamlessSegmented: {
    isModified: false,
    isCustomDefinition: false,
    resource: [
      ResourceModel.ASSET_BROWSE,
      ResourceModel.ASSET_ORPHAN,
      ResourceModel.LICENSED_PACKAGE,
      ResourceModel.ASSET_SHARED_PACK,
      ResourceModel.ASSET_ORDER,
      ResourceModel.ASSET_CART,
      ResourceModel.ASSET_PREVIEW_REQUEST,
    ],
    id: 'seamlessSegmented',
    label: 'common.asset.user_media_data.seamless_segmented',
    sourceListKey: 'seamless_segmented',
    format: ValueFormat.SELECTOR,
    input: {
      objectPath: 'user_media_data.seamless_segmented',
      type: FieldType.SELECT,
      readonly: false,
      validators: [],
    },
  },
  segment: {
    isModified: false,
    isCustomDefinition: false,
    resource: [
      ResourceModel.ASSET_BROWSE,
      ResourceModel.ASSET_ORPHAN,
      ResourceModel.LICENSED_PACKAGE,
      ResourceModel.ASSET_SHARED_PACK,
      ResourceModel.ASSET_ORDER,
      ResourceModel.ASSET_CART,
      ResourceModel.ASSET_PREVIEW_REQUEST,
    ],
    id: 'segment',
    results2: {
      exportObjectPath: 'segment',
      sortable: false,
      objectPath: 'props.segment',
    },
    label: 'common.global.segment',
    format: ValueFormat.UPPERCASE,
    sourceListKey: SelectorSourceType.SEAMLESS_SEGMENTED,
    filters: {
      objectPath: 'segments',
      aggregationKey: 'segments',
      type: FilterType.SELECTOR,
      validFormat: 'keyword',
    },
  },
  restoreStatus: {
    isModified: false,
    isCustomDefinition: false,
    resource: [ResourceModel.ASSET_ORDER, ResourceModel.ASSET_SHARED_PACK],
    id: 'restore_status',
    results2: {
      sortable: false,
      objectPath: 'restoreStatus',
      viewFormat: {
        pills: {
          'warming up': 'cold-blue',
          warm: 'failed',
        },
      },
    },
    label: 'common.global.restore_status',
    format: ValueFormat.DATE,
  },
  retention: {
    isModified: false,
    isCustomDefinition: false,
    resource: [
      ResourceModel.ASSET_BROWSE,
      ResourceModel.ASSET_ORPHAN,
      ResourceModel.LICENSED_PACKAGE,
      ResourceModel.ASSET_SHARED_PACK,
      ResourceModel.ASSET_ORDER,
      ResourceModel.ASSET_CART,
      ResourceModel.ASSET_PREVIEW_REQUEST,
    ],
    id: 'retention',
    label: 'common.asset.user_media_data.retention',
    sourceListKey: 'retention',
    format: ValueFormat.SELECTOR,
    input: {
      objectPath: 'user_media_data.retention',
      type: FieldType.SELECT,
      readonly: false,
      validators: [],
    },
  },
  coldOnly: {
    isModified: false,
    isCustomDefinition: false,
    resource: [
      ResourceModel.ASSET_BROWSE,
      ResourceModel.ASSET_ORPHAN,
      ResourceModel.LICENSED_PACKAGE,
      ResourceModel.ASSET_SHARED_PACK,
      ResourceModel.ASSET_ORDER,
      ResourceModel.ASSET_CART,
      ResourceModel.ASSET_PREVIEW_REQUEST,
    ],
    id: 'coldOnly',
    label: 'common.global.cold_only',
    sourceListKey: SelectorSourceType.YES_NO,
    format: ValueFormat.YES_NO,
    filters: {
      objectPath: SelectorSourceType.IS_COLD_ONLY,
      type: FilterType.SELECTOR,
      validFormat: 'keyword',
    },
    results2: {
      exportObjectPath: 'cold_only',
      objectPath: 'props.cold_only',
      sortable: true,
      sortObjectPath: 'cold_only',
      viewFormat: {
        modifiers: {
          yesNo: true,
        },
        pills: {
          default: '',
        },
      },
    },
  },
  assetMasterType: {
    isModified: false,
    isCustomDefinition: false,
    resource: [
      ResourceModel.ASSET_BROWSE,
      ResourceModel.ASSET_ORPHAN,
      ResourceModel.LICENSED_PACKAGE,
      ResourceModel.ASSET_SHARED_PACK,
      ResourceModel.ASSET_ORDER,
      ResourceModel.ASSET_CART,
      ResourceModel.ASSET_PREVIEW_REQUEST,
    ],
    id: 'assetMasterType',
    label: 'common.asset.general.type',
    format: ValueFormat.AS_IS,
    sourceListKey: SelectorSourceType.ASSET_MASTER_TYPE,
    filters: {
      objectPath: 'type',
      type: FilterType.CHECKBOX_LIST,
      validFormat: 'keyword',
      aggregationKey: 'type',
    },
  },
  assetType: {
    isModified: false,
    isCustomDefinition: false,
    resource: [
      ResourceModel.ASSET_BROWSE,
      ResourceModel.ASSET_ORPHAN,
      ResourceModel.LICENSED_PACKAGE,
      ResourceModel.ASSET_SHARED_PACK,
      ResourceModel.ASSET_ORDER,
      ResourceModel.ASSET_CART,
      ResourceModel.ASSET_PREVIEW_REQUEST,
    ],
    id: 'assetType',
    label: 'common.asset.general.type',
    format: ValueFormat.AS_IS,
    sourceListKey: SelectorSourceType.ASSET_MASTER_TYPE,
    input: {
      objectPath: 'general.type',
      type: FieldType.TEXT,
      readonly: true,
      validators: [],
    },
    filters: {
      objectPath: 'type',
      type: FilterType.CHECKBOX_LIST,
      validFormat: 'keyword',
      aggregationKey: 'type',
    },
  },
  mediaInfoType: {
    isModified: false,
    isCustomDefinition: false,
    resource: [
      ResourceModel.ASSET_BROWSE,
      ResourceModel.ASSET_ORPHAN,
      ResourceModel.LICENSED_PACKAGE,
      ResourceModel.ASSET_SHARED_PACK,
      ResourceModel.ASSET_ORDER,
      ResourceModel.ASSET_CART,
      ResourceModel.ASSET_PREVIEW_REQUEST,
    ],
    id: 'mediaInfoType',
    label: 'common.asset.additional.type',
    sourceListKey: 'asset_type',
    format: ValueFormat.SELECTOR,
    input: {
      // no 'type' key in MediaInfo interface
      // objectPath: 'mediainfo.type',
      objectPath: 'additional.type',
      type: FieldType.SELECT,
      readonly: true,
      validators: [],
    },
  },
  mediaInfoSubtype: {
    isModified: false,
    isCustomDefinition: false,
    resource: [
      ResourceModel.ASSET_BROWSE,
      ResourceModel.ASSET_ORPHAN,
      ResourceModel.LICENSED_PACKAGE,
      ResourceModel.ASSET_SHARED_PACK,
      ResourceModel.ASSET_ORDER,
      ResourceModel.ASSET_CART,
      ResourceModel.ASSET_PREVIEW_REQUEST,
    ],
    id: 'mediaInfoSubtype',
    label: 'common.asset.additional.subtype',
    sourceListKey: 'sub_type',
    format: ValueFormat.SELECTOR,
    input: {
      // no 'subtype' key in MediaInfo interface
      // objectPath: 'mediainfo.subtype',
      objectPath: 'additional.subtype',
      type: FieldType.SELECT,
      readonly: true,
      validators: [],
    },
  },
  disposition: {
    isModified: false,
    isCustomDefinition: false,
    resource: [
      ResourceModel.ASSET_BROWSE,
      ResourceModel.ASSET_ORPHAN,
      ResourceModel.LICENSED_PACKAGE,
      ResourceModel.ASSET_SHARED_PACK,
      ResourceModel.ASSET_ORDER,
      ResourceModel.ASSET_CART,
      ResourceModel.ASSET_PREVIEW_REQUEST,
    ],
    id: 'mediaInfoDisposition',
    results2: {
      exportObjectPath: 'disposition',
      sortable: true,
      objectPath: 'props.disposition',
      sortObjectPath: 'disposition',
    },
    label: 'common.asset.additional.disposition',
    sourceListKey: 'disposition',
    format: ValueFormat.AS_IS,
    input: {
      objectPath: 'additional.disposition',
      type: FieldType.SELECT,
      readonly: false,
      validators: [],
    },
    filters: {
      type: FilterType.CHECKBOX_LIST,
      validFormat: 'keyword',
      objectPath: 'disposition',
      aggregationKey: 'disposition',
    },
  },
  physicalVersionTitle: {
    isModified: false,
    isCustomDefinition: false,
    resource: [
      ResourceModel.ASSET_BROWSE,
      ResourceModel.ASSET_ORPHAN,
      ResourceModel.LICENSED_PACKAGE,
      ResourceModel.ASSET_SHARED_PACK,
      ResourceModel.ASSET_ORDER,
      ResourceModel.ASSET_CART,
      ResourceModel.ASSET_PREVIEW_REQUEST,
    ],
    id: 'physicalVersionTitle',
    label: 'common.asset.mediaset.physical_version_title',
    format: ValueFormat.AS_IS,
    input: {
      objectPath: 'mediaset.physical_version_title',
      type: FieldType.TEXT,
      readonly: false,
      validators: [],
    },
    results2: {
      exportObjectPath: 'physical_version_title',
      sortable: false,
      objectPath: 'props.physical_version_title',
    },
    filters: {
      objectPath: 'physical_version_title',
      type: FilterType.TEXT,
      validFormat: 'keyword',
    },
  },
  physicalVersionDescription: {
    isModified: false,
    isCustomDefinition: false,
    resource: [
      ResourceModel.ASSET_BROWSE,
      ResourceModel.ASSET_ORPHAN,
      ResourceModel.LICENSED_PACKAGE,
      ResourceModel.ASSET_SHARED_PACK,
      ResourceModel.ASSET_ORDER,
      ResourceModel.ASSET_CART,
      ResourceModel.ASSET_PREVIEW_REQUEST,
    ],
    id: 'physicalVersionDescription',
    label: 'common.asset.mediaset.physical_version_description',
    format: ValueFormat.AS_IS,
    input: {
      objectPath: 'mediaset.physical_version_description',
      type: FieldType.TEXT,
      readonly: false,
      validators: [],
    },
    results2: {
      exportObjectPath: 'physical_version_description',
      sortable: false,
      objectPath: 'props.physical_version_description',
    },
    filters: {
      objectPath: 'physical_version_description',
      type: FilterType.TEXT,
      validFormat: 'keyword',
    },
  },
  parentMediaId: {
    isModified: false,
    isCustomDefinition: false,
    resource: [
      ResourceModel.ASSET_BROWSE,
      ResourceModel.ASSET_ORPHAN,
      ResourceModel.LICENSED_PACKAGE,
      ResourceModel.ASSET_SHARED_PACK,
      ResourceModel.ASSET_ORDER,
      ResourceModel.ASSET_CART,
      ResourceModel.ASSET_PREVIEW_REQUEST,
    ],
    id: 'parentMediaId',
    label: 'common.asset.mediaset.parent_media_id',
    format: ValueFormat.AS_IS,
    input: {
      objectPath: 'mediaset.parent_media_id',
      type: FieldType.TEXT,
      readonly: false,
      validators: [],
    },
  },
  relationship: {
    isModified: false,
    isCustomDefinition: false,
    resource: [
      ResourceModel.ASSET_BROWSE,
      ResourceModel.ASSET_ORPHAN,
      ResourceModel.LICENSED_PACKAGE,
      ResourceModel.ASSET_SHARED_PACK,
      ResourceModel.ASSET_ORDER,
      ResourceModel.ASSET_CART,
      ResourceModel.ASSET_PREVIEW_REQUEST,
    ],
    id: 'relationship',
    label: 'common.asset.mediaset.relationship',
    sourceListKey: 'relationship',
    format: ValueFormat.SELECTOR,
    input: {
      objectPath: 'mediaset.relationship',
      type: FieldType.SELECT,
      readonly: false,
      validators: [],
    },
  },
  description: {
    isModified: false,
    isCustomDefinition: false,
    resource: [
      ResourceModel.ASSET_BROWSE,
      ResourceModel.ASSET_ORPHAN,
      ResourceModel.LICENSED_PACKAGE,
      ResourceModel.ASSET_SHARED_PACK,
      ResourceModel.ASSET_ORDER,
      ResourceModel.ASSET_CART,
      ResourceModel.ASSET_PREVIEW_REQUEST,
    ],
    id: 'description',
    label: 'common.asset.additional.description',
    format: ValueFormat.AS_IS,
    input: {
      objectPath: 'additional.description',
      type: FieldType.TEXTAREA,
      readonly: false,
      validators: [],
    },
    results2: {
      sortable: false,
      exportObjectPath: 'description',
      objectPath: 'props.description',
      sortObjectPath: 'description',
      viewFormat: {
        modifiers: {
          truncateCharacters: 80,
        },
      },
    },
  },
  synopsis: {
    isModified: false,
    isCustomDefinition: false,
    resource: [
      ResourceModel.ASSET_BROWSE,
      ResourceModel.ASSET_ORPHAN,
      ResourceModel.LICENSED_PACKAGE,
      ResourceModel.ASSET_SHARED_PACK,
      ResourceModel.ASSET_ORDER,
      ResourceModel.ASSET_CART,
      ResourceModel.ASSET_PREVIEW_REQUEST,
    ],
    id: 'synopsis',
    results2: {
      sortable: false,
      exportObjectPath: 'synopsis',
      objectPath: 'props.synopsis',
      viewFormat: {
        modifiers: {
          truncateCharacters: 80,
        },
      },
    },
    label: 'common.asset.additional.synopsis',
    format: ValueFormat.AS_IS,
    input: {
      objectPath: 'additional.synopsis',
      type: FieldType.TEXTAREA,
      readonly: false,
      validators: [],
    },
  },
  synopsis_short: {
    isModified: false,
    isCustomDefinition: false,
    resource: [
      ResourceModel.ASSET_BROWSE,
      ResourceModel.ASSET_ORPHAN,
      ResourceModel.LICENSED_PACKAGE,
      ResourceModel.ASSET_SHARED_PACK,
      ResourceModel.ASSET_ORDER,
      ResourceModel.ASSET_CART,
      ResourceModel.ASSET_PREVIEW_REQUEST,
    ],
    id: 'synopsisShort',
    results2: {
      exportObjectPath: 'synopsis_short',
      sortable: false,
      objectPath: 'props.synopsis_short',
      viewFormat: {
        modifiers: {
          truncateCharacters: 80,
        },
      },
    },
    label: 'common.asset.additional.synopsis_short',
    format: ValueFormat.AS_IS,
    input: {
      objectPath: 'additional.synopsis_short',
      type: FieldType.TEXTAREA,
      readonly: false,
      validators: [],
    },
  },
  aiSynopsis: {
    isModified: false,
    isCustomDefinition: false,
    resource: [
      ResourceModel.ASSET_BROWSE,
      ResourceModel.ASSET_ORPHAN,
      ResourceModel.LICENSED_PACKAGE,
      ResourceModel.ASSET_SHARED_PACK,
      ResourceModel.ASSET_ORDER,
      ResourceModel.ASSET_CART,
      ResourceModel.ASSET_PREVIEW_REQUEST,
    ],
    id: 'aiSynopsis',
    label: 'common.asset.additional.synopsis',
    format: ValueFormat.AS_IS,
    input: {
      objectPath: 'additional.synopsis',
      type: FieldType.AI_TRANSCODE_SUMMARY,
      readonly: false,
      validators: [],
    },
  },
  genre: {
    isModified: false,
    isCustomDefinition: false,
    resource: [
      ResourceModel.ASSET_BROWSE,
      ResourceModel.ASSET_ORPHAN,
      ResourceModel.LICENSED_PACKAGE,
      ResourceModel.ASSET_SHARED_PACK,
      ResourceModel.ASSET_ORDER,
      ResourceModel.ASSET_CART,
      ResourceModel.ASSET_PREVIEW_REQUEST,
    ],
    id: 'genre',
    label: 'common.asset.additional.genre',
    sourceListKey: 'genre',
    format: ValueFormat.SELECTOR,
    input: {
      objectPath: 'additional.genre',
      type: FieldType.SELECT,
      readonly: false,
      validators: [],
    },
    results2: {
      exportObjectPath: 'genre',
      sortable: false,
      objectPath: 'props.genre',
    },
    filters: {
      objectPath: 'genre',
      aggregationKey: 'genre',
      validFormat: 'keyword',
      type: FilterType.SELECTOR,
    },
  },
  note: {
    isModified: false,
    isCustomDefinition: false,
    resource: [
      ResourceModel.ASSET_BROWSE,
      ResourceModel.ASSET_ORPHAN,
      ResourceModel.LICENSED_PACKAGE,
      ResourceModel.ASSET_SHARED_PACK,
      ResourceModel.ASSET_ORDER,
      ResourceModel.ASSET_CART,
      ResourceModel.ASSET_PREVIEW_REQUEST,
    ],
    id: 'note',
    label: 'common.asset.additional.note',
    format: ValueFormat.AS_IS,
    input: {
      objectPath: 'additional.note',
      type: FieldType.TEXT,
      readonly: false,
      validators: [],
    },
    filters: {
      objectPath: 'note',
      validFormat: 'keyword',
      type: FilterType.TEXT,
    },
    results2: {
      objectPath: 'props.note',
      exportObjectPath: 'note',
      sortObjectPath: 'note',
      sortable: true,
      viewFormat: {
        modifiers: {
          truncateCharacters: 80,
        },
      },
    },
  },
  tapeNo: {
    isModified: false,
    isCustomDefinition: false,
    resource: [
      ResourceModel.ASSET_BROWSE,
      ResourceModel.ASSET_ORPHAN,
      ResourceModel.LICENSED_PACKAGE,
      ResourceModel.ASSET_SHARED_PACK,
      ResourceModel.ASSET_ORDER,
      ResourceModel.ASSET_CART,
      ResourceModel.ASSET_PREVIEW_REQUEST,
    ],
    id: 'tapeNo',
    results2: {
      sortable: true,
      objectPath: 'props.tape_no',
      sortObjectPath: 'tape_no',
    },
    label: 'common.asset.additional.tape_no',
    format: ValueFormat.AS_IS,
    input: {
      objectPath: 'additional.tape_no',
      type: FieldType.TEXT,
      readonly: false,
      validators: [],
    },
    filters: {
      objectPath: 'tape_no',
      validFormat: 'keyword',
      type: FilterType.CHIPS,
    },
    useAsFilename: true,
  },
  altNo: {
    isModified: false,
    isCustomDefinition: false,
    resource: [
      ResourceModel.ASSET_BROWSE,
      ResourceModel.ASSET_ORPHAN,
      ResourceModel.LICENSED_PACKAGE,
      ResourceModel.ASSET_SHARED_PACK,
      ResourceModel.ASSET_ORDER,
      ResourceModel.ASSET_CART,
      ResourceModel.ASSET_PREVIEW_REQUEST,
    ],
    id: 'altNo',
    label: 'common.asset.additional.alt_no',
    format: ValueFormat.AS_IS,
    input: {
      objectPath: 'additional.alt_no',
      type: FieldType.TEXT,
      readonly: false,
      validators: [],
    },
    results2: {
      sortable: true,
      objectPath: 'props.alt_no',
      exportObjectPath: 'alt_no',
      sortObjectPath: 'alt_no',
    },
    filters: {
      objectPath: 'alt_no',
      validFormat: 'keyword',
      type: FilterType.CHIPS,
    },
  },
  captions: {
    isModified: false,
    isCustomDefinition: false,
    resource: [
      ResourceModel.ASSET_BROWSE,
      ResourceModel.ASSET_ORPHAN,
      ResourceModel.LICENSED_PACKAGE,
      ResourceModel.ASSET_SHARED_PACK,
      ResourceModel.ASSET_ORDER,
      ResourceModel.ASSET_CART,
      ResourceModel.ASSET_PREVIEW_REQUEST,
    ],
    id: 'captions',
    label: 'common.asset.additional.captions',
    format: ValueFormat.YES_NO,
    input: {
      objectPath: 'additional.captions',
      type: FieldType.CHECKBOX,
      readonly: false,
      validators: [],
    },
  },
  subtitleLanguage: {
    isModified: false,
    isCustomDefinition: false,
    resource: [
      ResourceModel.ASSET_BROWSE,
      ResourceModel.ASSET_ORPHAN,
      ResourceModel.LICENSED_PACKAGE,
      ResourceModel.ASSET_SHARED_PACK,
      ResourceModel.ASSET_ORDER,
      ResourceModel.ASSET_CART,
      ResourceModel.ASSET_PREVIEW_REQUEST,
    ],
    id: 'subtitleLanguage',
    label: 'common.asset.additional.subtitle',
    format: ValueFormat.SELECTOR,
    sourceListKey: 'language',
    input: {
      objectPath: 'additional.subtitle',
      type: FieldType.SELECT,
      readonly: false,
      validators: [],
    },
  },
  partNo: {
    isModified: false,
    isCustomDefinition: false,
    resource: [
      ResourceModel.ASSET_BROWSE,
      ResourceModel.ASSET_ORPHAN,
      ResourceModel.LICENSED_PACKAGE,
      ResourceModel.ASSET_SHARED_PACK,
      ResourceModel.ASSET_ORDER,
      ResourceModel.ASSET_CART,
      ResourceModel.ASSET_PREVIEW_REQUEST,
    ],
    id: 'partNo',
    label: 'common.asset.additional.part_no',
    format: ValueFormat.AS_IS,
    input: {
      objectPath: 'additional.part_no',
      type: FieldType.TEXT,
      readonly: false,
      validators: [],
    },
  },
  partTotal: {
    isModified: false,
    isCustomDefinition: false,
    resource: [
      ResourceModel.ASSET_BROWSE,
      ResourceModel.ASSET_ORPHAN,
      ResourceModel.LICENSED_PACKAGE,
      ResourceModel.ASSET_SHARED_PACK,
      ResourceModel.ASSET_ORDER,
      ResourceModel.ASSET_CART,
      ResourceModel.ASSET_PREVIEW_REQUEST,
    ],
    id: 'partTotal',
    label: 'common.asset.additional.part_total',
    format: ValueFormat.AS_IS,
    input: {
      objectPath: 'additional.part_total',
      type: FieldType.TEXT,
      readonly: false,
      validators: [],
    },
  },
  productionNo: {
    isModified: false,
    isCustomDefinition: false,
    resource: [
      ResourceModel.ASSET_BROWSE,
      ResourceModel.ASSET_ORPHAN,
      ResourceModel.LICENSED_PACKAGE,
      ResourceModel.ASSET_SHARED_PACK,
      ResourceModel.ASSET_ORDER,
      ResourceModel.ASSET_CART,
      ResourceModel.ASSET_PREVIEW_REQUEST,
    ],
    id: 'productionNo',
    label: 'common.asset.additional.production_no',
    format: ValueFormat.AS_IS,
    input: {
      objectPath: 'additional.production_no',
      type: FieldType.TEXT,
      readonly: false,
      validators: [],
    },
  },
  productionCompany: {
    isModified: false,
    isCustomDefinition: false,
    resource: [
      ResourceModel.ASSET_BROWSE,
      ResourceModel.ASSET_ORPHAN,
      ResourceModel.LICENSED_PACKAGE,
      ResourceModel.ASSET_SHARED_PACK,
      ResourceModel.ASSET_ORDER,
      ResourceModel.ASSET_CART,
      ResourceModel.ASSET_PREVIEW_REQUEST,
    ],
    id: 'productionCompany',
    label: 'common.global.production_company',
    format: ValueFormat.AS_IS,
    sourceListKey: SelectorSourceType.PRODUCTION_COMPANY,
    input: {
      objectPath: 'additional.production_company',
      type: FieldType.TEXT,
      readonly: false,
      validators: [],
    },
    results2: {
      sortable: false,
      exportObjectPath: 'production_company',
      objectPath: 'props.production_company',
    },
    filters: {
      objectPath: 'production_company',
      validFormat: 'keyword',
      aggregationKey: 'production_company',
      type: FilterType.SELECTOR,
    },
  },
  shootingStartDate: {
    isModified: false,
    isCustomDefinition: false,
    resource: [
      ResourceModel.ASSET_BROWSE,
      ResourceModel.ASSET_ORPHAN,
      ResourceModel.LICENSED_PACKAGE,
      ResourceModel.ASSET_SHARED_PACK,
      ResourceModel.ASSET_ORDER,
      ResourceModel.ASSET_CART,
      ResourceModel.ASSET_PREVIEW_REQUEST,
    ],
    id: 'shootingStartDate',
    label: 'common.asset.additional.shooting_start_date',
    format: ValueFormat.DATE,
    input: {
      objectPath: 'additional.shooting_start_date',
      type: FieldType.DATE,
      readonly: false,
      validators: [],
    },
  },
  shootingEndDate: {
    isModified: false,
    isCustomDefinition: false,
    resource: [
      ResourceModel.ASSET_BROWSE,
      ResourceModel.ASSET_ORPHAN,
      ResourceModel.LICENSED_PACKAGE,
      ResourceModel.ASSET_SHARED_PACK,
      ResourceModel.ASSET_ORDER,
      ResourceModel.ASSET_CART,
      ResourceModel.ASSET_PREVIEW_REQUEST,
    ],
    id: 'shootingEndDate',
    label: 'common.asset.additional.shooting_end_date',
    format: ValueFormat.DATE,
    input: {
      objectPath: 'additional.shooting_end_date',
      type: FieldType.DATE,
      readonly: false,
      validators: [],
    },
  },
  starRating: {
    isModified: false,
    isCustomDefinition: false,
    resource: [
      ResourceModel.ASSET_BROWSE,
      ResourceModel.ASSET_ORPHAN,
      ResourceModel.LICENSED_PACKAGE,
      ResourceModel.ASSET_SHARED_PACK,
      ResourceModel.ASSET_ORDER,
      ResourceModel.ASSET_CART,
      ResourceModel.ASSET_PREVIEW_REQUEST,
    ],
    id: 'starRating',
    label: 'common.asset.additional.star_rating',
    format: ValueFormat.AS_IS,
    input: {
      objectPath: 'additional.star_rating',
      type: FieldType.TEXT,
      readonly: false,
      mask: '0000',
      validators: [],
    },
  },
  userPlayerOffset: {
    isModified: false,
    isCustomDefinition: false,
    resource: [
      ResourceModel.ASSET_BROWSE,
      ResourceModel.ASSET_ORPHAN,
      ResourceModel.LICENSED_PACKAGE,
      ResourceModel.ASSET_SHARED_PACK,
      ResourceModel.ASSET_ORDER,
      ResourceModel.ASSET_CART,
      ResourceModel.ASSET_PREVIEW_REQUEST,
    ],
    id: 'userPlayerOffset',
    label: 'common.asset.user_media_data.player_offset',
    format: ValueFormat.AS_IS,
    input: {
      objectPath: 'user_media_data.player_offset',
      type: FieldType.TIMECODE,
      readonly: false,
      validators: [],
    },
    results2: {
      sortable: false,
      exportObjectPath: 'playerOffset',
      objectPath: 'playerOffset',
    },
    filters: {
      objectPath: 'player_offset',
      validFormat: 'range',
      type: FilterType.TIMECODE_RANGE,
      aggregationKey: 'player_offset',
    },
  },
  metadataType: {
    isModified: false,
    isCustomDefinition: false,
    resource: [
      ResourceModel.ASSET_BROWSE,
      ResourceModel.ASSET_ORPHAN,
      ResourceModel.LICENSED_PACKAGE,
      ResourceModel.ASSET_SHARED_PACK,
      ResourceModel.ASSET_ORDER,
      ResourceModel.ASSET_CART,
      ResourceModel.ASSET_PREVIEW_REQUEST,
    ],
    id: 'metadataType',
    label: 'common.asset.additional.type',
    format: ValueFormat.AS_IS,
    input: {
      objectPath: 'additional.type',
      type: FieldType.TEXT,
      readonly: false,
      validators: [],
    },
  },
  metadataSubType: {
    isModified: false,
    isCustomDefinition: false,
    resource: [
      ResourceModel.ASSET_BROWSE,
      ResourceModel.ASSET_ORPHAN,
      ResourceModel.LICENSED_PACKAGE,
      ResourceModel.ASSET_SHARED_PACK,
      ResourceModel.ASSET_ORDER,
      ResourceModel.ASSET_CART,
      ResourceModel.ASSET_PREVIEW_REQUEST,
    ],
    id: 'metadataSubType',
    label: 'common.asset.additional.subtype',
    sourceListKey: 'sub_type',
    format: ValueFormat.AS_IS,
    input: {
      objectPath: 'additional.subtype',
      type: FieldType.TEXT,
      readonly: false,
      validators: [],
    },
    results2: {
      sortable: true,
      objectPath: 'props.metadata_sub_type',
      exportObjectPath: 'metadata_sub_type',
      sortObjectPath: 'metadata_sub_type',
    },
    filters: {
      aggregationKey: 'metadata_sub_type',
      objectPath: 'metadata_sub_type',
      type: FilterType.TEXT,
      validFormat: 'keyword',
    },
  },
  isPreviewAppEnabled: {
    isModified: false,
    isCustomDefinition: false,
    resource: [
      ResourceModel.ASSET_BROWSE,
      ResourceModel.ASSET_ORPHAN,
      ResourceModel.LICENSED_PACKAGE,
      ResourceModel.ASSET_SHARED_PACK,
      ResourceModel.ASSET_ORDER,
      ResourceModel.ASSET_CART,
      ResourceModel.ASSET_PREVIEW_REQUEST,
    ],
    id: 'isPreviewAppEnabled',
    label: 'common.asset.additional.is_preview_app_enabled',
    format: ValueFormat.YES_NO,
    sourceListKey: 'yes_no',
    input: {
      objectPath: 'additional.is_preview_app_enabled',
      type: FieldType.CHECKBOX,
      readonly: false,
      validators: [],
    },
    filters: {
      objectPath: 'is_preview_app_enabled',
      type: FilterType.SELECTOR,
      validFormat: 'keyword',
    },
  },
  contentClass: {
    isModified: false,
    isCustomDefinition: false,
    resource: [
      ResourceModel.ASSET_BROWSE,
      ResourceModel.ASSET_ORPHAN,
      ResourceModel.LICENSED_PACKAGE,
      ResourceModel.ASSET_SHARED_PACK,
      ResourceModel.ASSET_ORDER,
      ResourceModel.ASSET_CART,
      ResourceModel.ASSET_PREVIEW_REQUEST,
    ],
    id: 'contentClass',
    results2: {
      sortable: true,
      objectPath: 'props.content_class',
      exportObjectPath: 'content_class',
      sortObjectPath: 'content_class',
    },
    label: 'common.asset.additional.content_class',
    sourceListKey: 'content_class',
    format: ValueFormat.AS_IS,
    input: {
      objectPath: 'additional.content_class',
      type: FieldType.SELECT,
      readonly: false,
      validators: [],
    },
    filters: {
      objectPath: 'content_class',
      aggregationKey: 'content_class',
      type: FilterType.SELECTOR,
      validFormat: 'keyword',
    },
  },
  contentType: {
    isModified: false,
    isCustomDefinition: false,
    resource: [
      ResourceModel.ASSET_BROWSE,
      ResourceModel.ASSET_ORPHAN,
      ResourceModel.LICENSED_PACKAGE,
      ResourceModel.ASSET_SHARED_PACK,
      ResourceModel.ASSET_ORDER,
      ResourceModel.ASSET_CART,
      ResourceModel.ASSET_PREVIEW_REQUEST,
    ],
    id: 'contentType',
    results2: {
      sortable: true,
      exportObjectPath: 'content_type',
      objectPath: 'props.content_type',
      sortObjectPath: 'content_type',
    },
    label: 'common.asset.additional.content_type',
    sourceListKey: 'content_type',
    format: ValueFormat.AS_IS,
    input: {
      objectPath: 'additional.content_type',
      type: FieldType.SELECT,
      readonly: false,
      validators: [],
    },
    filters: {
      objectPath: 'content_type',
      aggregationKey: 'content_type',
      type: FilterType.SELECTOR,
      validFormat: 'keyword',
    },
  },
  theme: {
    isModified: false,
    isCustomDefinition: false,
    resource: [
      ResourceModel.ASSET_BROWSE,
      ResourceModel.ASSET_ORPHAN,
      ResourceModel.LICENSED_PACKAGE,
      ResourceModel.ASSET_SHARED_PACK,
      ResourceModel.ASSET_ORDER,
      ResourceModel.ASSET_CART,
      ResourceModel.ASSET_PREVIEW_REQUEST,
    ],
    id: 'theme',
    results2: {
      sortable: true,
      exportObjectPath: 'theme',
      objectPath: 'props.theme',
      sortObjectPath: 'theme',
    },
    label: 'common.asset.additional.theme',
    format: ValueFormat.AS_IS,
    sourceListKey: 'theme',
    input: {
      objectPath: 'additional.theme',
      type: FieldType.TEXT,
      readonly: false,
      validators: [],
    },
    filters: {
      objectPath: 'theme',
      aggregationKey: 'theme',
      type: FilterType.SELECTOR,
      validFormat: 'keyword',
    },
  },
  themePrefilled: {
    isModified: false,
    isCustomDefinition: false,
    resource: [
      ResourceModel.ASSET_BROWSE,
      ResourceModel.ASSET_ORPHAN,
      ResourceModel.LICENSED_PACKAGE,
      ResourceModel.ASSET_SHARED_PACK,
      ResourceModel.ASSET_ORDER,
      ResourceModel.ASSET_CART,
      ResourceModel.ASSET_PREVIEW_REQUEST,
    ],
    id: 'themePrefilled',
    label: 'common.asset.additional.theme',
    sourceListKey: 'theme',
    format: ValueFormat.SELECTOR,
    input: {
      objectPath: 'additional.theme',
      type: FieldType.SELECT,
      readonly: false,
      validators: [],
    },
    results2: {
      sortable: true,
      exportObjectPath: 'theme',
      objectPath: 'props.theme',
      sortObjectPath: 'theme',
    },
  },
  variation: {
    isModified: false,
    isCustomDefinition: false,
    resource: [
      ResourceModel.ASSET_BROWSE,
      ResourceModel.ASSET_ORPHAN,
      ResourceModel.LICENSED_PACKAGE,
      ResourceModel.ASSET_SHARED_PACK,
      ResourceModel.ASSET_ORDER,
      ResourceModel.ASSET_CART,
      ResourceModel.ASSET_PREVIEW_REQUEST,
    ],
    id: 'variation',
    results2: {
      sortable: true,
      exportObjectPath: 'variation',
      objectPath: 'props.variation',
      sortObjectPath: 'variation',
    },
    label: 'common.asset.additional.variation',
    format: ValueFormat.AS_IS,
    input: {
      objectPath: 'additional.variation',
      type: FieldType.TEXT,
      readonly: false,
      validators: [],
    },
    filters: {
      objectPath: 'variation',
      type: FilterType.SELECTOR,
      validFormat: 'keyword',
      aggregationKey: 'variation',
    },
  },
  category: {
    isModified: false,
    isCustomDefinition: false,
    resource: [
      ResourceModel.ASSET_BROWSE,
      ResourceModel.ASSET_ORPHAN,
      ResourceModel.LICENSED_PACKAGE,
      ResourceModel.ASSET_SHARED_PACK,
      ResourceModel.ASSET_ORDER,
      ResourceModel.ASSET_CART,
      ResourceModel.ASSET_PREVIEW_REQUEST,
    ],
    id: 'category',
    results2: {
      sortable: true,
      exportObjectPath: 'category',
      objectPath: 'props.category',
      sortObjectPath: 'category',
    },
    label: 'common.asset.additional.category',
    format: ValueFormat.AS_IS,
    input: {
      objectPath: 'additional.category',
      type: FieldType.TEXT,
      readonly: false,
      validators: [],
    },
    filters: {
      objectPath: 'category',
      type: FilterType.SELECTOR,
      validFormat: 'keyword',
      aggregationKey: 'category',
    },
  },
  version: {
    isModified: false,
    isCustomDefinition: false,
    resource: [
      ResourceModel.ASSET_BROWSE,
      ResourceModel.ASSET_ORPHAN,
      ResourceModel.LICENSED_PACKAGE,
      ResourceModel.ASSET_SHARED_PACK,
      ResourceModel.ASSET_ORDER,
      ResourceModel.ASSET_CART,
      ResourceModel.ASSET_PREVIEW_REQUEST,
    ],
    id: 'version',
    filters: {
      objectPath: 'version',
      type: FilterType.TEXT,
      validFormat: 'keyword',
    },
    results2: {
      sortable: true,
      exportObjectPath: 'version',
      objectPath: 'props.version',
      sortObjectPath: 'version',
    },
    label: 'common.asset.additional.version',
    format: ValueFormat.AS_IS,
    input: {
      objectPath: 'additional.version',
      type: FieldType.TEXT,
      readonly: false,
      validators: [],
    },
  },
  metadataFramerate: {
    isModified: false,
    isCustomDefinition: false,
    resource: [
      ResourceModel.ASSET_BROWSE,
      ResourceModel.ASSET_ORPHAN,
      ResourceModel.LICENSED_PACKAGE,
      ResourceModel.ASSET_SHARED_PACK,
      ResourceModel.ASSET_ORDER,
      ResourceModel.ASSET_CART,
      ResourceModel.ASSET_PREVIEW_REQUEST,
    ],
    id: 'metadataFramerate',
    label: 'common.asset.additional.framerate',
    format: ValueFormat.AS_IS,
    input: {
      objectPath: 'additional.framerate',
      type: FieldType.TEXT,
      readonly: false,
      validators: [],
    },
  },
  vidaNumber: {
    isModified: false,
    isCustomDefinition: false,
    resource: [
      ResourceModel.ASSET_BROWSE,
      ResourceModel.ASSET_ORPHAN,
      ResourceModel.LICENSED_PACKAGE,
      ResourceModel.ASSET_SHARED_PACK,
      ResourceModel.ASSET_ORDER,
      ResourceModel.ASSET_CART,
      ResourceModel.ASSET_PREVIEW_REQUEST,
    ],
    id: 'vidaNumber',
    results2: {
      exportObjectPath: 'vida_number',
      sortable: false,
      objectPath: 'props.vida_number',
      sortObjectPath: 'vida_number',
    },
    label: 'common.asset.additional.vida_number',
    format: ValueFormat.AS_IS,
    input: {
      objectPath: 'additional.vida_number',
      type: FieldType.TEXT,
      readonly: false,
      validators: [],
    },
    filters: {
      objectPath: 'vida_number',
      type: FilterType.SELECTOR,
      validFormat: 'keyword',
      aggregationKey: 'vida_number',
    },
  },
  themeCode: {
    isModified: false,
    isCustomDefinition: false,
    resource: [
      ResourceModel.ASSET_BROWSE,
      ResourceModel.ASSET_ORPHAN,
      ResourceModel.LICENSED_PACKAGE,
      ResourceModel.ASSET_SHARED_PACK,
      ResourceModel.ASSET_ORDER,
      ResourceModel.ASSET_CART,
      ResourceModel.ASSET_PREVIEW_REQUEST,
    ],
    id: 'themeCode',
    results2: {
      sortable: false,
      exportObjectPath: 'theme_code',
      objectPath: 'props.theme_code',
      sortObjectPath: 'theme_code',
    },
    label: 'common.asset.additional.theme_code',
    format: ValueFormat.AS_IS,
    input: {
      objectPath: 'additional.theme_code',
      type: FieldType.TEXT,
      readonly: false,
      validators: [],
    },
  },
  txDate: {
    isModified: false,
    isCustomDefinition: false,
    resource: [
      ResourceModel.ASSET_BROWSE,
      ResourceModel.ASSET_ORPHAN,
      ResourceModel.LICENSED_PACKAGE,
      ResourceModel.ASSET_SHARED_PACK,
      ResourceModel.ASSET_ORDER,
      ResourceModel.ASSET_CART,
      ResourceModel.ASSET_PREVIEW_REQUEST,
    ],
    id: 'txDate',
    results2: {
      exportObjectPath: 'tx_date',
      sortable: true,
      objectPath: 'props.tx_date',
      sortObjectPath: 'tx_date',
      viewFormat: {
        modifiers: {
          dateFormat: 'date',
          timeZoneSensitive: true,
        },
      },
    },
    label: 'common.asset.ext_title_info.tx_date',
    format: ValueFormat.TZ_DATE,
    input: {
      objectPath: 'ext_title_info.tx_date',
      type: FieldType.DATE,
      readonly: false,
      validators: [],
    },
    filters: {
      objectPath: 'tx_date',
      type: FilterType.DATEPICKER_RANGE,
      validFormat: 'range',
    },
  },
  woDesc: {
    id: 'woDesc',
    label: 'Title',
    format: ValueFormat.AS_IS,
    resource: [ResourceModel.ASSET_ORDER, ResourceModel.ASSET_SHARED_PACK],
    results2: {
      exportObjectPath: 'wo_desc',
      objectPath: 'props.wo_desc',
      sortObjectPath: 'wo_desc',
      sortable: true,
    },
  },
  woNo: {
    id: 'woNo',
    label: 'Order number',
    format: ValueFormat.AS_IS,
    resource: [ResourceModel.ASSET_ORDER, ResourceModel.ASSET_SHARED_PACK],
    results2: {
      exportObjectPath: 'wo_no',
      objectPath: 'props.wo_no',
      sortObjectPath: 'wo_no',
      sortable: true,
    },
  },
  accountRepDesc: {
    id: 'accountRepDesc',
    label: 'Account Manager',
    format: ValueFormat.AS_IS,
    resource: [ResourceModel.ASSET_ORDER, ResourceModel.ASSET_SHARED_PACK],
    results2: {
      exportObjectPath: 'account_rep_desc',
      objectPath: 'props.account_rep_desc',
      sortObjectPath: 'account_rep_desc',
      sortable: true,
    },
  },
  contact: {
    id: 'contact',
    label: 'Client Contact',
    format: ValueFormat.AS_IS,
    resource: [ResourceModel.ASSET_ORDER, ResourceModel.ASSET_SHARED_PACK],
    results2: {
      exportObjectPath: 'contact',
      objectPath: 'props.contact',
      sortObjectPath: 'contact',
      sortable: true,
    },
  },
  po: {
    id: 'po',
    label: 'PO',
    format: ValueFormat.AS_IS,
    resource: [ResourceModel.ASSET_ORDER, ResourceModel.ASSET_SHARED_PACK],
    results2: {
      exportObjectPath: 'po',
      objectPath: 'props.po',
      sortObjectPath: 'po',
      sortable: true,
    },
  },
  phaseCode: {
    id: 'phaseCode',
    label: 'Phase',
    format: ValueFormat.AS_IS,
    resource: [ResourceModel.ASSET_ORDER, ResourceModel.ASSET_SHARED_PACK],
    results2: {
      exportObjectPath: 'phase_code',
      objectPath: 'phase_code',
      sortObjectPath: 'phase_code',
      sortable: true,
    },
  },
  dateChanged: {
    id: 'dateChanged',
    label: 'Modified Date',
    format: ValueFormat.DATETIME,
    resource: [ResourceModel.ASSET_ORDER, ResourceModel.ASSET_SHARED_PACK],
    results2: {
      exportObjectPath: 'date_changed',
      objectPath: 'date_changed',
      sortObjectPath: 'date_changed',
      sortable: true,
    },
  },
  woEndDt: {
    id: 'woEndDt',
    label: 'Due date',
    format: ValueFormat.DATETIME,
    resource: [ResourceModel.ASSET_ORDER, ResourceModel.ASSET_SHARED_PACK],
    results2: {
      exportObjectPath: 'wo_end_dt',
      objectPath: 'wo_end_dt',
      sortObjectPath: 'wo_end_dt',
      sortable: true,
    },
  },
  deliveryStatus: {
    id: 'deliveryStatus',
    label: 'Delivery status',
    format: ValueFormat.AS_IS,
    resource: [ResourceModel.ASSET_ORDER, ResourceModel.ASSET_SHARED_PACK],
    results2: {
      exportObjectPath: 'delivery_status',
      objectPath: 'delivery_status',
      sortObjectPath: 'delivery_status',
      sortable: true,
    },
  },
  statusNo: {
    id: 'statusNo',
    label: 'Status',
    format: ValueFormat.AS_IS,
    resource: [ResourceModel.ASSET_ORDER, ResourceModel.ASSET_SHARED_PACK],
    results2: {
      exportObjectPath: 'status_no',
      objectPath: 'status_no',
      sortObjectPath: 'status_no',
      sortable: true,
    },
  },
  status: {
    id: 'status',
    label: 'Status',
    format: ValueFormat.AS_IS,
    resource: [ResourceModel.ASSET_ORDER, ResourceModel.ASSET_SHARED_PACK],
    results2: {
      exportObjectPath: 'status',
      objectPath: 'status',
      sortObjectPath: 'status',
      sortable: true,
    },
  },
  comment: {
    id: 'comment',
    label: 'Comment',
    format: ValueFormat.AS_IS,
    resource: [ResourceModel.ASSET_ORDER, ResourceModel.ASSET_SHARED_PACK],
    results2: {
      exportObjectPath: 'comment',
      objectPath: 'comment',
      sortObjectPath: 'comment',
      sortable: true,
    },
  },
  ingestDate: {
    isModified: false,
    isCustomDefinition: false,
    resource: [
      ResourceModel.ASSET_BROWSE,
      ResourceModel.ASSET_ORPHAN,
      ResourceModel.LICENSED_PACKAGE,
      ResourceModel.ASSET_SHARED_PACK,
      ResourceModel.ASSET_ORDER,
      ResourceModel.ASSET_CART,
      ResourceModel.ASSET_PREVIEW_REQUEST,
    ],
    id: 'ingestDate',
    label: 'common.global.ingest_date',
    format: ValueFormat.DATE,
    filters: {
      objectPath: 'ingest_date',
      type: FilterType.DATEPICKER_RANGE,
      validFormat: 'range',
    },
  },
  librarySalesRights: {
    isModified: false,
    isCustomDefinition: false,
    resource: [
      ResourceModel.ASSET_BROWSE,
      ResourceModel.ASSET_ORPHAN,
      ResourceModel.LICENSED_PACKAGE,
      ResourceModel.ASSET_SHARED_PACK,
      ResourceModel.ASSET_ORDER,
      ResourceModel.ASSET_CART,
      ResourceModel.ASSET_PREVIEW_REQUEST,
    ],
    id: 'librarySalesRights',
    sourceListKey: SelectorSourceType.YES_NO,
    results2: {
      exportObjectPath: 'library_sales_rights',
      sortable: false,
      objectPath: 'props.library_sales_rights',
      viewFormat: {
        modifiers: {
          yesNo: true,
        },
      },
    },
    label: 'common.asset.ext_title_info.library_sales_rights',
    format: ValueFormat.YES_NO,
    input: {
      objectPath: 'ext_title_info.library_sales_rights',
      type: FieldType.CHECKBOX,
      readonly: false,
      validators: [],
    },
    filters: {
      objectPath: 'library_sales_rights',
      aggregationKey: '',
      validFormat: 'keyword',
      type: FilterType.SELECTOR,
    },
  },
  catalogItemParentUuid: {
    isModified: false,
    isCustomDefinition: false,
    resource: [
      ResourceModel.ASSET_BROWSE,
      ResourceModel.ASSET_ORPHAN,
      ResourceModel.LICENSED_PACKAGE,
      ResourceModel.ASSET_SHARED_PACK,
      ResourceModel.ASSET_ORDER,
      ResourceModel.ASSET_CART,
      ResourceModel.ASSET_PREVIEW_REQUEST,
    ],
    id: 'catalogItemParentUuid',
    results2: {
      exportObjectPath: 'catalog_item_parent_uuid',
      sortable: false,
      objectPath: 'props.catalog_item_parent_uuid',
      viewFormat: {
        modifiers: {
          yesNo: true,
        },
      },
    },
    label: 'common.asset.general.is_library_attached',
    format: ValueFormat.YES_NO,
  },
  seasonTitle: {
    isModified: false,
    isCustomDefinition: false,
    resource: [
      ResourceModel.ASSET_BROWSE,
      ResourceModel.ASSET_ORPHAN,
      ResourceModel.LICENSED_PACKAGE,
      ResourceModel.ASSET_SHARED_PACK,
      ResourceModel.ASSET_ORDER,
      ResourceModel.ASSET_CART,
      ResourceModel.ASSET_PREVIEW_REQUEST,
    ],
    id: 'seasonTitle',
    label: 'common.asset.ext_title_info.season_title',
    format: ValueFormat.AS_IS,
    input: {
      objectPath: 'ext_title_info.season_title',
      type: FieldType.TEXT,
      readonly: false,
      validators: [],
    },
    results2: {
      exportObjectPath: 'season_title',
      objectPath: 'props.season_title',
      sortable: false,
    },
    filters: {
      objectPath: 'season_title',
      aggregationKey: 'season_title',
      validFormat: 'keyword',
      type: FilterType.SELECTOR,
    },
  },
  seasonTitlePrefilled: {
    isModified: false,
    isCustomDefinition: false,
    resource: [
      ResourceModel.ASSET_BROWSE,
      ResourceModel.ASSET_ORPHAN,
      ResourceModel.LICENSED_PACKAGE,
      ResourceModel.ASSET_SHARED_PACK,
      ResourceModel.ASSET_ORDER,
      ResourceModel.ASSET_CART,
      ResourceModel.ASSET_PREVIEW_REQUEST,
    ],
    id: 'seasonTitlePrefilled',
    label: 'common.asset.ext_title_info.season_title',
    sourceListKey: 'season_title',
    format: ValueFormat.SELECTOR,
    input: {
      objectPath: 'ext_title_info.season_title',
      type: FieldType.SELECT,
      readonly: false,
      validators: [],
    },
  },
  eidrNumber: {
    isModified: false,
    isCustomDefinition: false,
    resource: [
      ResourceModel.ASSET_BROWSE,
      ResourceModel.ASSET_ORPHAN,
      ResourceModel.LICENSED_PACKAGE,
      ResourceModel.ASSET_SHARED_PACK,
      ResourceModel.ASSET_ORDER,
      ResourceModel.ASSET_CART,
      ResourceModel.ASSET_PREVIEW_REQUEST,
    ],
    id: 'eidrNumber',
    label: 'common.asset.ext_title_info.eidr_number',
    format: ValueFormat.AS_IS,
    input: {
      objectPath: 'ext_title_info.eidr_number',
      type: FieldType.TEXT,
      readonly: false,
      validators: [],
    },
  },
  originalTitle: {
    isModified: false,
    isCustomDefinition: false,
    resource: [
      ResourceModel.ASSET_BROWSE,
      ResourceModel.ASSET_ORPHAN,
      ResourceModel.LICENSED_PACKAGE,
      ResourceModel.ASSET_SHARED_PACK,
      ResourceModel.ASSET_ORDER,
      ResourceModel.ASSET_CART,
      ResourceModel.ASSET_PREVIEW_REQUEST,
    ],
    id: 'originalTitle',
    label: 'common.asset.ext_title_info.original_title',
    format: ValueFormat.AS_IS,
    input: {
      objectPath: 'ext_title_info.original_title',
      type: FieldType.TEXT,
      readonly: false,
      validators: [],
    },
  },
  otherTitle: {
    isModified: false,
    isCustomDefinition: false,
    resource: [
      ResourceModel.ASSET_BROWSE,
      ResourceModel.ASSET_ORPHAN,
      ResourceModel.LICENSED_PACKAGE,
      ResourceModel.ASSET_SHARED_PACK,
      ResourceModel.ASSET_ORDER,
      ResourceModel.ASSET_CART,
      ResourceModel.ASSET_PREVIEW_REQUEST,
    ],
    id: 'otherTitle',
    label: 'common.asset.ext_title_info.other_title',
    format: ValueFormat.AS_IS,
    input: {
      objectPath: 'ext_title_info.other_title',
      type: FieldType.TEXT,
      readonly: false,
      validators: [],
    },
  },
  releaseYear: {
    isModified: false,
    isCustomDefinition: false,
    resource: [
      ResourceModel.ASSET_BROWSE,
      ResourceModel.ASSET_ORPHAN,
      ResourceModel.LICENSED_PACKAGE,
      ResourceModel.ASSET_SHARED_PACK,
      ResourceModel.ASSET_ORDER,
      ResourceModel.ASSET_CART,
      ResourceModel.ASSET_PREVIEW_REQUEST,
    ],
    id: 'releaseYear',
    label: 'common.asset.ext_title_info.release_year',
    format: ValueFormat.AS_IS,
    input: {
      objectPath: 'ext_title_info.release_year',
      type: FieldType.TEXT,
      readonly: false,
      mask: '0000',
      validators: [],
    },
    filters: {
      type: FilterType.SELECTOR,
      validFormat: 'keyword',
      objectPath: 'release_year',
      aggregationKey: 'release_year',
      mask: '0000',
    },
    results2: {
      exportObjectPath: 'release_year',
      objectPath: 'props.release_year',
      sortObjectPath: 'release_year',
      sortable: true,
    },
  },
  programmeSubtitle: {
    isModified: false,
    isCustomDefinition: false,
    resource: [
      ResourceModel.ASSET_BROWSE,
      ResourceModel.ASSET_ORPHAN,
      ResourceModel.LICENSED_PACKAGE,
      ResourceModel.ASSET_SHARED_PACK,
      ResourceModel.ASSET_ORDER,
      ResourceModel.ASSET_CART,
      ResourceModel.ASSET_PREVIEW_REQUEST,
    ],
    id: 'programmeSubtitle',
    label: 'common.asset.ext_title_info.programme_subtitle',
    format: ValueFormat.AS_IS,
    input: {
      objectPath: 'ext_title_info.programme_subtitle',
      type: FieldType.TEXT,
      readonly: false,
      validators: [],
    },
  },
  artist: {
    isModified: false,
    isCustomDefinition: false,
    resource: [
      ResourceModel.ASSET_BROWSE,
      ResourceModel.ASSET_ORPHAN,
      ResourceModel.LICENSED_PACKAGE,
      ResourceModel.ASSET_SHARED_PACK,
      ResourceModel.ASSET_ORDER,
      ResourceModel.ASSET_CART,
      ResourceModel.ASSET_PREVIEW_REQUEST,
    ],
    id: 'artist',
    label: 'common.asset.ext_asset_info.artist',
    format: ValueFormat.AS_IS,
    input: {
      objectPath: 'ext_asset_info.artist',
      type: FieldType.TEXT,
      readonly: false,
      validators: [],
    },
  },
  dynamicRange: {
    isModified: false,
    isCustomDefinition: false,
    resource: [
      ResourceModel.ASSET_BROWSE,
      ResourceModel.ASSET_ORPHAN,
      ResourceModel.LICENSED_PACKAGE,
      ResourceModel.ASSET_SHARED_PACK,
      ResourceModel.ASSET_ORDER,
      ResourceModel.ASSET_CART,
      ResourceModel.ASSET_PREVIEW_REQUEST,
    ],
    id: 'dynamicRange',
    label: 'common.asset.ext_asset_info.dynamic_range',
    sourceListKey: 'dynamic_range',
    format: ValueFormat.SELECTOR,
    input: {
      objectPath: 'ext_asset_info.dynamic_range',
      type: FieldType.SELECT,
      readonly: false,
      validators: [],
    },
  },
  inventoryType: {
    isModified: false,
    isCustomDefinition: false,
    resource: [
      ResourceModel.ASSET_BROWSE,
      ResourceModel.ASSET_ORPHAN,
      ResourceModel.LICENSED_PACKAGE,
      ResourceModel.ASSET_SHARED_PACK,
      ResourceModel.ASSET_ORDER,
      ResourceModel.ASSET_CART,
      ResourceModel.ASSET_PREVIEW_REQUEST,
    ],
    id: 'inventoryType',
    label: 'common.asset.additional.inventory_type',
    sourceListKey: 'inventory_type',
    format: ValueFormat.SELECTOR,
    input: {
      objectPath: 'additional.inventory_type',
      type: FieldType.SELECT,
      readonly: false,
      validators: [],
    },
    results2: {
      sortable: true,
      exportObjectPath: 'inventory_type',
      objectPath: 'props.inventory_type',
      sortObjectPath: 'inventory_type',
    },
    filters: {
      objectPath: 'inventory_type',
      aggregationKey: 'inventory_type',
      type: FilterType.SELECTOR,
      validFormat: 'keyword',
    },
  },
  sequenceNo: {
    isModified: false,
    isCustomDefinition: false,
    resource: [
      ResourceModel.ASSET_BROWSE,
      ResourceModel.ASSET_ORPHAN,
      ResourceModel.LICENSED_PACKAGE,
      ResourceModel.ASSET_SHARED_PACK,
      ResourceModel.ASSET_ORDER,
      ResourceModel.ASSET_CART,
      ResourceModel.ASSET_PREVIEW_REQUEST,
    ],
    id: 'sequenceNo',
    label: 'common.asset.ext_asset_info.sequence_no',
    format: ValueFormat.AS_IS,
    input: {
      objectPath: 'ext_asset_info.sequence_no',
      type: FieldType.TEXT,
      readonly: false,
      validators: [],
    },
  },
  orderNumber: {
    isModified: false,
    isCustomDefinition: false,
    resource: [
      ResourceModel.ASSET_BROWSE,
      ResourceModel.ASSET_ORPHAN,
      ResourceModel.LICENSED_PACKAGE,
      ResourceModel.ASSET_SHARED_PACK,
      ResourceModel.ASSET_ORDER,
      ResourceModel.ASSET_CART,
      ResourceModel.ASSET_PREVIEW_REQUEST,
    ],
    id: 'orderNumber',
    label: 'common.asset.ext_asset_info.order_number',
    format: ValueFormat.AS_IS,
    input: {
      objectPath: 'ext_asset_info.order_number',
      type: FieldType.TEXT,
      readonly: false,
      validators: [],
    },
  },
  barcodeField: {
    isModified: false,
    isCustomDefinition: false,
    resource: [
      ResourceModel.ASSET_BROWSE,
      ResourceModel.ASSET_ORPHAN,
      ResourceModel.LICENSED_PACKAGE,
      ResourceModel.ASSET_SHARED_PACK,
      ResourceModel.ASSET_ORDER,
      ResourceModel.ASSET_CART,
      ResourceModel.ASSET_PREVIEW_REQUEST,
    ],
    id: 'barcodeField',
    label: 'common.asset.ext_asset_info.barcode_field',
    format: ValueFormat.AS_IS,
    input: {
      objectPath: 'ext_asset_info.barcode_field',
      type: FieldType.TEXT,
      readonly: false,
      validators: [],
    },
  },
  origin: {
    isModified: false,
    isCustomDefinition: false,
    resource: [
      ResourceModel.ASSET_BROWSE,
      ResourceModel.ASSET_ORPHAN,
      ResourceModel.LICENSED_PACKAGE,
      ResourceModel.ASSET_SHARED_PACK,
      ResourceModel.ASSET_ORDER,
      ResourceModel.ASSET_CART,
      ResourceModel.ASSET_PREVIEW_REQUEST,
    ],
    id: 'origin',
    label: 'common.asset.ext_asset_info.origin',
    format: ValueFormat.AS_IS,
    input: {
      objectPath: 'ext_asset_info.origin',
      type: FieldType.TEXT,
      readonly: false,
      validators: [],
    },
    results2: {
      exportObjectPath: 'origin',
      objectPath: 'props.origin',
      sortable: false,
    },
    filters: {
      objectPath: 'origin',
      aggregationKey: 'origin',
      validFormat: 'keyword',
      type: FilterType.SELECTOR,
    },
  },
  audioTracks: {
    isModified: false,
    isCustomDefinition: false,
    resource: [
      ResourceModel.ASSET_BROWSE,
      ResourceModel.ASSET_ORPHAN,
      ResourceModel.LICENSED_PACKAGE,
      ResourceModel.ASSET_SHARED_PACK,
      ResourceModel.ASSET_ORDER,
      ResourceModel.ASSET_CART,
      ResourceModel.ASSET_PREVIEW_REQUEST,
    ],
    id: 'audioTracks',
    label: 'common.asset.audio',
    format: ValueFormat.AS_IS,
    sourceListKey: 'audio_tracks',
    input: {
      objectPath: 'audioTracks',
      type: FieldType.AUDIO_TRACK,
      readonly: false,
      validators: [],
    },
  },
  audioChannelCount: {
    isModified: false,
    isCustomDefinition: false,
    resource: [
      ResourceModel.ASSET_BROWSE,
      ResourceModel.ASSET_ORPHAN,
      ResourceModel.LICENSED_PACKAGE,
      ResourceModel.ASSET_SHARED_PACK,
      ResourceModel.ASSET_ORDER,
      ResourceModel.ASSET_CART,
      ResourceModel.ASSET_PREVIEW_REQUEST,
    ],
    id: 'audioChannelCount',
    label: 'common.global.audio_channel_count',
    format: ValueFormat.AS_IS,
    results2: {
      exportObjectPath: 'audio_channel_count',
      sortable: false,
      objectPath: 'props.audio_channel_count',
    },
  },
  audioTracksTable: {
    isModified: false,
    isCustomDefinition: false,
    resource: [
      ResourceModel.ASSET_BROWSE,
      ResourceModel.ASSET_ORPHAN,
      ResourceModel.LICENSED_PACKAGE,
      ResourceModel.ASSET_SHARED_PACK,
      ResourceModel.ASSET_ORDER,
      ResourceModel.ASSET_CART,
      ResourceModel.ASSET_PREVIEW_REQUEST,
    ],
    id: 'audioTracksTable',
    label: 'common.asset.audio_table',
    format: ValueFormat.VALUES_OF_OBJECT,
    results2: {
      exportObjectPath: 'audio_tracks',
      objectPath: 'audio_tracks',
      sortable: false,
      viewFormat: {
        maxVisibleValues: 2,
      },
    },
    sourceListKey: 'audio_tracks',
    input: {
      objectPath: 'audioTracks',
      type: FieldType.AUDIO_TRACK_TABLE,
      readonly: false,
      validators: [],
    },
  },
  audioTracksTedial: {
    isModified: false,
    isCustomDefinition: false,
    resource: [
      ResourceModel.ASSET_BROWSE,
      ResourceModel.ASSET_ORPHAN,
      ResourceModel.LICENSED_PACKAGE,
      ResourceModel.ASSET_SHARED_PACK,
      ResourceModel.ASSET_ORDER,
      ResourceModel.ASSET_CART,
      ResourceModel.ASSET_PREVIEW_REQUEST,
    ],
    id: 'audioTracksTedial',
    label: 'common.asset.audio_tedial',
    format: ValueFormat.AS_IS,
    input: {
      objectPath: 'audioTracks',
      type: FieldType.AUDIO_TRACK_TEDIAL,
      readonly: true,
      validators: [],
    },
  },
  rating: {
    isModified: false,
    isCustomDefinition: false,
    resource: [
      ResourceModel.ASSET_BROWSE,
      ResourceModel.ASSET_ORPHAN,
      ResourceModel.LICENSED_PACKAGE,
      ResourceModel.ASSET_SHARED_PACK,
      ResourceModel.ASSET_ORDER,
      ResourceModel.ASSET_CART,
      ResourceModel.ASSET_PREVIEW_REQUEST,
    ],
    id: 'rating',
    label: 'common.asset.general.rating_uuids',
    format: ValueFormat.SELECTOR_MULTI,
    sourceListKey: SelectorSourceType.ASSET_GENERAL_RATINGS,
    input: {
      objectPath: 'general.rating_uuids',
      type: FieldType.SELECT_GROUP,
      readonly: false,
      validators: [],
    },
    results2: {
      objectPath: 'ratingsSummary',
      sortable: false,
      viewFormat: {
        maxVisibleValues: 2,
      },
    },
    filters: {
      aggregationKey: 'ratings',
      type: FilterType.SELECTOR,
      validFormat: 'keyword',
      objectPath: 'ratings',
    },
  },
  multiversion: {
    isModified: false,
    isCustomDefinition: false,
    resource: [
      ResourceModel.ASSET_BROWSE,
      ResourceModel.ASSET_ORPHAN,
      ResourceModel.LICENSED_PACKAGE,
      ResourceModel.ASSET_SHARED_PACK,
      ResourceModel.ASSET_ORDER,
      ResourceModel.ASSET_CART,
      ResourceModel.ASSET_PREVIEW_REQUEST,
    ],
    id: 'multiversion',
    label: 'common.asset.additional.multiversion',
    format: ValueFormat.SELECTOR_MULTI,
    sourceListKey: 'multiversion',
    input: {
      objectPath: 'additional.multiversion',
      type: FieldType.SELECT_MULTI,
      readonly: false,
      validators: [],
    },
    results2: {
      exportObjectPath: 'multiversion',
      objectPath: 'props.multiversion',
      sortable: false,
    },
    filters: {
      aggregationKey: 'multiversion',
      type: FilterType.SELECTOR,
      validFormat: 'keyword',
      objectPath: 'multiversion',
    },
  },
  isDirty: {
    isModified: false,
    isCustomDefinition: false,
    resource: [
      ResourceModel.ASSET_BROWSE,
      ResourceModel.ASSET_ORPHAN,
      ResourceModel.LICENSED_PACKAGE,
      ResourceModel.ASSET_SHARED_PACK,
      ResourceModel.ASSET_ORDER,
      ResourceModel.ASSET_CART,
      ResourceModel.ASSET_PREVIEW_REQUEST,
    ],
    id: 'isDirty',
    label: 'common.global.is_dirty',
    sourceListKey: SelectorSourceType.IS_DIRTY,
    format: ValueFormat.SELECTOR,
    filters: {
      objectPath: 'is_dirty',
      type: FilterType.SELECTOR,
      validFormat: 'keyword',
    },
  },
  isOrphan: {
    isModified: false,
    isCustomDefinition: false,
    resource: [
      ResourceModel.ASSET_BROWSE,
      ResourceModel.ASSET_ORPHAN,
      ResourceModel.LICENSED_PACKAGE,
      ResourceModel.ASSET_SHARED_PACK,
      ResourceModel.ASSET_ORDER,
      ResourceModel.ASSET_CART,
      ResourceModel.ASSET_PREVIEW_REQUEST,
    ],
    id: 'isOrphan',
    label: 'common.global.orphan_filter',
    sourceListKey: SelectorSourceType.ORPHAN,
    format: ValueFormat.SELECTOR,
    filters: {
      objectPath: 'orphan_filter',
      type: FilterType.SELECTOR,
      validFormat: 'keyword',
    },
  },
  isPurged: {
    isModified: false,
    isCustomDefinition: false,
    resource: [
      ResourceModel.ASSET_BROWSE,
      ResourceModel.ASSET_ORPHAN,
      ResourceModel.LICENSED_PACKAGE,
      ResourceModel.ASSET_SHARED_PACK,
      ResourceModel.ASSET_ORDER,
      ResourceModel.ASSET_CART,
      ResourceModel.ASSET_PREVIEW_REQUEST,
    ],
    id: 'isPurged',
    label: 'common.global.purged_filter',
    format: ValueFormat.YES_NO,
    sourceListKey: 'is_purged',
    filters: {
      objectPath: SelectorSourceType.IS_PURGED,
      type: FilterType.SELECTOR,
      validFormat: 'keyword',
    },
  },
  deletedAt: {
    isModified: false,
    isCustomDefinition: false,
    resource: [ResourceModel.ASSET_DELETED, ResourceModel.ASSET_BROWSE],
    id: 'deletedAt',
    results2: {
      sortable: true,
      sortObjectPath: 'deleted_date',
      objectPath: 'props.deleted_at',
      viewFormat: {
        modifiers: {
          dateFormat: 'date-time',
        },
      },
    },
    label: 'common.asset.general.deleted_at',
    format: ValueFormat.DATE,
  },
  purgedAt: {
    isModified: false,
    isCustomDefinition: false,
    resource: [ResourceModel.ASSET_DELETED],
    id: 'purgedAt',
    results2: {
      sortable: true,
      sortObjectPath: 'purged_date',
      objectPath: 'props.deleted_at',
      viewFormat: {
        modifiers: {
          dateFormat: 'date-time',
        },
      },
    },
    label: 'common.asset.general.purged_at',
    format: ValueFormat.DATE,
  },
  deletedBy: {
    isModified: false,
    isCustomDefinition: false,
    resource: [ResourceModel.ASSET_DELETED],
    id: 'deletedBy',
    results2: {
      sortable: false,
      sortObjectPath: 'deleted_by',
      objectPath: 'props.deleted_by',
    },
    label: 'common.asset.general.deleted_by',
    format: ValueFormat.AS_IS,
  },
  deleteReason: {
    isModified: false,
    isCustomDefinition: false,
    resource: [ResourceModel.ASSET_DELETED],
    id: 'deleteReason',
    results2: {
      sortable: false,
      sortObjectPath: 'delete_reason',
      objectPath: 'props.delete_reason',
    },
    label: 'common.asset.general.delete_reason',
    format: ValueFormat.AS_IS,
  },
  deletionBillable: {
    isModified: false,
    isCustomDefinition: false,
    resource: [ResourceModel.ASSET_DELETED],
    id: 'deletionBillable',
    results2: {
      sortable: false,
      sortObjectPath: 'deletion_billable',
      objectPath: 'props.deletion_billable',
      viewFormat: {
        modifiers: {
          yesNo: true,
        },
      },
    },
    label: 'common.asset.general.deletion_billable',
    format: ValueFormat.YES_NO,
  },
  isDeleted: {
    isModified: false,
    isCustomDefinition: false,
    resource: [
      ResourceModel.ASSET_BROWSE,
      ResourceModel.ASSET_ORPHAN,
      ResourceModel.ASSET_DELETED,
      ResourceModel.LICENSED_PACKAGE,
      ResourceModel.ASSET_SHARED_PACK,
      ResourceModel.ASSET_ORDER,
      ResourceModel.ASSET_CART,
      ResourceModel.ASSET_PREVIEW_REQUEST,
    ],
    id: 'isDeleted',
    label: 'common.global.deleted_filter',
    sourceListKey: 'is_deleted',
    format: ValueFormat.YES_NO,
    filters: {
      objectPath: SelectorSourceType.IS_DELETED,
      type: FilterType.SELECTOR,
      validFormat: 'keyword',
    },
  },
  isQuarantined: {
    isModified: false,
    isCustomDefinition: false,
    resource: [
      ResourceModel.ASSET_BROWSE,
      ResourceModel.ASSET_ORPHAN,
      ResourceModel.LICENSED_PACKAGE,
      ResourceModel.ASSET_SHARED_PACK,
      ResourceModel.ASSET_ORDER,
      ResourceModel.ASSET_CART,
      ResourceModel.ASSET_PREVIEW_REQUEST,
    ],
    id: 'isQuarantined',
    label: 'common.global.quarantined_filter',
    sourceListKey: 'yes_no',
    format: ValueFormat.YES_NO,
    filters: {
      objectPath: SelectorSourceType.IS_QUARANTINED,
      type: FilterType.SELECTOR,
      validFormat: 'keyword',
    },
    results2: {
      exportObjectPath: 'is_quarantined',
      objectPath: 'props.is_quarantined',
      sortable: false,
      viewFormat: {
        pills: {
          true: PillColorEnum.Orange,
          false: PillColorEnum.Done,
        },
        modifiers: {
          yesNo: true,
        },
      },
    },
    input: {
      objectPath: 'general.is_quarantined',
      type: FieldType.CHECKBOX,
      readonly: false,
      validators: [],
    },
  },
  shotDescription: {
    isModified: false,
    isCustomDefinition: false,
    resource: [ResourceModel.ASSET_CART, ResourceModel.ASSET_ORDER, ResourceModel.ASSET_SHARED_PACK],
    id: 'shotDescription',
    label: 'common.global.short_description',
    format: ValueFormat.AS_IS,
    results2: {
      exportObjectPath: 'note',
      sortable: false,
      objectPath: 'context.note',
    },
  },
  stage: {
    resource: [
      ResourceModel.ASSET_BROWSE,
      ResourceModel.ASSET_ORPHAN,
      ResourceModel.LICENSED_PACKAGE,
      ResourceModel.ASSET_PREVIEW_REQUEST,
    ],
    id: 'stage',
    label: 'common.global.stage',
    sourceListKey: SelectorSourceType.STAGE,
    format: ValueFormat.AS_IS,
    filters: {
      objectPath: 'stage',
      type: FilterType.SELECTOR,
      validFormat: 'keyword',
    },
  },
  publishProgress: {
    isModified: false,
    isCustomDefinition: false,
    resource: [ResourceModel.ASSET_SHARED_PACK, ResourceModel.ASSET_ORDER],
    id: 'publishProgress',
    label: 'common.global.publish_progress',
    format: ValueFormat.AS_IS,
    results2: {
      objectPath: 'publishProgress',
      sortable: false,
      viewFormat: {
        modifiers: {
          asPercentage: true,
        },
      },
    },
  },
  publishStatus: {
    isModified: false,
    isCustomDefinition: false,
    resource: [ResourceModel.ASSET_SHARED_PACK, ResourceModel.ASSET_ORDER],
    id: 'publishStatus',
    label: 'common.global.publish_status',
    format: ValueFormat.AS_IS,
    results2: {
      objectPath: 'publishStatus',
      sortable: false,
    },
  },
  embargo: {
    isModified: false,
    isCustomDefinition: false,
    resource: [
      ResourceModel.ASSET_BROWSE,
      ResourceModel.ASSET_ORPHAN,
      ResourceModel.LICENSED_PACKAGE,
      ResourceModel.ASSET_SHARED_PACK,
      ResourceModel.ASSET_ORDER,
      ResourceModel.ASSET_CART,
      ResourceModel.ASSET_PREVIEW_REQUEST,
    ],
    id: 'embargo',
    label: 'common.global.embargo',
    format: ValueFormat.DATE_TIME_UNIX_TIMESTAMP_LIMITED,
    sourceListKey: SelectorSourceType.EMBARGO,
    filters: {
      aggregationKey: 'general.embargo',
      objectPath: 'embargoed_to',
      type: FilterType.CHECKBOX_LIST,
      validFormat: 'keyword',
    },
    results2: {
      exportObjectPath: 'embargo',
      objectPath: 'embargo',
      sortable: false,
      viewFormat: {
        pills: {
          future: 'orange',
          'embargo infinity': 'orange',
        },
        modifiers: {
          dateFormat: 'date-time',
        },
      },
    },
    input: {
      objectPath: 'general.embargoed_to',
      type: FieldType.DATE_TIME_UNIX_TIMESTAMP_LIMITED,
      readonly: false,
      validators: [],
      writePermission: {
        permissions: [Permission.EDIT_EMBARGO_DATES],
        comparator: PermissionsComparators.EVERY,
      },
      readPermission: {
        permissions: [Permission.BROWSE_ASSETS_EMBARGOED, Permission.EDIT_EMBARGO_DATES],
        comparator: PermissionsComparators.SOME,
      },
    },
  },
  md5Hash: {
    isModified: false,
    isCustomDefinition: false,
    resource: [
      ResourceModel.ASSET_BROWSE,
      ResourceModel.ASSET_ORPHAN,
      ResourceModel.LICENSED_PACKAGE,
      ResourceModel.ASSET_SHARED_PACK,
      ResourceModel.ASSET_ORDER,
      ResourceModel.ASSET_CART,
      ResourceModel.ASSET_PREVIEW_REQUEST,
    ],
    id: 'md5Hash',
    label: 'common.asset.general.md5_hash',
    format: ValueFormat.AS_IS,
    input: {
      objectPath: 'general.md5.hash',
      type: FieldType.TEXT,
      readonly: true,
      validators: [],
    },
    results2: {
      exportObjectPath: 'md5.hash',
      objectPath: 'props.md5.hash',
      sortable: false,
    },
  },
  legacyIngestDate: {
    isModified: false,
    isCustomDefinition: false,
    resource: [
      ResourceModel.ASSET_BROWSE,
      ResourceModel.ASSET_ORDER,
      ResourceModel.ASSET_SHARED_PACK,
      ResourceModel.ASSET_CART,
    ],
    id: 'legacyIngestDate',
    label: 'common.asset.additional.legacy_ingest_date',
    format: ValueFormat.DATETIME,
    results2: {
      exportObjectPath: 'legacy_ingest_date',
      objectPath: 'props.legacy_ingest_date',
      viewFormat: {
        modifiers: {
          dateFormat: 'date-time',
        },
      },
      sortable: false,
    },
    input: {
      objectPath: 'additional.legacy_ingest_date',
      type: FieldType.DATE,
      readonly: false,
      validators: [],
    },
  },
  md5Date: {
    isModified: false,
    isCustomDefinition: false,
    resource: [
      ResourceModel.ASSET_BROWSE,
      ResourceModel.ASSET_ORPHAN,
      ResourceModel.LICENSED_PACKAGE,
      ResourceModel.ASSET_SHARED_PACK,
      ResourceModel.ASSET_ORDER,
      ResourceModel.ASSET_CART,
      ResourceModel.ASSET_PREVIEW_REQUEST,
    ],
    id: 'md5Date',
    label: 'common.asset.general.md5_date',
    format: ValueFormat.DATETIME,
    input: {
      objectPath: 'general.md5.date',
      type: FieldType.TEXT,
      readonly: true,
      validators: [],
    },
    results2: {
      exportObjectPath: 'md5.date',
      objectPath: 'props.md5.date',
      viewFormat: {
        modifiers: {
          dateFormat: 'date-time',
        },
      },
      sortable: false,
    },
  },
  blackBordersType: {
    isModified: false,
    isCustomDefinition: false,
    resource: [
      ResourceModel.ASSET_BROWSE,
      ResourceModel.ASSET_SHARED_PACK,
      ResourceModel.ASSET_ORDER,
      ResourceModel.ASSET_CART,
      ResourceModel.ASSET_PREVIEW_REQUEST,
    ],
    id: 'blackBordersType',
    label: 'common.asset.file_metadata.video.screen_size.black_borders.analysis_type',
    format: ValueFormat.AS_IS,
    input: {
      objectPath: 'file_metadata.video.screen_size.black_borders.analysis_type',
      type: FieldType.TEXT,
      readonly: true,
      validators: [],
    },
    results2: {
      exportObjectPath: 'black_borders.analysis_type',
      objectPath: 'props.black_borders.analysis_type',
      sortable: false,
    },
  },
  blackBordersLeft: {
    isModified: false,
    isCustomDefinition: false,
    resource: [
      ResourceModel.ASSET_BROWSE,
      ResourceModel.ASSET_SHARED_PACK,
      ResourceModel.ASSET_ORDER,
      ResourceModel.ASSET_CART,
      ResourceModel.ASSET_PREVIEW_REQUEST,
    ],
    id: 'blackBordersLeft',
    label: 'common.asset.file_metadata.video.screen_size.black_borders.left',
    format: ValueFormat.AS_IS,
    input: {
      objectPath: 'file_metadata.video.screen_size.black_borders.left',
      type: FieldType.TEXT,
      readonly: true,
      validators: [],
    },
    results2: {
      exportObjectPath: 'black_borders.left',
      objectPath: 'props.black_borders.left',
      sortable: false,
    },
  },
  blackBordersRight: {
    isModified: false,
    isCustomDefinition: false,
    resource: [
      ResourceModel.ASSET_BROWSE,
      ResourceModel.ASSET_SHARED_PACK,
      ResourceModel.ASSET_ORDER,
      ResourceModel.ASSET_CART,
      ResourceModel.ASSET_PREVIEW_REQUEST,
    ],
    id: 'blackBordersRight',
    label: 'common.asset.file_metadata.video.screen_size.black_borders.right',
    format: ValueFormat.AS_IS,
    input: {
      objectPath: 'file_metadata.video.screen_size.black_borders.right',
      type: FieldType.TEXT,
      readonly: true,
      validators: [],
    },
    results2: {
      exportObjectPath: 'black_borders.right',
      objectPath: 'props.black_borders.right',
      sortable: false,
    },
  },
  blackBordersTop: {
    isModified: false,
    isCustomDefinition: false,
    resource: [
      ResourceModel.ASSET_BROWSE,
      ResourceModel.ASSET_SHARED_PACK,
      ResourceModel.ASSET_ORDER,
      ResourceModel.ASSET_CART,
      ResourceModel.ASSET_PREVIEW_REQUEST,
    ],
    id: 'blackBordersTop',
    label: 'common.asset.file_metadata.video.screen_size.black_borders.top',
    format: ValueFormat.AS_IS,
    input: {
      objectPath: 'file_metadata.video.screen_size.black_borders.top',
      type: FieldType.TEXT,
      readonly: true,
      validators: [],
    },
    results2: {
      exportObjectPath: 'black_borders.top',
      objectPath: 'props.black_borders.top',
      sortable: false,
    },
  },
  blackBordersBottom: {
    isModified: false,
    isCustomDefinition: false,
    resource: [
      ResourceModel.ASSET_BROWSE,
      ResourceModel.ASSET_SHARED_PACK,
      ResourceModel.ASSET_ORDER,
      ResourceModel.ASSET_CART,
      ResourceModel.ASSET_PREVIEW_REQUEST,
    ],
    id: 'blackBordersBottom',
    label: 'common.asset.file_metadata.video.screen_size.black_borders.bottom',
    format: ValueFormat.AS_IS,
    input: {
      objectPath: 'file_metadata.video.screen_size.black_borders.bottom',
      type: FieldType.TEXT,
      readonly: true,
      validators: [],
    },
    results2: {
      exportObjectPath: 'black_borders.bottom',
      objectPath: 'props.black_borders.bottom',
      sortable: false,
    },
  },
  interlacingType: {
    isModified: false,
    isCustomDefinition: false,
    resource: [
      ResourceModel.ASSET_BROWSE,
      ResourceModel.ASSET_SHARED_PACK,
      ResourceModel.ASSET_ORDER,
      ResourceModel.ASSET_CART,
      ResourceModel.ASSET_PREVIEW_REQUEST,
    ],
    id: 'interlacingType',
    label: 'common.asset.file_metadata.video.screen_size.interlacing.analysis_type',
    format: ValueFormat.AS_IS,
    input: {
      objectPath: 'file_metadata.video.screen_size.interlacing.analysis_type',
      type: FieldType.TEXT,
      readonly: true,
      validators: [],
    },
    results2: {
      exportObjectPath: 'interlacing.analysis_type',
      objectPath: 'props.interlacing.analysis_type',
      sortable: false,
    },
    filters: {
      objectPath: 'video_interlacing_analysis_result',
      aggregationKey: 'video_interlacing_analysis_result',
      type: FilterType.CHECKBOX_LIST,
      validFormat: 'keyword',
    },
  },
  interlacingResult: {
    isModified: false,
    isCustomDefinition: false,
    resource: [
      ResourceModel.ASSET_BROWSE,
      ResourceModel.ASSET_SHARED_PACK,
      ResourceModel.ASSET_ORDER,
      ResourceModel.ASSET_CART,
      ResourceModel.ASSET_PREVIEW_REQUEST,
    ],
    id: 'interlacingResult',
    label: 'common.asset.file_metadata.video.screen_size.interlacing.analysis_result',
    format: ValueFormat.AS_IS,
    input: {
      objectPath: 'file_metadata.video.screen_size.interlacing.analysis_result',
      type: FieldType.TEXT,
      readonly: true,
      validators: [],
    },
    results2: {
      exportObjectPath: 'interlacing.analysis_result',
      objectPath: 'props.interlacing.analysis_result',
      sortable: false,
    },
  },
  interlacingTff: {
    isModified: false,
    isCustomDefinition: false,
    resource: [
      ResourceModel.ASSET_BROWSE,
      ResourceModel.ASSET_SHARED_PACK,
      ResourceModel.ASSET_ORDER,
      ResourceModel.ASSET_CART,
      ResourceModel.ASSET_PREVIEW_REQUEST,
    ],
    id: 'interlacingTff',
    label: 'common.asset.file_metadata.video.screen_size.interlacing.tff',
    format: ValueFormat.AS_IS,
    input: {
      objectPath: 'file_metadata.video.screen_size.interlacing.tff',
      type: FieldType.TEXT,
      readonly: true,
      validators: [],
    },
    results2: {
      exportObjectPath: 'interlacing.tff',
      objectPath: 'props.interlacing.tff',
      sortable: false,
    },
  },
  interlacingBff: {
    isModified: false,
    isCustomDefinition: false,
    resource: [
      ResourceModel.ASSET_BROWSE,
      ResourceModel.ASSET_SHARED_PACK,
      ResourceModel.ASSET_ORDER,
      ResourceModel.ASSET_CART,
      ResourceModel.ASSET_PREVIEW_REQUEST,
    ],
    id: 'interlacingBff',
    label: 'common.asset.file_metadata.video.screen_size.interlacing.bff',
    format: ValueFormat.AS_IS,
    input: {
      objectPath: 'file_metadata.video.screen_size.interlacing.bff',
      type: FieldType.TEXT,
      readonly: true,
      validators: [],
    },
    results2: {
      exportObjectPath: 'interlacing.bff',
      objectPath: 'props.interlacing.bff',
      sortable: false,
    },
  },
  interlacingProgressive: {
    isModified: false,
    isCustomDefinition: false,
    resource: [
      ResourceModel.ASSET_BROWSE,
      ResourceModel.ASSET_SHARED_PACK,
      ResourceModel.ASSET_ORDER,
      ResourceModel.ASSET_CART,
      ResourceModel.ASSET_PREVIEW_REQUEST,
    ],
    id: 'interlacingProgressive',
    label: 'common.asset.file_metadata.video.screen_size.interlacing.progressive',
    format: ValueFormat.AS_IS,
    input: {
      objectPath: 'file_metadata.video.screen_size.interlacing.progressive',
      type: FieldType.TEXT,
      readonly: true,
      validators: [],
    },
    results2: {
      exportObjectPath: 'interlacing.progressive',
      objectPath: 'props.interlacing.progressive',
      sortable: false,
    },
  },
  interlacingUndetermined: {
    isModified: false,
    isCustomDefinition: false,
    resource: [
      ResourceModel.ASSET_BROWSE,
      ResourceModel.ASSET_SHARED_PACK,
      ResourceModel.ASSET_ORDER,
      ResourceModel.ASSET_CART,
      ResourceModel.ASSET_PREVIEW_REQUEST,
    ],
    id: 'interlacingUndetermined',
    label: 'common.asset.file_metadata.video.screen_size.interlacing.undetermined',
    format: ValueFormat.AS_IS,
    input: {
      objectPath: 'file_metadata.video.screen_size.interlacing.undetermined',
      type: FieldType.TEXT,
      readonly: true,
      validators: [],
    },
    results2: {
      exportObjectPath: 'interlacing.undetermined',
      objectPath: 'props.interlacing.undetermined',
      sortable: false,
    },
  },
  interlacingRatio: {
    isModified: false,
    isCustomDefinition: false,
    resource: [
      ResourceModel.ASSET_BROWSE,
      ResourceModel.ASSET_SHARED_PACK,
      ResourceModel.ASSET_ORDER,
      ResourceModel.ASSET_CART,
      ResourceModel.ASSET_PREVIEW_REQUEST,
    ],
    id: 'interlacingRatio',
    label: 'common.asset.file_metadata.video.screen_size.interlacing.ratio',
    format: ValueFormat.AS_IS,
    input: {
      objectPath: 'file_metadata.video.screen_size.interlacing.ratio',
      type: FieldType.TEXT,
      readonly: true,
      validators: [],
    },
    results2: {
      exportObjectPath: 'interlacing.ratio',
      objectPath: 'props.interlacing.ratio',
      sortable: false,
    },
  },
  interlacingFrameTelecined: {
    isModified: false,
    isCustomDefinition: false,
    resource: [
      ResourceModel.ASSET_BROWSE,
      ResourceModel.ASSET_SHARED_PACK,
      ResourceModel.ASSET_ORDER,
      ResourceModel.ASSET_CART,
      ResourceModel.ASSET_PREVIEW_REQUEST,
    ],
    id: 'interlacingFrameTelecined',
    label: 'common.asset.file_metadata.video.screen_size.interlacing.is_4th_frame_telecined',
    format: ValueFormat.AS_IS,
    input: {
      objectPath: 'file_metadata.video.screen_size.interlacing.v',
      type: FieldType.TEXT,
      readonly: true,
      validators: [],
    },
    results2: {
      exportObjectPath: 'interlacing.is_4th_frame_telecined',
      objectPath: 'props.interlacing.is_4th_frame_telecined',
      sortable: false,
    },
  },
  interlacingTelecined: {
    isModified: false,
    isCustomDefinition: false,
    resource: [
      ResourceModel.ASSET_BROWSE,
      ResourceModel.ASSET_SHARED_PACK,
      ResourceModel.ASSET_ORDER,
      ResourceModel.ASSET_CART,
      ResourceModel.ASSET_PREVIEW_REQUEST,
    ],
    id: 'interlacingTelecined',
    label: 'common.asset.file_metadata.video.screen_size.interlacing.is_3_2_telecined',
    format: ValueFormat.AS_IS,
    input: {
      objectPath: 'file_metadata.video.screen_size.interlacing.is_3_2_telecined',
      type: FieldType.TEXT,
      readonly: true,
      validators: [],
    },
    results2: {
      exportObjectPath: 'interlacing.is_3_2_telecined',
      objectPath: 'props.interlacing.is_3_2_telecined',
      sortable: false,
    },
  },
  isUploaded: {
    isModified: false,
    resource: [
      ResourceModel.ASSET_BROWSE,
      ResourceModel.ASSET_ORPHAN,
      ResourceModel.LICENSED_PACKAGE,
      ResourceModel.ASSET_SHARED_PACK,
      ResourceModel.ASSET_ORDER,
      ResourceModel.ASSET_CART,
      ResourceModel.ASSET_PREVIEW_REQUEST,
    ],
    id: 'isUploaded',
    label: 'common.global.is_uploaded',
    format: ValueFormat.YES_NO,
    results2: {
      exportObjectPath: 'is_uploaded',
      objectPath: 'props.is_uploaded',
      sortable: false,
      viewFormat: {
        greyLineIf: 'false',
        pills: {
          true: 'done',
          false: 'white-dashed',
        },
        modifiers: {
          yesNo: true,
        },
      },
    },
  },
  assetStatus: {
    isModified: false,
    resource: [
      ResourceModel.ASSET_BROWSE,
      ResourceModel.ASSET_ORPHAN,
      ResourceModel.LICENSED_PACKAGE,
      ResourceModel.ASSET_SHARED_PACK,
      ResourceModel.ASSET_ORDER,
      ResourceModel.ASSET_CART,
      ResourceModel.ASSET_PREVIEW_REQUEST,
    ],
    id: 'assetStatus',
    label: 'common.global.asset_status',
    sourceListKey: SelectorSourceType.ASSET_STATUS,
    format: ValueFormat.SELECTOR,
    filters: {
      objectPath: 'is_uploaded',
      type: FilterType.SELECTOR,
      validFormat: 'keyword',
    },
  },
  coldStatus: {
    isModified: false,
    isCustomDefinition: false,
    resource: [
      ResourceModel.ASSET_BROWSE,
      ResourceModel.ASSET_ORPHAN,
      ResourceModel.LICENSED_PACKAGE,
      ResourceModel.ASSET_SHARED_PACK,
      ResourceModel.ASSET_ORDER,
      ResourceModel.ASSET_CART,
    ],
    id: 'coldStatus',
    label: 'common.global.storage_label',
    sourceListKey: SelectorSourceType.HOT_COLD,
    format: ValueFormat.AS_IS,
    filters: {
      objectPath: 'storage',
      type: FilterType.SELECTOR,
      validFormat: 'keyword',
    },
    results2: {
      exportObjectPath: 'storage',
      objectPath: 'props.storage',
      sortObjectPath: 'storage',
      sortable: true,
      viewFormat: {
        pills: {
          cold: 'cold-blue',
          hot: '',
        },
      },
    },
  },
  storageStatus: {
    isModified: false,
    isCustomDefinition: false,
    resource: [
      ResourceModel.ASSET_BROWSE,
      ResourceModel.ASSET_CART,
      ResourceModel.ASSET_PREVIEW_REQUEST,
      ResourceModel.ASSET_SHARED_PACK,
      ResourceModel.ASSET_ORDER,
    ],
    id: 'storageStatus',
    label: 'common.global.storage_status',
    format: ValueFormat.AS_IS,
    results2: {
      exportObjectPath: 'storage_status',
      objectPath: 'storageStatus',
      sortable: true,
      sortObjectPath: 'estimated_restore_at',
      viewFormat: {
        pills: {
          completed: '',
          default: 'done-light',
        },
        modifiers: {
          dateFormat: 'date-time',
          dateCountdown: {
            condition: (item: any) =>
              item.props.storage === 'cold' &&
              item.props.storage_status === 'active' &&
              !!item.props.estimated_restore_at,
          },
        },
      },
    },
  },
  transcodeProgress: {
    isModified: false,
    isCustomDefinition: false,
    resource: [ResourceModel.ASSET_ORDER],
    id: 'transcodeProgress',
    label: 'common.global.transcode_progress',
    format: ValueFormat.AS_IS,
    results2: {
      exportObjectPath: 'transcode_progress',
      sortable: false,
      objectPath: 'transcode_progress',
      viewFormat: {
        modifiers: {
          asPercentage: true,
        },
      },
    },
  },
  transcodeStatus: {
    isModified: false,
    isCustomDefinition: false,
    resource: [ResourceModel.ASSET_SHARED_PACK, ResourceModel.ASSET_ORDER],
    sourceListKey: SelectorSourceType.TRANSCODE_STATUS,
    id: 'transcodeStatus',
    label: 'common.global.transcode_status.label',
    format: ValueFormat.AS_IS,
    input: {
      type: FieldType.SELECT,
      readonly: true,
    },
    results2: {
      objectPath: 'transcodeStatus',
      exportObjectPath: 'transcode_status',
      sortObjectPath: 'transcode_status',
      sortable: false,
      viewFormat: {
        modifiers: {
          removeDashes: true,
        },
        pills: {
          not_initialized: 'white-dashed',
          submitted: 'yellow',
          'in-progress': 'yellow',
          inProgress: 'yellow',
          completed: 'done',
          'download ready': 'done',
          default: 'yellow',
          failed: 'failed',
        },
      },
    },
  },
  clippingStatus: {
    isModified: false,
    isCustomDefinition: false,
    resource: [ResourceModel.ASSET_ORDER, ResourceModel.ASSET_SHARED_PACK],
    id: 'clippingStatus',
    label: 'common.global.clipping_status.label',
    format: ValueFormat.AS_IS,
    results2: {
      exportObjectPath: 'clipping_status',
      objectPath: 'clippingStatus',
      sortable: true,
      sortObjectPath: 'clipping_status',
      viewFormat: {
        pills: {
          not_initialized: 'white-dashed',
          submitted: 'yellow',
          completed: 'done',
          'in-progress': 'yellow',
          'Download Ready': 'done',
          default: 'yellow',
          failed: 'failed',
        },
      },
    },
  },
  clipSalesforceName: {
    isModified: false,
    isCustomDefinition: false,
    resource: [ResourceModel.ASSET_ORDER, ResourceModel.ASSET_SHARED_PACK],
    id: 'clipSalesforceName',
    label: 'common.global.clip_salesforce_name',
    format: ValueFormat.AS_IS,
    input: {
      objectPath: 'clipSalesforceName',
      type: FieldType.TEXT,
      readonly: true,
    },
    results2: {
      exportObjectPath: 'clip_sales_force_name',
      objectPath: 'clipSalesforceName',
      sortable: true,
      sortObjectPath: 'clip_sales_force_name',
    },
  },
  transcriptions: {
    isModified: false,
    isCustomDefinition: false,
    resource: [
      ResourceModel.ASSET_BROWSE,
      ResourceModel.ASSET_ORPHAN,
      ResourceModel.LICENSED_PACKAGE,
      ResourceModel.ASSET_PREVIEW_REQUEST,
    ],
    id: 'transcriptions',
    filters: {
      objectPath: 'transcriptions',
      type: FilterType.CHIPS,
      validFormat: 'keyword',
    },
    results2: {
      exportObjectPath: 'transcriptions',
      objectPath: 'props.transcriptions',
      sortable: false,
      viewFormat: {
        maxVisibleValues: 1,
      },
    },
    label: 'common.asset.additional.transcriptions',
    format: ValueFormat.AS_IS,
  },
  packageTitle: {
    isModified: false,
    isCustomDefinition: false,
    resource: [
      ResourceModel.ASSET_BROWSE,
      ResourceModel.ASSET_ORPHAN,
      ResourceModel.LICENSED_PACKAGE,
      ResourceModel.ASSET_SHARED_PACK,
      ResourceModel.ASSET_ORDER,
      ResourceModel.ASSET_CART,
      ResourceModel.ASSET_PREVIEW_REQUEST,
    ],
    id: 'packageTitleUuid',
    filters: {
      objectPath: 'licensed_package_uuids',
      aggregationKey: 'licensed_package_uuids',
      type: FilterType.TEXT,
      validFormat: 'keyword',
    },
    label: 'common.asset.additional.package_title',
    format: ValueFormat.AS_IS,
  },
  deliveryPackName: {
    isModified: false,
    isCustomDefinition: false,
    resource: [
      ResourceModel.ASSET_BROWSE,
      ResourceModel.ASSET_ORPHAN,
      ResourceModel.LICENSED_PACKAGE,
      ResourceModel.ASSET_SHARED_PACK,
      ResourceModel.ASSET_ORDER,
      ResourceModel.ASSET_CART,
      ResourceModel.ASSET_PREVIEW_REQUEST,
    ],
    id: 'deliveryPackName',
    label: 'common.asset.additional.delivery_pack_name',
    format: ValueFormat.AS_IS,
    input: {
      objectPath: 'additional.delivery_pack_name',
      type: FieldType.TEXT,
      readonly: true,
      validators: [],
    },
    filters: {
      objectPath: 'delivery_pack_name',
      aggregationKey: 'delivery_pack_name',
      type: FilterType.TEXT_AUTOCOMPLETE,
      validFormat: 'keyword',
    },
    results2: {
      exportObjectPath: 'delivery_pack_name',
      objectPath: 'props.delivery_pack_name',
      sortable: false,
    },
    useAsFilename: true,
  },
};

export const assetFieldsArray = Object.values(assetFields);

export function useAsFilenameSelectOptions(fieldsPaths?: string[]): SelectOption[] {
  const selectOptions: SelectOption[] = assetFieldsArray.reduce((options, field) => {
    if (field.input?.objectPath && field.useAsFilename) {
      options.push({
        label: field.label,
        key: field.input.objectPath,
      });
    }
    return options;
  }, [] as SelectOption[]);

  if (!fieldsPaths || fieldsPaths.length === 0) {
    return selectOptions;
  }

  const uniqueFieldPaths = new Set(fieldsPaths);
  return selectOptions.filter((option) => uniqueFieldPaths.has(option.key?.toString() ?? ''));
}
