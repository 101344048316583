import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { FieldsOptionsService } from '@vdms-hq/api-contract';
import { map, switchMap, tap } from 'rxjs/operators';
import { Option } from '@vdms-hq/shared';

@Injectable()
export class SelectorsFetcher {
  constructor(private readonly fieldsOptionsService: FieldsOptionsService) {}
  refresh$ = new BehaviorSubject<void>(undefined);
  loading$ = new BehaviorSubject<boolean>(false);

  fields$ = this.refresh$.pipe(
    tap(() => this.loading$.next(true)),
    switchMap(() =>
      this.fieldsOptionsService.getTypes().pipe(
        map((field) => field.reduce((prev, curr): Option[] => [...prev, ...curr.fields], [] as Option[])),
        tap(() => this.loading$.next(false)),
      ),
    ),
  );
}
