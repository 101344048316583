import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'rename',
})
export class RenamePipe implements PipeTransform {
  transform = RenamePipe.transform;

  static transform = (value: string) => {
    if (!value) {
      return value;
    }
    switch (value?.toLowerCase()) {
      case 'created':
        return 'Initializing';
      case 'creating_stream':
        return 'Creating Stream';
      case 'creating_proxy':
        return 'Creating Proxy';
      case 'vida':
        return 'Vida';
      case 'launchpad':
        return 'Launchpad';
      case 'storefront':
        return 'Storefront';
      case 'connect2':
        return 'Connect2';
      case 'sharing_order':
        return 'Sharing Order';
      case 'delivery_destination':
        return 'Delivery Destination';
      case 'email_delivery':
        return 'Email Delivery';
      case 'warm_up':
        return 'Warm Up';
      case 'ai_processing':
        return 'AI Processing';
      default:
        return value;
    }
  };
}
