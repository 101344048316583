import { Injectable } from '@angular/core';
import { ApiPaginatedResponse, ApiResponse } from '../api.model';
import { ApiService } from '../api.service';
import { GetResponseData } from '../../operators/get-response-data.operator';
import {
  AnalyzeMetadataJobInterface,
  AnalyzeMetadataJobStatus,
  AnalyzeMetadataPickInput,
  AnalyzeMetadataRequestInput,
  METADATA_API_PREFIX,
  MetadataRestartInput,
} from './models/metadata-recognition.model';
import { Observable } from 'rxjs';
import { HttpHeaders } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class MetadataRecognitionApiService {
  constructor(private readonly apiService: ApiService) {}

  savePickedData(uuid: string, payload: AnalyzeMetadataPickInput) {
    return this.apiService
      .post<AnalyzeMetadataPickInput, ApiResponse<void>>(`${METADATA_API_PREFIX.ROOT}/${uuid}/pick`, payload)
      .pipe(GetResponseData);
  }

  restartMetadataRecognition(uuid: string, payload: MetadataRestartInput) {
    return this.apiService
      .post<AnalyzeMetadataRequestInput, ApiResponse<AnalyzeMetadataJobInterface[]>>(
        `${METADATA_API_PREFIX.ROOT}/${uuid}/restart`,
        payload,
      )
      .pipe(GetResponseData);
  }

  getMetadata(uuid: string, headers: HttpHeaders): Observable<ApiPaginatedResponse<AnalyzeMetadataJobInterface>> {
    return this.apiService.get<ApiPaginatedResponse<AnalyzeMetadataJobInterface>>(
      `${METADATA_API_PREFIX.ROOT}/${uuid}/data`,
      {
        headers,
      },
    );
  }

  getMetadataStatus(uuid: string): Observable<{ [key in AnalyzeMetadataJobStatus]: number }> {
    return this.apiService
      .get<ApiResponse<{ [key in AnalyzeMetadataJobStatus]: number }>>(`${METADATA_API_PREFIX.ROOT}/${uuid}/status`)
      .pipe(GetResponseData);
  }

  requestAnalyzeMetadataProcess(uuid: string, payload: AnalyzeMetadataRequestInput) {
    return this.apiService
      .post<AnalyzeMetadataRequestInput, ApiResponse<AnalyzeMetadataJobInterface[]>>(
        `${METADATA_API_PREFIX.ROOT}/${uuid}/request`,
        payload,
      )
      .pipe(GetResponseData);
  }
}
