import moment from 'moment-timezone';
import { ApprovalStatus, DeliveryDestinationJobStatusEnum, OrderAssetModel, OrderModel } from '@vdms-hq/api-contract';
import { SimpleDetailsList } from '@vdms-hq/ui';
import { AssetFlatView, AssetFlatView2 } from '@vdms-hq/asset-results';
import { DataProviderService } from '@vdms-hq/selectors';
import { StorageUrlService } from '@vdms-hq/storage';
import { ActivatedClientService } from '@vdms-hq/activated-client';
import { Timecode } from '@vdms-hq/timecode';

export enum OrderActionsProperties {
  APPROVE_ASSET = 'order.approve_asset',
  REJECT_ASSET = 'order.reject_asset',
  DOWNLOAD_CLEARANCE_NOTES = 'order.download_clearance_notes',
  PREVIEW_SALESFORCE_REQUEST = 'order.preview_salesforce_request',
  APPROVE_ORDER = 'order.approve_order',
  DOWNLOAD_ASPERA = 'order.download_aspera',
  ADD_TO_CART = 'order.add_to_cart',
  RETRY_DELIVERY_DESTINATIONS = 'order.retry_delivery_destinations',
  TRANSCODE_STATUS = 'order.transcode_status',
  RETRIGGER_CLIPPING = 'order.retrigger_clipping',
  FORCE_SALESFORCE_REQUEST = 'order.force_salesforce_request',
  REDELIVER_ORDER = 'order.redeliver_order',
  DOWNLOAD_ALL_ASPERA_FOLDERS = 'order.download_all_aspera_folders',
  DOWNLOAD_ALL_ASPERA = 'order.download_all_aspera',
  DOWNLOAD_DELIVERY_CONFIRMATION = 'order.download_delivery_confirmation',
  DOWNLOAD_MANY_DELIVERY_CONFIRMATION = 'order.download_many_delivery_confirmation',
}

export interface AssetFiltersForm {
  [key: string]: any;

  text?: string;
  facilityOfOrigin?: string;
  assetMasterType?: string[];
  txDate?: {
    from?: moment.Moment;
    to?: moment.Moment;
  };
}

export type OrderViewModel = OrderModel & {
  title?: string;
  metadata?: SimpleDetailsList[];
};

export type OrderAssetViewModel = AssetFlatView & OrderAssetModel;

export class OrderAssetViewModel2 extends AssetFlatView2 {
  constructor(
    public override props: OrderAssetModel['asset'],
    private originalOrder: OrderAssetModel,
    deps: {
      dataProvider: DataProviderService;
      storageUrlService: StorageUrlService;
      activatedClientService: ActivatedClientService;
    },
  ) {
    super(props, {
      dataProvider: deps.dataProvider,
      storageUrlService: deps.storageUrlService,
      activatedClientService: deps.activatedClientService,
    });
  }

  static fromOrderItem(
    props: OrderAssetModel,
    deps: {
      dataProvider: DataProviderService;
      storageUrlService: StorageUrlService;
      activatedClientService: ActivatedClientService;
    },
  ): OrderAssetViewModel2 {
    return new OrderAssetViewModel2(props.asset, props, deps);
  }

  get uuid() {
    return this.asset.uuid;
  }

  get orderUuid() {
    return this.originalOrder.uuid;
  }

  get asset() {
    return this.props;
  }

  get restoreStatus() {
    return this.props.storage === 'cold' ? 'warming up' : 'warm';
  }

  get approvalStatus(): ApprovalStatus {
    return this.originalOrder.approval_status;
  }

  get publishProgress() {
    return this.originalOrder?.publish_progress;
  }

  get publishStatus() {
    return this.originalOrder?.publish_status;
  }

  get storageStatus() {
    return this.props.storage_status === 'active' && this.props.storage === 'cold'
      ? this.props.estimated_restore_at
      : this.props.storage_status;
  }

  get isApproved(): boolean {
    return this.approvalStatus === ApprovalStatus.APPROVED;
  }

  get isRejected(): boolean {
    return this.approvalStatus === ApprovalStatus.REJECTED;
  }

  get approval_status() {
    return ApprovalStatus[this.originalOrder.approval_status];
  }

  get clippingStatus() {
    return this.originalOrder.clipping_status;
  }

  get clipSalesforceName() {
    return this.originalOrder.clip_sales_force_name;
  }

  get deliveryDestinations() {
    return this.originalOrder.delivery_destinations;
  }

  get downloadUuid() {
    return this.originalOrder.download_uuid;
  }

  override get timecodeIn() {
    if (this.originalOrder.timecode_in) {
      return Timecode.fromTimecode(this.originalOrder.timecode_in, this.framerate, false);
    }
    return super.timecodeIn;
  }

  override get timecodeOut() {
    if (this.originalOrder.timecode_out) {
      return Timecode.fromTimecode(this.originalOrder.timecode_out, this.framerate, false);
    }
    return super.timecodeOut;
  }

  get salesForceUri() {
    return this.originalOrder.sales_force_uri;
  }

  get context() {
    return { note: this.note };
  }

  get note() {
    return this.originalOrder.note;
  }

  get externalId() {
    return this.originalOrder.external_id;
  }

  get isAssetDownloadReady(): boolean {
    if (!this.deliveryDestinations?.length) {
      return true;
    }

    return this.deliveryDestinations.every((destination) =>
      destination.configs.every((config) => {
        const condition = config.jobs
          .filter((job) => job.type === 'transcode')
          .every((job) => !['not_initialized', 'started', 'submitted', 'failed'].includes(job.status));

        return condition;
      }),
    );
  }

  get clipping_status() {
    return this.originalOrder.clipping_status;
  }

  get transcode_progress() {
    return this.originalOrder.transcode_progress;
  }

  get transcodeStatus() {
    if (this.asset.storage === 'cold' && this.asset.storage_status === 'active') {
      return 'Awaiting restoration';
    }

    if (this.originalOrder.transcode_status) {
      return this.originalOrder.transcode_status;
    }
    if (!this.deliveryDestinations) {
      return this.clippingStatus ?? 'Download Ready';
    }

    return 'Download Ready';
  }

  get assetAvailableToRetrigger() {
    return this.deliveryDestinations?.some((dd) =>
      dd.configs.some((config) =>
        config.jobs.some((job) =>
          [DeliveryDestinationJobStatusEnum.SUBMITTED, DeliveryDestinationJobStatusEnum.STARTED].includes(job.status),
        ),
      ),
    );
  }
}
