<vdms-hq-ui-dialog-wrapper>
  <h1 title>{{ 'pages.fields.create_field' | translate }}</h1>
  <div class="fit-inputs dialog-content" content>
    <form [formGroup]="createSelectOptionForm" class="fit-inputs metadata-wrapper">
      <vdms-hq-ui-form-input-text
        formControlName="label"
        [label]="'pages.fields.columns.label' | translate"
      ></vdms-hq-ui-form-input-text>

      <vdms-hq-autocomplete
        formControlName="type_name"
        [label]="'pages.fields.columns.type' | translate"
        [autocompleteList]="typeSelectOptions"
      ></vdms-hq-autocomplete>
    </form>
  </div>

  <div footer [align]="'end'">
    <button mat-raised-button color="secondary" (click)="cancel()">{{ 'common.global.cancel' | translate }}</button>
    <button [disabled]="!createSelectOptionForm.valid" mat-raised-button color="primary" (click)="save()">
      {{ 'common.global.save' | translate }}
    </button>
  </div>
</vdms-hq-ui-dialog-wrapper>
