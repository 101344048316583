import { ChangeDetectionStrategy, Component, forwardRef, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR, UntypedFormControl } from '@angular/forms';
import moment from 'moment';
import { noop } from 'rxjs';
import { FormControlValueAccessorComponent } from '../../models/form/inputs/form-control-value-accessor.component';

type OuterValue = string | number | Date | null | undefined;
type InnerValue = string | null;

@Component({
  selector: 'vdms-hq-ui-form-input-date',
  templateUrl: './form-input-date.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => FormInputDateComponent),
    },
  ],
})
export class FormInputDateComponent extends FormControlValueAccessorComponent<OuterValue, InnerValue> {
  override innerFormControl = new UntypedFormControl(null);

  @Input() format: 'utc' | 'local' = 'local';
  @Input() dateChangeFn: (dateChangeValue: unknown) => void = noop;
  @Input() dateInputFn: (dateInputValue: unknown) => void = noop;

  @Input() minDate?: string;
  @Input() maxDate?: string;

  get isUtcFormat() {
    return this.format === 'utc';
  }

  override transformInnerToOuter(value: InnerValue) {
    const innerValue = moment(value);

    if (this.isUtcFormat) {
      return innerValue.isValid()
        ? moment.utc(innerValue.toISOString(true).substr(0, 10)).startOf('day').toISOString()
        : null;
    }

    return innerValue.toISOString();
  }

  override transformOuterToInner(value: OuterValue): InnerValue {
    if (!value) {
      return null;
    }

    if (this.outerValue) {
      return this.outerValue as unknown as InnerValue;
    }

    const innerValue = moment(value);

    if (!innerValue.isValid()) {
      return null;
    }

    return innerValue.format('YYYY-MM-DDT00:00:00');
  }
}
