<vdms-hq-ui-dialog-wrapper>
  <h1 title>{{ title | translate }} {{ editPackages.value ? '- ' + (editPackagesButton | translate) : '' }}</h1>
  <div content class="relative" [formGroup]="form" *ngIf="(editPackages | async) === false; else editPackagesView">
    <vdms-hq-ui-form-section layout="single" [divider]="false">
      <vdms-hq-ui-form-input-text
        formControlName="name"
        [label]="'common.dialogs.rights_contracts.create.fields.name' | translate"
      ></vdms-hq-ui-form-input-text>
    </vdms-hq-ui-form-section>
    <vdms-hq-ui-form-section layout="grid-2-columns" [divider]="false">
      <vdms-hq-ui-form-input-date
        formControlName="start_date"
        format="utc"
        [label]="'common.dialogs.rights_contracts.create.fields.start_date' | translate"
      ></vdms-hq-ui-form-input-date>
      <vdms-hq-ui-form-input-date
        formControlName="end_date"
        format="utc"
        [label]="'common.dialogs.rights_contracts.create.fields.end_date' | translate"
      ></vdms-hq-ui-form-input-date>
      <vdms-hq-ui-form-input-text
        formControlName="contract_id"
        [label]="'common.dialogs.rights_contracts.create.fields.contract_id' | translate"
      ></vdms-hq-ui-form-input-text>
      <vdms-hq-ui-form-input-select
        [disabled]="partnersOptions.length === 0"
        [label]="'common.dialogs.rights_contracts.create.fields.partners' | translate"
        [selectOptions]="partnersOptions"
        [loading]="rightsPartnersAddDsService.isLoading$ | async"
        formControlName="partner_uuid"
      >
      </vdms-hq-ui-form-input-select>
    </vdms-hq-ui-form-section>

    <div class="d-flex flex-col justify-content-end">
      <vdms-hq-ui-form-input-checkbox
        formControlName="notifications_enabled"
        [label]="'common.dialogs.rights_contracts.create.fields.notifications_enabled' | translate"
      ></vdms-hq-ui-form-input-checkbox>
      <vdms-hq-ui-form-input-checkbox
        [formControl]="readyToReleaseControl"
        [label]="'common.dialogs.rights_contracts.create.set_ready_to_release' | translate"
      ></vdms-hq-ui-form-input-checkbox>
    </div>
  </div>
  <ng-template #editPackagesView>
    <div class="relative">
      <vdms-hq-ui-loader
        *ngIf="licensedPackagesAddDsService.isLoading$ | async"
        [backdrop]="true"
        mode="over-parent"
      ></vdms-hq-ui-loader>
      <form
        [formGroup]="form"
        *ngIf="licensedPackagesAddDsService.connection$ | async; let options"
        class="form-content"
      >
        <vdms-hq-dynamic-form-group-renderer
          [form]="licensedPackagesAddDsService.filters"
          [fieldsConfig]="filtersConfig"
          [hasLoader]="true"
          [fullWidth]="true"
        ></vdms-hq-dynamic-form-group-renderer>
        <mat-divider></mat-divider>
        <vdms-hq-ui-add-dialog-selectable-tiles
          [config]="licensedPackagesTilesConfig"
          [items]="licensedPackagesAddDsService.connection$ | async"
          [selected]="selectedLicensePackagesControl.value"
          [loading$]="licensedPackagesAddDsService.isLoading$"
          (selectedChange)="selectLicensedPackage($event)"
        ></vdms-hq-ui-add-dialog-selectable-tiles>

        <vdms-hq-ui-empty-results
          *ngIf="licensedPackagesAddDsService.emptyResults$ | async"
          class="data__results-empty"
          [message]="'Could not find any licensed packages'"
          [type]="'compact'"
          [icon]="'folder_special'"
        ></vdms-hq-ui-empty-results>

        <mat-paginator
          [pageSizeOptions]="licensedPackagesAddDsService.pageSizeOptions"
          [length]="licensedPackagesAddDsService.total$ | async"
          [pageIndex]="licensedPackagesAddDsService.pageIndex$ | async"
          [pageSize]="licensedPackagesAddDsService.pageSize$ | async"
          (page)="licensedPackagesAddDsService.pageChange($event)"
          [showFirstLastButtons]="true"
        >
        </mat-paginator>
      </form>
    </div>
  </ng-template>
  <div footer>
    <vdms-hq-ui-button color="secondary" (click)="closeDialog()">
      {{ 'common.global.cancel' | translate }}
    </vdms-hq-ui-button>
    <vdms-hq-ui-button color="secondary" (click)="toggleEditPackages()">
      {{
        editPackages.value
          ? ('common.dialogs.rights_contracts.create.back_to_details' | translate)
          : (editPackagesButton | translate)
      }}
    </vdms-hq-ui-button>
    <vdms-hq-ui-button color="primary" [loading]="isLoading$ | async" (click)="onSubmit()" [disabled]="form.invalid">
      {{ saveButton | translate }}
    </vdms-hq-ui-button>
  </div>
</vdms-hq-ui-dialog-wrapper>
