import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Filters } from '@vdms-hq/api-contract';
import { filterArrayModified, filterObjectModified } from '../../utils/filter-modification';

@Component({
  selector: 'vdms-hq-applied-dynamic-filters',
  templateUrl: './applied-dynamic-filters.component.html',
  styleUrls: ['./applied-dynamic-filters.component.scss'],
})
export class AppliedDynamicFiltersComponent {
  @Input() filters: Filters = {};
  @Input() configKey: string | undefined;
  @Output() changed = new EventEmitter<Filters>();

  get emptyQueryParams(): boolean {
    return Object.values(this.filters || {}).length === 0;
  }

  isQuarantineOrDeleted() {
    return this.configKey == 'deleted' || this.configKey == 'quarantined';
  }

  canDeleteFilter(filter: { key: string }) {
    if (filter.key == 'isQuarantined' && this.configKey == 'quarantined') {
      return false;
    }
    if (filter.key == 'isDeleted' && this.configKey == 'deleted') {
      return false;
    }
    return true;
  }

  onRemove<T>(valueToRemove: T, key: string, index: number): void {
    this.#removeValueFromQueryParams(key, valueToRemove, index);
    this.changed.emit(this.filters);
  }

  onClearAll(): void {
    this.changed.emit({});
  }

  #removeValueFromQueryParams<T>(key: string, valueToRemove: T, index: number): void {
    const { filterDef, value } = this.filters?.[key] as any;

    const modifiedArrayFilter = filterArrayModified(value, valueToRemove);
    if (modifiedArrayFilter) {
      (this.filters as Filters)[key] = {
        filterDef: filterDef,
        value: modifiedArrayFilter as [],
      };
      return;
    }
    const modifiedObjectFilter = filterObjectModified(value, index);
    if (modifiedObjectFilter) {
      (this.filters as Filters)[key].value = { ...value, ...modifiedObjectFilter };
      return;
    }

    this.#resetPrimitiveFilter(key);
  }

  #resetPrimitiveFilter(key: string): void {
    const { [key]: _, ...modifiedFilters } = this.filters as Filters;
    this.filters = modifiedFilters;
  }
}
