import { Component, inject, OnDestroy } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DynamicFilterInput, DynamicFiltersModule } from '@vdms-hq/dynamic-filters';
import { TranslateModule } from '@ngx-translate/core';
import {
  ActivatedClientModule,
  ActivatedClientService,
  Permission,
  PermissionService,
  WithPermissions,
} from '@vdms-hq/activated-client';
import { ApiContractModule, CollectionModelFlat } from '@vdms-hq/api-contract';
import { combineLatest, Observable, startWith, Subject, switchMap, take, takeUntil } from 'rxjs';
import {
  ActionContextLess,
  DataAction as Action,
  DataPresentationHeaderComponent,
  DefaultMultiViewType,
  FloatingControlsComponent,
  MultipleDataPresentationComponent,
  MultipleViewConfiguration,
  UIButtonModule,
  UiEmptyCartModule,
  UIEmptyResultsModule,
  UIInfinityLoaderModule,
  UILayoutModule,
  UILoaderModule,
  UILoadMoreModule,
  UISimpleDetailsListModule,
} from '@vdms-hq/ui';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AuthService } from '@vdms-hq/auth';
import { CollectionsActionsService } from '../../logic/services/collections-actions.service';
import { map, withLatestFrom } from 'rxjs/operators';
import { CollectionsMultiDs } from '../../logic/datasources/collections-multi-ds.service';
import { Router } from '@angular/router';
import { COLLECTIONS_ROUTER_BASE } from './../collections-routes';
import { AddToCartActionsService, CartStateService } from '@vdms-hq/cart-core';
import { TABLE_TYPE, ViewSettingsService } from '@vdms-hq/view-settings';
import { STATUS_OPTIONS } from '../../logic/utils/collections-filters-provider';
import { defaultFilters } from './config';

@Component({
  selector: 'vdms-hq-collections',
  templateUrl: './collections.component.html',
  imports: [
    CommonModule,
    DynamicFiltersModule,
    TranslateModule,
    ActivatedClientModule,
    ApiContractModule,
    UILayoutModule,
    UIInfinityLoaderModule,
    UISimpleDetailsListModule,
    UIButtonModule,
    MatTooltipModule,
    FloatingControlsComponent,
    UILoaderModule,
    UILoadMoreModule,
    UiEmptyCartModule,
    UIEmptyResultsModule,
    MultipleDataPresentationComponent,
    DataPresentationHeaderComponent,
  ],
  standalone: true,
})
export class CollectionsComponent extends WithPermissions() implements OnDestroy {
  private auth = inject(AuthService);
  private viewSettingsService = inject(ViewSettingsService);
  private cartStateService = inject(CartStateService);
  public collectionsActions = inject(CollectionsActionsService);
  public addToCartActionsService = inject(AddToCartActionsService);
  public multiDataSource = inject(CollectionsMultiDs);
  private activatedClientService = inject(ActivatedClientService);
  private permissionService = inject(PermissionService);
  private router = inject(Router);

  filtersConfig: DynamicFilterInput[] = [];
  title = 'common.title';
  userUuid = '';
  #destroyed$: Subject<void> = new Subject<void>();

  #actions: Observable<Action<CollectionModelFlat>[]> = this.cartStateService.isEnabled$.pipe(
    startWith(false),
    withLatestFrom(
      this.cartStateService.isClipOnlyAndPermitted$,
      this.permissionService.verifyWithOwnedPermissions$([Permission.SHARE_COLLECTIONS]),
      this.auth.id$,
    ),
    switchMap(([isDisabled, permitted, permittedToShare, userUuid]) => {
      let actions: Action<CollectionModelFlat>[] = permittedToShare
        ? [
            {
              key: 'share',
              icon: 'share',
              onDoubleClick: false,
              label: 'common.global.share',
              hiddenIf: (element: CollectionModelFlat) => element.owner !== userUuid,
            },
          ]
        : [];

      if (permitted) {
        actions.push({
          key: 'cart',
          icon: 'add_shopping_cart',
          onDoubleClick: false,
          label: 'common.global.add_to_cart',
          disabledIf: () => isDisabled,
        });
      }

      return this.permissionService
        .filterItemsAgainstPermissions$([
          {
            key: 'collection',
            icon: 'playlist_add',
            onDoubleClick: false,
            label: 'common.global.add_to_collection',
            permissions: { ids: [Permission.EDIT_COLLECTIONS] },
            hiddenIf: (element: CollectionModelFlat) => element.access_type === 'dashboard',
          },
          {
            key: 'edit',
            icon: 'edit',
            onDoubleClick: false,
            label: 'common.global.edit',
            permissions: { ids: [Permission.EDIT_COLLECTIONS] },
            hiddenIf: (element: CollectionModelFlat) => element.owner !== userUuid,
          },
          {
            key: 'delete',
            icon: 'delete',
            onDoubleClick: false,
            label: 'common.global.delete',
            permissions: { ids: [Permission.DELETE_COLLECTIONS] },
            hiddenIf: (element: CollectionModelFlat) => element.owner !== userUuid,
          },
          {
            key: 'delete',
            icon: 'delete',
            onDoubleClick: false,
            label: 'common.global.delete_shared',
            permissions: { ids: [Permission.DELETE_COLLECTIONS] },
            hiddenIf: (element: CollectionModelFlat) => element.owner === userUuid,
          },
        ])
        .pipe(
          take(1),
          map((config) => {
            actions = [...(actions || []), ...config];
            actions.push({
              key: 'preview',
              label: 'common.global.open_collection',
              icon: 'visibility',
              onDoubleClick: true,
            });

            return actions;
          }),
        );
    }),
  );

  collectionConfiguration$: Observable<MultipleViewConfiguration<CollectionModelFlat>> = combineLatest([
    this.activatedClientService.permissions$,
    this.auth.authDefinite$,
    this.viewSettingsService.defaultViewFor$(TABLE_TYPE.COLLECTION),
    this.#actions,
  ]).pipe(
    takeUntil(this.#destroyed$),
    map(([permissions, auth, multiViewType, actions]) => {
      this.multiDataSource.filters.controls['keyword'].reset();
      this.userUuid = auth.id;
      this.filtersConfig = defaultFilters;
      if (!permissions.includes(this.Permission.BROWSE_SHARED_COLLECTIONS)) {
        this.filtersConfig = this.filtersConfig.map((f) => {
          if (f.id == 'collectionType') {
            return {
              ...f,
              selectOptions: STATUS_OPTIONS.filter((opt) => {
                return !['all', 'shared'].includes(opt.key);
              }),
            };
          }
          return f;
        });
      }

      return {
        multiView: {
          pagination: true,
          defaultView: multiViewType,
          emptyResults: {
            message: 'Could not find any collections',
            icon: 'insights',
          },
        },
        gridAdvanced: {
          actions: actions,
          content: {
            titlePath: 'name',
            metadata: [
              {
                label: 'common.collection_type',
                valuePath: 'access_type',
                viewFormat: {
                  modifiers: {
                    textTransform: 'capitalize',
                  },
                },
              },
              {
                label: 'common.collection_owner',
                valuePath: 'owner_name',
                hiddenIf: (item: CollectionModelFlat) => item.access_type === 'owned',
              },
              {
                label: 'common.assets_number',
                valuePath: 'number_of_assets',
                fallback: 'Empty',
              },
              {
                label: 'common.created_at',
                valuePath: 'created_at',
                viewFormat: {
                  modifiers: {
                    dateFormat: 'date-time',
                  },
                },
              },
              {
                label: 'common.updated_at',
                valuePath: 'updated_at',
                viewFormat: {
                  modifiers: {
                    dateFormat: 'date-time',
                  },
                },
              },
              {
                label: 'common.description',
                valuePath: 'description',
                fullLine: true,
                fallback: 'N/A',
              },
            ],
          },
          image: {
            bgPath: 'custom_cover_path',
            bgHoverPath: 'custom_cover_path',
          },
        },
        gridTiles: {
          actions: actions,
          layout: {
            columns: 'up-to-6',
          },
          content: {
            titlePath: 'name',
            metadata: [
              {
                label: 'common.assets_number',
                valuePath: 'number_of_assets',
                fallback: 'Empty',
              },
              {
                label: 'common.collection_type',
                valuePath: 'access_type',
              },
              {
                label: 'common.collection_owner',
                valuePath: 'owner_name',
                hiddenIf: (item: CollectionModelFlat) => item.access_type !== 'shared',
              },
            ],
          },
          image: {
            bgPath: 'custom_cover_path',
            bgHoverPath: 'custom_cover_path',
          },
        },
        tableAdvanced: {
          actions: actions,
          columnsEnabled: [
            'name',
            'description',
            'owner_type',
            'owner_name',
            'number_of_assets',
            'created_at',
            'updated_at',
            'actions',
          ],
          columns: [
            {
              id: 'name',
              label: 'common.collection_name',
              valuePath: 'name',
              sortable: true,
              viewFormat: {
                modifiers: {
                  truncateCharacters: 50,
                },
              },
            },
            {
              id: 'description',
              label: 'common.description',
              valuePath: 'description',
              viewFormat: {
                modifiers: {
                  truncateCharacters: 50,
                },
              },
            },
            {
              id: 'owner_type',
              label: 'common.collection_type',
              valuePath: 'owner_type',
            },
            {
              id: 'owner_name',
              label: 'common.collection_owner',
              valuePath: 'owner_name',
            },
            {
              id: 'number_of_assets',
              label: 'common.assets_number',
              valuePath: 'number_of_assets',
              fallback: 'Empty',
            },
            {
              id: 'created_at',
              label: 'common.created_at',
              valuePath: 'created_at',
              viewFormat: {
                modifiers: {
                  dateFormat: 'date-time',
                },
              },
            },
            {
              id: 'updated_at',
              label: 'common.updated_at',
              valuePath: 'updated_at',
              viewFormat: {
                modifiers: {
                  dateFormat: 'date-time',
                },
              },
            },
            {
              id: 'actions',
              type: 'actions',
            },
          ],
        },
      };
    }),
  );

  headerActions$: Observable<ActionContextLess[]> = this.permissionService
    .verifyWithOwnedPermissions$([Permission.CREATE_COLLECTIONS])
    .pipe(
      map((hasPermission) =>
        hasPermission
          ? [
              {
                key: 'create',
                label: 'pages.lists.create_new',
              },
            ]
          : [],
      ),
    );

  get collectionType(): string {
    return this.multiDataSource.filters.controls['collectionType'].value || 'all';
  }

  ngOnDestroy(): void {
    this.#destroyed$.next();
    this.#destroyed$.complete();
  }

  setPreferredTableView(view: DefaultMultiViewType) {
    this.viewSettingsService.saveDefaultViewFor(TABLE_TYPE.COLLECTION, view);
  }

  customActionHandler(key: string, element?: CollectionModelFlat) {
    switch (key) {
      case 'create':
        this.collectionsActions.openCreateDialog();
        break;
    }

    if (!element) {
      return;
    }

    switch (key) {
      case 'preview':
        this.router.navigate([COLLECTIONS_ROUTER_BASE.COLLECTIONS, element.uuid]).then();
        break;
      case 'delete':
        this.collectionsActions.deleteCollection(element.uuid, element.name, element.owner !== this.userUuid);
        break;
      case 'edit':
        this.collectionsActions.openEditDialog(element);
        break;
      case 'share':
        this.collectionsActions.openShareDialog(element);
        break;
      case 'export':
        this.collectionsActions.exportCollection(element.uuid);
        break;
      case 'cart':
        this.addToCartActionsService.addCollections([element.uuid]);
        break;
      case 'collection':
        this.collectionsActions.addToCollection(element.uuid);
        break;
    }
  }
}
