import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { ThemePalette } from '@angular/material/core';

export type ButtonColors = 'primary' | 'secondary' | 'warn' | 'accent' | 'transparent' | 'primary-transparent';
export enum ButtonColorsEnum {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  WARN = 'warn',
  TRANSPARENT = 'transparent',
}

@Component({
  selector: 'vdms-hq-ui-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    '[class.disabled]': 'disabled',
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonComponent implements OnInit {
  @Input() color: ButtonColors = 'transparent';
  @Input() disabled = false;
  @Input() icon?: string;
  @Input() iconColor?: string;
  @Input() iconOnly = false;
  @Input() loading = false;
  @Input() tooltip = '';
  @Input() isSubmit = false;
  // todo: remove fieldset after refactoring preview player controls
  @Input() size: 'small' | 'medium' | 'fieldset' | 'full' | undefined = undefined;
  @Input() asDownloadLink = false;

  matColor: ThemePalette;
  isMatFlatBtn!: boolean;
  isMatBtn!: boolean;

  ngOnInit() {
    this.isMatFlatBtn = this.color !== 'transparent';
    this.isMatBtn = this.color === 'transparent';

    switch (this.color) {
      case 'primary':
        this.matColor = 'primary';
        break;
      case 'secondary':
        this.matColor = 'accent';
        break;
      case 'warn':
        this.matColor = 'warn';
        break;
    }
  }
}
